import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import * as jspdf from 'jspdf';
import $ from "jquery";
import html2canvas from 'html2canvas';
import * as moment from 'moment';

@Component({
  selector: 'app-all-camera-list',
  templateUrl: './all-camera-list.component.html',
  styleUrls: ['./all-camera-list.component.css']
})
export class AllCameraListComponent implements OnInit {
  date;
  type='on';
  allCam;
  loader:boolean=true;
  camStaus;
  constructor(
    private router: Router,
    private centerService: CenterDetailsService
  ) { }

  onClickBack(){
    this.router.navigate(['/cCSearch']);
  }

  allOnCamera(){
    this.loader= true;
    this.centerService.getAllCamDet().subscribe((allCam:any) =>{
      //console.log(allCam);
      this.allCam = allCam.cameraDetails.filter(cam => cam.isCameraActive === '1');
      //console.log(this.allCam)
      this.loader=false;
      this.camStaus = 'on';
    });
  }

  allOffCamera(){
    this.loader= true;
    this.centerService.getAllCamDet().subscribe((allCam:any) =>{
      //console.log(allCam);
      this.allCam = allCam.cameraDetails.filter(cam => cam.isCameraActive === '0');
      //console.log(this.allCam)
      this.loader= false;
      this.camStaus = 'off';
    });
  }

  onClickPdf() {
    var HTML_Width = $(".output-print").width();
    var HTML_Height = $(".output-print").height();
    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;
    var todayDate = moment();
    var timeStamp = todayDate.format('YYYYMMDDHHmmss')
    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;


    html2canvas($(".output-print")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');


      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      var pdf = new jspdf('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(PDF_Width, PDF_Height);
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      pdf.save(timeStamp + ".pdf");
    });
  }

  ngOnInit() {

    this.date = moment().format('YYYY-MM-DD');
    this.centerService.getAllCamDet().subscribe((allCam:any) =>{
      //console.log(allCam);
      this.allCam = allCam.cameraDetails.filter(cam => cam.isCameraActive === '1');
      this.camStaus = 'on';
      this.loader=false;
    });
  }

}
