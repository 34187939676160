import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './authService/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ImageCaptureService {

  options;
  domain = this.authService.domain;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  /* ===============================================================
      Authentication Header
  =============================================================== */
  createAuthenticationHeaders() {
    this.authService.loadToken();
      this.options = { headers : new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'authorization': this.authService.authToken
      })
    }
  }
  
  imageCapture(imageData) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'image/imageCapture', imageData, this.options);
  }

  getAllImages(dateImage, streamName) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'image/allImages/' + dateImage + '/' + streamName, this.options);
  }
}
