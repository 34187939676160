import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../authService/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PiaServiceService {

  options;
  domain = this.authService.domain;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
  ) { }

  /* ===============================================================
      Authentication Header
  =============================================================== */
  createAuthenticationHeaders() {
    this.authService.loadToken();
      this.options = { headers : new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'authorization': this.authService.authToken
      })
    }
  }

  /* ===============================================================
      Create New PIA
  =============================================================== */
  createPia(data){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'tpDetail/createNewTp', data, this.options);
  }

  /* ===============================================================
      Scheme Assignment
  =============================================================== */
  schemeAssignment(data){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'tpDetail/schemeAssignment', data, this.options);
  }

  /* ===============================================================
      Get PIA Details
  =============================================================== */
  getPia(accessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'tpDetail/getOneTp/' + accessId, this.options);
  }

  /* ===============================================================
      Get All PIA Details
  =============================================================== */
  getAllPia(schemeAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'tpDetail/getAllTp/' + schemeAccessId, this.options);
  }

  /* ===============================================================
      PIA/TP Dashboard Side Count
  =============================================================== */
  getTPSideCount(tpAccessId, date) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getTPSideCount/' + tpAccessId + '/' + date, this.options);
  }

  /* ===============================================================
      PIA/TP Dashboard Main Count
  =============================================================== */
  getTPMainCount(tpAccessId, schemeAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getTPSCBySchemes/' + tpAccessId + '/' + schemeAccessId, this.options);
  }

  /* ===============================================================
      Get Attendance Employee Data Count 
  =============================================================== */
  getAttenDataCountEmpByTp(tpAccessId, today) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getAttenDataCountEmpByTp/' + tpAccessId + '/' + today, this.options);
  }

  /* ===============================================================
      Get Attendance Teacher Data Count 
  =============================================================== */
  getAttenDataCountTeachByTp(tpAccessId, today) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getAttenDataCountTeachByTp/' + tpAccessId + '/' + today, this.options);
  }

    /* ===============================================================
      Get Attendance Student Data Count 
  =============================================================== */
  getAttenDataCountStuByTp(tpAccessId, today) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getAttenDataCountStuByTp/' + tpAccessId + '/' + today, this.options);
  }
  /* ===============================================================
      Get All Pia
  =============================================================== */
  getAllTP() {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'tpDetail/getTpList', this.options);
  }

  /* ===============================================================
      Edit PIA
  =============================================================== */
  editUpdatePia(data) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'tpDetail/updateTpAllDetails', data, this.options);
  }

}
