import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { AuthService } from 'src/app/services/authService/auth.service';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.css']
})
export class ResourceComponent implements OnInit {

  now;
  todayDate;
  accessId;
  courseDetails;
  centerAccessID;
  courseAccessId;
  courseAccessID;
  createResSec:boolean;
  centerDetails;
  allResourcesData;
  roleId;
  allResouces;
  resourcesType;
  studentLen;
  teacherLen;

  courseName;
  selectStep;
  allBatch;
  batchAccessId;
  batchCode;
  lHintCourse;
  lDataCourse;
  lHinttextCourse;
  lHintBatch;
  lDataBatch;
  lHinttextBatch;

  search;
  courseCss= 'hide';
  batchCss= 'hide';
  resourceMar= 'hide';

  secFilter = 'left-p-bar';
  fLocation = 'hide';
  allCourseList;
  allBatchList='hide';
  rightSec = 'expand';
  header = 'expand-h'
  allUnit;
  unitCode;
  resCount;

  activeMenuStudent;
  activeMenuTeacher;
  activeMenuEmployee;
  
  testing:boolean;
  inputData;

  constructor(
    private router: Router,
    private authService: AuthService,
    private centerService: CenterDetailsService
  ) { }

  /* ===============================================================
       Profile Fetch
   =============================================================== */
   fetchProfile() {
    this.accessId = localStorage.getItem('UserInfo');
    this.authService.getProfile(this.accessId).subscribe((profile: any) => {
      //console.log(profile);
      this.roleId = profile.user.roleId;
      if(profile.user.roleId == 77){
        this.centerAccessID = profile.user.accessId;
        this.createResSec = true;
      } else if(profile.user.roleId == 0o0 ){
        this.centerAccessID = localStorage.getItem('UserInfoCenterAccessID');
        this.createResSec = true;
      }else{
        this.centerAccessID = localStorage.getItem('UserInfoCenterAccessID');
        this.createResSec = false;
      }
      this.centerService.getCenter(this.centerAccessID).subscribe((centerDetail:any) =>{
        //console.log(centerDetail);
        this.centerDetails = centerDetail.centerDetails;
        //console.log(this.centerDetails)
        this.courseDetails = centerDetail.centerDetails.associatedCourseInfo;
      });
      // this.centerService.allCourse(this.centerAccessID).subscribe((data: any) => {
      //   this.courseDetails = data.courseDetails.sort((a, b) => a.courseName > b.courseName ? 1 : -1);
      //   //console.log(this.courseDetails)
      // });
      this.centerService.allResourcesCount(this.centerAccessID).subscribe((resCount:any)=>{
        //console.log(resCount);
        this.resCount = resCount;
      });
    });
  }

  onClickStudent(){
    this.centerService.allStudents(this.centerAccessID).subscribe((resources:any) =>{
      this.allResourcesData = resources.resourceDetails.filter(res => res.resourceType === 'S');
      this.allResouces = this.allResourcesData.sort((a, b) => a.resourceName > b.resourceName ? 1 : -1);
      this.secFilter = 'left-p-s-bar';
      this.fLocation = 'show';
      this.allCourseList = 'show';
      this.allBatchList = 'hide';
      this.rightSec = '';
      this.header = '';
      this.lHintCourse = 'hint-select';
      this.activeMenuStudent = 'active-menu';
      this.activeMenuEmployee = '';
      this.activeMenuTeacher = '';
      this.resourceMar = 'show';
      this.courseCss = 'hide';
      this.batchCss = 'hide';
      this.selectStep = 'student';
      this.search ='';
      this.testing = false;
    })
  }
  
  onSelectCourse(course) {
    this.courseName = course.courseName;
    this.courseAccessId = course.courseAccessId;
    this.selectStep = 'student';
    this.centerService.courseBasicResources(this.centerAccessID, this.courseAccessId).subscribe((resources: any) => {
      this.allResourcesData = resources.resourceDetails.filter(res => res.resourceType === 'S');
      this.allResouces = this.allResourcesData.sort((a, b) => a.resourceName > b.resourceName ? 1 : -1);
      this.centerService.getAllBatch(this.centerAccessID, course.courseAccessId).subscribe((allBatch: any) => {
        this.allBatch = allBatch.batchDetails.sort((a, b) => a.batchCode > b.batchCode ? 1 : -1);
        //console.log(this.allBatch);
        this.allCourseList = 'hide';
        this.allBatchList = 'show';
        this.batchAccessId = null;
        this.batchCode = null;
        this.lHintCourse = 'hint-select';
        this.lDataCourse = 'l-data';
        this.lHinttextCourse = 'bottom-text';
        this.lHintBatch = '';
        this.lDataBatch = '';
        this.lHinttextBatch = '';

        this.search = '';
        this.courseCss = 'show';
        this.resourceMar = 'show'
        this.batchCss = 'hide';
        this.testing = false;
      });
    });
  }

  onSelectBatch(batch) {
    this.batchCode = batch.batchCode;
    this.batchAccessId = batch.accessId;
    this.selectStep = 'student';
    this.centerService.batchBasicResources(this.centerAccessID, this.batchAccessId).subscribe((resources: any) => {
      this.allResourcesData = resources.resourceDetails.filter(res => res.resourceType === 'S');
      this.allResouces = this.allResourcesData.sort((a, b) => a.resourceName > b.resourceName ? 1 : -1);
      this.lHintBatch = 'hint-select';
      this.lDataBatch = 'l-data';
      this.lHinttextBatch = 'bottom-text';
      this.batchCss = 'show';
      this.resourceMar = 'show';
      this.search ='';
      this.testing = false;
    });
  }

  onClickCourse(){
    this.allCourseList = 'show';
    this.allBatchList = 'hide';
  }

  onClickUnit(){
    this.allCourseList = 'hide';
    this.allBatchList = 'show';
  }


  onClickTeacher(){
    this.centerService.allTeachers(this.centerAccessID).subscribe((resourceData:any) =>{
      //console.log(resourceData);
      this.allResouces = resourceData.resourceDetails;
      this.secFilter = 'left-p-bar';
      this.fLocation = 'hide';
      this.rightSec = 'expand';
      this.header = 'expand-h'
      this.activeMenuStudent = '';
      this.activeMenuEmployee = '';
      this.activeMenuTeacher = 'active-menu';

      this.unitCode = undefined;
      this.courseAccessId = undefined

      this.lHintCourse = '';
      this.lDataCourse= '';
      this.lHinttextCourse= '';
      this.lHintBatch= '';
      this.lDataBatch= '';
      this.lHinttextBatch= '';

      this.allUnit =  undefined;
      this.resourceMar = 'show'
      this.courseCss = 'hide';
      this.batchCss = 'hide';
      this.selectStep = 'teacher';
      this.search ='';
      this.testing = false;
    });
  }

  onClickEmployee(){
    this.centerService.allEmployee(this.centerAccessID).subscribe((resourceData:any) =>{
      //console.log(resourceData);
      this.allResouces = resourceData.resourceDetails;
      this.secFilter = 'left-p-bar';
      this.fLocation = 'hide';
      this.rightSec = 'expand';
      this.header = 'expand-h'
      this.activeMenuStudent = '';
      this.activeMenuEmployee = 'active-menu';
      this.activeMenuTeacher = '';

      this.lHintCourse = '';
      this.lDataCourse= '';
      this.lHinttextCourse= '';
      this.lHintBatch= '';
      this.lDataBatch= '';
      this.lHinttextBatch= '';

      this.allUnit =  undefined;
      this.resourceMar = 'show'
      this.courseCss = 'hide';
      this.batchCss = 'hide';
      this.selectStep = 'staff';
      this.search ='';
      this.testing = false;
    });
  }

  onclickCreRes(){
    this.router.navigate(['/selectClass']);
  }

  somethingChanged(data) {

    this.inputData = data
    if (data.length > 2) {
      this.testing = true;
    } else {
      this.testing = false
    }
  }

  ngOnInit() {
    this.now = moment(); // add this 2 of 4
    this.todayDate = this.now.format('Do MMMM YYYY');
    this.fetchProfile();
  }

}
