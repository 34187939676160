import { Component, OnInit, Directive, ElementRef, Input, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from 'src/app/services/authService/auth.service';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import * as moment from 'moment';
import { AdminServiceService } from 'src/app/services/adminService/admin-service.service';
import { ClassService } from 'src/app/services/classService/class.service';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent implements OnInit {

  now;
  date;
  form: FormGroup;
  batchForm: FormGroup
  centerDetails;
  accessId;
  timestamp;
  datetime;
  username;
  usertype;
  centerAccessId;
  centerDetail;
  schemeAccessId;
  schemeCode;
  shortSchemeCode;
  tpAccessId;
  tpCode;
  tpName;
  centerAccessID;
  centerCode;
  centerName;
  centerLocation;
  centerAddress;
  contactPersonName;
  contactPersonMobile;
  courseDetails;
  courseCreateCss;
  centerCodeCSS;
  disableBtn;
  startDate;
  endDate;
  startDt;
  endDt;

  courseAccessId;
  courseAccessIdCre;
  courseName;
  courseCode;
  createBatchBtn;
  districtCode;
  districtName;

  creBatchSec;

  allBatch;
  newBatchCss = 'hide';
  roleId;
  creBtn: boolean = true;
  batchStrTime;
  batchEndTime;

  selectBatch;
  updateBatchForm: FormGroup;
  updateBatchSec;

  courseUpdateCss;
  selectCourse;
  corsUpdateform: FormGroup;
  batchUpdateType = 'others';

  updateBatchFormCodeHr: FormGroup;

  allSector;
  finalAllSector;
  sectorFormArray;
  sectorDetails;
  sectorData = [];
  associatedSector = [];
  sectorForm: FormGroup;
  associatedCourse;

  allCourse;
  sectorAccessId;
  sectorName;

  selectNCourse;
  selectOCourse;
  allNBatch;
  allOBatch;
  selectNBatch;
  selectOBatch;

  search;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private location: Location,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private authService: AuthService,
    private classService: ClassService,
    private adminService: AdminServiceService,
    private centerService: CenterDetailsService,
  ) {
    this.createForm(); this.createFormBatch(); this.updateFormBatch(); this.updateFormCourse(); this.updateFormBthCodeHour(); this.onChangeSecForm();
  }

  // Min moment: February 12 2018, 10:30
  today = new Date();
  public min = new Date(this.today.setDate(this.today.getDate() - 1));
  public selectedMoment = new Date();

  @ViewChild('dt3') redel: ElementRef;

  @HostListener('focus', ['$event.target'])
  focusFunction() {
    this.centerCodeCSS = 'show';
  }
  setInputFoucusOut() {
    this.centerCodeCSS = '';
  }

  /* ===============================================================
        Validation
  =============================================================== */
  createForm() {
    this.form = this.formBuilder.group({
      courseName: [null, [Validators.required, Validators.pattern("^.{3,180}$")]],
      courseCode: [null, [Validators.required, Validators.pattern("^.{3,50}$")]],
    });
  }

  createFormBatch() {
    this.batchForm = this.formBuilder.group({
      batchId: [null, [Validators.required, Validators.pattern("^.{3,50}$")]],
      strendDate: [null, [Validators.required]],
      totalHour: [null, [Validators.required, Validators.pattern("^.{2,4}$")]],
      batchStartTime: [null, [Validators.required]],
      batchEndTime: [null, [Validators.required]],
      batchType: [null, [Validators.required]],
    });
  }

  updateFormBatch() {
    this.updateBatchForm = this.formBuilder.group({
      upDtstrendDate: [null, [Validators.required]],
      upDtBatchStartTime: [null, [Validators.required]],
      upDtBatchEndTime: [null, [Validators.required]],
      upDtBatchType: [null, [Validators.required]],
    });
  }

  updateFormCourse() {
    this.corsUpdateform = this.formBuilder.group({
      updtCourseName: [null, [Validators.required, Validators.pattern("^.{3,180}$")]],
      updtCourseCode: [null, [Validators.required, Validators.pattern("^.{3,50}$")]],
    });
  }

  updateFormBthCodeHour() {
    this.updateBatchFormCodeHr = this.formBuilder.group({
      updateBatchId: [null, [Validators.required, Validators.pattern("^.{3,50}$")]],
      updateTotalHour: [null, [Validators.required, Validators.pattern("^.{2,4}$")]],
    });
  }

  /* ===============================================================
      Validation
=============================================================== */
  onChangeSecForm() {
    this.sectorForm = this.formBuilder.group({
      userSector: this.formBuilder.array([], Validators.required)
    });
  }

  /* ===============================================================
    Profile Fetch
  =============================================================== */
  fetchProfile() {
    let key = 'UserInfo';
    this.accessId = localStorage.getItem(key);
    this.authService.getProfile(this.accessId).subscribe((profile: any) => {
      this.username = profile.user.username;
      //console.log(this.username);
      this.roleId = profile.user.roleId;
      if (profile.user.roleId == 77) {
        this.centerAccessID = profile.user.accessId;
      } else {
        this.centerAccessID = localStorage.getItem('UserInfoCenterAccessID');
      }
      this.centerService.getCenter(this.centerAccessID).subscribe((data: any) => {
        this.centerDetails = data.centerDetails
        this.centerDetail = data.centerDetails;
        //console.log(this.centerDetail)
        this.schemeAccessId = this.centerDetail.schemeAccessId;
        this.schemeCode = this.centerDetail.schemeCode;
        console.log(this.schemeCode);
        
        this.tpAccessId = this.centerDetail.tpAccessId;
        this.tpCode = this.centerDetail.tpCode;
        this.tpName = this.centerDetail.tpName;
        this.centerAccessID = this.centerDetail.accessId;
        this.centerCode = this.centerDetail.centerCode;
        this.centerName = this.centerDetail.centerName;

        this.centerLocation = this.centerDetail.location;
        this.centerAddress = this.centerDetail.address;
        this.contactPersonName = this.centerDetail.contactPerson;
        this.contactPersonMobile = this.centerDetail.mobile;

        this.districtCode = this.centerDetail.districtCode;
        this.districtName = this.centerDetail.districtName;

        this.allCourse = this.centerDetail.associatedCourseInfo;
        console.log(this.allCourse);

        this.adminService.getAllSectorCourse(this.schemeCode).subscribe((sector: any) => {
          console.log(sector);
          this.allSector = sector.sectorCourseDetails.sort((a, b) => a.courseName > b.courseName ? 1 : -1);
          this.finalAllSector = sector.sectorCourseDetails.sort((a, b) => a.courseName > b.courseName ? 1 : -1);
        });
        
        this.centerService.allCourse(this.centerAccessID).subscribe((data: any) => {
          this.courseDetails = data.courseDetails;
          console.log(this.courseDetails);
          //console.log(data.courseDetails.length)
          if (0 == data.courseDetails.length) {
            this.courseCreateCss = 'overlay-visible';
          } else {
          }
        });
      });
    });
  }

  /* ===============================================================
      Center Details Fetch
  =============================================================== */
  centerDetailsFetch() {
    let key = 'UserInfo';
    this.centerAccessId = localStorage.getItem(key);
    this.centerService.getCenter(this.centerAccessId).subscribe((data: any) => {
      //console.log(data);
      this.centerDetail = data.centerDetails;
      this.schemeAccessId = this.centerDetail.schemeAccessId;
      this.schemeCode = this.centerDetail.schemeCode;
      this.tpAccessId = this.centerDetail.tpAccessId;
      this.tpCode = this.centerDetail.tpCode;
      this.tpName = this.centerDetail.tpName;
      this.centerAccessID = this.centerDetail.accessId;
      this.centerCode = this.centerDetail.centerCode;
      this.centerName = this.centerDetail.centerName;
      this.centerService.allCourse(this.centerAccessID).subscribe((data: any) => {
        this.courseDetails = data.courseDetails;
        //console.log(this.courseDetails);
        //console.log(data.courseDetails.length)
        if (0 == data.courseDetails.length) {
          this.courseCreateCss = 'overlay-visible';
        } else {
        }
      });
    });
  }

  /* ===============================================================
      Create Course 
  =============================================================== */
  onCreateCourse() {
    this.disableBtn = 'click-disable';
    this.now = moment();
    this.timestamp = this.now.format('YYYYMMDDHHmmss');
    this.datetime = this.now.format("YYYY-MM-DD HH:mm:s");
    const user = {
      accessId: "DWS-OPTG-CB-" + this.timestamp,
      schemeAccessId: this.schemeAccessId,
      schemeCode: this.schemeCode,
      tpAccessId: this.tpAccessId,
      tpCode: this.tpCode,
      tpName: this.tpName,
      centerAccessId: this.centerAccessID,
      centerCode: this.centerCode,
      centerName: this.centerName,
      courseCode: this.form.get('courseCode').value,
      courseName: this.form.get('courseName').value,
      createdBy: this.username,
      createdAt: this.datetime
    }
    this.centerService.createCourse(user).subscribe((data: any) => {
      //console.log(data);
      if (data.success == 1) {
        this.toastr.success(data.message, 'success');
      } else {
        this.toastr.info(data.message, 'info');
      }
      this.form.reset();
      this.courseCreateCss = '';
      this.centerService.allCourse(this.centerAccessID).subscribe((data: any) => {
        this.courseDetails = data.courseDetails;
        //console.log(this.courseDetails);
        this.disableBtn = '';
      });
    });
  }

  /* ===============================================================
      on Click Create Course Section Open
  =============================================================== */
  onClickCreateCourse() {
    this.courseCreateCss = 'overlay-visible';
    this.allSector = this.finalAllSector;   
  }

  onClickBatch(data) {
    console.log(data);

    this.courseAccessId = data.courseAccessId;
    this.courseName = data.courseName;
    this.courseCode = data.courseCode;
    this.centerService.getAllBatch(this.centerAccessID, data.courseAccessId).subscribe((allBatch: any) => {
      this.allBatch = allBatch.batchDetails;
      console.log(this.allBatch.length)
      if (this.allBatch.length === 0) {
        //this.newBatchCss = 'flex-show';
        //this.createBatchBtn = 'hide';
        this.toastr.info('Batch not found, create the batch.')
      } else {
        this.newBatchCss = 'hide';
        this.createBatchBtn = 'show';
        this.courseAccessIdCre = data.courseAccessId;
      }
    });
  }

  onClickHideBatch(data) {
    this.centerService.getAllBatch(this.centerAccessID, data.courseAccessId).subscribe((allBatch: any) => {
      this.allBatch = allBatch.batchDetails;
      if (this.allBatch.length === 0) {
        //this.newBatchCss = 'flex-show';
        //this.createBatchBtn = 'hide';
      } else {
        this.newBatchCss = 'hide';
        this.createBatchBtn = 'show';
      }
    });
    this.courseAccessId = undefined;
    this.courseAccessIdCre = undefined;
  }

  onClickBatchOld(data) {
    //console.log(data);

    this.courseAccessId = data.accessId;
    this.courseName = data.courseName;
    this.courseCode = data.courseCode;
    this.centerService.getAllBatch(this.centerAccessID, data.accessId).subscribe((allBatch: any) => {
      this.allBatch = allBatch.batchDetails;
      //console.log(this.allBatch)
      if (this.allBatch.length === 0) {
        //this.newBatchCss = 'flex-show';
        //this.createBatchBtn = 'hide';
        this.toastr.info('Batch not found, create the batch.')
      } else {
        this.newBatchCss = 'hide';
        this.createBatchBtn = 'show';
        this.courseAccessIdCre = data.accessId;
      }
    });
  }

  onClickHideBatchOld(data) {
    this.centerService.getAllBatch(this.centerAccessID, data.accessId).subscribe((allBatch: any) => {
      this.allBatch = allBatch.batchDetails;
      if (this.allBatch.length === 0) {
        //this.newBatchCss = 'flex-show';
        //this.createBatchBtn = 'hide';
      } else {
        this.newBatchCss = 'hide';
        this.createBatchBtn = 'show';
      }
    });
    this.courseAccessId = undefined;
    this.courseAccessIdCre = undefined;
  }

  onClickBtDel(batch){
    const delData ={
      batchAccessId: batch.accessId
    }
    //console.log(delData);
    this.centerService.removeBatch(delData).subscribe((info:any)=>{
      //console.log(info);
      
      if(info.success === 1){
        this.toastr.success(info.message);
        this.centerService.getAllBatch(this.centerAccessID, batch.accessId).subscribe((allBatch: any) => {
          this.allBatch = allBatch.batchDetails;
          if (this.allBatch.length === 0) {
            //this.newBatchCss = 'flex-show';
            //this.createBatchBtn = 'hide';
          } else {
            this.newBatchCss = 'hide';
            this.createBatchBtn = 'show';
          }
        });
      }else{
        this.toastr.error(info.message);
      }
    });
  }

  /* ===============================================================
      on Click Add new batch
  =============================================================== */
  onclickAddNewBcth(coursedata) {
    console.log(coursedata)
    this.newBatchCss = 'flex-show';
    this.courseAccessIdCre = undefined;
    this.courseAccessId = coursedata.courseAccessId;
    this.courseName = coursedata.courseName;
    this.courseCode = coursedata.courseCode;
    this.sectorName = coursedata.sectorName;
    this.sectorAccessId = coursedata.sectorAccessId;
    this.createBatchBtn = 'hide';
    this.creBatchSec = 'overlay-visible';
  }


  onChange(value) {
    if (value != null) {
      this.startDt = value[0];
      this.endDt = value[1];
    } else { }

  }

  onChangeStrTime(value) {
    //console.log(value);
    if (value != null) {
      this.batchStrTime = this.datePipe.transform(value._d, 'HH:mm:ss');
    } else { }
    //console.log(this.batchStrTime);
  }

  onChangeEndTime(value) {
    //console.log(value);
    if (value != null) {
      this.batchEndTime = this.datePipe.transform(value._d, 'HH:mm:ss');
      //console.log(this.batchEndTime);
    } else { }
  }

  onCreateBatch() {
    this.creBtn = false;
    this.now = moment();
    this.timestamp = this.now.format('YYYYMMDDHHmmss');
    this.datetime = this.now.format("YYYY-MM-DD HH:mm:s");
    this.startDate = this.datePipe.transform(this.startDt._d, 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform(this.endDt._d, 'yyyy-MM-dd');
    const newBatchData = {
      accessId: "DWS-OPTG-BA-" + this.timestamp,
      schemeAccessId: this.schemeAccessId,
      schemeCode: this.schemeCode,
      tpAccessId: this.tpAccessId,
      tpCode: this.tpCode,
      tpName: this.tpName,
      centerAccessId: this.centerAccessID,
      centerCode: this.centerCode,
      centerName: this.centerName,
      centerLocation: this.centerLocation,
      centerAddress: this.centerAddress,
      contactPersonName: this.contactPersonName,
      contactPersonMobile: this.contactPersonMobile,
      courseAccessId: this.courseAccessId,
      courseCode: this.courseCode,
      courseName: this.courseName,
      sectorAccessId: this.sectorAccessId,
      sectorName: this.sectorName,
      districtCode: this.districtCode,
      districtName: this.districtName,
      batchCode: this.batchForm.get('batchId').value,
      batchHours: this.batchForm.get('totalHour').value,
      batchType: this.batchForm.get('batchType').value,
      batchStratDate: this.startDate + ' ' + this.batchStrTime,
      batchEndDate: this.endDate + ' ' + this.batchEndTime,
      batchStartTime: this.batchStrTime,
      batchEndTime: this.batchEndTime,
      createdBy: this.username,
      createdAt: this.datetime
    }
    // console.log(newBatchData);
    // setTimeout(() => {
    //     this.batchForm.reset();
    //     this.creBtn = true;
    //     this.createBatchBtn = 'show';
    //     this.newBatchCss = 'hide';
    //     this.creBatchSec = ''
    // }, 5000);
    this.centerService.createBatch(newBatchData).subscribe((data: any) => {
      //console.log(data);
      if (data.success === 1) {
        this.centerService.getAllBatch(this.centerAccessID, this.courseAccessId).subscribe((allBatch: any) => {
          this.allBatch = allBatch.batchDetails;
          this.batchForm.reset();
          this.creBtn = true;
          this.createBatchBtn = 'show';
          this.newBatchCss = 'hide';
          this.creBatchSec = ''
          this.toastr.success('Batch Created')
        });
      } else { }
    });
  }


  /* ===============================================================
      Batch Update
  =============================================================== */
  onClickOthers() {
    this.batchUpdateType = 'others';
    //console.log(this.batchUpdateType);
    this.updateBatchFormCodeHr.reset();
  }

  onClickCodeHour() {
    this.batchUpdateType = 'codehour';
    this.updateBatchForm.reset();
  }


  onClickBtUpDt(batch) {
    this.selectBatch = batch;
    //console.log(batch);
    this.updateBatchSec = 'overlay-visible';
  }

  onChangeUpDtStrTime(value) {
    //console.log(value);
    if (value != null) {
      this.batchStrTime = this.datePipe.transform(value._d, 'HH:mm:ss');
    } else { }
    //console.log(this.batchStrTime);
  }

  onChangeUpdtEndTime(value) {
    //console.log(value);
    if (value != null) {
      this.batchEndTime = this.datePipe.transform(value._d, 'HH:mm:ss');
      //console.log(this.batchEndTime);
    } else { }
  }

  onUpdateBatch() {
    this.creBtn = false;
    this.startDate = this.datePipe.transform(this.startDt._d, 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform(this.endDt._d, 'yyyy-MM-dd');
    const updateData = {
      batchAccessId: this.selectBatch.accessId,
      batchStratDate: this.startDate,
      batchEndDate: this.endDate,
      batchStartTime: this.batchStrTime,
      batchEndTime: this.batchEndTime,
      batchType: this.updateBatchForm.get('upDtBatchType').value,
      schemeAccessId: this.selectBatch.schemeAccessId,
      schemeCode: this.selectBatch.schemeCode,
      tpAccessId: this.selectBatch.tpAccessId,
      tpCode: this.selectBatch.tpCode,
      tpName: this.selectBatch.tpName,
      centerAccessId: this.selectBatch.centerAccessId,
      centerCode: this.selectBatch.centerCode,
      centerName: this.selectBatch.centerName,
      contactPersonName: this.centerDetail.contactPerson,
      contactPersonMobile: this.centerDetail.mobile,
      centerLocation: this.centerDetail.location,
      centerAddress: this.centerDetail.address,
      districtCode: this.centerDetail.districtCode,
      districtName: this.centerDetail.districtName,
      courseAccessId: this.selectBatch.courseAccessId,
      courseCode: this.selectBatch.courseCode,
      courseName: this.selectBatch.courseName,
      updatedBy: this.username,
      updatedAt: moment().format("YYYY-MM-DD HH:mm:s")
    }
    //console.log(updateData);
    this.centerService.updateBatch(updateData).subscribe((info: any) => {
      if (info.success === 1) {
        this.centerService.getAllBatch(this.centerAccessID, this.selectBatch.courseAccessId).subscribe((allBatch: any) => {
          this.allBatch = allBatch.batchDetails;
          this.creBtn = true;
          this.updateBatchSec = '';
          this.updateBatchForm.reset();
          this.selectBatch = undefined;
          this.toastr.success('Batch updated.')
        });
      } else { }
    });
  }

  onUpdateBatchCodeHr() {
    this.creBtn = false;
    const updateData = {
      batchAccessId: this.selectBatch.accessId,
      batchCode: this.updateBatchFormCodeHr.get('updateBatchId').value,
      batchHours: this.updateBatchFormCodeHr.get('updateTotalHour').value
    }
    //console.log(updateData)
    this.centerService.updateBatchCodeHour(updateData).subscribe((info: any) => {
      //console.log(info)
      if (info.success === 1) {
        this.centerService.getAllBatch(this.centerAccessID, this.selectBatch.courseAccessId).subscribe((allBatch: any) => {
          this.allBatch = allBatch.batchDetails;
          this.creBtn = true;
          this.updateBatchSec = '';
          this.updateBatchFormCodeHr.reset();
          this.selectBatch = undefined;
          this.toastr.success('Batch updated.')
        });
      } else { }
    });
  }


  /* ===============================================================
      Course Update Sec popup open
  =============================================================== */
  onClickCorUpdate(data) {
    this.courseUpdateCss = 'overlay-visible';
    this.selectCourse = data;
    //console.log(data);

  }

  /* course update */
  onCreateCourseUpdate() {
    this.disableBtn = 'click-disable';
    const updateData = {
      courseAccessId: this.selectCourse.accessId,
      courseName: this.corsUpdateform.get('updtCourseName').value,
      courseCode: this.corsUpdateform.get('updtCourseCode').value,
    }
    //console.log(updateData);
    this.centerService.updateCourse(updateData).subscribe((info: any) => {
      if (info.success == 1) {
        this.toastr.success(info.message, 'success');
      } else {
        this.toastr.info(info.message, 'info');
      }
      this.corsUpdateform.reset();
      this.courseUpdateCss = '';
      this.centerService.allCourse(this.centerAccessID).subscribe((data: any) => {
        this.courseDetails = data.courseDetails;
        //console.log(this.courseDetails);
        this.disableBtn = '';
      });
    })
  }

  /* ===============================================================
      Back Function
  =============================================================== */
  back() {
    this.courseCreateCss = '';
    this.form.reset();
    this.centerCodeCSS = '';
    this.selectBatch = undefined;
    this.updateBatchSec = '';
    this.updateBatchForm.reset();
    this.creBatchSec = '';
    this.batchForm.reset();
    this.createBatchBtn = 'show';
    this.corsUpdateform.reset();
    this.courseUpdateCss = '';
    this.updateBatchFormCodeHr.reset();
    this.batchUpdateType = 'others';
  }

  /* ===============================================================
      Select Sector
  =============================================================== */
  onChangeSector(sector: any, isChecked: boolean) {
    this.sectorFormArray = <FormArray>this.sectorForm.controls.userSector;
    if (isChecked) {
      this.sectorDetails = sector;
      this.sectorFormArray.push(new FormControl(sector));
      this.sectorData = this.sectorFormArray.value;
      //console.log(this.sectorData);
      this.associatedCourse = this.sectorData.map(item => item.accessId);
      var unique = this.sectorData.map(item => item.sectorAccessId);
      this.associatedSector = unique.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      })
      //console.log(this.associatedSector);
    } else {
      this.sectorDetails = sector;
      let index = this.sectorFormArray.controls.findIndex(x => x.value == sector);
      this.sectorFormArray.removeAt(index);
      this.sectorData = this.sectorFormArray.value;
      //console.log(this.sectorData);
      this.associatedCourse = this.sectorData.map(item => item.accessId);
      var unique = this.sectorData.map(item => item.sectorAccessId);
      this.associatedSector = unique.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      })
      //console.log(this.associatedSector);
    }
  }

  somethingChanged() {


  }

  /* Create course new format */
  onClickSecCourse() {
    this.disableBtn = 'click-disable';
    console.log(this.sectorData);
    this.sectorData.forEach((element, i) => {
      setTimeout(() => {
        if (element.courseName === 'Administration') {
          //console.log('admin');
          const creData = {
            centerAccessId: this.centerAccessID,
            sectorAccessId: element.sectorAccessId,
            sectorName: element.sectorName,
            courseAccessId: element.accessId,
            courseName: element.courseName,
            courseCode: element.courseCode
          }
          //console.log(creData);
          this.centerService.createCourseNewFormat(creData).subscribe((info: any) => {
            this.now = moment();
            this.timestamp = this.now.format('YYYYMMDDHHmmss');
            this.datetime = this.now.format("YYYY-MM-DD HH:mm:s");
            if(this.schemeCode === 'ELSTP'){
              this.shortSchemeCode = 'ELS';
            }else if(this.schemeCode === 'RSTP'){
              this.shortSchemeCode = 'RST';
            }else if(this.schemeCode === 'DDU-GKY'){
              this.shortSchemeCode = 'DDU';
            }else if(this.schemeCode === 'PMKVY'){
              this.shortSchemeCode = 'PMK';
            }else if(this.schemeCode === 'TRIAL'){
              this.shortSchemeCode = 'TRI';
            }else{}
            const newBatchData = {
              accessId: "DWS-OPTG-BA-" + this.timestamp,
              schemeAccessId: this.schemeAccessId,
              schemeCode: this.schemeCode,
              tpAccessId: this.tpAccessId,
              tpCode: this.tpCode,
              tpName: this.tpName,
              centerAccessId: this.centerAccessID,
              centerCode: this.centerCode,
              centerName: this.centerName,
              centerLocation: this.centerLocation,
              centerAddress: this.centerAddress,
              contactPersonName: this.contactPersonName,
              contactPersonMobile: this.contactPersonMobile,
              courseAccessId: element.accessId,
              courseCode: element.courseCode,
              courseName: element.courseName,
              sectorAccessId: element.sectorAccessId,
              sectorName: element.sectorName,
              districtName: this.districtName,
              districtCode: this.districtCode,
              batchType: 'N/A',
              batchCode: this.shortSchemeCode + '-999',
              batchHours: '999',

              batchStratDate: moment().format('YYYY-MM-DD') + ' 09:00:00',
              batchEndDate: moment().add(3, 'M').format('YYYY-MM-DD') + ' 18:00:00',

              batchStartTime: '09:00:00',
              batchEndTime: '18:00:00',
              createdBy: this.username,
              createdAt: this.datetime
            }
            //console.log(newBatchData);
            this.centerService.createBatch(newBatchData).subscribe((batch: any) => {
              //console.log(batch);
              if(this.sectorData.length === 1){
                this.backSecCourse();
              }else if(this.sectorData.length === i + 1){
                this.backSecCourse();
              }else{}
            });
          });
        } else {
          //console.log('others');
          const creData = {
            centerAccessId: this.centerAccessID,
            sectorAccessId: element.sectorAccessId,
            sectorName: element.sectorName,
            courseAccessId: element.accessId,
            courseName: element.courseName,
            courseCode: element.courseCode
          }
          //console.log(creData);
          this.centerService.createCourseNewFormat(creData).subscribe((info: any) => {
            //console.log(info);
            if (info.success === 1) {
              if (this.sectorData.length === i + 1) {
                setTimeout(() => {
                  const isAssginData = {
                    centerAccessId: this.centerAccessID,
                    associatedCourse: this.associatedCourse,
                    associatedSector: this.associatedSector
                  }
                  //console.log(isAssginData);   
                  this.centerService.createSecCourseAssgin(isAssginData).subscribe((assginInfo: any) => {
                    //console.log(assginInfo);

                    if (assginInfo.success === 1) {
                      this.centerService.getCenter(this.centerAccessID).subscribe((data: any) => {
                        this.allCourse = data.centerDetails.associatedCourseInfo;
                        this.toastr.success(assginInfo.message)
                        this.courseCreateCss = '';
                        this.sectorData = [];
                        this.associatedSector = [];
                        this.associatedCourse = undefined;
                        this.disableBtn = '';
                        this.search = '';
                        while (this.sectorFormArray.length !== 0) {
                          this.sectorFormArray.removeAt(0)
                        }
                      });
                    } else {
                      this.toastr.error(info.message);
                      this.courseCreateCss = '';
                      this.sectorData = [];
                      this.associatedSector = [];
                      this.associatedCourse = undefined;
                      this.disableBtn = '';
                      this.search = '';
                      while (this.sectorFormArray.length !== 0) {
                        this.sectorFormArray.removeAt(0)
                      }
                    }
                  })
                }, 2000);
              }
            } else {
              this.toastr.error(info.message);
              this.courseCreateCss = '';
              this.sectorData = [];
              this.associatedSector = [];
              this.associatedCourse = undefined;
              this.disableBtn = '';
              this.search = '';
              while (this.sectorFormArray.length !== 0) {
                this.sectorFormArray.removeAt(0)
              }
            }
          });
        }
      }, 1000 * (i + 1));
    });
  }

  backSecCourse() {
    this.courseCreateCss = '';
    this.sectorData = [];
    this.associatedSector = [];
    this.associatedCourse = undefined;
    this.disableBtn = '';
    this.allSector = [];
    this.search = '';
    if(this.sectorFormArray != undefined ){
      while (this.sectorFormArray.length !== 0) {
        this.sectorFormArray.removeAt(0)
      }
    }else{}
    this.centerService.getCenter(this.centerAccessID).subscribe((data: any) => {
      this.allCourse = data.centerDetails.associatedCourseInfo;
    });
  }

  onSecNCourse(nCourse){
    this.selectNCourse = nCourse;
    this.centerService.getAllBatch(this.centerAccessID, nCourse.courseAccessId).subscribe((allBatch: any) => {
      this.allNBatch = allBatch.batchDetails;
      //console.log(this.allNBatch);
      this.selectNBatch = undefined;
    });
  }

  onSecOCourse(oCourse){
    this.selectOCourse = oCourse;
    //console.log(this.selectOCourse);
    this.centerService.getAllBatch(this.centerAccessID, oCourse.accessId).subscribe((allBatch: any) => {
      this.allOBatch = allBatch.batchDetails;
      this.selectOBatch = undefined;
      //console.log(this.allOBatch);
    });
  }

  onSecNBatch(nBatch){
    this.selectNBatch = nBatch;

  }

  onSecOBatch(oBatch){
    this.selectOBatch = oBatch;
  }

  onClickMigrate(){
    const migrateData ={
      nBatchAccessId: this.selectNBatch.accessId, 
      nBatchCode: this.selectNBatch.batchCode, 
      nBatchHours: this.selectNBatch.batchHours, 
      nCourseAccessId: this.selectNBatch.courseAccessId, 
      nCourseCode:  this.selectNBatch.courseCode, 
      nCourseName:  this.selectNBatch.courseName, 
      nSectorAccessId:  this.selectNBatch.sectorAccessId,
      nSectorName:  this.selectNBatch.sectorName,

      oCenterAccessId: this.selectOBatch.centerAccessId, 
      oCourseAccessId: this.selectOBatch.courseAccessId, 
      oBatchAccessId: this.selectOBatch.accessId
    }
    //console.log(migrateData);
    this.classService.migrateResources(migrateData).subscribe((info:any) =>{
      //console.log(info);
      if(info.success === 1){
        this.selectNBatch = undefined;
        this.selectOBatch = undefined;
        this.selectNCourse = undefined;
        this.selectOCourse = undefined;
        this.toastr.success('Resource migrate done.');
      }else{
        this.selectNBatch = undefined;
        this.selectOBatch = undefined;
        this.selectNCourse = undefined;
        this.selectOCourse = undefined;
        this.toastr.error('Resource migrate faild.');
      }
    });

  }

  /* ===============================================================
      JavaScript Load 
  =============================================================== */
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/js/common.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  ngOnInit() {
    //console.log(this.courseAccessId)
    this.now = moment(); // add this 2 of 4
    this.date = this.now.format('Do MMMM YYYY');
    this.loadScript();
    this.fetchProfile();
  
  }

}
