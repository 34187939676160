import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from 'src/app/services/authService/auth.service';
import { AdminServiceService } from 'src/app/services/adminService/admin-service.service';
import { PiaServiceService } from 'src/app/services/piaService/pia-service.service';
import * as moment from 'moment';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';

@Component({
  selector: 'app-create-pia',
  templateUrl: './create-pia.component.html',
  styleUrls: ['./create-pia.component.css']
})
export class CreatePiaComponent implements OnInit {

  now;
  date;
  form: FormGroup;
  accessId;
  username;
  timestamp;
  datetime;
  schemeData = [];
  schemes = [];
  disableBtn;
  allDistrict;
  getAllData;
  district;
  districtCode;
  roleId;
  trialScAccessId;
  schemeAccessId;
  schemeCode;
  schemeName;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private location: Location,
    private toastr: ToastrService,
    private authService: AuthService,
    private adminService: AdminServiceService,
    private centerService: CenterDetailsService,
    private piaService: PiaServiceService
  ) {
    this.createForm()
    // this.form.valueChanges.subscribe(
    //   result => console.log(this.form.status))
  }

  /* ===============================================================
        Validation
  =============================================================== */
  createForm() {
    this.form = this.formBuilder.group({
      tpName: [null, [Validators.required, Validators.pattern("^.{3,120}$")]],
      tpShortName: [null, [Validators.required, Validators.pattern("^.{3,40}$")]],
      tpCode: [null, [Validators.required, Validators.pattern("^.{3,20}$")]],
      contactPerson: [null, [Validators.required, Validators.pattern("^.{3,60}$")]],
      mobile: [null, Validators.compose([Validators.required, Validators.pattern("^\\d{10,10}$")])],
      email: [null, [Validators.required, Validators.pattern("[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]],
      address: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
      location: [null, [Validators.required, Validators.pattern("^.{3,40}$")]],
      district: [null, Validators.required],
      username: [null, [Validators.required, Validators.pattern("^.{3,15}$")]],
      userscheme: [null, [Validators.required]]
    });
  }

  // onChange(scheme:any, isChecked: boolean) {
  //   const schemeFormArray = <FormArray>this.form.controls.userscheme;
  //   if (isChecked) {
  //     schemeFormArray.push(new FormControl(scheme));
  //     this.schemeData = schemeFormArray.value;
  //     this.associatedSchemes = this.schemeData.map(item =>item.accessId);
  //     console.log(this.associatedSchemes);
  //   } else {
  //     let index = schemeFormArray.controls.findIndex(x => x.value == scheme)
  //     schemeFormArray.removeAt(index);
  //     this.schemeData = schemeFormArray.value;
  //     this.associatedSchemes = this.schemeData.map(item =>item.accessId);
  //     console.log(this.associatedSchemes);
  //   }
  // }
  onChange(scheme) {
    this.schemeAccessId = scheme.accessId
    this.schemeCode = scheme.schemeCode;
    this.schemeName = scheme.schemeName;
  }

  onChangeDis(data) {
    this.getAllData = data.split(',');
    this.districtCode = this.getAllData.pop();
    //console.log(this.districtCode);
    this.district = this.getAllData.join(',');
    //console.log(this.district);
  }

  /* ===============================================================
    Profile Fetch
  =============================================================== */
  fetchProfile() {
    let key = 'UserInfo';
    this.accessId = localStorage.getItem(key);
    this.authService.getProfile(this.accessId).subscribe((profile: any) => {
      this.username = profile.user.username;
      this.roleId = profile.user.roleId;
      this.centerService.getAllDistrict().subscribe((district: any) => {
        //console.log(district);
        this.allDistrict = district.districtDetails;
      });
      this.adminService.getScheme().subscribe((scheme: any) => {
        this.schemes = scheme.schemeDetails;
        if (this.roleId == 0o0) {
          this.trialScAccessId = undefined;
        } else {
          this.trialScAccessId = this.schemes[this.schemes.length - 1].accessId;
        }
      });
    });
  }

  /* ===============================================================
    All Scheme Information Fretch
  =============================================================== */
  fetchScheme() {
    // this.adminService.getScheme().subscribe((scheme:any) =>{
    //   this.schemes = scheme.schemeDetails;
    //   if(this.roleId == 0o0){
    //     this.trialScAccessId = undefined;
    //   }else{
    //     this.trialScAccessId = this.schemes[this.schemes.length-1].accessId;         
    //   }
    // });
  }

  /* ===============================================================
      Create PIA / TP
  =============================================================== */
  /* Profile Create */
  onCreatePia() {
    this.disableBtn = 'click-disable';
    this.now = moment();
    this.timestamp = this.now.format('YYYYMMDDHHmmss');
    this.datetime = this.now.format("YYYY-MM-DD HH:mm:s");
    const user = {
      email: this.form.get('email').value,
      username: this.form.get('username').value,
      roleId: 88,
      accessId: "DWS-OPTG-PA-" + this.timestamp,
      createdBy: this.username,
      createdAt: this.datetime
    };
    //this.onCreatePiaDetails();
    this.authService.registerUser(user).subscribe((data:any) => {
      if(data.success === 1){
        this.onCreatePiaDetails();
      }else{}
    });
  }
  /* Pia Details Create */
  onCreatePiaDetails() {
    const user = {
      accessId: "DWS-OPTG-PA-" + this.timestamp,
      tpCode: this.schemeCode + '-' + this.form.get('tpCode').value,
      tpName: this.form.get('tpName').value,
      contactPerson: this.form.get('contactPerson').value,
      mobile: this.form.get('mobile').value,
      email: this.form.get('email').value,

      associatedSchemes: this.schemeAccessId,
      schemeAccessId: this.schemeAccessId,
      schemeCode: this.schemeCode,
      schemeName: this.schemeName,

      address: this.form.get('address').value,
      location: this.form.get('location').value,
      districtName: this.district,
      districtCode: this.districtCode,
      tpShortName: this.form.get('tpShortName').value,
      createdBy: this.username,
      createdAt: this.datetime
    }
    //console.log(user);
    this.piaService.createPia(user).subscribe((data:any) => {
      if (data.success == 1) {
        this.toastr.success(data.message, 'success');
      } else {
        this.toastr.info(data.message, 'info');
      }
      this.form.reset();
      this.disableBtn = '';
      if (this.roleId == 0o0) {
        this.router.navigate(['/SUDashboard']);
        localStorage.removeItem('piaAccessUserInfo');
        localStorage.removeItem('UserInfoCenterAccessID');
      } else {
        this.router.navigate(['/adminDashboard']);
        localStorage.removeItem('piaAccessUserInfo');
        localStorage.removeItem('UserInfoCenterAccessID');
      }
    });
  }

  /* ===============================================================
      Back Function
  =============================================================== */
  back() {
    if (this.roleId == 0o0) {
      this.router.navigate(['/SUDashboard']);
    } else {
      this.router.navigate(['/adminDashboard']);
    }
  }

  /* ===============================================================
      JavaScript Load 
  =============================================================== */
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/js/common.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  ngOnInit() {
    this.fetchProfile();
    this.fetchScheme();
    this.loadScript();
    this.now = moment();
    this.date = this.now.format('Do MMMM YYYY');
  }

}
