import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../authService/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ClassService {

  options;
  domain = this.authService.domain;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  /* ===============================================================
      Authentication Header
  =============================================================== */
  createAuthenticationHeaders() {
    this.authService.loadToken();
      this.options = { headers : new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'authorization': this.authService.authToken
      })
    }
  }

  /* ===============================================================
      Create Class
  =============================================================== */
  createClass(data){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'classDetail/createNewClass', data, this.options);
  }

  /* ===============================================================
      Update Class
  =============================================================== */
  updateClass(data){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'classDetail/updateClassName', data, this.options);
  }

  /* ===============================================================
      Delete Class
  =============================================================== */
  deleteClass(data){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'classDetail/removeClass', data, this.options);
  }

  /* ===============================================================
      Camera Assgin
  =============================================================== */
  cameraAssgin(data){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'cameraDetail/createNewCamV2', data, this.options);
  }

  /* ===============================================================
      Get One Class
  =============================================================== */
  getClass(accessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'classDetail/getOneClass/' + accessId, this.options);
  }

  /* ===============================================================
      Get All Class
  =============================================================== */
  getAllClass(accessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'classDetail/getAllClass/' + accessId, this.options);
  }

  /* ===============================================================
      Get Camera Information
  =============================================================== */
  getCamInfo(classAccessId){
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'cameraDetail/getOneCamera/' + classAccessId , this.options);
  }

  /* ===============================================================
     Image Capture
  =============================================================== */
  imageCapture(data){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'cameraDetail/imageCapture', data, this.options);
  }

  /* ===============================================================
      Video Recording Start
  =============================================================== */
  videoRecStart(data){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'cameraDetail/startRecording', data, this.options);
  }

  /* ===============================================================
      Video Recording Stop
  =============================================================== */
  videoRecStop(data){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'cameraDetail/stopRecording', data, this.options);
  }

  /* ===============================================================
      Camera Availabililty
  =============================================================== */
  cameraAvailabililty(data){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'cameraDetail/checkCameraAvailability', data, this.options);
  }

  /* ===============================================================
      On Camera
  =============================================================== */
  onCamera(data){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'cameraDetail/onCamera ', data, this.options);
  }

  /* ===============================================================
      OFf Camera
  =============================================================== */
  offCamera(data){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'cameraDetail/offCamera ', data, this.options);
  }

  /* ===============================================================
      OFf Camera
  =============================================================== */
  onCameraForAudit(data){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'cameraDetail/onCameraForAudit', data, this.options);
  }
  
  /* ===============================================================
      Resolution Change
  =============================================================== */
  resolutonChange(data){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'cameraDetail/setResolution' , data, this.options);
  }

  /* ===============================================================
    Delete Camare Assgin
  =============================================================== */
  deleteCameraAssgin(data){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'cameraDetail/removeCamera' , data, this.options);
  }

  /* ===============================================================
    Migate Resources
  =============================================================== */
  migrateResources(data){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'batchDetail/migrateResource' , data, this.options);
  }

  /* ===============================================================
    Enroll BKBY
  =============================================================== */
  cameraEnroll(data){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'attendanceDetail/camReg' , data, this.options);
  }
  
}
