import { Component, OnInit, Output, EventEmitter, Compiler } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


import { AuthService } from 'src/app/services/authService/auth.service';
import { ClassService } from 'src/app/services/classService/class.service';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
@Component({
  selector: 'app-admin-nav',
  templateUrl: './admin-nav.component.html',
  styleUrls: ['./admin-nav.component.css']
})
export class AdminNavComponent implements OnInit {

  accessId;
  profileAccessId;
  usertype;
  currentComponent;
  centerAccessId;
  classDetails;
  listClass: boolean;
  listClassHistory: boolean;
  listClassAudit: boolean;
  classAccessId;
  toastr = 'hide';
  toastrCannotAssgin = 'hide';
  toastrSelectCenter = 'hide';
  classCuntCss = 'hide';
  camDetails;
  allCourse;
  courseAccessId;
  activeClassView;
  activeClassHistory;
  activeClassAtten;
  getCamID;
  getDeviceID;
  camNtFond = 'hide';
  liveStream: boolean;
  audit:boolean
  history: boolean;
  resources:boolean;
  attendance: boolean;
  centerPanel: boolean;
  preLoader = 'hide';
  reloadSec= 'reload-t-sec';

  public timer;

  @Output()
  uploaded = new EventEmitter<string>();

  @Output()
  uploadedHis = new EventEmitter<string>();

  constructor(
    private router: Router,
    private _compiler: Compiler,
    private route: ActivatedRoute,
    private authService: AuthService,
    private classService: ClassService,
    private centerService: CenterDetailsService
  ) { }

  onClickAnalytics() {
    this.router.navigate(['/adminDashboard']);
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    localStorage.removeItem('CourseAccessIDAttendance');
    let piaAccesskey = 'piaAccessUserInfo';
    localStorage.removeItem(piaAccesskey);
    let centerAccessId = 'UserInfoCenterAccessID';
    localStorage.removeItem(centerAccessId);
    if (this.currentComponent == 'AuditComponent') {
      let camId = 'CameraID';
      this.getCamID = localStorage.getItem(camId);
      let deviceId = 'DeviceID';
      this.getDeviceID = localStorage.getItem(deviceId);
      this.preLoader = 'show';
      this.reloadSecFun();
      const offPrams = {
        cameraDeviceId: this.getDeviceID,
        cameraId: this.getCamID,
        channelId: '11'
      }
      this.classService.offCamera(offPrams).subscribe((offcam: any) => {
        //console.log(offcam);
        localStorage.removeItem('CameraID');
        localStorage.removeItem('DeviceID');
        location.reload();
      });
    }else if(this.currentComponent == 'LiveStreamComponent'){
      localStorage.removeItem('totalCamList');
    } else {
      this.router.navigate(['/adminDashboard']);
    }
  }

  onclickSdcPanel() {
    this.router.navigate(['centerDashboard/u/', 2]);
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    localStorage.removeItem('CourseAccessIDAttendance');
    if (this.currentComponent == 'AuditComponent') {
      let camId = 'CameraID';
      this.getCamID = localStorage.getItem(camId);
      let deviceId = 'DeviceID';
      this.getDeviceID = localStorage.getItem(deviceId);
      this.preLoader = 'show';
      this.reloadSecFun();
      const offPrams = {
        cameraDeviceId: this.getDeviceID,
        cameraId: this.getCamID,
        channelId: '11'
      }
      //console.log(offPrams);
      this.classService.offCamera(offPrams).subscribe((offcam: any) => {
        //console.log(offcam);
        if(offcam.success == 1){
        localStorage.removeItem('CameraID');
        localStorage.removeItem('DeviceID');
        location.reload();
        }else{
          //success 0
        }
      });
    }else if(this.currentComponent == 'LiveStreamComponent'){
      localStorage.removeItem('totalCamList');
    } else { }
  }

  onclickPiaPanel() {
    this.router.navigate(['piaDashboard/u/', 1]);
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    localStorage.removeItem('CourseAccessIDAttendance');
    if (this.currentComponent == 'AuditComponent') {
      let camId = 'CameraID';
      this.getCamID = localStorage.getItem(camId);
      let deviceId = 'DeviceID';
      this.getDeviceID = localStorage.getItem(deviceId);
      this.preLoader = 'show';
      this.reloadSecFun();
      const offPrams = {
        cameraDeviceId: this.getDeviceID,
        cameraId: this.getCamID,
        channelId: '11'
      }
      this.classService.offCamera(offPrams).subscribe((offcam: any) => {
        //console.log(offcam);
        if(offcam.success ==1){
          localStorage.removeItem('CameraID');
          localStorage.removeItem('DeviceID');
          location.reload();
        }else{
          //success 0
        }
      });
    }else if(this.currentComponent == 'LiveStreamComponent'){
      localStorage.removeItem('totalCamList');
    } else { }
  }

  onClickResources() {
    this.router.navigate(['resource']);
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    localStorage.removeItem('CourseAccessIDAttendance');
    if (this.currentComponent == 'AuditComponent') {
      let camId = 'CameraID';
      this.getCamID = localStorage.getItem(camId);
      let deviceId = 'DeviceID';
      this.getDeviceID = localStorage.getItem(deviceId);
      this.preLoader = 'show';
      this.reloadSecFun();
      const offPrams = {
        cameraDeviceId: this.getDeviceID,
        cameraId: this.getCamID,
        channelId: '11'
      }
      this.classService.offCamera(offPrams).subscribe((offcam: any) => {
        //console.log(offcam);
        if(offcam.success ==1){
          localStorage.removeItem('CameraID');
          localStorage.removeItem('DeviceID');
          location.reload();
        }else{
          //success 0
        }
      });
    }else if(this.currentComponent == 'LiveStreamComponent'){
      localStorage.removeItem('totalCamList');
    } else { }
  }

  onClickReReport(){
    this.router.navigate(['report']);
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    localStorage.removeItem('CourseAccessIDAttendance');
    if (this.currentComponent == 'AuditComponent') {
      let camId = 'CameraID';
      this.getCamID = localStorage.getItem(camId);
      let deviceId = 'DeviceID';
      this.getDeviceID = localStorage.getItem(deviceId);
      this.preLoader = 'show';
      this.reloadSecFun();
      const offPrams = {
        cameraDeviceId: this.getDeviceID,
        cameraId: this.getCamID,
        channelId: '11'
      }
      this.classService.offCamera(offPrams).subscribe((offcam: any) => {
        //console.log(offcam);
        if(offcam.success ==1){
          localStorage.removeItem('CameraID');
          localStorage.removeItem('DeviceID');
          location.reload();
        }else{
          //success 0
        }
      });
    }else if(this.currentComponent == 'LiveStreamComponent'){
      localStorage.removeItem('totalCamList');
    } else { }
  }

  onLogoutClick() {
    if (this.currentComponent == 'AuditComponent') {
      let getCamId = 'CameraID';
      this.getCamID = localStorage.getItem(getCamId);
      let deviceId = 'DeviceID';
      this.getDeviceID = localStorage.getItem(deviceId);
      this.preLoader = 'show';
      this.reloadSecFun();
      const offPrams = {
        cameraId: this.getCamID,
        cameraDeviceId: this.getDeviceID,
        channelId: '11'
      }
      this.classService.offCamera(offPrams).subscribe((offcam: any) => {
        //console.log(offcam);
        if(offcam.success == 1){
          let key = 'UserInfo';
          this.accessId = localStorage.getItem(key);
          const accessId = {
            accessId: this.accessId,
          }
          this.authService.userLogout(accessId).subscribe((data) => {
            //console.log(data);
            this.router.navigate(['/']);
            this._compiler.clearCache();
            setTimeout(() => {
              location.reload();
            }, 100);
          });
        }else{
          //success 0
        }
      });

    }else if(this.currentComponent == 'LiveStreamComponent'){
      localStorage.removeItem('totalCamList');
      let key = 'UserInfo';
      this.accessId = localStorage.getItem(key);
      const accessId = {
        accessId: this.accessId,
      }
      this.authService.userLogout(accessId).subscribe((data) => {
        //console.log(data);
        this.router.navigate(['/']);
        this._compiler.clearCache();
        setTimeout(() => {
          location.reload();
        }, 100);
      });
    } else {
      let key = 'UserInfo';
      this.accessId = localStorage.getItem(key);
      const accessId = {
        accessId: this.accessId,
      }
      this.authService.userLogout(accessId).subscribe((data) => {
        //console.log(data);
        this.router.navigate(['/']);
        this._compiler.clearCache();
        setTimeout(() => {
          location.reload();
        }, 100);
      });
    }
  }

  onClickLiveStream() {
    //this.listClass = !this.listClass;
    this.listClassHistory = false;
    this.listClassAudit = false;
    this.router.navigate(['liveStream/u/', 2]);
    // if (this.centerAccessId == undefined) {
    //   this.toastr = 'show';
    //   setTimeout(() => {
    //     this.toastr = 'hide';
    //   }, 5000);
    // } else { }
  }

  selectClass(data) {
    //console.log(data);
    if (data.isCameraAssigned == '1') {
      localStorage.removeItem('ClassAccessIdHis');
      localStorage.removeItem('CourseAccessIDAttendance');
      this.classAccessId = data.accessId;
      localStorage.setItem('ClassAccessId', this.classAccessId);
      this.classService.getCamInfo(this.classAccessId).subscribe((camData: any) => {
        //console.log(camData);
        const camAvailData = {
          cameraDeviceId: camData.cameraDetails.cameraDeviceId
        }
        this.classService.cameraAvailabililty(camAvailData).subscribe((camAvail: any) => {
          //console.log(camAvail);
          if (camAvail.success == 1) {
            this.activeClassView = data.accessId;
            this.listClass = false;
            if (camData.success == 1) {
              let getCamId = 'CameraID';
              this.getCamID = localStorage.getItem(getCamId);
              if (this.getCamID == null) {
                const onPrams = {
                  cameraDeviceId: camData.cameraDetails.cameraDeviceId,
                  cameraId: camData.cameraDetails.cameraId,
                  channelId: '11'
                }
                this.preLoader = 'show';
                this.reloadSecFun();
                this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                  //console.log(onCam);
                  if(onCam.success == 1){
                    let camId = 'CameraID';
                    localStorage.setItem(camId, camData.cameraDetails.cameraId);
                    let deviceId = 'DeviceID';
                    localStorage.setItem(deviceId, camData.cameraDetails.cameraDeviceId)
                    this.camDetails = camData.cameraDetails;
                    //console.log(this.camDetails);
                    //console.log(this.camDetails.streamUrl);
                    let key = 'CameraUrl';
                    localStorage.setItem(key, this.camDetails.streamUrl);
                    this.router.navigate(['liveStream/u/', 2]);
                    setTimeout(() => {
                      location.reload();
                    }, 2000);
                  }else{
                    // success 0
                  }
                });
              } else {
                /*Camera Off */
                this.preLoader = 'show';
                let getCamId = 'CameraID';
                this.resetTime();
                this.reloadSecFun();
                this.getCamID = localStorage.getItem(getCamId);
                let deviceId = 'DeviceID';
                this.getDeviceID = localStorage.getItem(deviceId);
                if (camData.cameraDetails.cameraId != this.getCamID) {
                  const offPrams = {
                    cameraDeviceId: this.getDeviceID,
                    cameraId: this.getCamID,
                    channelId: '11'
                  }
                  this.classService.offCamera(offPrams).subscribe((offcam: any) => {
                    //console.log(offcam);
                    /*Camera Start */
                    if(offcam.success ==1){
                      setTimeout(() => {
                        const onPrams = {
                          cameraDeviceId: camData.cameraDetails.cameraDeviceId,
                          cameraId: camData.cameraDetails.cameraId,
                          channelId: '11'
                        }
                        this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                          //console.log(onCam);
                          if(onCam.success == 1){
                            setTimeout(() => {
                              let camId = 'CameraID';
                              localStorage.setItem(camId, camData.cameraDetails.cameraId);
                              let deviceId = 'DeviceID';
                              localStorage.setItem(deviceId, camData.cameraDetails.cameraDeviceId)
                            }, 2000);
                            this.camDetails = camData.cameraDetails;
                            //console.log(this.camDetails);
                            let key = 'CameraUrl';
                            localStorage.setItem(key, this.camDetails.streamUrl);
                            this.router.navigate(['liveStream/u/', 2]);
                              //console.log('video js load')
                              // this.loadScript();
                              setTimeout(() => {
                                location.reload();
                              }, 2000);
                          }else{
                            //success 0
                          }
                        });
                      }, 2000);
                    }else{
                      //success 0
                    }
                  });
                } else { 
                  setTimeout(() => {
                    let camId = 'CameraID';
                    localStorage.setItem(camId, camData.cameraDetails.cameraId);
                    let deviceId = 'DeviceID';
                    localStorage.setItem(deviceId, camData.cameraDetails.cameraDeviceId)
                  }, 2000);
                  this.camDetails = camData.cameraDetails;
                  //console.log(this.camDetails);
                  let key = 'CameraUrl';
                  localStorage.setItem(key, this.camDetails.streamUrl);
                  this.router.navigate(['liveStream/u/', 2]);
                    //console.log('video js load')
                    // this.loadScript();
                    setTimeout(() => {
                      location.reload();
                    }, 2000);

                }
              }
            } else { }
          } else {
            //console.log(camAvail.message);
            this.camNtFond = 'show';
            localStorage.removeItem('ClassAccessId');
            setTimeout(() => {
              this.camNtFond = 'hide';
            }, 5000);
          }
        });

      });
    } else {
      this.toastrCannotAssgin = 'show';
      setTimeout(() => {
        this.toastrCannotAssgin = 'hide';
      }, 5000);
    }
  }

  onClickAudit(){
    this.listClassAudit = !this.listClassAudit;
    this.listClassHistory = false;
    this.listClass = false;

    if (this.centerAccessId == undefined) {
      this.toastr = 'show';
      setTimeout(() => {
        this.toastr = 'hide';
      }, 5000);
    } else { }
  }

  selectClassAudit(data){
    if (data.isCameraAssigned == '1') {
      localStorage.removeItem('ClassAccessIdHis');
      localStorage.removeItem('CourseAccessIDAttendance');
      this.classAccessId = data.accessId;
      localStorage.setItem('ClassAccessId', this.classAccessId);
      this.classService.getCamInfo(this.classAccessId).subscribe((camData: any) => {
        //console.log(camData);
        const camAvailData = {
          cameraDeviceId: camData.cameraDetails.cameraDeviceId
        }
        this.classService.cameraAvailabililty(camAvailData).subscribe((camAvail: any) => {
          //console.log(camAvail);
          if (camAvail.success == 1) {
            this.activeClassView = data.accessId;
            this.listClass = false;
            if (camData.success == 1) {
              let getCamId = 'CameraID';
              this.getCamID = localStorage.getItem(getCamId);
              if (this.getCamID == null) {
                const onPrams = {
                  cameraDeviceId: camData.cameraDetails.cameraDeviceId,
                  cameraId: camData.cameraDetails.cameraId,
                  channelId: '11'
                }
                this.preLoader = 'show';
                this.reloadSecFun();
                this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                  //console.log(onCam);
                  if(onCam.success == 1){
                    let camId = 'CameraID';
                    localStorage.setItem(camId, camData.cameraDetails.cameraId);
                    let deviceId = 'DeviceID';
                    localStorage.setItem(deviceId, camData.cameraDetails.cameraDeviceId)
                    this.camDetails = camData.cameraDetails;
                    //console.log(this.camDetails);
                    //console.log(this.camDetails.streamUrl);
                    let key = 'CameraUrl';
                    localStorage.setItem(key, this.camDetails.streamUrl);
                    this.router.navigate(['audit']);
                    setTimeout(() => {
                      location.reload();
                    }, 2000);
                  }else{
                    // success 0
                  }
                });
              } else {
                /*Camera Off */
                this.preLoader = 'show';
                let getCamId = 'CameraID';
                this.resetTime();
                this.reloadSecFun();
                this.getCamID = localStorage.getItem(getCamId);
                let deviceId = 'DeviceID';
                this.getDeviceID = localStorage.getItem(deviceId);
                if (camData.cameraDetails.cameraId != this.getCamID) {
                  const offPrams = {
                    cameraDeviceId: this.getDeviceID,
                    cameraId: this.getCamID,
                    channelId: '11'
                  }
                  this.classService.offCamera(offPrams).subscribe((offcam: any) => {
                    //console.log(offcam);
                    /*Camera Start */
                    if(offcam.success ==1){
                      setTimeout(() => {
                        const onPrams = {
                          cameraDeviceId: camData.cameraDetails.cameraDeviceId,
                          cameraId: camData.cameraDetails.cameraId,
                          channelId: '11'
                        }
                        this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                          //console.log(onCam);
                          if(onCam.success == 1){
                            setTimeout(() => {
                              let camId = 'CameraID';
                              localStorage.setItem(camId, camData.cameraDetails.cameraId);
                              let deviceId = 'DeviceID';
                              localStorage.setItem(deviceId, camData.cameraDetails.cameraDeviceId)
                            }, 2000);
                            this.camDetails = camData.cameraDetails;
                            //console.log(this.camDetails);
                            let key = 'CameraUrl';
                            localStorage.setItem(key, this.camDetails.streamUrl);
                            this.router.navigate(['audit']);
                              //console.log('video js load')
                              // this.loadScript();
                              setTimeout(() => {
                                location.reload();
                              }, 2000);
                          }else{
                            //success 0
                          }
                        });
                      }, 2000);
                    }else{
                      //success 0
                    }
                  });
                } else { 
                  setTimeout(() => {
                    let camId = 'CameraID';
                    localStorage.setItem(camId, camData.cameraDetails.cameraId);
                    let deviceId = 'DeviceID';
                    localStorage.setItem(deviceId, camData.cameraDetails.cameraDeviceId)
                  }, 2000);
                  this.camDetails = camData.cameraDetails;
                  //console.log(this.camDetails);
                  let key = 'CameraUrl';
                  localStorage.setItem(key, this.camDetails.streamUrl);
                  this.router.navigate(['audit']);
                    //console.log('video js load')
                    // this.loadScript();
                    setTimeout(() => {
                      location.reload();
                    }, 2000);

                }
              }
            } else { }
          } else {
            //console.log(camAvail.message);
            this.camNtFond = 'show';
            localStorage.removeItem('ClassAccessId');
            setTimeout(() => {
              this.camNtFond = 'hide';
            }, 5000);
          }
        });

      });
    } else {
      this.toastrCannotAssgin = 'show';
      setTimeout(() => {
        this.toastrCannotAssgin = 'hide';
      }, 5000);
    }
  }


  onClickHistory() {
    this.listClassHistory = !this.listClassHistory;
    this.listClass = false;
    this.listClassAudit = false;
    if (this.centerAccessId == undefined) {
      this.toastr = 'show';
      setTimeout(() => {
        this.toastr = 'hide';
      }, 5000);
    } else { }
  }

  selectClassHistory(data) {
    //if (data.isCameraAssigned == '1') {
      localStorage.removeItem('ClassAccessId');
      localStorage.removeItem('CourseAccessIDAttendance');
      this.classAccessId = data.accessId;
      this.activeClassHistory = data.accessId;
      let classAccessId = 'ClassAccessIdHis';
      localStorage.setItem(classAccessId, this.classAccessId);
      this.router.navigate(['/history/u/', 2]);
      this.uploadedHis.emit('complete');
      if (this.currentComponent == 'AuditComponent') {
        let camId = 'CameraID';
        this.getCamID = localStorage.getItem(camId);
        let deviceId = 'DeviceID';
        this.getDeviceID = localStorage.getItem(deviceId);
        this.preLoader = 'show';
        this.reloadSecFun();
        const offPrams = {
          cameraDeviceId: this.getDeviceID,
          cameraId: this.getCamID,
          channelId: '11'
        }
        this.classService.offCamera(offPrams).subscribe((offcam: any) => {
          //console.log(offcam);
          if(offcam.success ==1){
            localStorage.removeItem('CameraID');
            localStorage.removeItem('DeviceID');
            location.reload();
          }else{
            //success 0
          }
        });
      }else if(this.currentComponent == 'LiveStreamComponent'){
        localStorage.removeItem('totalCamList');
      } else { }
    // } else {
    //   this.toastrCannotAssgin = 'show';
    //   setTimeout(() => {
    //     this.toastrCannotAssgin = 'hide';
    //   }, 5000);
    // }
  }

  onClickAttendance() {
    this.listClass = false;
    this.listClassHistory = false;
    if (this.centerAccessId == undefined) {
      this.toastr = 'show';
      setTimeout(() => {
        this.toastr = 'hide';
      }, 5000);
    } else {
      localStorage.removeItem('ClassAccessId');
      localStorage.removeItem('ClassAccessIdHis');
      this.router.navigate(['/attendance/u/', 2]);
  
      if ( this.currentComponent == 'AuditComponent') {
        let camId = 'CameraID';
        this.getCamID = localStorage.getItem(camId);
        let deviceId = 'DeviceID';
        this.getDeviceID = localStorage.getItem(deviceId);
        this.preLoader = 'show';
        this.reloadSecFun();
        const offPrams = {
          cameraDeviceId: this.getDeviceID,
          cameraId: this.getCamID,
          channelId: '11'
        }
        //console.log(offPrams);
        this.classService.offCamera(offPrams).subscribe((offcam: any) => {
          //console.log(offcam);
          if(offcam.success == 1){
            localStorage.removeItem('CameraID');
            localStorage.removeItem('DeviceID');
            location.reload();
          }else{
            //success 0
          }
        })
      }else if(this.currentComponent == 'LiveStreamComponent'){
        localStorage.removeItem('totalCamList');
      }else{}
     }
  }

  // selectClassAttendance(data) {
  //   localStorage.removeItem('ClassAccessId');
  //   localStorage.removeItem('ClassAccessIdHis');
  //   this.courseAccessId = data.accessId;
  //   this.activeClassAtten = data.accessId;
  //   let courseAccessId = 'CourseAccessIDAttendance';
  //   localStorage.setItem(courseAccessId, this.courseAccessId);
  //   let centerAccessId = 'CenterAccessIDAttendance';
  //   //console.log(centerAccessId);
  //   localStorage.setItem(centerAccessId, data.centerAccessId);
  //   this.router.navigate(['/attendance/u/', 2]);

  //   if (this.currentComponent == 'LiveStreamComponent') {
  //     let camId = 'CameraID';
  //     this.getCamID = localStorage.getItem(camId);
  //     let deviceId = 'DeviceID';
  //     this.getDeviceID = localStorage.getItem(deviceId);
  //     this.preLoader = 'show';
  //     this.reloadSecFun();
  //     const offPrams = {
  //       cameraDeviceId: this.getDeviceID,
  //       cameraId: this.getCamID,
  //       channelId: '11'
  //     }
  //     //console.log(offPrams);
  //     this.classService.offCamera(offPrams).subscribe((offcam: any) => {
  //       //console.log(offcam);
  //       if(offcam.success == 1){
  //         localStorage.removeItem('CameraID');
  //         localStorage.removeItem('DeviceID');
  //         location.reload();
  //       }else{
  //         //success 0
  //       }
  //     })
  //   }else{}
  // }

  onclickPiaList() {
    this.router.navigate(['/piaList']);
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    localStorage.removeItem('CourseAccessIDAttendance');
    localStorage.removeItem('piaAccessUserInfo');
    localStorage.removeItem('UserInfoCenterAccessID');
    if (this.currentComponent == 'AuditComponent') {
      let camId = 'CameraID';
      this.getCamID = localStorage.getItem(camId);
      let deviceId = 'DeviceID';
      this.getDeviceID = localStorage.getItem(deviceId);
      this.preLoader = 'show';
      this.reloadSecFun();
      const offPrams = {
        cameraDeviceId: this.getDeviceID,
        cameraId: this.getCamID,
        channelId: '11'
      }
      this.classService.offCamera(offPrams).subscribe((offcam: any) => {
        //console.log(offcam);
        if(offcam.success == 1){
          localStorage.removeItem('CameraID');
          localStorage.removeItem('DeviceID');
          location.reload();
        }else{
          //success 0
        }
      });
    } else if(this.currentComponent == 'LiveStreamComponent'){
      localStorage.removeItem('totalCamList');
    }else { }
  }

  onclickPiaCre() {
    this.router.navigate(['/piaCreation']);
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    localStorage.removeItem('CourseAccessIDAttendance');
    localStorage.removeItem('piaAccessUserInfo');
    localStorage.removeItem('UserInfoCenterAccessID');
    if (this.currentComponent == 'AuditComponent') {
      let camId = 'CameraID';
      this.getCamID = localStorage.getItem(camId);
      let deviceId = 'DeviceID';
      this.getDeviceID = localStorage.getItem(deviceId);
      this.preLoader = 'show';
      this.reloadSecFun();
      const offPrams = {
        cameraDeviceId: this.getDeviceID,
        cameraId: this.getCamID,
        channelId: '11'
      }
      this.classService.offCamera(offPrams).subscribe((offcam: any) => {
        //console.log(offcam);
        if(offcam.success == 1){
          localStorage.removeItem('CameraID');
          localStorage.removeItem('DeviceID');
          location.reload();
        }else{
          // success 0
        }
      });
    }else if(this.currentComponent == 'LiveStreamComponent'){
      localStorage.removeItem('totalCamList');
    } else { }
  }

  ngOnInit() {
    //console.log(localStorage.getItem('CameraID'))
    this.currentComponent = this.route.component['name'];
    //console.log(this.currentComponent);
    if (this.currentComponent == 'AdminDashboardComponent') {
      setTimeout(() => {
        this.toastr = 'show';
      }, 2000);
      setTimeout(() => {
        this.toastr = 'hide';
      }, 9000);
    } else if (this.currentComponent == 'PiaDashboardComponent') {
      setTimeout(() => {
        this.toastrSelectCenter = 'show';
      }, 2000);
      setTimeout(() => {
        this.toastrSelectCenter = 'hide';
      }, 9000);
    } else { }
    let centerAccessId = 'UserInfoCenterAccessID';
    this.centerAccessId = localStorage.getItem(centerAccessId);
    //console.log(this.centerAccessId);
    if (this.centerAccessId != undefined) {
      //console.log('get all class');
      this.liveStream = true;
      this.audit = true;
      this.history = true;
      this.attendance = true;
      this.centerPanel = true;
      this.resources = true;

      let centerAccessId = 'UserInfoCenterAccessID';
      this.centerAccessId = localStorage.getItem(centerAccessId);
      this.classService.getAllClass(this.centerAccessId).subscribe((data: any) => {
        this.classDetails = data.classDetails;
      });
      this.centerService.allCourse(this.centerAccessId).subscribe((data: any) => {
        //console.log(data);
        this.allCourse = data.courseDetails;
      });
      let activeClassView = 'ClassAccessId';
      this.activeClassView = localStorage.getItem(activeClassView);

      let activeClassHistory = 'ClassAccessIdHis';
      this.activeClassHistory = localStorage.getItem(activeClassHistory);

      let activeClassAtten = 'CourseAccessIDAttendance';
      this.activeClassAtten = localStorage.getItem(activeClassAtten);

    } else { }
    this.reloadSecFun();
  }

  resetTime(){
    clearTimeout(this.timer);
  }

  reloadSecFun(){
    this.timer = setTimeout(() => {
      if(this.preLoader == 'show'){
        this.reloadSec = 'r-t-enable';
      }else{
        this.reloadSec = 'reload-t-sec';
      }
    }, 20000);
  }

  onclickReload(){
    location.reload();
  }

}
