import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

import { AuthService } from 'src/app/services/authService/auth.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-create-admin',
  templateUrl: './create-admin.component.html',
  styleUrls: ['./create-admin.component.css']
})
export class CreateAdminComponent implements OnInit {
  form;
  now;
  date;
  datetime;
  timestamp;
  accessId;
  username;
  disableBtn;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthService
  ) {
    this.createForm() ;
   }

  /* ===============================================================
        Validation
  =============================================================== */
  createForm() {
    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.pattern("[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]],
      username: [null, [Validators.required, Validators.pattern("^.{3,15}$")]],
    });
  }

  /* ===============================================================
    Profile Fetch
  =============================================================== */
  fetchProfile(){
    let key = 'UserInfo';
    this.accessId = localStorage.getItem(key);
    this.authService.getProfile(this.accessId).subscribe((profile:any) =>{
      this.username = profile.user.username;
    });
  }

  /* ===============================================================
      Create Admin
  =============================================================== */
  createAdmin() {
    this.disableBtn = 'click-disable';
    this.now = moment();
    this.timestamp = this.now.format('YYYYMMDDHHmmss');
    this.datetime  = this.now.format("YYYY-MM-DD HH:mm:s");
    const user = {
      "email":  this.form.get('email').value,
      "username":  this.form.get('username').value,
      "roleId": 99,
      "accessId":  "DWS-OPTG-AD-"+ this.timestamp,
      "createdBy": this.username,
      "createdAt": this.datetime
    };
    this.authService.registerUser(user).subscribe((data:any) => {
      if(data.success == 1){
        this.toastr.success(data.message, 'success');
      }else{
        this.toastr.info(data.message, 'info');
      }
      this.disableBtn = '';
      this.form.reset();
      this.router.navigate(['/SUDashboard']);
    });
  }

  /* ===============================================================
      Back Function
  =============================================================== */
  back(){
    this.router.navigate(['/SUDashboard']);
  }


  /* ===============================================================
      JavaScript Load 
  =============================================================== */
  public loadScript() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/js/common.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script); 
  }

  ngOnInit() {
    this.loadScript();
    this.fetchProfile();
    this.now = moment();
    this.date = this.now.format('Do MMMM YYYY');
  }

}
