import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'attendanceSearchFilter'
})
export class SearchPipe implements PipeTransform {

  transform(allAttendance: any, search: any): any {
    if (!allAttendance || !search) {
      return allAttendance;
    }
    return allAttendance.filter(allattendance =>
      allattendance.resourceName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      allattendance.resourceCode.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      allattendance.courseName.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }
}
