import { Component, OnInit } from '@angular/core';
import { CCService } from 'src/app/services/cCService/c-c.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';

@Component({
  selector: 'app-sdc-update',
  templateUrl: './sdc-update.component.html',
  styleUrls: ['./sdc-update.component.css']
})
export class SdcUpdateComponent implements OnInit {

  centerList;
  mainSec: boolean = false;
  selectSdc;
  updateSec;
  form: FormGroup;
  loader = 'show';
  disableBtn;
  search;
  sdcEdit;
  editform: FormGroup;
  editSdcSel;
  sdcName;
  sdcCode;
  sdcContactPerson;
  sdcEmail;
  sdcPhone;
  address;
  sdcLocation;
  districtCode;
  district;
  allDistrict;
  districtName;
  getDistrictCode;

  constructor(
    private formBuilder: FormBuilder,
    private cCService: CCService,
    private centerService: CenterDetailsService
  ) { 
    this.createForm(); 
    this.editFormValidation(); 
  }

  /* ===============================================================
        Validation
  =============================================================== */
  createForm() {
    this.form = this.formBuilder.group({
      centerName: [null, [Validators.required, Validators.pattern("^.{3,250}$")]],
      centerCode: [null, [Validators.required, Validators.pattern("^.{3,120}$")]],
    });
  }

  onSelectSdc(sdc) {
    this.selectSdc = sdc;
    this.updateSec = 'overlay-visible';
  }

  back() {
    this.updateSec = '';
    this.form.reset();
    this.selectSdc = undefined;
  }

  onUpdateSdc() {
    this.disableBtn = 'click-disable';
    const updateData = {
      centerAccessId: this.selectSdc.accessId,
      centerName: this.form.get('centerName').value,
      centerCode: this.form.get('centerCode').value
    }
    //console.log(updateData);
    this.centerService.updateCenter(updateData).subscribe((info: any) => {
      //console.log(info);
      this.loader = 'show';
      this.cCService.getAllCenter().subscribe((allSdc: any) => {
        //console.log(allSdc);
        this.centerList = allSdc.centerDetails.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
        this.form.reset();
        this.disableBtn = '';
        this.selectSdc = undefined;
        this.updateSec = '';
        this.loader = 'hide';
        this.search = '';
      });
    });
  }

  somethingChanged(value) {
    if (value.length <= 0) {
      this.mainSec = false;
    } else {
      this.mainSec = true;
    }
  }

  onSelectSdcMigrate(sdc) {
    this.loader = 'show';
    //console.log(sdc);
    const remoData = {
      centerAccessId: sdc.accessId
    }
    //console.log(remoData);
    this.centerService.removeCenter(remoData).subscribe((info: any) => {
      //console.log(info);
      if (info.success === 1) {
        const creData = {
          accessId: sdc.accessId,
          schemeAccessId: sdc.schemeAccessId,
          schemeCode: sdc.schemeCode,
          tpAccessId: sdc.tpAccessId,
          tpCode: sdc.tpCode,
          tpName: sdc.tpName,
          centerCode: sdc.centerCode,
          centerName: sdc.centerName,
          contactPerson: sdc.contactPerson,
          mobile: sdc.mobile,
          email: sdc.email,
          address: sdc.address,
          location: sdc.location,
          districtName: sdc.districtName,
          districtCode: sdc.districtCode,
          centerType: sdc.centerType,
          createdBy: sdc.createdBy,
          createdAt: sdc.createdAt
        }
        //console.log(creData);
        this.centerService.createCenter(creData).subscribe((creInfo: any) => {
         // console.log(creInfo);
          if (creInfo.success === 1) {
            this.cCService.getAllCenter().subscribe((allSdc: any) => {
              //console.log(allSdc);
              this.loader = 'hide';
              this.centerList = allSdc.centerDetails.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
            });
          }
        });
      }
    });
  }

  /* ===============================================================
      Sdc Active inactive
  =============================================================== */
  onclickDeactive(sdc){
    //console.log(sdc);
    this.loader = 'show';
    const deact={
      centerAccessId: sdc.accessId,
      isCenterActive: '0'
    }
    this.centerService.sdcActiveInactive(deact).subscribe((deactInfo:any)=>{
      //console.log(deactInfo);
      if (deactInfo.success === 1) {
        this.cCService.getAllCenter().subscribe((allSdc: any) => {
          //console.log(allSdc);
          this.loader = 'hide';
          this.centerList = allSdc.centerDetails.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
        });
      }
    });
  }

  onclickActive(sdc){
    //console.log(sdc);
    this.loader = 'show';
    const actInact={
      centerAccessId: sdc.accessId,
      isCenterActive: '1'
    }
    this.centerService.sdcActiveInactive(actInact).subscribe((actiInfo:any)=>{
      //console.log(actiInfo);
      if (actiInfo.success === 1) {
        this.cCService.getAllCenter().subscribe((allSdc: any) => {
          //console.log(allSdc);
          this.loader = 'hide';
          this.centerList = allSdc.centerDetails.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
        });
      }
    });
  }

  /* ===============================================================
      JavaScript Load 
  =============================================================== */
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/js/common.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  ngOnInit() {
    this.loadScript();
    this.cCService.getAllCenter().subscribe((allSdc: any) => {
      //console.log(allSdc);
      this.loader = 'hide';
      this.centerList = allSdc.centerDetails.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
      //console.log(this.centerList);
      
    });

    this.centerService.getAllDistrict().subscribe((district: any) => {
      //console.log(district);
      this.allDistrict = district.districtDetails;
    });
  }


  onSelectSdcEdit(sdc){
    this.sdcEdit = 'overlay-visible';
    this.editSdcSel = sdc; 
    //console.log(this.editSdcSel);
    this.sdcName= this.editSdcSel.centerName;
    this.sdcCode = this.editSdcSel.centerCode;
    this.sdcContactPerson= this.editSdcSel.contactPerson;
    this.sdcEmail= this.editSdcSel.email;
    this.sdcPhone= this.editSdcSel.mobile;
    this.address = this.editSdcSel.address;
    this.sdcLocation = this.editSdcSel.location;
    this.districtName = this.editSdcSel.districtName;
    this.getDistrictCode = this.editSdcSel.districtCode;
  }

  /* ===============================================================
      Validation
  =============================================================== */
  editFormValidation() {
    this.editform = this.formBuilder.group({
      sdcName: [null, [Validators.required, Validators.pattern("^.{3,250}$")]],
      sdcCode: [null, [Validators.required, Validators.pattern("^.{3,120}$")]],

      contactPerson: [null, [Validators.required, Validators.pattern("^.{3,20}$")]],
      mobile: [null, Validators.compose([Validators.required, Validators.pattern("^\\d{10,10}$")])],
      email: [null, [Validators.required, Validators.pattern("[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]],
      district: [null, Validators.required],
      location: [null, [Validators.required, Validators.pattern("^.{3,40}$")]],
      address: [null, [Validators.required, Validators.maxLength(150), Validators.minLength(3)]],
    });
  }

  editBack(){
    this.sdcEdit = '';
    this.editSdcSel = undefined; 
    //console.log(this.editSdcSel);
    this.sdcName= undefined;
    this.sdcCode = undefined;
    this.sdcContactPerson= undefined;
    this.sdcEmail= undefined;
    this.sdcPhone= undefined;
    this.address = undefined;
    this.sdcLocation =  undefined;
    this.districtName = undefined;
    this.getDistrictCode = undefined;
  }

  onChangeDis(data) {
    var getAllData = data.split(',');
    this.districtCode = getAllData.pop();
    //console.log(this.districtCode);
    this.district = getAllData.join(',');
    //console.log(this.district);
  }

  onSdcUpdate(){
    const data = {

      accessId: this.editSdcSel.accessId,
      updateData:{
        accessId: this.editSdcSel.accessId,
        schemeAccessId: this.editSdcSel.schemeAccessId,
        schemeCode: this.editSdcSel.schemeCode,
        tpAccessId: this.editSdcSel.tpAccessId,
        tpCode: this.editSdcSel.tpCode,
        tpName: this.editSdcSel.tpName,
        location: this.editform.get('location').value,
        districtName: this.district,
        districtCode: this.districtCode,
        centerType: this.editSdcSel.centerType,
        createdBy: this.editSdcSel.createdBy,
        createdAt: this.editSdcSel.createdAt,  
        centerName: this.editform.get('sdcName').value,
        centerCode: this.editform.get('sdcCode').value,
        contactPerson: this.editform.get('contactPerson').value,
        mobile: this.editform.get('mobile').value,
        email: this.editform.get('email').value,
        address: this.editform.get('address').value,
      }

    }
    //console.log(data);
    this.centerService.editUpdateCenter(data).subscribe((info:any) =>{
      //console.log(info);
      this.sdcEdit = '';
      this.cCService.getAllCenter().subscribe((allSdc: any) => {
        //console.log(allSdc);
        this.loader = 'hide';
        this.centerList = allSdc.centerDetails.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
        //console.log(this.centerList);
      });
    });
  }

}
