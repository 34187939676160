import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { IMqttMessage, MqttModule, IMqttServiceOptions, MqttService } from 'ngx-mqtt';

import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { ClassService } from 'src/app/services/classService/class.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/authService/auth.service';
import { ToastrService } from 'ngx-toastr';
declare var jwplayer: any;

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css']
})
export class AuditComponent implements OnInit {

  stream;
  countData;
  persons;
  total_persons = 0;
  num_recognized = 0;
  num_unrecognized = 0
  private subscription: Subscription;
  public message: string;
  allBatch;
  cameraDeviceId;
  cameraId;
  streamUrl;
  roleId;
  infoData;
  data;
  data1;
  data2;
  data3;
  arWidth;
  arHeight;
  Width;
  textData
  fecialSec: boolean;
  districtName;
  locationName;
  Twidth;
  Hwidth;

  barWidth;
  barHeight;
  bdata
  bdata1
  bdata2
  bdata3
  canvasBool: boolean;
  centerAccessId;
  allCourse;
  courseAccessId;
  intervel;
  firstLoader= 'show';
  secondLoader = 'hide';
   i =0
  @ViewChild('canvas') canvas: ElementRef;
  cx: CanvasRenderingContext2D;
  drawingSubscription: Subscription;

  constructor(
    private router: Router,
    private _sanitizer: DomSanitizer,
    private _mqttService: MqttService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private classService: ClassService,
    private centerService: CenterDetailsService
  ) { }

  detailsFetch() {
    this.classService.getCamInfo(localStorage.getItem('ClassAccessId')).subscribe((camInfo: any) => {
      //console.log(camInfo);
      this.cameraDeviceId = camInfo.cameraDetails.cameraDeviceId;
      this.centerAccessId = camInfo.cameraDetails.centerAccessId;
      this.streamUrl = camInfo.cameraDetails.streamUrl;
      this.infoData = camInfo.cameraDetails;
      this.cameraId =  camInfo.cameraDetails.cameraId;
      //console.log(this.infoData);
      //console.log(this.centerAccessId);
      this.centerService.getCenter(camInfo.cameraDetails.centerAccessId).subscribe((centerDetail: any) => {
        //console.log(centerDetail);
        if (centerDetail.success == 1) {
          this.districtName = centerDetail.centerDetails.districtName;
          this.locationName = centerDetail.centerDetails.location;
          setTimeout(() => {
            this.firstLoader = 'hide';
          }, 30000);
        } else {
          //success 0
        }
      });
      // this.centerService.getAllBatchByCenter(camInfo.cameraDetails.centerAccessId).subscribe((batch: any) => {
      //   //console.log(batch);
      //   this.allBatch = batch.batchDetails;
      // });

      this.centerService.allCourse(camInfo.cameraDetails.centerAccessId).subscribe((data: any) => {
        //console.log(data);
        this.allCourse = data.courseDetails;
        //console.log(this.allCourse)
      });
    })
  }

  onSelectCourse(course){
    //console.log(course);
    this.courseAccessId= course.accessId;
    this.centerService.enrollmentDetails(course.centerAccessId, course.accessId).subscribe((data: any) => {
      if(data.success === 1){
        this.canvasBool = true;
        this.fecialSec = true;
        const inspeData = {
          userId: 'RSLDC',
          centerAccessId: this.centerAccessId,
          cameraDeviceId: this.cameraDeviceId,
          courseAccessId: course.accessId,
          streamUrl: 'https://638ee05658d80.streamlock.net/live/' + this.cameraId + '/playlist.m3u8',
          streamIp: '52.183.136.186',
          streamPort: '1935'
        }
        //console.log(inspeData);
        this.centerService.inspectStream(inspeData).subscribe((inspect: any) => {
          //console.log(inspect);
          if (inspect.success == 1) {
            this.autoUnsubscribe();
            this.toastrService.info(inspect.message, 'Audit started');
            this.subscription = this._mqttService.observe(this.cameraDeviceId).subscribe((message: IMqttMessage) => {
              //console.log(message)
              this.message = message.payload.toString();
              this.countData = JSON.parse(this.message);
              //console.log(this.i++)
              //this.location = this.data.location;
              //console.log(this.countData);
              if(this.countData.status === 1){
                this.persons = this.countData.person_detections;
                this.total_persons = this.countData.total_persons;
                this.num_recognized = this.countData.num_recognized;
                this.num_unrecognized = this.countData.num_unrecognized;
                const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
                this.cx = canvasEl.getContext('2d');
                // set the width and height
                canvasEl.width = window.innerWidth;
                canvasEl.height = window.innerHeight;
      
                this.countData.recognized_person_detections.forEach(element => {
                  //console.log(element);
                  this.arWidth = window.innerWidth / 1920;
                  this.arHeight = window.innerHeight / 1080;
                  this.Width = this.data - this.data2;
                  this.textData = this.Width / 2;
                  this.data = element.x2 * this.arWidth;
                  this.data1 = element.y2 * this.arHeight;
                  this.data2 = element.x1 * this.arWidth;
                  this.data3 = element.y1 * this.arHeight;
                  this.Twidth = this.cx.measureText(element.batchId).width;
                  this.Hwidth = this.Twidth / 2;
                  this.cx.lineWidth = 2;
                  this.cx.strokeStyle = '#ccff00';
                  this.cx.strokeRect(this.data2, this.data3, this.data - this.data2, this.data1 - this.data3);
                  this.cx.fillStyle = '#ccff00';
                  this.cx.fillRect(this.data2 - this.Hwidth, this.data3 - 35, this.Twidth + 40, 30);
                  this.cx.textAlign = "center";
                  this.cx.font = "600 12px Lato";
                  this.cx.fillStyle = "#000000";
                  this.cx.fillText(element.name, this.data2 + this.textData, this.data3 - 25);
                  this.cx.fillText(element.batchId, this.data2 + this.textData, this.data3 - 10);
                });
                this.countData.person_bbox_detections.forEach(bBox => {
                  this.barWidth = window.innerWidth / 1920;
                  this.barHeight = window.innerHeight / 1080;
                  this.bdata = bBox.x2 * this.barWidth;
                  this.bdata1 = bBox.y2 * this.barHeight;
                  this.bdata2 = bBox.x1 * this.barWidth;
                  this.bdata3 = bBox.y1 * this.barHeight;
      
                  this.cx.lineWidth = 1;
                  this.cx.strokeStyle = '#FF5733';
                  this.cx.strokeRect(this.bdata2, this.bdata3, this.bdata - this.bdata2, this.bdata1 - this.bdata3);
                });
              }else if(this.countData.status === 2){
                this.autoUnsubscribe();
              }else if(this.countData.status === 0){
                this.toastrService.error('Try again after a few minutes', 'Audit failed');
              }
    
            });
          } else { 
            this.toastrService.warning(inspect.message, 'Audit responce');
            setTimeout(() => {
              this.onClickBack();
            }, 5000);
            //console.log('fff');
          }
        });
      }else{
        this.canvasBool = true;
        this.fecialSec = true;
        const inspeData = {
          userId: 'RSLDC',
          centerAccessId: this.centerAccessId,
          cameraDeviceId: this.cameraDeviceId,
          courseAccessId: 'DWS-OP-RSLDC-1234',
          streamUrl: 'https://' + this.streamUrl + '/playlist.m3u8',
          streamIp: '52.183.136.186',
          streamPort: '1935'
        }
        //console.log(inspeData);
        this.centerService.inspectStream(inspeData).subscribe((inspect: any) => {
          //console.log(inspect);
          if (inspect.success == 1) {
            this.autoUnsubscribe();
            this.toastrService.info(inspect.message, 'Audit started');
            this.subscription = this._mqttService.observe(this.cameraDeviceId).subscribe((message: IMqttMessage) => {
              //console.log(message)
              this.message = message.payload.toString();
              this.countData = JSON.parse(this.message);
              //this.location = this.data.location;
              //console.log(this.countData);
              if(this.countData.status === 1){
                this.persons = this.countData.person_detections;
                this.total_persons = this.countData.total_persons;
                this.num_recognized = this.countData.num_recognized;
                this.num_unrecognized = this.countData.num_unrecognized;
                const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
                this.cx = canvasEl.getContext('2d');
                // set the width and height
                canvasEl.width = window.innerWidth;
                canvasEl.height = window.innerHeight;
      
                this.countData.recognized_person_detections.forEach(element => {
                  //console.log(element);

                  this.arWidth = window.innerWidth / 1920;
                  this.arHeight = window.innerHeight / 1080;
                  this.Width = this.data - this.data2;
                  this.textData = this.Width / 2;
                  this.data = element.x2 * this.arWidth;
                  this.data1 = element.y2 * this.arHeight;
                  this.data2 = element.x1 * this.arWidth;
                  this.data3 = element.y1 * this.arHeight;
                  this.Twidth = this.cx.measureText(element.batchId).width;
                  this.cx.lineWidth = 2;
                  this.cx.strokeStyle = '#ccff00';
                  this.cx.strokeRect(this.data2, this.data3, this.data - this.data2, this.data1 - this.data3);
                  this.cx.fillStyle = '#ccff00';
                  // this.cx.fillRect(this.data2, this.data3 - 35, this.Twidth, 30);
                  this.cx.textAlign = "center";
                  this.cx.font = "600 12px Lato";
                  this.cx.fillStyle = "#000000";
                  this.cx.fillText(element.name, this.data2 + this.textData, this.data3 - 10);
                  this.cx.fillText(element.batchId, this.data2 + this.textData, this.data3 - 25);
                });
                this.countData.person_bbox_detections.forEach(bBox => {
                  this.barWidth = window.innerWidth / 1920;
                  this.barHeight = window.innerHeight / 1080;
                  this.bdata = bBox.x2 * this.barWidth;
                  this.bdata1 = bBox.y2 * this.barHeight;
                  this.bdata2 = bBox.x1 * this.barWidth;
                  this.bdata3 = bBox.y1 * this.barHeight;
      
                  this.cx.lineWidth = 1;
                  this.cx.strokeStyle = '#FF5733';
                  this.cx.strokeRect(this.bdata2, this.bdata3, this.bdata - this.bdata2, this.bdata1 - this.bdata3);
                });
              }else if(this.countData.status === 2){
                this.autoUnsubscribe()
              }else if(this.countData.status === 0){
                this.toastrService.error('Try again after a few minutes', 'Audit failed');
              }
    
            });
          } else {
            this.toastrService.warning(inspect.message, 'Audit responce');
            setTimeout(() => {
              this.onClickBack();
            }, 5000);
            //console.log('fff');
            
           }
        });
      }
    });
  }

  onSelectBatch() {
    //console.log();
    this.canvasBool = true;
    this.fecialSec = true;
    const inspeData = {
      userId: 'RSLDC',
      centerAccessId: this.centerAccessId,
      cameraDeviceId: this.cameraDeviceId,
      streamUrl: 'https://' + this.streamUrl + '/playlist.m3u8',
      streamIp: '52.183.136.186',
      streamPort: '1935'
    }
    //console.log(inspeData);
    this.centerService.inspectStream(inspeData).subscribe((inspect: any) => {
      //console.log(inspect);
      if (inspect.success == 1) {
        //this.autoUnsubscribe();
        this.toastrService.info('It will stop by itself in 2 minutes', 'Audit started');
        this.subscription = this._mqttService.observe(this.cameraDeviceId).subscribe((message: IMqttMessage) => {
          //console.log(message)
          this.message = message.payload.toString();
          this.countData = JSON.parse(this.message);
          //this.location = this.data.location;
          //console.log(this.countData);
          if(this.countData.status === 1){
            this.persons = this.countData.person_detections;
            this.total_persons = this.countData.total_persons;
            this.num_recognized = this.countData.num_recognized;
            this.num_unrecognized = this.countData.num_unrecognized;
            const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
            this.cx = canvasEl.getContext('2d');
            // set the width and height
            canvasEl.width = window.innerWidth;
            canvasEl.height = window.innerHeight;
  
            this.countData.recognized_person_detections.forEach(element => {
              //console.log(element);
              this.arWidth = window.innerWidth / 1920;
              this.arHeight = window.innerHeight / 1080;
              this.Width = this.data - this.data2;
              this.textData = this.Width / 2;
              this.data = element.x2 * this.arWidth;
              this.data1 = element.y2 * this.arHeight;
              this.data2 = element.x1 * this.arWidth;
              this.data3 = element.y1 * this.arHeight;
              this.Twidth = this.cx.measureText(element.batchId).width;
              this.cx.lineWidth = 2;
              this.cx.strokeStyle = '#ccff00';
              this.cx.strokeRect(this.data2, this.data3, this.data - this.data2, this.data1 - this.data3);
              this.cx.fillStyle = '#ccff00';
              // this.cx.fillRect(this.data2, this.data3 - 35, this.Twidth, 30);
              this.cx.textAlign = "center";
              this.cx.font = "600 12px Lato";
              this.cx.fillStyle = "#000000";
              this.cx.fillText(element.name, this.data2 + this.textData, this.data3 - 10);
              this.cx.fillText(element.batchId, this.data2 + this.textData, this.data3 - 25);
            });
            this.countData.person_bbox_detections.forEach(bBox => {
              this.barWidth = window.innerWidth / 1920;
              this.barHeight = window.innerHeight / 1080;
              this.bdata = bBox.x2 * this.barWidth;
              this.bdata1 = bBox.y2 * this.barHeight;
              this.bdata2 = bBox.x1 * this.barWidth;
              this.bdata3 = bBox.y1 * this.barHeight;
  
              this.cx.lineWidth = 1;
              this.cx.strokeStyle = '#FF5733';
              this.cx.strokeRect(this.bdata2, this.bdata3, this.bdata - this.bdata2, this.bdata1 - this.bdata3);
            });
          }else if(this.countData.status === 2){
            this.autoUnsubscribe()
          }else if(this.countData.status === 0){
            this.toastrService.error('Try again after a few minutes', 'Audit failed');
          }

        });
      } else { }
    });
  }

  public unsafePublish(topic: string, message: string): void {
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: true });
  }

  autoUnsubscribe() {
    this.intervel = setInterval(()=>{
      this.toastrService.info('The attempt was successful', 'Audit stopped');
      this.onClickBack();
      clearInterval(this.intervel);
      this.fecialSec = false;
      this.subscription.unsubscribe();
      this.canvasBool = false;
      this.total_persons = 0;
      this.num_recognized = 0;
      this.num_unrecognized = 0
      this.countData.recognized_person_detections.forEach(element => {
        //console.log(element)
        this.arWidth = window.innerWidth / 1920;
        this.arHeight = window.innerHeight / 1080;
        this.data = element.x2 * this.arWidth;
        this.data1 = element.y2 * this.arHeight;
        this.data2 = element.x1 * this.arWidth;
        this.data3 = element.y1 * this.arHeight;
        this.cx.clearRect(this.data2, this.data3, this.data - this.data2, this.data1 - this.data3);

        this.countData.person_bbox_detections.forEach(bBox => {
          this.barWidth = window.innerWidth / 1920;
          this.barHeight = window.innerHeight / 1080;
          this.bdata = bBox.x2 * this.barWidth;
          this.bdata1 = bBox.y2 * this.barHeight;
          this.bdata2 = bBox.x1 * this.barWidth;
          this.bdata3 = bBox.y1 * this.barHeight;
          this.cx.strokeRect(this.bdata2, this.bdata3, this.bdata - this.bdata2, this.bdata1 - this.bdata3);
        });
      });
    }, 120000)
  }

  onClickBack() {
    localStorage.removeItem('CameraUrl');
    localStorage.removeItem('ClassAccessId');
    const offPrams = {
      cameraDeviceId: this.cameraDeviceId,
      cameraId: this.cameraId,
      channelId: '13'
    }
    //console.log(offPrams);
    this.classService.onCameraForAudit(offPrams).subscribe((offCam: any) => {
      //console.log(offCam);
        if (this.roleId === '99') {
          this.router.navigate(['/sdcdetail']);
    
        } else if (this.roleId === '22') {
          this.router.navigate(['/cCSearch']);
    
        } else if (this.roleId === '00') {
          this.router.navigate(['centerDashboard/u/', 3]);
         } else { }
    });


  }

  ngOnInit() {
    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      this.roleId = profile.user.roleId;
    });
    /* This code for Flash player */
    //this.stream = this._sanitizer.bypassSecurityTrustUrl('rtmp://' + localStorage.getItem('CameraUrl'));
    //console.log(this.stream);

    /* This code for JW player */
    // jwplayer('video').setup({
    //   file: 'http://' + localStorage.getItem('CameraUrl') + '/playlist.m3u8',
    //   height: 500,
    //   width: 500,
    // });
    this.stream  = 'http://' + localStorage.getItem('CameraUrl') + '/playlist.m3u8'
    this.detailsFetch();
  }

}
