import { Component, OnInit, Output, EventEmitter, Compiler } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from 'src/app/services/authService/auth.service';
import { ClassService } from 'src/app/services/classService/class.service';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';

@Component({
  selector: 'app-pia-nav',
  templateUrl: './pia-nav.component.html',
  styleUrls: ['./pia-nav.component.css']
})
export class PiaNavComponent implements OnInit {

  accessId;
  usertype;
  currentComponent;
  centerAccessId;
  classDetails;
  listClass: boolean;
  listClassHistory: boolean;
  listClassAttendance: boolean;
  classAccessId;
  toastr = 'hide';
  toastrCannotAssgin = 'hide';
  classCuntCss = 'hide';
  camDetails;
  allCourse;
  courseAccessId;
  activeClassView;
  activeClassHis;
  activeClassAttendance;
  getCamID;
  getDeviceID;
  camNtFond = 'hide';
  preLoader = 'hide';
  reloadSec= 'reload-t-sec';

  liveStream: boolean;
  history: boolean;
  resouces: boolean;
  attendance: boolean;
  sdcPanel: boolean;

  roleId;

  public timer;
  allCamera;
  @Output()
  uploaded = new EventEmitter<string>();

  @Output()
  uploadedHis = new EventEmitter<string>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _compiler: Compiler,
    private authService: AuthService,
    private classService: ClassService,
    private centerService: CenterDetailsService
  ) { }

  onClickAnalytics() {
    this.router.navigate(['/piaDashboard/u/', 0]);
    this.route.params.subscribe(params => {
      this.usertype = params.userType;
    });
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    localStorage.removeItem('CourseAccessIDAttendance');
    if (this.currentComponent == 'LiveStreamComponent') {
      //localStorage.removeItem('totalCamList');
      this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
      for (let a = 0; a < this.allCamera.length; a++) {
        setTimeout(() => {
          //console.log(this.allCamera[a].isCameraActive)
          if (this.allCamera[a].isCameraActive === '1') {
            const offPrams = {
              cameraDeviceId: this.allCamera[a].cameraDeviceId,
              cameraId: this.allCamera[a].cameraId,
              channelId: '13'
            }
            //console.log(offPrams);
            this.classService.offCamera(offPrams).subscribe((offCam: any) => {
              //console.log(offCam);
            });
          }
          if (a + 1 === this.allCamera.length) {
            localStorage.removeItem('totalCamList');
            //console.log('f')
          }else{}
        }, 1000 * (a + 1));
      }
    } else { }
    if (this.usertype == 1) {
      let centerAccessId = 'UserInfoCenterAccessID';
      localStorage.removeItem(centerAccessId);
    } else { }
  }

  onclicSdcPanel() {
    this.router.navigate(['/centerDashboard/u/', 1]);
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    localStorage.removeItem('CourseAccessIDAttendance');
    if (this.currentComponent == 'LiveStreamComponent') {
      //localStorage.removeItem('totalCamList');
      this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
      for (let a = 0; a < this.allCamera.length; a++) {
        setTimeout(() => {
          //console.log(this.allCamera[a].isCameraActive)
          if (this.allCamera[a].isCameraActive === '1') {
            const offPrams = {
              cameraDeviceId: this.allCamera[a].cameraDeviceId,
              cameraId: this.allCamera[a].cameraId,
              channelId: '13'
            }
            //console.log(offPrams);
            this.classService.offCamera(offPrams).subscribe((offCam: any) => {
              //console.log(offCam);
            });
          }
          if (a + 1 === this.allCamera.length) {
            localStorage.removeItem('totalCamList');
            //console.log('f')
          }else{}
        }, 1000 * (a + 1));
      }
    } else { }
  }

  onClickResources() {
    this.router.navigate(['resource']);
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    localStorage.removeItem('CourseAccessIDAttendance');
    if (this.currentComponent == 'LiveStreamComponent') {
      //localStorage.removeItem('totalCamList');
      this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
      for (let a = 0; a < this.allCamera.length; a++) {
        setTimeout(() => {
          //console.log(this.allCamera[a].isCameraActive)
          if (this.allCamera[a].isCameraActive === '1') {
            const offPrams = {
              cameraDeviceId: this.allCamera[a].cameraDeviceId,
              cameraId: this.allCamera[a].cameraId,
              channelId: '13'
            }
            //console.log(offPrams);
            this.classService.offCamera(offPrams).subscribe((offCam: any) => {
              //console.log(offCam);
            });
          }
          if (a + 1 === this.allCamera.length) {
            localStorage.removeItem('totalCamList');
            //console.log('f')
          }else{}
        }, 1000 * (a + 1));
      }
    } else { }
  }

  onLogoutClick() {
    if (this.currentComponent == 'LiveStreamComponent') {
      //localStorage.removeItem('totalCamList');
      this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
      for (let a = 0; a < this.allCamera.length; a++) {
        setTimeout(() => {
          //console.log(this.allCamera[a].isCameraActive)
          if (this.allCamera[a].isCameraActive === '1') {
            const offPrams = {
              cameraDeviceId: this.allCamera[a].cameraDeviceId,
              cameraId: this.allCamera[a].cameraId,
              channelId: '13'
            }
            //console.log(offPrams);
            this.classService.offCamera(offPrams).subscribe((offCam: any) => {
              //console.log(offCam);
            });
          }
          if (a + 1 === this.allCamera.length) {
            localStorage.removeItem('totalCamList');
            //console.log('f')
            let key = 'UserInfo';
            this.accessId = localStorage.getItem(key);
            const accessId = {
              accessId: this.accessId,
            }
            this.authService.userLogout(accessId).subscribe((data) => {
              //console.log(data);
              this.router.navigate(['/']);
              localStorage.clear();
              this._compiler.clearCache();
              setTimeout(() => {
                location.reload();
              }, 100);
            })
          }else{}
        }, 1000 * (a + 1));
      }
    } else {
      let key = 'UserInfo';
      this.accessId = localStorage.getItem(key);
      const accessId = {
        accessId: this.accessId,
      }
      this.authService.userLogout(accessId).subscribe((data) => {
        //console.log(data);
        this.router.navigate(['/']);
        localStorage.clear();
        this._compiler.clearCache();
        setTimeout(() => {
          location.reload();
        }, 100);
      });
    }
  }

  onClickLiveStream() {
    //this.listClass = !this.listClass;
    this.listClassHistory = false;
    this.listClassAttendance = false;
    this.router.navigate(['liveStream/u/', 1]);
  }

  selectClass(data) {
    if (data.isCameraAssigned == '1') {
      localStorage.removeItem('ClassAccessIdHis');
      localStorage.removeItem('CourseAccessIDAttendance');
      this.classAccessId = data.accessId;
      localStorage.setItem('ClassAccessId', this.classAccessId);
      this.classService.getCamInfo(this.classAccessId).subscribe((camData: any) => {
        //console.log(camData);
        const camAvailData = {
          cameraDeviceId: camData.cameraDetails.cameraDeviceId
        }
        this.classService.cameraAvailabililty(camAvailData).subscribe((camAvail: any) => {
          //console.log(camAvail);
          if (camAvail.success == 1) {
            this.activeClassView = data.accessId;
            this.listClass = false;
            if (camData.success == 1) {
              let getCamId = 'CameraID';
              this.getCamID = localStorage.getItem(getCamId);
              if (this.getCamID == null) {
                const onPrams = {
                  cameraDeviceId: camData.cameraDetails.cameraDeviceId,
                  cameraId: camData.cameraDetails.cameraId,
                  channelId: '13'
                }
                this.preLoader = 'show';
                this.reloadSecFun();
                this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                  //console.log(onCam);
                  if(onCam.success == 1){
                    let camId = 'CameraID';
                    localStorage.setItem(camId, camData.cameraDetails.cameraId);
                    let deviceId = 'DeviceID';
                    localStorage.setItem(deviceId, camData.cameraDetails.cameraDeviceId)
  
                    this.camDetails = camData.cameraDetails;
                    //console.log(this.camDetails);
                    let key = 'CameraUrl';
                    localStorage.setItem(key, this.camDetails.streamUrl);
                    this.router.navigate(['liveStream/u/', 1]);
                    setTimeout(() => {
                      location.reload();
                    }, 2000);
                  }else{
                    // success 0
                  }
                });
              } else {
                /*Camera Off */
                this.preLoader = 'show';
                let getCamId = 'CameraID';
                this.resetTime();
                this.reloadSecFun();
                this.getCamID = localStorage.getItem(getCamId);
                let deviceId = 'DeviceID';
                this.getDeviceID = localStorage.getItem(deviceId);
                if (camData.cameraDetails.cameraId != this.getCamID) {
                  const offPrams = {
                    cameraDeviceId: this.getDeviceID,
                    cameraId: this.getCamID,
                    channelId: '11'
                  }
                  this.classService.offCamera(offPrams).subscribe((offcam: any) => {
                    //console.log(offcam);
                    /*Camera Start */
                    if(offcam.success == 1){
                      setTimeout(() => {
                        const onPrams = {
                          cameraDeviceId: camData.cameraDetails.cameraDeviceId,
                          cameraId: camData.cameraDetails.cameraId,
                          channelId: '13'
                        }
                        this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                          //console.log(onCam);
                          setTimeout(() => {
                            let camId = 'CameraID';
                            localStorage.setItem(camId, camData.cameraDetails.cameraId);
                            let deviceId = 'DeviceID';
                            localStorage.setItem(deviceId, camData.cameraDetails.cameraDeviceId)
                          }, 2000);
                          this.camDetails = camData.cameraDetails;
                          //console.log(this.camDetails);
                          let key = 'CameraUrl';
                          localStorage.setItem(key, this.camDetails.streamUrl);
                          this.router.navigate(['liveStream/u/', 1]);
                          setTimeout(() => {
                            location.reload();
                          }, 2000);
                        });
                      }, 2000);
                    }else{
                      // success 0
                    }
                  });
                } else {
                  
                 }
              }
            } else { }
          } else {
            //console.log(camAvail.message);
            this.camNtFond = 'show';
            localStorage.removeItem('ClassAccessId');
            setTimeout(() => {
              this.camNtFond = 'hide';
            }, 5000);
          }
        });

      });
    } else {
      this.toastrCannotAssgin = 'show';
      setTimeout(() => {
        this.toastrCannotAssgin = 'hide';
      }, 5000);
    }
  }

  onClickHistory() {
    this.listClassHistory = !this.listClassHistory;
    this.listClass = false;
    this.listClassAttendance = false;
  }

  selectClassHistory(data) {
    //if (data.isCameraAssigned == '1') {
      localStorage.removeItem('ClassAccessId');
      localStorage.removeItem('CourseAccessIDAttendance');
      this.classAccessId = data.accessId;
      this.activeClassHis = data.accessId;
      let classAccessId = 'ClassAccessIdHis';
      localStorage.setItem(classAccessId, this.classAccessId);
      this.router.navigate(['/history/u/', 1]);
      this.uploadedHis.emit('complete');
      if (this.currentComponent == 'LiveStreamComponent') {
        //localStorage.removeItem('totalCamList');
        this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
        for (let a = 0; a < this.allCamera.length; a++) {
          setTimeout(() => {
            //console.log(this.allCamera[a].isCameraActive)
            if (this.allCamera[a].isCameraActive === '1') {
              const offPrams = {
                cameraDeviceId: this.allCamera[a].cameraDeviceId,
                cameraId: this.allCamera[a].cameraId,
                channelId: '13'
              }
              //console.log(offPrams);
              this.classService.offCamera(offPrams).subscribe((offCam: any) => {
                //console.log(offCam);
              });
            }
            if (a + 1 === this.allCamera.length) {
              localStorage.removeItem('totalCamList');
              //console.log('f')
            }else{}
          }, 1000 * (a + 1));
        }
      } else { }
    // } else {
    //   this.toastrCannotAssgin = 'show';
    //   setTimeout(() => {
    //     this.toastrCannotAssgin = 'hide';
    //   }, 5000);
    // }
  }

  onClickAttendance() {
    this.listClass = false;
    this.listClassHistory = false;
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    this.router.navigate(['/attendance/u/', 1]);
    if (this.currentComponent == 'LiveStreamComponent') {
      //localStorage.removeItem('totalCamList');
      this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
      for (let a = 0; a < this.allCamera.length; a++) {
        setTimeout(() => {
          //console.log(this.allCamera[a].isCameraActive)
          if (this.allCamera[a].isCameraActive === '1') {
            const offPrams = {
              cameraDeviceId: this.allCamera[a].cameraDeviceId,
              cameraId: this.allCamera[a].cameraId,
              channelId: '13'
            }
            //console.log(offPrams);
            this.classService.offCamera(offPrams).subscribe((offCam: any) => {
              //console.log(offCam);
            });
          }
          if (a + 1 === this.allCamera.length) {
            localStorage.removeItem('totalCamList');
            //console.log('f')
          }else{}
        }, 1000 * (a + 1));
      }
    } else { }
  }

  selectClassAttendance(data) {
    //console.log(data);
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    this.courseAccessId = data.accessId;
    this.activeClassAttendance = data.accessId;
    let courseAccessId = 'CourseAccessIDAttendance';
    localStorage.setItem(courseAccessId, this.courseAccessId);
    let centerAccessId = 'CenterAccessIDAttendance';
    //console.log(centerAccessId);
    localStorage.setItem(centerAccessId, data.centerAccessId);
    this.router.navigate(['/attendance/u/', 1]);
    if (this.currentComponent == 'LiveStreamComponent') {
      localStorage.removeItem('totalCamList');
    } else { }
  }

  onclickCenterList() {
    this.router.navigate(['/centersList']);
    this.route.params.subscribe(params => {
      this.usertype = params.userType;
    });
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    localStorage.removeItem('CourseAccessIDAttendance');
    if (this.currentComponent == 'LiveStreamComponent') {
      //localStorage.removeItem('totalCamList');
      this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
      for (let a = 0; a < this.allCamera.length; a++) {
        setTimeout(() => {
          //console.log(this.allCamera[a].isCameraActive)
          if (this.allCamera[a].isCameraActive === '1') {
            const offPrams = {
              cameraDeviceId: this.allCamera[a].cameraDeviceId,
              cameraId: this.allCamera[a].cameraId,
              channelId: '13'
            }
            //console.log(offPrams);
            this.classService.offCamera(offPrams).subscribe((offCam: any) => {
              //console.log(offCam);
            });
          }
          if (a + 1 === this.allCamera.length) {
            localStorage.removeItem('totalCamList');
            //console.log('f')
          }else{}
        }, 1000 * (a + 1));
      }
    } else { }
  }

  onclickCenCre() {
    this.router.navigate(['/centerCreation']);
    this.route.params.subscribe(params => {
      this.usertype = params.userType;
    });
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    localStorage.removeItem('CourseAccessIDAttendance');
    if (this.currentComponent == 'LiveStreamComponent') {
      //localStorage.removeItem('totalCamList');
      this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
      for (let a = 0; a < this.allCamera.length; a++) {
        setTimeout(() => {
          //console.log(this.allCamera[a].isCameraActive)
          if (this.allCamera[a].isCameraActive === '1') {
            const offPrams = {
              cameraDeviceId: this.allCamera[a].cameraDeviceId,
              cameraId: this.allCamera[a].cameraId,
              channelId: '13'
            }
            //console.log(offPrams);
            this.classService.offCamera(offPrams).subscribe((offCam: any) => {
              //console.log(offCam);
            });
          }
          if (a + 1 === this.allCamera.length) {
            localStorage.removeItem('totalCamList');
            //console.log('f')
          }else{}
        }, 1000 * (a + 1));
      }
    } else { }
  }

  ngOnInit() {
    this.currentComponent = this.route.component['name'];
    //console.log(this.currentComponent);
    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      console.log(profile);
      this.roleId = profile.user.roleId;
    });
    if (this.currentComponent == 'PiaDashboardComponent') {
      setTimeout(() => {
        this.toastr = 'show';
      }, 2000);
      setTimeout(() => {
        this.toastr = 'hide';
      }, 9000);
    } else { }
    let centerAccessId = 'UserInfoCenterAccessID';
    this.centerAccessId = localStorage.getItem(centerAccessId);
    //console.log(this.centerAccessId); 
    if (this.centerAccessId != undefined) {
      //console.log('get all class');
      this.liveStream = true;
      this.history = true;
      this.attendance = true;
      this.sdcPanel = true;
      this.resouces = true;
      let centerAccessId = 'UserInfoCenterAccessID';
      this.centerAccessId = localStorage.getItem(centerAccessId);
      this.classService.getAllClass(this.centerAccessId).subscribe((data: any) => {
        this.classDetails = data.classDetails;
      });
      this.centerService.allCourse(this.centerAccessId).subscribe((data: any) => {
        //console.log(data);
        this.allCourse = data.courseDetails;
      });

      let activeClassView = 'ClassAccessId';
      this.activeClassView = localStorage.getItem(activeClassView);

      let activeClassHis = 'ClassAccessIdHis';
      this.activeClassHis = localStorage.getItem(activeClassHis);

      let activeClassAttendance = 'CourseAccessIDAttendance';
      this.activeClassAttendance = localStorage.getItem(activeClassAttendance);
    } else {
      //console.log('other');
    }
    this.reloadSecFun();
  }

  resetTime(){
    clearTimeout(this.timer);
  }

  reloadSecFun(){
   setTimeout(() => {
      if(this.preLoader == 'show'){
        this.reloadSec = 'r-t-enable';
      }else{
        this.reloadSec = 'reload-t-sec';
      }
    }, 20000);
  }

  onclickReload(){
    location.reload();
  }

  /* ===============================================================
      Streamdian Video JS JavaScript Load 
  =============================================================== */
  public loadScript() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/player/player.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script); 
  }
}
