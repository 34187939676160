import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { AdminServiceService } from 'src/app/services/adminService/admin-service.service';
import { PiaServiceService } from 'src/app/services/piaService/pia-service.service';

import * as moment from 'moment';
import { AuthService } from 'src/app/services/authService/auth.service';

@Component({
  selector: 'app-center-list',
  templateUrl: './center-list.component.html',
  styleUrls: ['./center-list.component.css']
})
export class CenterListComponent implements OnInit {

  now;
  date;
  accessId;
  allCenters;
  schemes;
  piaDetils
  usertype;
  centerList
  schemeAsccessId;
  schemeCode;
  roleId;

  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private authService: AuthService,
    private adminService: AdminServiceService,
    private piaService: PiaServiceService,
    private centerService: CenterDetailsService
  ) { 

  }

  piaDetailsFetch(){
    //DWS-OPTG-PA-20221019132611


    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      console.log(profile);
      this.roleId = profile.user.roleId;
      if(profile.user.roleId === '88'){
        this.accessId = localStorage.getItem('UserInfo')
        this.piaService.getPia(this.accessId).subscribe((piaDetils:any) =>{
          console.log(piaDetils);
          this.piaDetils = piaDetils.tpDetails;
          this.schemeAsccessId = this.piaDetils.schemeAccessId;
          this.schemeCode = this.piaDetils.schemeCode;
          this.centerService.getAllCenter(this.accessId).subscribe((allCenter:any) =>{
            this.allCenters = allCenter.centerDetails;   
            this.centerList = this.allCenters.filter(centerLst => centerLst.schemeAccessId === this.schemeAsccessId );
            console.log(this.centerList);
          });
       })
      }else if(profile.user.roleId === '44'){

        //http://20.204.97.191:1919/tpDetail/getAllCenter/DWS-OPTG-PA-20221019132611
        //http://20.204.97.191:1919/tpDetail/getAllCenter/DWS-OPTG-PA-20221019132611

        this.accessId = profile.user.accessId
        this.centerService.getAllCenterByScheme(this.accessId).subscribe((allCenter:any) =>{
          this.allCenters = allCenter.centerDetails;   
          this.centerList = this.allCenters;
          console.log(this.centerList);
        });
      }
    })

  }

  onSelectionChange(data){
    this.schemeAsccessId = data.schemeAccessId;
    this.schemeCode = data.schemeCode;
    let key = 'UserInfo';
    this.accessId = localStorage.getItem(key);
    this.centerService.getAllCenter(this.accessId).subscribe((allCenter:any) =>{
      this.allCenters = allCenter.centerDetails;   
      this.centerList = this.allCenters.filter(centerLst => centerLst.schemeAccessId === this.schemeAsccessId );
    });
  }

  /* ===============================================================
      Center Dashboard Route
  =============================================================== */
  onSelectCenter(accessId){
    let centerAccessId = 'UserInfoCenterAccessID';
    localStorage.setItem(centerAccessId, accessId);
    this.router.navigate(['/centerDashboard/u/', 1])
  }

  /* ===============================================================
      Back Function
  =============================================================== */
  back(){
    this.router.navigate(['/piaDashboard/u/', 0])
  }

  ngOnInit() {
    this.piaDetailsFetch();
    this.now = moment(); // add this 2 of 4
    this.date = this.now.format('Do MMMM YYYY');
  }

}
