import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services/authService/auth.service';
import { ImageCaptureService } from '../../services/image-capture.service';
import { VideoRecordService } from '../../services/video-record.service';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

declare var $: any;
declare var Chart: any;
declare var window: any;
declare var document: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  profileData;
  roleId;
  accessId;
  isVerified;
  isActive;
  username;
  loader: boolean = false;
  constructor(
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    private videoRecordService: VideoRecordService,
    private imageCaptureService: ImageCaptureService,
  ) { }

  /* ===============================================================
       Profile Fetch
   =============================================================== */
  fetchProfile() {
    this.loader = true;
    const user = {
      username: 'Commandcenter',
      password: 'rsldc2015@492!'
    }
    this.authService.login(user).subscribe((data: any) => {
      console.log(data);
      
      if (data.success == 1) {
        this.accessId = data.user.accessId;
        this.authService.storeUserData(data.token);

        let key = 'UserInfo';
        localStorage.setItem(key, this.accessId);

        this.authService.getProfile(this.accessId).subscribe((profile: any) => {
          if (profile.success == 0) {
            this.authService.userLogout(this.accessId).subscribe((data) => {
              this.router.navigate(['/']);
            });
          } else {
            this.loader = false;
            this.isVerified = profile.user.isVerified;
            if (this.isVerified == 0) {
              this.router.navigate(['/verify']);
            } else {
              this.isActive = profile.user.isActive
              if (this.isActive == 0) {
                console.log('Another Screen and account suspended');
              } else {
                this.roleId = profile.user.roleId;
                this.username = profile.user.username;
                //console.log(this.username);
                if (this.username == 'ATPLADMIN' || this.username == 'ADMINAPAR') {
                  //console.log('can`t login');
                  this.toastr.info('Kindly contact the admin', 'Access denied');
                  this.logout();
                } else {
                  //console.log('log in')
                  if (this.roleId == 0o0) {
                    this.router.navigate(['/SUDashboard']);
                    localStorage.removeItem('UserInfoCenterAccessID');
                  } else if (this.roleId == 99) {
                    this.router.navigate(['/adminDashboard']);
                    localStorage.removeItem('UserInfoCenterAccessID');
                  } else if (this.roleId == 88) {
                    this.router.navigate(['/piaDashboard/u/', 0]);
                    localStorage.removeItem('UserInfoCenterAccessID');
                  } else if (this.roleId == 44) {
                    this.router.navigate(['/centersList']);
                    localStorage.removeItem('UserInfoCenterAccessID');
                  } else if (this.roleId == 77) {
                    this.router.navigate(['/centerDashboard/u/', 0]);
                  } else if (this.roleId == 22) {
                    if (this.username === "HARYANACOMMAND") {
                      this.router.navigate(['/command-center'])
                    } else {
                      this.router.navigate(['/cCSearch']);
                    }
                  } else if (this.roleId == 66) {
                    this.router.navigate(['/allResources']);
                  } else {
                    this.authService.userLogout(this.accessId).subscribe((data) => {
                      this.router.navigate(['/']);
                    });
                  }
                }
              }
            }
          }
        });
      }
    });

  }

  logout() {
    let key = 'UserInfo';
    this.accessId = localStorage.getItem(key);
    const accessId = {
      accessId: this.accessId,
    }
    this.authService.userLogout(accessId).subscribe((data) => {
      this.router.navigate(['/']);
    });
  }
  ngOnInit() {
    this.fetchProfile();
    //this.logout();
    // if (localStorage['firstTimeLoad'] != 'TRUE') {
    //   localStorage['firstTimeLoad'] = 'TRUE';
    //   console.log('true');
    // }
    // else {
    //   console.log('false');
    // }

  }
}
