import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cameraSerach'
})
export class CameraSerachPipe implements PipeTransform {

  transform(allCamera: any, search: any): any {
    if (!allCamera || !search) {
      return allCamera;
    }
    return allCamera.filter(cam =>
      cam.cameraDeviceId.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      cam.centerName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      cam.schemeCode.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }

}
