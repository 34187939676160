// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: true,
//   domain: 'http://104.211.166.126:1919/'
// };

// export const environment = {
//   production: true,
//   domain: 'http://20.204.97.191:1919/'
// };

export const environment = {
  production: true,
  domain: 'https://www.api.optage.co.in/'
};

/* 104.211.159.217 ->  20.204.97.191
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
