import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  domain = environment.domain;
  authToken;
  user;
  options;

  constructor(
    private http: HttpClient,
    public jwtHelper: JwtHelperService
  ) { }

  /* ===============================================================
      Authentication Header
  =============================================================== */
  createAuthenticationHeaders() {
    this.loadToken();
      this.options = { headers : new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'authorization': this.authToken
      })
    }
  }
  
  /* ===============================================================
      Token Load 
  =============================================================== */
  loadToken(){
    this.authToken = localStorage.getItem('token');
  }
   
  /* ===============================================================
      Registration api 
  =============================================================== */
  registerUser(user){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'authentication/register', user);
  }

  /* ===============================================================
       Get all user
  =============================================================== */
  getAllUser(){
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'authentication/getAllUser', this.options);
  }

  /* ===============================================================
      Update Password api 
  =============================================================== */
  updatePassword(user){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'authentication/updatePassword', user);
  }

  
  /* ===============================================================
      Update Tp Email 
  =============================================================== */
  updateTpEmail(user){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'authentication/updateEmailForTp', user);
  }

  
  /* ===============================================================
      Update Sdc Email 
  =============================================================== */
  updateSdcEmail(user){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'authentication/updateEmailForCenter', user);
  }

  /* ===============================================================
      Login api 
  =============================================================== */
  login(user){
    return this.http.post(this.domain + 'authentication/login', user);
  }

  /* ===============================================================
       Profile Fetch api
  =============================================================== */
  getProfile(accessId){
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'authentication/profile/'+ accessId, this.options);
  }

  /* ===============================================================
      Public Profile Fetch api
  =============================================================== */
  getPublicProfile(username){
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'authentication/publicProfile/' + username, this.options);
  }

  /* ===============================================================
       Profile Fetch api
  =============================================================== */
  getProfileData(accessid){
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'authentication/profileData/'+ accessid, this.options);
  }

  /* ===============================================================
      Is Logged in Set 
  =============================================================== */
  isLoggedIn(data){
    return this.http.post(this.domain + 'authentication/loggedIn', data);
  }

  /* ===============================================================
      Change Passwpord api 
  =============================================================== */
  changePassword(user){
    return this.http.post(this.domain + 'authentication/changePassword', user);
  }

  /* ===============================================================
      Forgot Passwpord api 
  =============================================================== */
  forgotPassword(user){
    return this.http.post(this.domain + 'authentication/forgotPass', user);
  }

  /* ===============================================================
      OTP Verify 
  =============================================================== */
  verifyOtp(user){
    return this.http.post(this.domain + 'authentication/verifyOtp', user);
  }

  /* ===============================================================
      Logout api
  =============================================================== */
  userLogout(accessId){
    this.authToken = null;
    this.user = null;
    localStorage.clear();  
    return this.http.post(this.domain + 'authentication/logout', accessId);
  }

  /* ===============================================================
      JSON Data Store in Local api
  =============================================================== */
  storeUserData(token) {
    localStorage.setItem('token',token);
    this.authToken = token;
  }

  /* ===============================================================
      Is Logged In api
  =============================================================== */
  loggedIn(){
    return !this.jwtHelper.isTokenExpired(this.authToken);
  }
}
