import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-c-cdashboard',
  templateUrl: './c-cdashboard.component.html',
  styleUrls: ['./c-cdashboard.component.css']
})
export class CCDashboardComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  onClickSearch(){
    this.router.navigate(['/cCSearch']);
  }

  onClickAutoPlay(){
    this.router.navigate(['/cCAutoPlay']);
  }

  onClickVideoWall(){
    this.router.navigate(['/cCVideoWall']);
  }
  ngOnInit() {
  }

}
