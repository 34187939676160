import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ClassService } from 'src/app/services/classService/class.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/authService/auth.service';

@Component({
  selector: 'app-select-class',
  templateUrl: './select-class.component.html',
  styleUrls: ['./select-class.component.css']
})
export class SelectClassComponent implements OnInit {

  roleId;
  accessId;
  classDetails;
  camDetails;
  classAccessId;
  camNtFond = 'hide';
  centerAccessID;
  constructor(
    private router: Router,
    private location: Location,
    private toastr: ToastrService,
    private authService: AuthService,
    private classService: ClassService
  ) { }

  /* ===============================================================
    All Class Fetch
  =============================================================== */
  getAllClass() {
    this.accessId = localStorage.getItem('UserInfo');
    this.authService.getProfile(this.accessId).subscribe((profile: any) => {
      //console.log(profile);
      this.roleId = profile.user.roleId;
      if (profile.user.roleId == 77) {
        this.centerAccessID = profile.user.accessId;
      } else if (profile.user.roleId == 0o0) {
        this.centerAccessID = localStorage.getItem('UserInfoCenterAccessID');
      } else {
        this.centerAccessID = localStorage.getItem('UserInfoCenterAccessID');
      }
      this.classService.getAllClass(this.centerAccessID).subscribe((data: any) => {
        this.classDetails = data.classDetails;
        //console.log(this.classDetails);
        if (this.classDetails.length == 0) {
          //console.log('go to class create');
          this.toastr.info('Please Create Class', 'info');
          setTimeout(() => {
            this.router.navigate(['/class']);
          }, 1000);
        } else { }
      });
    });
  }

  onClickSelectClass(data) {
    //console.log(data);
    if (data.isCameraAssigned == 1) {
      //console.log('go to create res');
      this.classAccessId = data.accessId;
      this.classService.getCamInfo(this.classAccessId).subscribe((camData: any) => {
        if (camData.success == 1) {
          const camAvailData = {
            cameraDeviceId: camData.cameraDetails.cameraDeviceId
          }
          this.classService.cameraAvailabililty(camAvailData).subscribe((camAvail: any) => {
            if (camAvail.success == 1) {
              const onPrams = {
                cameraDeviceId: camData.cameraDetails.cameraDeviceId,
                cameraId: camData.cameraDetails.cameraId,
                channelId: '11'
              }
              this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                //console.log(onCam);
                if (onCam.success == 1) {
                  localStorage.setItem('ResCameraID', camData.cameraDetails.cameraId);
                  localStorage.setItem('ResDeviceID', camData.cameraDetails.cameraDeviceId)
                  this.router.navigate(['/createResources']);
                  setTimeout(() => {
                    location.reload();
                  }, 2000);
                } else {
                  //on camera success 0
                }
              })
            } else {
              //camera not avail
              this.toastr.info('Camera not Found', 'Info');
            }
          });
        } else {
          //camera information not get
        }
      });
    } else {
      //console.log('camera not assgin');
      this.toastr.info('Camera not assgin', 'Info');
    }

  }

  back() {
    //console.log('dshksjdj')
    this.router.navigate(['/centerDashboard/u/', 0])
  }

  ngOnInit() {
    this.getAllClass();
  }

}
