import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../authService/auth.service';


@Injectable({
  providedIn: 'root'
})
export class CCService {

  options;
  domain = this.authService.domain;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  /* ===============================================================
      Authentication Header
  =============================================================== */
  createAuthenticationHeaders() {
    this.authService.loadToken();
      this.options = { headers : new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'authorization': this.authService.authToken
      })
    }
  }

  /* ===============================================================
      Get Center Details
  =============================================================== */
  getCenter() {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'centerDetail/getCenters' , this.options);
  }

  /* ===============================================================
      Get All Center
  =============================================================== */
  getAllCenter() {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'centerDetail/getCenterSAList' , this.options);
  }

  /* ===============================================================
      Get Center Details
  =============================================================== */
  getAllCameraBySdc(centerAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'centerDetail/getAllCameraByCenter/' + centerAccessId , this.options);
  }

  /* ===============================================================
      Get Center Details
  =============================================================== */
  getAllActiveCameraBySdc(centerAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'centerDetail/getAllCameraActiveByCenter/' + centerAccessId , this.options);
  }

  /* ===============================================================
      Get Center Details
  =============================================================== */
  streamKeepLive(data) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'cameraDetail/streamKeepAlive', data, this.options);
  }

  /* ===============================================================
      System Check
  =============================================================== */
  systemCheck() {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'authentication/checkAppServerStatus' , this.options);
  }

  /* ===============================================================
      Get Center Details
  =============================================================== */
  getCenterHaryana() {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'centerDetail/getAllCenterByScheme/DWS-OPTG-SH-20200310235964' , this.options);
  }
}
