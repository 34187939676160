import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auto-logout',
  templateUrl: './auto-logout.component.html',
  styleUrls: ['./auto-logout.component.css']
})
export class AutoLogoutComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  gotoLogin(){
    this.router.navigate(['/']);
  }

  ngOnInit() {
  }

}
