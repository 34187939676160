import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';

import { AuthService } from 'src/app/services/authService/auth.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { ClassService } from 'src/app/services/classService/class.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  dateImage;
  dateVideo;
  dataImages;
  dataVideos;
  streamName;
  streamNameVideo;
  date;
  now;
  roleId;
  currentDate;
  streamName1;
  hisroryDate;
  src;
  imageLength;
  videoLength;
  jsTest;
  accessId;
  allCam;
  showLiveStream: boolean = false;
  showHistory: boolean = true;
  activeLivestream = ' ';
  activeHistory = 'active';
  timer: any;
  activeClassCam;
  isImagesAvailable;
  isVideosAvailable;
  myModal;
  slideCount;
  prevHide;
  nextHide;
  myVidModal;
  slideVidCount;
  prevVidHide;
  nextVidHide;

  todayDate;
  classAccessId;
  tpName;
  className;
  centerCode;
  districtName;
  locationName;
  usertype;
  adminNav: boolean;
  centerNav: boolean;
  piaNav: boolean;
  changeDate;

  toMailId = ['suvendudewan95@gamil.com', 'falguni3141@gmail.com', 'suvendudewan95@rediffmail.com'];
  attachFiles = ['https://optage.blob.core.windows.net/captured-images/20191227165300.jpg', 'https://optage.blob.core.windows.net/captured-images/20191227165256.jpg',
                'https://optage.blob.core.windows.net/recorded-video/20191227165248.mp4']

  /* ===============================================================
      Keybord key Event
  =============================================================== */
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {

    if (event.keyCode === 27) {
      if (this.slideCount != null) {
        this.closeModal();
      } else if (this.slideVidCount != null) {
        this.closeVidModal();
      } else { }
    }

    if (event.keyCode === 39) {
      //Image Next
      if (this.slideCount != null) {
        if (this.slideCount != this.imageLength) {
          this.slideCount++;
          if (this.slideCount == '1') {
            this.prevHide = 'hide';
          } else {
            this.prevHide = 'show';
          }
          if (this.imageLength == this.slideCount) {
            this.nextHide = 'hide';
          } else {
            this.nextHide = 'show';
          }
        }
      }
      //Video Next
      if (this.slideVidCount != null) {
        if (this.slideVidCount != this.videoLength) {
          this.slideVidCount++;
          if (this.slideVidCount == '1') {
            this.prevVidHide = 'hide';
          } else {
            this.prevVidHide = 'show';
          }
          if (this.videoLength == this.slideVidCount) {
            this.nextVidHide = 'hide';
          } else {
            this.nextVidHide = 'show';
          }
        }
      }
    } else if (event.keyCode === 37) {
      //Image Prev
      if (this.slideCount != null) {
        if (this.slideCount != '1') {
          this.slideCount--;
          if (this.slideCount == '1') {
            this.prevHide = 'hide';
          } else {
            this.prevHide = 'show';
          }
          if (this.imageLength == this.slideCount) {
            this.nextHide = 'hide';
          } else {
            this.nextHide = 'show';
          }
        }
      }
      //Video Prev
      if (this.slideVidCount != null) {
        if (this.slideVidCount != '1') {
          this.slideVidCount--;
          if (this.slideVidCount == '1') {
            this.prevVidHide = 'hide';
          } else {
            this.prevVidHide = 'show';
          }
          if (this.videoLength == this.slideVidCount) {
            this.nextVidHide = 'hide';
          } else {
            this.nextVidHide = 'show';
          }
        }
      }
    } else { }
  }

  // Min moment: February 12 2018, 10:30
  public min = new Date(2018, 1, 12, 10, 30);

  // Max moment: April 21 2018, 20:30
  public max = new Date;
  public selectedMoment = new Date();
  private paramsSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private authService: AuthService,
    private classService: ClassService,
    private centerService: CenterDetailsService
  ) { }



  /* ===============================================================
        Date basic Image and video Fetch
    =============================================================== */
  onChange(value) {
    let key = 'ClassAccessIdHis';
    this.classAccessId = localStorage.getItem(key);
    //console.log(this.classAccessId);
    this.route.params.subscribe(paramas => {
      this.date = (this.datePipe.transform(value._d, 'yyyy-MM-dd'));
      //console.log(this.date);
      this.changeDate = this.date;
      this.centerService.imageHistory(this.classAccessId, this.date).subscribe((data: any) => {
        this.dataImages = data.imageDetails;
        //console.log(this.dataImages)
        this.imageLength = this.dataImages.length;
      });
      this.centerService.videoHistory(this.classAccessId, this.date).subscribe((data: any) => {
        this.dataVideos = data.videoDetails;
        //console.log(this.dataVideos)
        this.videoLength = this.dataVideos.length;
      });
    });
  }

  myEvent() {

  }

  /* ===============================================================
      Get Center Details
  =============================================================== */
  fetchCenterDetails() {
    this.classAccessId = localStorage.getItem('ClassAccessIdHis');
    //console.log(this.centerAccessId);
    this.classService.getClass(this.classAccessId).subscribe((classDetail: any) => {
      //console.log(classDetail);
      this.tpName = classDetail.classDetails.tpName;
      this.className = classDetail.classDetails.classSubName;
      this.centerCode = classDetail.classDetails.centerCode;
      this.centerService.getCenter(classDetail.classDetails.centerAccessId).subscribe((centerDetail:any) =>{
        //console.log(centerDetail);
        this.districtName = centerDetail.centerDetails.districtName;
        this.locationName = centerDetail.centerDetails.location;
      })
      this.now = moment(); // add this 2 of 4
      this.date = this.now.format('YYYY-MM-DD');
      /* All Images */
      this.centerService.imageHistory(classDetail.classDetails.accessId, this.date).subscribe((data: any) => {
        this.dataImages = data.imageDetails;
        this.imageLength = this.dataImages.length;
      });
      /* All Videos */
      this.centerService.videoHistory(classDetail.classDetails.accessId, this.date).subscribe((data: any) => {
        this.dataVideos = data.videoDetails;
        this.videoLength = this.dataVideos.length;
      });
    });
  }

  someMethod(event) {
    this.classAccessId = localStorage.getItem('ClassAccessIdHis');
    //console.log(this.centerAccessId);
    this.classService.getClass(this.classAccessId).subscribe((classDetail: any) => {
      //console.log(classDetail);
      this.tpName = classDetail.classDetails.tpName;
      this.className = classDetail.classDetails.classSubName;
      this.centerService.getCenter(classDetail.classDetails.centerAccessId).subscribe((centerDetail:any) =>{
        //console.log(centerDetail);
        this.districtName = centerDetail.centerDetails.districtName;
        this.locationName = centerDetail.centerDetails.districtName;
      });
      this.now = moment(); // add this 2 of 4
      this.date = this.now.format('YYYY-MM-DD');
      if(this.changeDate == undefined){
        /* All Images */
        this.centerService.imageHistory(classDetail.classDetails.accessId, this.date).subscribe((data: any) => {
          this.dataImages = data.imageDetails;
          this.imageLength = this.dataImages.length;
        });
        /* All Videos */
        this.centerService.videoHistory(classDetail.classDetails.accessId, this.date).subscribe((data: any) => {
          this.dataVideos = data.videoDetails;
          this.videoLength = this.dataVideos.length;
        });
      }else{
        this.date = this.changeDate;
        this.centerService.imageHistory(classDetail.classDetails.accessId, this.changeDate).subscribe((data: any) => {
          this.dataImages = data.imageDetails;
          this.imageLength = this.dataImages.length;
        });
        /* All Videos */
        this.centerService.videoHistory(classDetail.classDetails.accessId, this.changeDate).subscribe((data: any) => {
          this.dataVideos = data.videoDetails;
          this.videoLength = this.dataVideos.length;
        });
      }
    });
  }


  /* ===============================================================
      Gallery
  =============================================================== */
  /* Image */
  currentSlide(data) {
    this.slideCount = data;
    this.myModal = 'show';
    if (this.slideCount == '1') {
      this.prevHide = 'hide';
    } else {
      this.prevHide = 'show';
    }
    if (this.imageLength == this.slideCount) {
      this.nextHide = 'hide';
    } else {
      this.nextHide = 'show';
    }
  }
  closeModal() {
    this.myModal = 'hide';
    this.slideCount = null;
  }
  prev() {
    this.slideCount--;
    if (this.slideCount == '1') {
      this.prevHide = 'hide';
    } else {
      this.prevHide = 'show';
    }
    if (this.imageLength == this.slideCount) {
      this.nextHide = 'hide';
    } else {
      this.nextHide = 'show';
    }
  }
  next() {
    this.slideCount++;
    if (this.slideCount == '1') {
      this.prevHide = 'hide';
    } else {
      this.prevHide = 'show';
    }
    if (this.imageLength == this.slideCount) {
      this.nextHide = 'hide';
    } else {
      this.nextHide = 'show';
    }
  }

  /* Video */
  openVidModal(data) {
    this.slideVidCount = data;
    this.myVidModal = 'show';
    if (this.slideVidCount == '1') {
      this.prevVidHide = 'hide';
    } else {
      this.prevVidHide = 'show';
    }
    if (this.videoLength == this.slideVidCount) {
      this.nextVidHide = 'hide';
    } else {
      this.nextVidHide = 'show';
    }
  }
  closeVidModal() {
    this.myVidModal = 'hide';
    this.slideVidCount = null;
  }
  prevVid() {
    this.slideVidCount--;
    if (this.slideVidCount == '1') {
      this.prevVidHide = 'hide';
    } else {
      this.prevVidHide = 'show';
    }
    if (this.videoLength == this.slideVidCount) {
      this.nextVidHide = 'hide';
    } else {
      this.nextVidHide = 'show';
    }
  }
  nextVid() {
    this.slideVidCount++;
    if (this.slideVidCount == '1') {
      this.prevVidHide = 'hide';
    } else {
      this.prevVidHide = 'show';
    }
    if (this.videoLength == this.slideVidCount) {
      this.nextVidHide = 'hide';
    } else {
      this.nextVidHide = 'show';
    }
  }

  sendMail(){
    const allData = {
      sendTo: this.toMailId,
      attachFiles: this.attachFiles,
      emailSubject: 'Test Mail',
      emailText: 'Test Mail',
    }
    console.log(allData);
    this.centerService.sendMail(allData).subscribe((mail:any)=>{
      console.log(mail);
    });

  }

  ngOnInit() {
    this.now = moment(); // add this 2 of 4
    this.todayDate = this.now.format('Do MMMM YYYY');
    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile:any) =>{
      this.roleId = profile.user.roleId;
    });
    this.paramsSubscription = this.route.params
      .subscribe(params => {
        this.fetchCenterDetails();
      });
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }
}
