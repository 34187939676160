import { Component, OnInit } from '@angular/core';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import * as jspdf from 'jspdf';
import $ from "jquery";
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/services/authService/auth.service';

@Component({
  selector: 'app-all-res-list',
  templateUrl: './all-res-list.component.html',
  styleUrls: ['./all-res-list.component.css']
})
export class AllResListComponent implements OnInit {
  resAtten;
  allRes;
  resourceType;
  loader:boolean = true;
  allStudent;
  allTeacher;
  allStaff;
  date;
  roleId;
  centerDetails;
  dateToday;
  allBatch;
  valueList: boolean;

    // Min moment: February 12 2018, 10:30
    public min = new Date(2018, 1, 12, 10, 30);

    // Max moment: April 21 2018, 20:30
    public max = new Date;
    public selectedMoment = new Date();

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private authService: AuthService,
    private centerService: CenterDetailsService
  ) { }

  back(){
    this.router.navigate(['/cCSearch']);
    localStorage.removeItem('resType');
  }
  onClickResType(resType){
    this.loader= true;
    this.centerService.attendanceByToday(localStorage.getItem('selectSdcAccessID'), moment().format('YYYY-MM-DD')).subscribe((todayAtten:any) =>{
      this.resAtten = todayAtten.resourceDetails;
      //console.log(localStorage.getItem('resType'));
      //console.log(todayAtten);
      this.loader= false;
      if(resType === 'all'){
        this.allRes = this.resAtten;
        //console.log(this.allRes);
        this.resourceType= 'Resource';
        this.allStudent = this.resAtten.filter(res => res.resourceType === 'S');
        this.allTeacher = this.resAtten.filter(res => res.resourceType === 'T');
        this.allStaff = this.resAtten.filter(res => res.resourceType === 'E');

      }else if(resType === 'student'){
        this.allRes = this.resAtten.filter(res => res.resourceType === 'S');
        this.resourceType= 'Student';
      }else if(resType === 'teacher'){
        this.allRes = this.resAtten.filter(res => res.resourceType === 'T');
        this.resourceType= 'Teacher';
      }else if(resType === 'staff'){
        this.allRes = this.resAtten.filter(res => res.resourceType === 'E');
        this.resourceType= 'Staff';
      }else{}
    });
  }

  inputClickTp(){
    this.valueList = !this.valueList;
  }

  onClickPdf() {
    var HTML_Width = $(".output-print").width();
    var HTML_Height = $(".output-print").height();
    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;
    var todayDate = moment();
    var timeStamp = todayDate.format('YYYYMMDDHHmmss')
    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;


    html2canvas($(".output-print")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');


      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      var pdf = new jspdf('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(PDF_Width, PDF_Height);
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      pdf.save(timeStamp + ".pdf");
    });
  }


  ngOnInit() {
    console.log(localStorage.getItem('selectSdcAccessID'));
    
    this.date = moment().format('YYYY-MM-DD');
    this.centerService.getCenter(localStorage.getItem('selectSdcAccessID')).subscribe((centerDetail: any) => {
      //console.log(centerDetail);
      this.centerDetails = centerDetail.centerDetails;

      this.centerService.getAllBatchByCenter(localStorage.getItem('selectSdcAccessID')).subscribe((batch:any) =>{
        this.allBatch = batch.batchDetails;
        //console.log(this.allBatch);
        
      });
    });

    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      this.roleId = profile.user.roleId;

    });
    this.centerService.attendanceByToday(localStorage.getItem('selectSdcAccessID'), moment().format('YYYY-MM-DD')).subscribe((todayAtten:any) =>{
      this.resAtten = todayAtten.resourceDetails;
      //console.log(localStorage.getItem('resType'));
      //console.log(todayAtten);
      this.loader= false;
      if(localStorage.getItem('resType') === 'all'){
        this.allRes = this.resAtten;
        this.resourceType= 'Resource';
        this.allStudent = this.resAtten.filter(res => res.resourceType === 'S');
        this.allTeacher = this.resAtten.filter(res => res.resourceType === 'T');
        this.allStaff = this.resAtten.filter(res => res.resourceType === 'E');
      }else if(localStorage.getItem('resType') === 'student'){
        this.allRes = this.resAtten.filter(res => res.resourceType === 'S');
        this.resourceType= 'Student';
      }else if(localStorage.getItem('resType') === 'teacher'){
        this.allRes = this.resAtten.filter(res => res.resourceType === 'T');
        this.resourceType= 'Teacher';
      }else if(localStorage.getItem('resType') === 'staff'){
        this.allRes = this.resAtten.filter(res => res.resourceType === 'E');
        this.resourceType= 'Staff';
      }
    });
  }

  onChangeAtten(value) {
    this.loader = true;
    this.date = (this.datePipe.transform(value._d, 'yyyy-MM-dd'));
    this.dateToday = moment().format('YYYY-MM-DD');
    //console.log(this.date);
    this.centerService.attendanceByToday(localStorage.getItem('selectSdcAccessID'),this.date).subscribe((todayAtten:any) =>{
      this.resAtten = todayAtten.resourceDetails;
      //console.log(localStorage.getItem('resType'));
      //console.log(todayAtten);
      this.loader= false;
      if(localStorage.getItem('resType') === 'all'){
        this.allRes = this.resAtten;
        this.resourceType= 'Resource';
        this.allStudent = this.resAtten.filter(res => res.resourceType === 'S');
        this.allTeacher = this.resAtten.filter(res => res.resourceType === 'T');
        this.allStaff = this.resAtten.filter(res => res.resourceType === 'E');
      }else if(localStorage.getItem('resType') === 'student'){
        this.allRes = this.resAtten.filter(res => res.resourceType === 'S');
        this.resourceType= 'Student';
      }else if(localStorage.getItem('resType') === 'teacher'){
        this.allRes = this.resAtten.filter(res => res.resourceType === 'T');
        this.resourceType= 'Teacher';
      }else if(localStorage.getItem('resType') === 'staff'){
        this.allRes = this.resAtten.filter(res => res.resourceType === 'E');
        this.resourceType= 'Staff';
      }
    });

  }


  onSelectAllBatch(){
    this.allRes = this.resAtten.filter(res => res.resourceType === 'S');
  }

  onSelectBatch(batch){ 
    this.valueList= false; 
    var student = this.resAtten.filter(res => res.resourceType === 'S');
    this.allRes = student.filter(res => res.batchCode === batch.batchCode);
  }

  inTime(data){
    //console.log(data);
    var attendance = data.filter(res => res.attendanceDate === this.date);
    //console.log(attendance);
    return attendance[0].attendanceTime;
  }

  outTime(data){
    var attendance = data.filter(res => res.attendanceDate === this.date)
    return attendance;
  }

}
