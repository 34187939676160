import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { PiaServiceService } from 'src/app/services/piaService/pia-service.service';

@Component({
  selector: 'app-edit-pia',
  templateUrl: './edit-pia.component.html',
  styleUrls: ['./edit-pia.component.css']
})
export class EditPiaComponent implements OnInit {

  allPia;
  loader= 'show';
  mainSec: boolean = false;
  piaEdit;
  editPiaSel;
  editform: FormGroup;
  piaName;
  piaCode;
  piaContactPerson;
  piaEmail;
  piaPhone;
  piaLocation;
  address;
  districtName;
  allDistrict;
  districtCode;
  district;
  getDistrictCode;

  constructor(
    private formBuilder: FormBuilder,
    private centerService: CenterDetailsService,
    private piaService: PiaServiceService
  ) { this.editFormValidation(); }

  ngOnInit() {
    this.piaService.getAllTP().subscribe((data:any)=>{
      //console.log(data);
      this.allPia = data.tpDetails;
      this.loader= 'hide';
      this.centerService.getAllDistrict().subscribe((district: any) => {
        //console.log(district);
        this.allDistrict = district.districtDetails;
      });
    });
  }

  somethingChanged(value) {
    if (value.length <= 0) {
      this.mainSec = false;
    } else {
      this.mainSec = true;
    }
  }

  onSelectPiaEdit(pia){
    //console.log(pia);
    this.piaEdit = 'overlay-visible';
    this.editPiaSel = pia; 
    this.piaName= this.editPiaSel.tpName;
    this.piaCode = this.editPiaSel.tpCode;
    this.piaContactPerson= this.editPiaSel.contactPerson;
    this.piaEmail= this.editPiaSel.email;
    this.piaPhone= this.editPiaSel.mobile;
    this.address = this.editPiaSel.address;
    this.piaLocation = this.editPiaSel.location;
    this.districtName = this.editPiaSel.districtName;
    this.getDistrictCode = this.editPiaSel.districtCode;
    //console.log(this.districtName);    
   }

    /* ===============================================================
      Validation
  =============================================================== */
  editFormValidation() {
    this.editform = this.formBuilder.group({
      piaName: [null, [Validators.required, Validators.pattern("^.{3,250}$")]],
      piaCode: [null, [Validators.required, Validators.pattern("^.{3,120}$")]],

      contactPerson: [null, [Validators.required, Validators.pattern("^.{3,20}$")]],
      mobile: [null, Validators.compose([Validators.required, Validators.pattern("^\\d{10,10}$")])],
      email: [null, [Validators.required, Validators.pattern("[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]],
      district: [null, Validators.required],
      location: [null, [Validators.required, Validators.pattern("^.{3,40}$")]],
      address: [null, [Validators.required, Validators.maxLength(150), Validators.minLength(3)]],
    });
  }

  editBack(){
    this.piaEdit = '';
    this.editPiaSel = undefined; 
    //console.log(this.editSdcSel);
    this.piaName= undefined;
    this.piaCode = undefined;
    this.piaContactPerson= undefined;
    this.piaEmail= undefined;
    this.piaPhone= undefined;
    this.piaLocation = undefined;
    this.address = undefined;
    this.districtName = undefined;
    this.getDistrictCode = undefined;
  }

  onChangeDis(data) {
    var getAllData = data.split(',');
    this.districtCode = getAllData.pop();
    //console.log(this.districtCode);
    this.district = getAllData.join(',');
    //console.log(this.district);
  }

  onPiaUpdate(){
    const data = {

      accessId: this.editPiaSel.accessId,
      updateData:{
        accessId: this.editPiaSel.accessId,
        tpCode: this.editform.get('piaCode').value,
        tpName: this.editform.get('piaName').value,
        contactPerson: this.editform.get('contactPerson').value,
        mobile: this.editform.get('mobile').value,
        email: this.editform.get('email').value,
  
        associatedSchemes: this.editPiaSel.schemeAccessId,
        schemeAccessId: this.editPiaSel.schemeAccessId,
        schemeCode: this.editPiaSel.schemeCode,
        schemeName: this.editPiaSel.schemeName,
  
        address: this.editform.get('address').value,
        location: this.editform.get('location').value,
        districtName: this.district,
        districtCode: this.districtCode,
        tpShortName: this.editPiaSel.tpShortName,
        createdBy: this.editPiaSel.createdBy,
        createdAt: this.editPiaSel.createdAt
      }

    }
    //console.log(data);
    this.piaService.editUpdatePia(data).subscribe((info:any) =>{
      //console.log(info);
      this.piaEdit = '';
      this.piaService.getAllTP().subscribe((data:any)=>{
        //console.log(data);
        this.allPia = data.tpDetails;
        this.loader= 'hide';
      });
    });
  }

}
