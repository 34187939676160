import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/authService/auth.service';
import { AdminServiceService } from 'src/app/services/adminService/admin-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.css']
})
export class UserUpdateComponent implements OnInit {
  allUser;
  formVerify;
  formEmail;
  updateSec = 'hide';
  updateUser;
  logedInUser;
  emailUpdateData;
  createBtn:boolean;
  show: boolean = false;
  confmshow;
  mainSec:boolean= false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthService,
    private adminService: AdminServiceService
  ) {
    this.createFormVerify();
    this.createFrom();
  }

  /* ===============================================================
    Validation
  =============================================================== */
  /* Password Form Valid */
  createFormVerify() {
    this.formVerify = this.formBuilder.group({
      passwordVerify: [null, [Validators.required, Validators.pattern("^.{5,15}$")]],
      confirmVerify: ['', [Validators.required, Validators.pattern("^.{5,15}$")]]
    }, { validator: this.matchingPasswordsVerify('passwordVerify', 'confirmVerify') });
  }

  matchingPasswordsVerify(PasswordVerify, ConfirmpasswordVerify) {
    return (group: FormGroup) => {
      if (group.controls[PasswordVerify].value === group.controls[ConfirmpasswordVerify].value) {
        return null;
      } else {
        return { 'matchingPasswordsVerify': true }
      }
    }
  }

  /* Email Form Valid */
   createFrom(){
    this.formEmail = this.formBuilder.group({
      email: [null, [Validators.required, Validators.pattern("[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]],
    })
   }

  /* ===============================================================
    Fetch Profile and Condition basic show & hide verify Section
  =============================================================== */
  fetchProfile() {
    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      //console.log(profile);
      this.logedInUser = profile.user;
    });
  }

  onClickUserUpdate(data) {
    this.updateSec = 'show';
    this.updateUser = data;
   // console.log(data);
  }

  back(){
    this.updateSec = 'hide';
    this.updateUser = undefined;
    this.formVerify.reset();
    this.formEmail.reset();
    this.emailUpdateData = undefined;
  }

    /* click event function toggle */
    password() {
      this.show = !this.show;
    }
    confomPassword() {
      this.confmshow = !this.confmshow
    }

  /* Profile Verify For TP & Center */
  verifyForm() {
    this.createBtn = true;
    const user = {
      email: this.updateUser.email,
      password: this.formVerify.get('passwordVerify').value,
      updatedBy: this.logedInUser.username,
      updatedAt: moment().format('YYYY-MM-DD HH:mm:ss')
    }
    //console.log(user);
    this.authService.updatePassword(user).subscribe((data: any) => {
      //console.log(data);
      this.formVerify.reset();
      this.updateSec = 'hide';
      this.updateUser = undefined;
      this.createBtn = false;
      this.toastr.success('Password updated.');
    });
  }

  /* ===============================================================
      Email Update
  =============================================================== */
  onClickEmailUpdate(data){
    this.emailUpdateData = data;
    //console.log(this.emailUpdateData);
  }

  updateEmailForm(){
    this.createBtn = true;
    const emailData ={
      email: this.formEmail.get('email').value , 
      accessId: this.emailUpdateData.accessId , 
      updatedBy: this.logedInUser.username, 
      updatedAt:moment().format('YYYY-MM-DD HH:mm:ss')
    }
    //console.log(emailData);
    if(this.emailUpdateData.roleId === '88'){
      this.authService.updateTpEmail(emailData).subscribe((info:any) =>{
        //console.log(info);
        this.authService.getAllUser().subscribe((allUser: any) => {
          //console.log(allUser);
          this.allUser = allUser.user;
          this.createBtn = false;
          this.formEmail.reset();
          this.emailUpdateData = undefined;
          this.toastr.success('E-mail updated.');
        });
      });
    }else if(this.emailUpdateData.roleId === '77'){
      this.authService.updateSdcEmail(emailData).subscribe((info:any) =>{
        //console.log(info);
        this.authService.getAllUser().subscribe((allUser: any) => {
          //console.log(allUser);
          this.allUser = allUser.user;
          this.createBtn = false;
          this.formEmail.reset();
          this.emailUpdateData = undefined;
          this.toastr.success('E-mail updated.');
        });
      });     
    }else{}
  }




  somethingChanged(value){
    if (value.length <= 0) {
      this.mainSec = false;
    } else {
      this.mainSec = true;
    }
  }

  /* ===============================================================
      JavaScript Load 
  =============================================================== */
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/js/common.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  ngOnInit() {
    this.fetchProfile();
    this.loadScript();
    this.authService.getAllUser().subscribe((allUser: any) => {
      //console.log(allUser);
      this.allUser = allUser.user;
    });
  }

}
