import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sectorSer'
})
export class SectorSerPipe implements PipeTransform {

  transform(allSector: any, search: any): any {
    if (!allSector || !search) {
      return allSector;
    }
    return allSector.filter(allsector =>
      allsector.courseName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      allsector.courseCode.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }
}
