import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Chart } from "chart.js";

import { CCService } from 'src/app/services/cCService/c-c.service';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { ClassService } from 'src/app/services/classService/class.service';
import { AuthService } from 'src/app/services/authService/auth.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
declare var jwplayer: any;

@Component({
  selector: 'app-sdc-details',
  templateUrl: './sdc-details.component.html',
  styleUrls: ['./sdc-details.component.css']
})
export class SdcDetailsComponent implements OnInit {

  date;
  allData = [];
  selectScheme;
  selectDistrict;
  selectPia;
  allSdc;
  selectSec = 'liveStm';
  allAttendance;
  selectSdcAccessId;
  cameraList;
  allCamera;
  selectClass;
  finalCameraList

  disableBtn;
  datetime;
  captureImageSec;
  username;
  capturedImageUrl;
  startRecBtn: boolean = true;
  stopRecBtn: boolean = false;
  selectVidForZoom;
  selectSdc;
  allCount;

  loader: boolean;

  LineChart: any[];

  onStep;
  streamDetailsLoader;

  dataImages;
  dataVideos;
  myModal;
  slideCount;
  prevHide;
  nextHide;
  myVidModal;
  slideVidCount;
  prevVidHide;
  nextVidHide;
  imageLength;
  videoLength;
  histType;
  changeDate;
  intervel;

    // Min moment: February 12 2018, 10:30
    public min = new Date(2018, 1, 12, 10, 30);

    // Max moment: April 21 2018, 20:30
    public max = new Date;
    public selectedMoment = new Date();


  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private ccService: CCService,
    private authService: AuthService,
    private classService: ClassService,
    private centerService: CenterDetailsService
  ) { }

  /* ===============================================================
      Fetch Profile
  =============================================================== */
  fetchProfile() {
    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      this.username = profile.user.username;
    });
  }

  onClickScheme() {
    this.router.navigate(['/adminDashboard']);
    localStorage.setItem('selectType', 'scheme');
  }

  onClickDistrict() {
    this.router.navigate(['/adminDashboard']);
    localStorage.setItem('selectType', 'dist');
  }

  onClickPia() {
    this.router.navigate(['/adminDashboard']);
    localStorage.setItem('selectType', 'pia');
  }

  onClickLiveStream() {
    this.selectSec = 'liveStm';
  }

  onClickDashboard() {
    this.centerService.getCentMainCount(this.selectSdcAccessId, moment().format("YYYY-MM-DD")).subscribe((data: any) => {
      //console.log(data);
      this.allCount = data;
      this.selectSec = 'dashboard';
      this.LineChart = new Chart('mainchart', {
        type: 'bar',
        data: {
          labels: ["All", "Student", "Teacher", "Staff"],
          datasets: [{
            label: '',
            data: [this.allCount.allRegisteredStudent + this.allCount.allRegisteredTeacher + this.allCount.allRegisteredEmployee, this.allCount.allRegisteredStudent,
            this.allCount.allRegisteredTeacher, this.allCount.allRegisteredEmployees],
            barThickness: 80,
            backgroundColor: [
              'rgba(52, 73, 94,0.6)',
              'rgba(52, 152, 219,0.6)',
              'rgba(230, 126, 34,0.6)',
              'rgba(155, 89, 182,0.6)',
            ],
          }]
        },
        options: {
          title: {
            text: "Line Chart",
            display: false
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 10,
                display: false,
              },
              gridLines: {
                drawBorder: true,
                display: false
              }
            }],
            xAxes: [{
              gridLines: {
                drawBorder: true,
                display: false
              }
            }]
          }
        }
      })

      this.LineChart = new Chart('studentChart', {
        type: 'horizontalBar',
        data: {
          labels: ["Register", "Present", "Absent"],
          datasets: [{
            label: '',
            data: [this.allCount.allRegisteredStudent, this.allCount.attendanceCountStudent, this.allCount.allRegisteredStudent - this.allCount.attendanceCountStudent],
            barThickness: 30,
            backgroundColor: [
              'rgba(52, 152, 219,0.9)',
              'rgba(52, 152, 219,0.6)',
              'rgba(52, 152, 219,0.3)',
            ],
          }]
        },
        options: {
          title: {
            text: "Line Chart",
            display: false
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              gridLines: {
                drawBorder: true,
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                display: false,
              },
              gridLines: {
                drawBorder: true,
                display: false
              }
            }]
          }
        }
      })
      this.LineChart = new Chart('teacherChart', {
        type: 'horizontalBar',
        data: {
          labels: ["Register", "Present", "Absent"],
          datasets: [{
            label: '',
            data: [this.allCount.allRegisteredTeacher, this.allCount.attendanceCountTeacher, this.allCount.allRegisteredTeacher - this.allCount.attendanceCountTeacher],
            barThickness: 30,
            backgroundColor: [
              'rgba(230, 126, 34,0.9)',
              'rgba(230, 126, 34,0.6)',
              'rgba(230, 126, 34,0.3)',
            ],
          }],

        },
        options: {
          title: {
            text: "Line Chart",
            display: false
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              gridLines: {
                drawBorder: true,
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                display: false,
              },
              gridLines: {
                drawBorder: true,
                display: false
              }
            }]
          }
        }
      })
      this.LineChart = new Chart('staffChart', {
        type: 'horizontalBar',
        data: {
          labels: ["Register", "Present", "Absent"],
          datasets: [{
            label: '',
            data: [this.allCount.allRegisteredEmployees, this.allCount.attendanceCountEmp, this.allCount.allRegisteredEmployees - this.allCount.attendanceCountEmp],
            barThickness: 30,
            backgroundColor: [
              'rgba(155, 89, 182,0.9)',
              'rgba(155, 89, 182,0.6)',
              'rgba(155, 89, 182,0.3)',
            ],
          }],

        },
        options: {
          title: {
            text: "Line Chart",
            display: false
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              gridLines: {
                drawBorder: true,
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                display: false,
              },
              gridLines: {
                drawBorder: true,
                display: false
              }
            }]
          }
        }
      })
    });
  }

  onClickAttendance() {
    this.loader = true;
    this.date = moment().format('YYYY-MM-DD');
    this.centerService.attendanceByToday(this.selectSdcAccessId, this.date).subscribe((todayAtten: any) => {
      this.allAttendance = todayAtten.resourceDetails;
      //console.log(todayAtten);
      this.selectSec = 'attendance';
      this.loader = false;
    });
  }

  onClickHistory() {
    this.loader = true;
    this.selectSec = 'history';
    if(this.changeDate === undefined){
      this.date = moment().format('YYYY-MM-DD');
    }else{
      this.date = this.changeDate;
    }
    //console.log(this.selectSdcAccessId);
    this.centerService.getAllImageByCenter(this.selectSdcAccessId, this.date).subscribe((image: any) => {
      //console.log(image);
      this.loader = false;
      this.dataImages = image.imageDetails;
      this.histType = 'image';
    });
  }

  onClickImage() {
    this.loader = true;
    if(this.changeDate === undefined){
      this.date = moment().format('YYYY-MM-DD');
    }else{
      this.date = this.changeDate;
    }
    this.centerService.getAllImageByCenter(this.selectSdcAccessId, this.date).subscribe((image: any) => {
      //console.log(image);
      this.loader = false;
      this.dataImages = image.imageDetails;
      this.histType = 'image';
    });
  }

  onClickVideo() {
    this.loader = true;
    if(this.changeDate === undefined){
      this.date = moment().format('YYYY-MM-DD');
    }else{
      this.date = this.changeDate;
    }
    this.centerService.getAllVideoByCenter(this.selectSdcAccessId, this.date).subscribe((video: any) => {
      //console.log(video);
      this.loader = false;
      this.dataVideos = video.videoDetails;
      this.histType = 'video';
    });
  }


  /* ===============================================================
        Date basic Image and video Fetch
    =============================================================== */
    onChange(value) {
      this.loader = true;
      this.date = (this.datePipe.transform(value._d, 'yyyy-MM-dd'));
      this.changeDate = this.date;
      //console.log(this.date);
      if(this.histType === 'image'){
        this.centerService.getAllImageByCenter(this.selectSdcAccessId, this.date).subscribe((image: any) => {
          //console.log(image);
          this.loader = false;
          this.dataImages = image.imageDetails;
          this.histType = 'image';
        });
      }else if(this.histType === 'video'){
        this.centerService.getAllVideoByCenter(this.selectSdcAccessId, this.date).subscribe((video: any) => {
          //console.log(video);
          this.loader = false;
          this.dataVideos = video.videoDetails;
          this.histType = 'video';
        });
      }else{}

    }

  /* ===============================================================
      Gallery
  =============================================================== */
  /* Image */
  currentSlide(data) {
    this.slideCount = data;
    this.imageLength = this.dataImages.length;
    this.myModal = 'show';
    if (this.slideCount == '1') {
      this.prevHide = 'hide';
    } else {
      this.prevHide = 'show';
    }
    if (this.imageLength == this.slideCount) {
      this.nextHide = 'hide';
    } else {
      this.nextHide = 'show';
    }
  }
  closeModal() {
    this.myModal = 'hide';
    this.slideCount = null;
  }
  prev() {
    this.slideCount--;
    if (this.slideCount == '1') {
      this.prevHide = 'hide';
    } else {
      this.prevHide = 'show';
    }
    if (this.imageLength == this.slideCount) {
      this.nextHide = 'hide';
    } else {
      this.nextHide = 'show';
    }
  }
  next() {
    this.slideCount++;
    if (this.slideCount == '1') {
      this.prevHide = 'hide';
    } else {
      this.prevHide = 'show';
    }
    if (this.imageLength == this.slideCount) {
      this.nextHide = 'hide';
    } else {
      this.nextHide = 'show';
    }
  }

  /* Video */
  openVidModal(data) {
    this.slideVidCount = data;
    this.videoLength =this.dataVideos.length;
    this.myVidModal = 'show';
    if (this.slideVidCount == '1') {
      this.prevVidHide = 'hide';
    } else {
      this.prevVidHide = 'show';
    }
    if (this.videoLength == this.slideVidCount) {
      this.nextVidHide = 'hide';
    } else {
      this.nextVidHide = 'show';
    }
  }
  closeVidModal() {
    this.myVidModal = 'hide';
    this.slideVidCount = null;
  }
  prevVid() {
    this.slideVidCount--;
    if (this.slideVidCount == '1') {
      this.prevVidHide = 'hide';
    } else {
      this.prevVidHide = 'show';
    }
    if (this.videoLength == this.slideVidCount) {
      this.nextVidHide = 'hide';
    } else {
      this.nextVidHide = 'show';
    }
  }
  nextVid() {
    this.slideVidCount++;
    if (this.slideVidCount == '1') {
      this.prevVidHide = 'hide';
    } else {
      this.prevVidHide = 'show';
    }
    if (this.videoLength == this.slideVidCount) {
      this.nextVidHide = 'hide';
    } else {
      this.nextVidHide = 'show';
    }
  }

  onClickResources() {
    this.selectSec = 'resource';
  }

  /* ===============================================================
      OnSelect SDC
  =============================================================== */
  onSelectSdc(sdc) {
    this.allData = [];
    this.selectSdc = sdc;
    //console.log(sdc);
    this.selectSdcAccessId = sdc.accessId;
    this.selectSec = 'liveStm';
    this.allData.push({
      schemeCode: this.selectScheme, districtName: this.selectDistrict, piaName: this.selectPia, sdcAccessId: sdc.accessId, sdcCode: sdc.centerCode, sdcName: sdc.centerName,
      sdcLocation: sdc.location, sdcDistrict: sdc.districtName
    });
    this.loader = true;
    this.ccService.getAllCameraBySdc(sdc.accessId).subscribe((cam: any) => {
      //console.log(cam)
      this.cameraList = cam.cameraDetails;
      if (localStorage.getItem('totalCamList') === null) {
        //console.log('llll');
        this.onStep = 'first';
        for (let i = 0; i < this.cameraList.length; i++) {
            const camAvailData = {
              cameraDeviceId: this.cameraList[i].cameraDeviceId
            }
            this.classService.cameraAvailabililty(camAvailData).subscribe((camAvail: any) => {
              //console.log(camAvail)
              if (camAvail.success == 1) {
                const camspeed = {
                  cameraDeviceId: this.cameraList[i].cameraDeviceId
                }
                this.centerService.camSpeed(camspeed).subscribe((camSpeed: any) => {
                  //console.log(camSpeed)
                });
              }else{
                //cam not avail
              }
            });
            if (i + 1 === this.cameraList.length) {
              setTimeout(() => {
                this.onStep = 'second';
                this.ccService.getAllCameraBySdc(this.selectSdcAccessId).subscribe((cam: any) => {
                  //console.log(cam);
                  this.cameraList = cam.cameraDetails;
                  for (let t = 0; t < this.cameraList.length; t++) {
                    setTimeout(() => {
                    //console.log(this.cameraList[x].isCameraActive)
                    if (this.cameraList[t].isCameraActive === '1') {
                      const onPrams = {
                        cameraDeviceId: this.cameraList[t].cameraDeviceId,
                        cameraId: this.cameraList[t].cameraId,
                        channelId: '12'
                      }
                      //console.log(onPrams);
                      this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                        //console.log(onCam);
                      });
                    } else { }
                    if (t + 1 === this.cameraList.length) {
                      this.onStep = 'third';
                      localStorage.setItem('totalCamList', JSON.stringify(this.cameraList));
                      localStorage.setItem('allData', JSON.stringify(this.allData));
                      this.streamDetailsLoader = 'show';
                      setTimeout(() => {
                        this.loader = false;
                        // this.cameraList.forEach(element => {
                        //   jwplayer(element.cameraId).setup({
                        //     file: 'http://' + element.streamUrl + '/playlist.m3u8',
                        //     height: 500,
                        //     width: 500,
                        //   });
                        // });
                        this.finalCameraList = this.cameraList;
                        setTimeout(() => {
                          this.streamDetailsLoader = 'hide';
                        }, 7777);
                      }, 2000);
                    }else{}
                    },1000*(t+1));
                  }
                });
              }, 2000);
            }
        }
      } else {
        //console.log('dddd');
        this.onStep = 'first';
        this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
        for (let u = 0; u < this.allCamera.length; u++) {
          setTimeout(() => {
            if (this.allCamera[u].isCameraActive === '1') {
              const offPrams = {
                cameraDeviceId: this.allCamera[u].cameraDeviceId,
                cameraId: this.allCamera[u].cameraId,
                channelId: '12'
              }
              //console.log(offPrams);
              this.classService.offCamera(offPrams).subscribe((offCam: any) => {
                //console.log(offCam);
              });
            }
          }, 1000*(u+1));
          if (u + 1 === this.allCamera.length) {
            for (let v = 0; v < this.cameraList.length; v++) {
              const camAvailData = {
                cameraDeviceId: this.cameraList[v].cameraDeviceId
              }
              this.classService.cameraAvailabililty(camAvailData).subscribe((camAvail: any) => {
                //console.log(camAvail);
                if (camAvail.success === 1) {
                  const camSpeedData = {
                    cameraDeviceId: this.cameraList[v].cameraDeviceId
                  }
                  //console.log(camSpeedData)
                  this.centerService.camSpeed(camSpeedData).subscribe((camSpe: any) => {
                    //console.log(camSpe);
                  });
                }
              });
              if (v + 1 === this.cameraList.length) {
                setTimeout(() => {
                  this.onStep = 'second';
                  this.ccService.getAllCameraBySdc(this.selectSdcAccessId).subscribe((cam: any) => {
                    this.cameraList = cam.cameraDetails;
                    for (let w = 0; w < this.cameraList.length; w++) {
                      setTimeout(() => {
                        if (this.cameraList[w].isCameraActive === '1') {
                          const onPrams = {
                            cameraDeviceId: this.cameraList[w].cameraDeviceId,
                            cameraId: this.cameraList[w].cameraId,
                            channelId: '12'
                          }
                          //console.log(onPrams);
                          this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                            //console.log(onCam);
                          });
                        }else{}
                        if (w + 1 === this.cameraList.length) {
                          this.onStep = 'third';
                          this.streamDetailsLoader = 'show';
                          localStorage.setItem('totalCamList', JSON.stringify(this.cameraList));
                          localStorage.setItem('allData', JSON.stringify(this.allData));
                          setTimeout(() => {
                            this.loader = false;
                            // this.cameraList.forEach(element => {
                            //   //console.log(element.cameraId);
                            //   //console.log( 'http://' + element.streamUrl +'/playlist.m3u8')
                            //   jwplayer(element.cameraId).setup({
                            //     file: 'http://' + element.streamUrl + '/playlist.m3u8',
                            //     height: 500,
                            //     width: 500,
                            //   });
                            // });
                            this.finalCameraList = this.cameraList;
                            setTimeout(() => {
                              this.streamDetailsLoader = 'hide';
                            }, 7777);
                          }, 2000);
                        }
                      }, 1000*(w+1));
                    }
                  });
                }, 2000);
              }
            }
          }
        }
      }
    });
  }

  /* ===============================================================
     Video Zoom Section
  =============================================================== */
  onClickVideoZoom(data) {
    this.selectClass = data;
    this.selectVidForZoom = data.cameraDeviceId;
    //console.log(this.selectClass);
  }

  onClickVidZomClo() {
    this.selectVidForZoom = undefined;
    //console.log('ddddddd')
  }


  /* ===============================================================
    Image Capture
  =============================================================== */
  captureImage() {
    this.disableBtn = 'click-disable';
    this.datetime = moment().format("YYYY-MM-DD HH:mm:s");
    const user = {
      user: "RSLDC",
      streamName: this.selectClass.cameraId,
      centerAccessId: this.selectClass.centerAccessId,
      classAccessId: this.selectClass.classAccessId,
      capturedDate: moment().format("YYYY-MM-DD"),
      capturedTime: moment().format("HH:mm"),
      createdBy: this.username,
      createdAt: this.datetime
    }
    //console.log(user);
    this.classService.imageCapture(user).subscribe((data: any) => {
      //console.log(data);
      this.captureImageSec = true;
      this.capturedImageUrl = data.imagePath;
      setTimeout(() => {
        this.disableBtn = '';
      }, 1000);
      setTimeout(() => {
        this.captureImageSec = false;
      }, 2220);
    });
  }

  /* ===============================================================
  Video Recording Start
  =============================================================== */
  recordingStart() {
    const user = {
      user: "RSLDC",
      streamName: this.selectClass.cameraId,
    }
    this.classService.videoRecStart(user).subscribe((data: any) => {
      //console.log(data);
      if (data.success == 1) {
        this.startRecBtn = false;
        this.stopRecBtn = true;
        this.toastr.info('The maximum length of the video to record is 1 minute', 'Recording started');
        this.autoStopVidRec();
      } else { }
    });
  }

  /* ===============================================================
    Video Recording Stop
  =============================================================== */
  recordingStop() {
    this.datetime = moment().format("YYYY-MM-DD HH:mm:s");
    const user = {
      user: "RSLDC",
      streamName: this.selectClass.cameraId,
      centerAccessId: this.selectClass.centerAccessId,
      classAccessId: this.selectClass.classAccessId,
      recordedDate: moment().format("YYYY-MM-DD"),
      recordedTime: moment().format("HH:mm"),
      createdBy: this.username,
      createdAt: this.datetime
    }
    this.classService.videoRecStop(user).subscribe((data: any) => {
      //console.log(data);
      if (data.success == 1) {
        this.startRecBtn = true;
        this.stopRecBtn = false;
        this.toastr.info('You can check the recorded video in the history', 'Recording stopped');
        clearInterval(this.intervel);
      } else { }
    });
  }

  /* ===============================================================
    Video Auto Stop
  =============================================================== */
  autoStopVidRec() {
    this.intervel = setInterval(() =>{
      this.recordingStop();
      this.startRecBtn = true;
      this.stopRecBtn = false;
    },60000)
  }

  /* ===============================================================
      On Click Audit
  =============================================================== */
  onClickAudit(data){
    //console.log(data)
    if(data.cameraSpeed > 30){
      //console.log('fff')
      const onPrams = {
        cameraDeviceId: data.cameraDeviceId,
        cameraId: data.cameraId,
        channelId: '11'
      }
      this.classService.onCameraForAudit(onPrams).subscribe((onCam: any) => {
        //console.log(onCam);
        localStorage.setItem('ClassAccessId', data.classAccessId);
        localStorage.setItem('CameraUrl', data.streamUrl);
        this.router.navigate(['audit']);
        // setTimeout(() => {
        //   location.reload();
        // }, 1000);
      });
    }else{//console.log('aaaa');
      
    }
  }


  /* ===============================================================
      JavaScript Load 
  =============================================================== */
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/js/common.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  ngOnInit() {
    /* Get All Center */
    this.ccService.getCenter().subscribe((sdc: any) => {
      //console.log(sdc);
      this.allSdc = sdc.centerDetails;
    });
    this.loadScript();
    this.fetchProfile();
    /* On Select data Fetch */
    //localStorage.removeItem('allData')
    this.allData = JSON.parse(localStorage.getItem('allData'));
    //console.log(this.allData)
    if (this.allData != null) {
      this.selectSdcAccessId = this.allData[0].sdcAccessId;
      this.centerService.getCenter(this.selectSdcAccessId).subscribe((sdc: any) => {
        this.selectSdc = sdc.centerDetails;
      });
      if (this.allData[0].schemeCode != 'N/A') {
        this.selectScheme = this.allData[0].schemeCode;
      } else {
        this.selectScheme = 'N/A'
      }
      if (this.allData[0].districtName != 'N/A') {
        this.selectDistrict = this.allData[0].districtName;
      } else {
        this.selectDistrict = 'N/A'
      }
      if (this.allData[0].piaName != 'N/A') {
        this.selectPia = this.allData[0].piaName;
      } else {
        this.selectPia = 'N/A'
      }
      this.loader = true;
      this.ccService.getAllCameraBySdc(this.selectSdcAccessId).subscribe((cam: any) => {
        //console.log(cam)
        this.cameraList = cam.cameraDetails;
        if (localStorage.getItem('totalCamList') === null) {
          //console.log('llll');
          this.onStep = 'first';
          for (let a = 0; a < this.cameraList.length; a++) {
            const camAvailData = {
              cameraDeviceId: this.cameraList[a].cameraDeviceId
            }
            this.classService.cameraAvailabililty(camAvailData).subscribe((camAvail: any) => {
              //console.log(camAvail);
              if (camAvail.success === 1) {
                const camSpeedData = {
                  cameraDeviceId: this.cameraList[a].cameraDeviceId
                }
                this.centerService.camSpeed(camSpeedData).subscribe((camSpe: any) => {
                  //console.log(camSpe)
                });
              } else {
                //cam not avail
              }
            });
            if (a + 1 === this.cameraList.length) {
              setTimeout(() => {
                this.onStep = 'second';
                this.ccService.getAllCameraBySdc(this.selectSdcAccessId).subscribe((cam: any) => {
                  //console.log(cam)
                  this.cameraList = cam.cameraDetails;
                  //console.log(this.cameraList)
                  for (let b = 0; b < this.cameraList.length; b++) {
                    if (this.cameraList[b].isCameraActive === '1') {
                      const onPrams = {
                        cameraDeviceId: this.cameraList[b].cameraDeviceId,
                        cameraId: this.cameraList[b].cameraId,
                        channelId: '12'
                      }
                      //console.log(onPrams);
                      this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                        //console.log(onCam);
                      });
                    } else { }
                    if (b + 1 === this.cameraList.length) {
                      this.onStep= 'third';
                      this.streamDetailsLoader = 'show';
                      localStorage.setItem('totalCamList', JSON.stringify(this.cameraList));
                      setTimeout(() => {
                        this.loader = false;
                        // this.cameraList.forEach(element => {
                        //   jwplayer(element.cameraId).setup({
                        //     file: 'http://' + element.streamUrl + '/playlist.m3u8',
                        //     height: 500,
                        //     width: 500,
                        //   });
                        // });
                        this.finalCameraList = this.cameraList;
                        setTimeout(() => {
                          this.streamDetailsLoader = 'hide';
                        }, 7777);
                      }, 2000);
                    } else { }
                  }
                });
              }, 2000);
            }
          }
        } else {
          //console.log('dddd');
          this.onStep ='first';
          this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
          //console.log(this.allCamera);
          for (let c = 0; c < this.allCamera.length; c++) {
            //console.log(this.allCamera[c].isCameraActive);
            setTimeout(() => {
              if (this.allCamera[c].isCameraActive === '1') {
                const offPrams = {
                  cameraDeviceId: this.allCamera[c].cameraDeviceId,
                  cameraId: this.allCamera[c].cameraId,
                  channelId: '12'
                }
                //console.log(offPrams);
                this.classService.offCamera(offPrams).subscribe((onCam: any) => {
                  //console.log(onCam);
                });
              }
            }, 1000 * (c + 1));
            if (c + 1 === this.allCamera.length) {
              //console.log(this.cameraList);
              for (let d = 0; d < this.cameraList.length; d++) {
                setTimeout(() => {
                  const camAvailData = {
                    cameraDeviceId: this.cameraList[d].cameraDeviceId
                  }
                  this.classService.cameraAvailabililty(camAvailData).subscribe((camAvail: any) => {
                    //console.log(camAvail)
                    if (camAvail.success === 1) {
                      const camSpeedData = {
                        cameraDeviceId: this.cameraList[d].cameraDeviceId
                      }
                      //console.log(camSpeedData)
                      this.centerService.camSpeed(camSpeedData).subscribe((camSpe: any) => {
                        //console.log(camSpe);
                      });
                    } else {
                      //cam not avail
                    }
                  });
                  if (d + 1 === this.cameraList.length) {
                    setTimeout(() => {
                      this.onStep = 'second';
                      this.ccService.getAllCameraBySdc(this.selectSdcAccessId).subscribe((cam: any) => {
                        this.cameraList = cam.cameraDetails;
                        //console.log(this.cameraList);
                        for (let e = 0; e < this.cameraList.length; e++) {
                          setTimeout(() => {
                            //console.log(this.cameraList[x].isCameraActive)
                            if (this.cameraList[e].isCameraActive === '1') {
                              const onPrams = {
                                cameraDeviceId: this.cameraList[e].cameraDeviceId,
                                cameraId: this.cameraList[e].cameraId,
                                channelId: '12'
                              }
                              //console.log(onPrams);
                              this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                                //console.log(onCam);
                              });
                            } else { }
                            if (e + 1 === this.cameraList.length) {
                              //console.log('show Stream list');
                              this.onStep = 'third';
                              this.streamDetailsLoader = 'show';
                              localStorage.setItem('totalCamList', JSON.stringify(this.cameraList));
                              setTimeout(() => {
                                this.loader = false;
                                // this.cameraList.forEach(element => {
                                //   jwplayer(element.cameraId).setup({
                                //     file: 'http://' + element.streamUrl + '/playlist.m3u8',
                                //     height: 500,
                                //     width: 500,
                                //   });
                                // });
                                this.finalCameraList = this.cameraList;
                                setTimeout(() => {
                                  this.streamDetailsLoader = 'hide';
                                }, 7777);
                              }, 2000);
                            } else { }
                          }, 1000 * (c + 1));
                        }
                      });
                    }, 2000);
                  }
                }, 1000 * (d + 1));
              }
            }
          }
        }
      });
    } else { }
    /* 1st time stream on after reload */
  }

  ngAfterViewInit() {
  }

}
