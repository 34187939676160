import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js';

import { PiaServiceService } from 'src/app/services/piaService/pia-service.service';

import * as moment from 'moment';
import { AdminServiceService } from 'src/app/services/adminService/admin-service.service';
import { AuthService } from 'src/app/services/authService/auth.service';

@Component({
  selector: 'app-pia-dashboard',
  templateUrl: './pia-dashboard.component.html',
  styleUrls: ['./pia-dashboard.component.css']
})
export class PiaDashboardComponent implements OnInit {

  now;
  date;
  time;
  roleId;
  usertype;
  adminNav: boolean;
  piaNav: boolean;
  accessId;
  piaDetils;
  tpName;
  LineChart: any[];
  flashSet;
  flash: boolean;
  todayDate;
  allSideCount;
  allScheme;
  schemeAccessId;
  location;
  district;

  allTpCount;
  allCamera;
  allCenterCount;
  allNonResiCenter;
  allResiCenter;
  firstScheme;
  batchCount;

  gaugeType = "arch";
  gaugesize = "100";
  gaugeAppendText = "%";
  gaugeCap = 'round';
  gaugeThick = '4';

  thresholdConfig = {
    '0': { color: '#ff435f' },
    '50': { color: '#d35400' },
    '75.5': { color: '#27ae60' }
  };
  teacherGaugeValue = 100;
  studentGaugeValue = 100;
  employeeGaugeValue = 100;
  teacherGauge: boolean = false;
  studentGauge: boolean = false;
  employeeGauge: boolean = false;
  studentAttenData;
  teacherAttenData;
  employeeAttenData;
  wishMessage;
  wishMessageCss;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private adminService: AdminServiceService,
    private piaService: PiaServiceService
  ) { }

  piaDetailsFetch() {
    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      this.roleId = profile.user.roleId;
      if (this.roleId == 88) {
        this.accessId = localStorage.getItem('UserInfo');
        //console.log(this.accessId);
      } else {
        this.accessId = localStorage.getItem('piaAccessUserInfo');
        //console.log(this.accessId);
      }
      //console.log(this.accessId);
      this.piaService.getPia(this.accessId).subscribe((piaDetils: any) => {
        //console.log(piaDetils);
        this.piaDetils = piaDetils.tpDetails;
        this.tpName = this.piaDetils.tpName;
        this.location = this.piaDetils.location;
        this.district = this.piaDetils.districtName;
        this.allScheme = piaDetils.tpDetails.schemesInfo;
        //console.log(this.allScheme);
        this.schemeAccessId = piaDetils.tpDetails.schemeAccessId;
        //console.log(this.schemeAccessId);
        this.piaService.getTPMainCount(this.accessId, this.schemeAccessId).subscribe((data: any) => {
          //console.log(data);
          this.allTpCount = data.allTpCount;
          this.allCamera = data.allCamera;
          this.allCenterCount = data.allCenterCount;
          this.allNonResiCenter = data.allNonResiCenter;
          this.allResiCenter = data.allResiCenter;
          this.batchCount = data.batchCount;
        });
      });
      /*Side Count Fetch */
      this.now = moment(); // add this 2 of 4
      this.todayDate = this.now.format('YYYY-MM-DD');
      this.piaService.getTPSideCount(this.accessId, this.todayDate).subscribe((data: any) => {
        //console.log(data);
        this.allSideCount = data;
      });

      this.piaService.getAttenDataCountStuByTp(this.accessId, this.todayDate).subscribe((studentData: any) => {
        //console.log(studentData);
        this.studentAttenData = studentData;
        if (this.studentAttenData.attenPercentage != null) {
          this.studentGauge = true;
          setTimeout(() => {
            this.studentGaugeValue = this.studentAttenData.attenPercentage.toFixed();
          }, 2000);
        } else {
          this.studentGauge = true;
          setTimeout(() => {
            this.studentGaugeValue = 0;
          }, 2000);
        }
      });
      this.piaService.getAttenDataCountTeachByTp(this.accessId, this.todayDate).subscribe((teacherData: any) => {
        //console.log(teacherData);
        this.teacherAttenData = teacherData;
        if (this.teacherAttenData.attenPercentage != null) {
          this.teacherGauge = true;
          setTimeout(() => {
            this.teacherGaugeValue = this.teacherAttenData.attenPercentage.toFixed();
          }, 2000);
        } else {
          this.teacherGauge = true;
          setTimeout(() => {
            this.teacherGaugeValue = 0;
          }, 2000);
        }
      });
      this.piaService.getAttenDataCountEmpByTp(this.accessId, this.todayDate).subscribe((employeeData: any) => {
        //console.log(employeeData);
        this.employeeAttenData = employeeData;
        if (this.employeeAttenData.attenPercentage != null) {
          this.employeeGauge = true;
          setTimeout(() => {
            this.employeeGaugeValue = this.employeeAttenData.attenPercentage.toFixed();
          }, 2000);

        } else {
          this.employeeGauge = true;
          setTimeout(() => {
            this.employeeGaugeValue = 0;
          }, 2000);
        }
      });
    });
  }
  /* ===============================================================
      Main Count
  =============================================================== */
  selectScheme(schemAccessId) {
    //console.log(schemAccessId);
    this.schemeAccessId = schemAccessId;
    this.piaService.getTPMainCount(this.accessId, schemAccessId).subscribe((data: any) => {
      //console.log(data);
      this.allTpCount = data.allTpCount;
      this.allCamera = data.allCamera;
      this.allCenterCount = data.allCenterCount;
      this.allNonResiCenter = data.allNonResiCenter;
      this.allResiCenter = data.allResiCenter;
      this.batchCount = data.batchCount;
    });
  }


  ngOnInit() {
    this.piaDetailsFetch();
    this.now = moment(); // add this 2 of 4
    this.date = this.now.format('Do MMMM YYYY');
    this.time = this.now.format('HH');
    //console.log(this.time);
    if (this.time == '00' || this.time == '01' || this.time == '02' || this.time == '03' || this.time == '04' || this.time == '05') {
      this.wishMessage = 'Good Mornigng';
      this.wishMessageCss = 'morning';
    } else if (this.time == '06' || this.time == '07' || this.time == '08' || this.time == '09' || this.time == '10' || this.time == '11') {
      this.wishMessage = 'Good Morning';
      this.wishMessageCss = 'morning';
    } else if (this.time == '12' || this.time == '13' || this.time == '14' || this.time == '15' || this.time == '16') {
      this.wishMessage = 'Good Afternoon';
      this.wishMessageCss = 'afternoon';
    } else if (this.time == '17' || this.time == '18' || this.time == '19' || this.time == '20' || this.time == '21' || this.time == '22' || this.time == '23' || this.time == '24') {
      this.wishMessage = 'Good Evening';
      this.wishMessageCss = 'evening';
    } else { }

  }
}
