import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cCenter'
})
export class CCenterPipe implements PipeTransform {

  transform(centerList: any, search: any): any {
    if (!centerList || !search) {
      return centerList;
    }
    return centerList.filter(centerlist =>
      centerlist.centerName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      centerlist.tpName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      centerlist.location.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      centerlist.districtName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      centerlist.centerCode.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }

}
