import { Component, OnInit } from '@angular/core';
import { PiaServiceService } from 'src/app/services/piaService/pia-service.service';
import { Router } from '@angular/router';
import * as jspdf from 'jspdf';
import $ from "jquery";
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';

@Component({
  selector: 'app-all-pia-list',
  templateUrl: './all-pia-list.component.html',
  styleUrls: ['./all-pia-list.component.css']
})
export class AllPiaListComponent implements OnInit {

  allPia;
  allSdc;
  date;
  selePiaAccessId;
  loader:boolean=true;
  sdcAccessId;
  allCount;
  constructor(
    private centerService: CenterDetailsService,
    private piaService: PiaServiceService,
    private router: Router
  ) { }

  onClickBack(){
    this.router.navigate(['/cCSearch']);
  }

  onClickSelectTp(pia){
    //console.log(pia);
    this.selePiaAccessId = pia.accessId;
    this.sdcAccessId = undefined;
    this.allCount = undefined;
    this.centerService.getAllCenter(pia.accessId).subscribe((sdc:any) =>{
      //console.log(sdc);
      this.allSdc = sdc.centerDetails;
    });
  }

  onClickExpand(sdc){
    //console.log(sdc)
    this.sdcAccessId= sdc.accessId;
    this.centerService.getCentMainCount(sdc.accessId, moment().format('YYYY-MM-DD')).subscribe((data: any) => {
      //console.log(data);
      this.allCount = data;
    });
  }
  onClickPdf() {
    var HTML_Width = $(".output-print").width();
    var HTML_Height = $(".output-print").height();
    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;
    var todayDate = moment();
    var timeStamp = todayDate.format('YYYYMMDDHHmmss')
    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;


    html2canvas($(".output-print")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');


      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      var pdf = new jspdf('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(PDF_Width, PDF_Height);
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      pdf.save(timeStamp + ".pdf");
    });
  }

  ngOnInit() {
    this.date = moment().format('YYYY-MM-DD');
    this.piaService.getAllTP().subscribe((data:any)=>{
      //console.log(data);
      this.allPia = data.tpDetails;
      this.loader=false;
    });
  }

}
