import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'resSearch'
})
export class ResSearchPipe implements PipeTransform {

  transform(allResouces: any, search: any): any {
    if (!allResouces || !search) {
      return allResouces;
    }
    return allResouces.filter(allattendance =>
      allattendance.resourceName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      allattendance.resourceCode.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      allattendance.courseName.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }

}
