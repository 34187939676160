import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { AuthService } from 'src/app/services/authService/auth.service';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import * as moment from 'moment';
import { ClassService } from 'src/app/services/classService/class.service';

@Component({
  selector: 'app-create-resources',
  templateUrl: './create-resources.component.html',
  styleUrls: ['./create-resources.component.css']
})
export class CreateResourcesComponent implements OnInit {

  now;
  date;
  form: FormGroup;
  accessId;
  timestamp;
  datetime;
  studentAccessId;
  username;
  classDetails;
  centerAccessId;
  centerDetail;
  schemeAccessId;
  schemeCode;
  tpAccessId;
  tpCode;
  tpName;
  centerAccessID;
  centerCode;
  centerName;
  courseDetails;
  selectCourseSec = 'show';
  studentDetailsSec = 'hide';
  courseAccessId;
  courseCode;
  courseName;
  stream;
  liveStream='live-Stream-hide';
  dSec='live-Stream-show';
  imagePath;
  imageFile;
  selectProfileImage;
  imageFolder: Array<any> = [];
  imageUrl;
  cameraId;
  disableBtn :boolean=false;
  imageCaptureBtn:boolean = true;
  testUrl= 'rtsp://35.154.64.51:1935/live/office';
  imageName;
  disableBtnImage;
  roleId;

  selectBatchSec= 'hide';
  allBatch;
  batchAccessId;
  batchCode;
  batchHours;
  selectBatchAccessId;
  selectBatchCode;
  selectBatchHours;
  //rtmp://169.56.102.163:1935/live/oviu0021

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private location: Location,
    private toastr: ToastrService,
    private _sanitizer: DomSanitizer,
    private authService: AuthService,
    private classService: ClassService,
    private centerService: CenterDetailsService
  ) {
    this.createForm();
  }

  /* ===============================================================
        Validation
  =============================================================== */
  createForm() {
    this.form = this.formBuilder.group({
      resourceType: [null, [Validators.required]],
      resourceName: [null, [Validators.required, Validators.pattern("^.{3,40}$")]],
      resourceCode: [null, [Validators.required, Validators.pattern("^.{3,40}$")]],
      gender: [null, [Validators.required]],
    });
  }

  /* ===============================================================
    Profile Fetch
  =============================================================== */
  fetchProfile() {
    let key = 'UserInfo';
    this.accessId = localStorage.getItem(key);
    this.authService.getProfile(this.accessId).subscribe((profile: any) => {
      this.username = profile.user.username;
    });
  }

  /* ===============================================================
      Center Details Fetch
  =============================================================== */
  centerDetailsFetch() {
    this.accessId = localStorage.getItem('UserInfo');
    this.authService.getProfile(this.accessId).subscribe((profile: any) => {
      //console.log(profile);
      this.roleId = profile.user.roleId;
      if (profile.user.roleId == 77) {
        this.centerAccessId = profile.user.accessId;
      } else if (profile.user.roleId == 0o0) {
        this.centerAccessId = localStorage.getItem('UserInfoCenterAccessID');
      } else {
        this.centerAccessId = localStorage.getItem('UserInfoCenterAccessID');
      }

    this.centerService.getCenter(this.centerAccessId).subscribe((data: any) => {
      this.centerDetail = data.centerDetails;
      this.schemeAccessId = this.centerDetail.schemeAccessId;
      this.schemeCode = this.centerDetail.schemeCode;
      this.tpAccessId = this.centerDetail.tpAccessId;
      this.tpCode = this.centerDetail.tpCode;
      this.tpName = this.centerDetail.tpName;
      this.centerAccessID = this.centerDetail.accessId;
      this.centerCode = this.centerDetail.centerCode;
      this.centerName = this.centerDetail.centerName;
      this.centerService.allCourse(this.centerAccessID).subscribe((data: any) => {
        this.courseDetails = data.courseDetails;
        //console.log(this.courseDetails);
        if(this.courseDetails.length == 0){
          //console.log('goto course cre');
          this.toastr.info('Create course from course screen', 'Course not found');
          setTimeout(() => {
            this.router.navigate(['/course']);
          }, 1000);
        }else{        
        }
      });
    });
  });
  }

  /* ===============================================================
    All Class Fetch
  =============================================================== */
  getAllClass(){
    let key = 'UserInfo';
    this.accessId = localStorage.getItem(key);
    this.classService.getAllClass(this.accessId).subscribe((data:any)=>{
      this.classDetails = data.classDetails;
    });
  }

  /* ===============================================================
      Create Resources( Student and Teacher)
  =============================================================== */
  /* Select Course */
  onClickSelectCourse(data) {
    //console.log(data);
    this.courseAccessId = data.accessId;
    this.courseCode = data.courseCode;
    this.courseName = data.courseName;
    this.centerService.getAllBatch(this.centerAccessID, data.accessId).subscribe((allBatch:any)=>{
      this.allBatch = allBatch.batchDetails;
      //console.log(this.allBatch);
      if(this.allBatch.length == 0){
        this.toastr.info('Create batch from course screen', 'Batch not found');
      }else{
        this.selectBatchSec = 'show';
      }
    })
  }

  /* Select Batch */
  onClickBatch(data){
    //console.log(data);
    this.batchAccessId = data.accessId;
    this.batchCode = data.batchCode;
    this.batchHours = data.batchHours;
  }

  onClickSelectBatch(){
    this.selectBatchAccessId = this.batchAccessId;
    this.selectBatchCode = this.batchCode;
    this.selectBatchHours = this.batchHours;
    this.selectBatchSec = 'hide';
    setTimeout(()=>{   
      this.selectCourseSec = 'hide';
      this.studentDetailsSec = 'show';
    }, 1000);
  }

  onClickCancelBatchSec(){
    this.selectBatchAccessId = null;
    this.selectBatchCode = null;
    this.selectBatchHours = null;
    this.batchAccessId = null;
    this.batchCode = null;
    this.batchHours = null;
    this.selectBatchSec = 'hide';
  }

  /* Student Details Sec And go to get student image sec */
  onClickStudentDetails() {
    this.now = moment();
    this.timestamp = this.now.format('YYYYMMDDHHmmss');
    this.datetime = this.now.format("YYYY-MM-DD HH:mm:s");
    this.studentAccessId = "DWS-OPTG-RS-" + this.timestamp
    //console.log(this.studentAccessId);
    this.liveStream = 'live-Stream-show';
    this.dSec = 'live-Stream-hide';
  }

  /* Image Capture */
  imageCapture() {
    this.disableBtnImage = 'click-disable';
    this.cameraId = localStorage.getItem('ResCameraID');
    const user = {
      accessId: this.studentAccessId,
      user: 'RSLDC',
      streamName: this.cameraId
    }
    //console.log(user);
    this.centerService.imageCapture(user).subscribe((data: any) => {
      //console.log(data);
      if(data.success == 1){
        this.imagePath = data.imagePath;
        this.imageFile = data.fileNames;
        this.imageFile.forEach(data => {
          this.imageUrl = this.imagePath + data;
        });
        this.imageFolder.push(this.imageUrl);
        //console.log(this.imageFolder);
        if(this.imageFolder.length < 10){
          //console.log('show');
          this.imageCaptureBtn = true
        }else{
          //console.log('hide');
          this.imageCaptureBtn = false;
        }
        setTimeout(() => {
          this.disableBtnImage = '';
        }, 1000);
      }else{}
    });
  }

  /* Capture Image Delete*/
  imageDelete(data1){
    //console.log(data1);
    this.filename(data1);
    this.imageName = this.filename(data1);
    const user = {
      accessId: this.studentAccessId,
      user: 'RSLDC',
      fileName: this.imageName
    }
    //console.log(user);
    this.centerService.imageDelete(user).subscribe((data:any) =>{
      //console.log(data);
      if(data.success == 1){
        const index: number = this.imageFolder.indexOf(data1);
        if (index !== -1) {
            this.imageFolder.splice(index, 1);
            //console.log(this.imageFolder);
        }  
      }else{}
    });
  }

  filename(path){
  path = path.substring(path.lastIndexOf("/")+ 1);
  return (path.match(/[^.]+(\.[^?#]+)?/) || [])[0];
}

  /* Select Image for Profile Picture */
  selectImage(data){
    this.selectProfileImage = data ;
    this.disableBtn = true;
  }
  /* Create Resources  */
  onCreateResources() {
    this.disableBtn = false;
    this.now = moment();
    this.timestamp = this.now.format('YYYYMMDDHHmmss');
    this.datetime = this.now.format("YYYY-MM-DD HH:mm:s");
    const user = {
      accessId: this.studentAccessId,
      schemeAccessId: this.schemeAccessId,
      schemeCode: this.schemeCode,
      tpAccessId: this.tpAccessId,
      tpCode: this.tpCode,
      tpName: this.tpName,
      centerAccessId: this.centerAccessID,
      centerCode: this.centerCode,
      centerName: this.centerName,
      batchAccessId: this.selectBatchAccessId,
      batchCode: this.selectBatchCode,
      batchHours: this.selectBatchHours,
      resourceType: this.form.get('resourceType').value,
      resourceName: this.form.get('resourceName').value,
      resourceCode: this.form.get('resourceCode').value,
      gender: this.form.get('gender').value,
      imagePath: this.imageFolder,
      profileImagePath: this.selectProfileImage,
      courseAccessId: this.courseAccessId,
      courseCode: this.courseCode,
      courseName: this.courseName,
      createdBy: this.username,
      createdAt: this.datetime
    }
    //console.log(user);
    this.centerService.createResoures(user).subscribe(data => {
      //console.log(data);
      this.form.reset();
      this.toastr.success('View resource from resource screen', 'Resource created');
      this.selectProfileImage = null;
      this.studentAccessId = null;
      while (this.imageFolder.length !== 0) {
        this.imageFolder.shift();
      }
      this.liveStream = 'live-Stream-hide';
      this.dSec = 'live-Stream-show';
    });

  }

  /* ===============================================================
      Back Function
  =============================================================== */
  back() {
    // this.selectCourseSec = 'show';
    // this.studentDetailsSec = 'hide';
    // this.courseName = null;
    // location.reload();
    this.studentDetailsSec = 'hide-opacity';
    this.selectCourseSec = 'show';
    this.courseAccessId = null;
    this.courseName  = null;
    this.batchAccessId = undefined;
    this.selectProfileImage = null;
    this.studentAccessId = null;
    while (this.imageFolder.length !== 0) {
      this.imageFolder.shift();
    }
    this.liveStream = 'live-Stream-hide';
    this.dSec = 'live-Stream-show';
    this.disableBtn = false;
    this.form.reset();
    this.loadScript();
  }

  /* ===============================================================
      JavaScript Load 
  =============================================================== */
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/js/common.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
 

  ngOnInit() {
    this.now = moment();
    this.date = this.now.format('Do MMMM YYYY');
    this.fetchProfile();
    this.centerDetailsFetch();
    this.loadScript();
    this.getAllClass();
    this.cameraId = localStorage.getItem('ResCameraID');
    //console.log(this.cameraId);
    this.stream = this._sanitizer.bypassSecurityTrustUrl('rtmp://35.154.64.51:1935/live/' + this.cameraId);
    //console.log(this.stream);
    /* This Code for Flashphoner */
    //return this.stream = this._sanitizer.bypassSecurityTrustResourceUrl('https://wcs5-eu.flashphoner.com:8888/embed_player?urlServer=wss://wcs5-eu.flashphoner.com:8443&streamName=' + this.testUrl + '&mediaProviders=WebRTC,Flash,MSE,WSPlayer?autoplay=1')
    //this.imageCapture();
  }

}
