import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from 'src/app/services/authService/auth.service';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { ClassService } from 'src/app/services/classService/class.service';
import * as moment from 'moment';

@Component({
  selector: 'app-create-class',
  templateUrl: './create-class.component.html',
  styleUrls: ['./create-class.component.css']
})
export class CreateClassComponent implements OnInit {

  now;
  date;
  roleId;
  form: FormGroup;
  accessId;
  timestamp;
  datetime;
  username;
  usertype;
  centerAccessId;
  centerDetail;
  schemeAccessId;
  schemeCode;
  tpAccessId;
  tpCode;
  tpName;
  centerAccessID;
  centerCode;
  centerName;
  allClass;
  createSecCss;
  creBtn:boolean=true;
  selectClassAcesId;

  selectClassCamAssgin;
  assginCss;
  assginform:FormGroup;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private location: Location,
    private toastr: ToastrService,
    private authService: AuthService,
    private centerService: CenterDetailsService,
    private classService: ClassService
  ) { this.createForm(); this.createFormCamAssgin() }

  /* ===============================================================
        Validation
  =============================================================== */
  createForm() {
    this.form = this.formBuilder.group({
      className: [null, [Validators.required, Validators.pattern("^.{3,40}$")]],
      classSubName: [null, [Validators.required, Validators.pattern("^.{3,40}$")]],
    });
  }

  createFormCamAssgin() {
    this.assginform = this.formBuilder.group({
      cameraId: [null, [Validators.required, Validators.pattern("^.{3,40}$")]],
    });
  }

  /* ===============================================================
    Profile Fetch
  =============================================================== */
  fetchProfile() {
    let key = 'UserInfo';
    this.accessId = localStorage.getItem(key);
    this.authService.getProfile(this.accessId).subscribe((profile: any) => {
      this.username = profile.user.username;
      this.roleId = profile.user.roleId;
      if(profile.user.roleId == 77){
        this.centerAccessId = profile.user.accessId;
      }else{
        this.centerAccessId = localStorage.getItem('UserInfoCenterAccessID');
      }
      this.centerService.getCenter(this.centerAccessId).subscribe((data: any) => {
        this.centerDetail = data.centerDetails;
        //console.log(this.centerDetail);
        this.schemeAccessId = this.centerDetail.schemeAccessId;
        this.schemeCode = this.centerDetail.schemeCode;
        this.tpAccessId = this.centerDetail.tpAccessId;
        this.tpCode = this.centerDetail.tpCode;
        this.tpName = this.centerDetail.tpName;
        this.centerAccessID = this.centerDetail.accessId;
        this.centerCode = this.centerDetail.centerCode;
        this.centerName = this.centerDetail.centerName;
        this.classService.getAllClass(this.centerAccessID).subscribe((data: any) => {
          console.log(data);
          this.allClass = data.classDetails;
          if(this.allClass.lenght == 0){
            this.createSecCss = 'overlay-visible';
          }else{}
        });
      });
    });
  }

  /* ===============================================================
      Create Center 
  =============================================================== */
  onCreateClass() {
    this.creBtn = false;
    this.now = moment();
    this.timestamp = this.now.format('YYYYMMDDHHmmss');
    this.datetime = this.now.format("YYYY-MM-DD HH:mm:s");
    const user = {
      accessId: "DWS-OPTG-CL-" + this.timestamp,
      schemeAccessId: this.schemeAccessId,
      schemeCode: this.schemeCode,
      tpAccessId: this.tpAccessId,
      tpCode: this.tpCode,
      tpName: this.tpName,
      centerAccessId: this.centerAccessID,
      centerCode: this.centerCode,
      centerName: this.centerName,
      className: this.form.get('className').value,
      classSubName: this.form.get('classSubName').value,
      createdBy: this.username,
      createdAt: this.datetime
    }
    //console.log(user);
    this.classService.createClass(user).subscribe((data:any) => {
      if(data.success == 1){
        this.toastr.success(data.message, 'success');
      }else{
        this.toastr.info(data.message, 'info');
      }
      this.classService.getAllClass(this.centerAccessID).subscribe((data: any) => {
        //console.log(data);
        this.allClass = data.classDetails;
        this.creBtn = true;
        if(this.allClass.lenght == 0){
          this.createSecCss = 'overlay-visible';
        }else{}
      });
      this.form.reset();
      this.createSecCss = '';
    });
  }

  createNewClass(){
    this.createSecCss = 'overlay-visible';
  }

  onClickClassDel(data){
    //console.log(data);
    this.selectClassAcesId = data.accessId;
  }

  canfDel(){
    this.selectClassAcesId = undefined;
  }

  confDel(){
    this.creBtn = false;
    const classData = {
      classAccessId: this.selectClassAcesId
    }
    //console.log(classData);
    this.classService.deleteClass(classData).subscribe((info:any) =>{
      this.classService.getAllClass(this.centerAccessID).subscribe((data: any) => {
        //console.log(data);
        this.allClass = data.classDetails;
        this.creBtn = true;
        this.selectClassAcesId = undefined;
      });
    });
  }

  back() {
    this.createSecCss = '';
    this.form.reset();
  }

  onClickCamAssgin(data){
    console.log(data);
    this.selectClassCamAssgin = data;
    this.assginCss = 'overlay-visible';
  }

  canfCamAssgin(){
    this.assginCss = '';
  }

  cameraAssgin(){   
    this.creBtn = false;
    const classData = {
      centerAccessId: this.selectClassCamAssgin.centerAccessId,
      classAccessId: this.selectClassCamAssgin.accessId,
      className: this.selectClassCamAssgin.className,
      classSubName: this.selectClassCamAssgin.classSubName,
      cameraDeviceId: this.assginform.get('cameraId').value,
      cameraId: this.assginform.get('cameraId').value.replace(/-/g,''),
      streamUrl: "20.198.76.215:1935/live/"+this.assginform.get('cameraId').value.replace(/-/g,'')
    }
    console.log(classData);
    this.classService.cameraAssgin(classData).subscribe((info:any) =>{
      this.classService.getAllClass(this.centerAccessID).subscribe((data: any) => {
        console.log(info);
        this.allClass = data.classDetails;
        this.creBtn = true;
        this.selectClassCamAssgin = undefined;
        this.assginCss = '';
        this.assginform.reset();
      });
    });
  }

  backAssgin() {
    this.assginCss = '';
    this.assginform.reset();
  }

  /* ===============================================================
      JavaScript Load 
  =============================================================== */
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/js/common.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  ngOnInit() {
    this.now = moment();
    this.date = this.now.format('Do MMMM YYYY');
    this.loadScript();
    this.fetchProfile();
  }

}
