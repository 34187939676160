import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../authService/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminServiceService {

  options;
  domain = this.authService.domain;

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  /* ===============================================================
      Authentication Header
  =============================================================== */
  createAuthenticationHeaders() {
    this.authService.loadToken();
      this.options = { headers : new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'authorization': this.authService.authToken
      })
    }
  }

  /* ===============================================================
      Get All Scheme Information
  =============================================================== */
  getScheme() {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'schemeDetail/getScheme', this.options);
  }
  getSchemeWithTrial() {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'schemeDetail/getSchemeWithTrial', this.options);
  }

  /* ===============================================================
      Admin Details
  =============================================================== */
  adminDetils(data){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'adminDetail/saveAdminDetail', data, this.options);
  }

  /* ===============================================================
      Admin Profile Detaails fetch
  =============================================================== */
  adminProfileFetch(accessID) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'adminDetail/adminInfo/' + accessID, this.options);
  }


  /* ===============================================================
      Admin Dashboard Side Count
  =============================================================== */
  getADSideCount(date) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getADSideCount/' + date, this.options);
  }

  /* ===============================================================
      Admin Dashboard  Count
  =============================================================== */
  getDashboardCount(schemeAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getDashboardCount/' + schemeAccessId, this.options);
  }

  /* ===============================================================
      Admin Dashboard Main Count
  =============================================================== */
  getADMainCount(schemeAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getADSCBySchemes/' + schemeAccessId, this.options);
  }

  /* ===============================================================
      Get Attendance Student Data count by scheme
  =============================================================== */
  getAttenDataCountStuByScheme(schemeAccessId, today) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getAttenDataCountStuByScheme/' + schemeAccessId + '/' + today, this.options);
  }

    /* ===============================================================
      Get Attendance Teacher Data count by scheme
  =============================================================== */
  getAttenDataCountTeachByScheme(schemeAccessId, today) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getAttenDataCountTeachByScheme/' + schemeAccessId + '/' + today, this.options);
  }

  /* ===============================================================
      Get Attendance Employee Data count by scheme
  =============================================================== */
  getAttenDataCountEmpByScheme(schemeAccessId, today) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getAttenDataCountEmpByScheme/' + schemeAccessId + '/' + today, this.options);
  }

  /* ===============================================================
    Today Attendance center List
  =============================================================== */
  getAllRsldcListByAttendanceDate(today) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'attendanceDetail/getAllRsldcListByAttendanceDate/' + today, this.options);
  }

  /* ===============================================================
    Attendance all count
  =============================================================== */
  getAttendanceAllCountRsldc(centerCode, today) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'attendanceDetail/getAttendanceAllCountRsldc/' + centerCode + '/' + today, this.options);
  }

  /* ===============================================================
    RSLDC all Resource List
  =============================================================== */
  allResource(){
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'resourceDetail/getAllResForSearch', this.options);
  }

  /* ===============================================================
    RSLDC Resource Edit
  =============================================================== */
  resourceEdit(resDate){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'resourceDetail/assignStudentNewData', resDate, this.options);
  }

  /* ===============================================================
    RSLDC Resource Edit
  =============================================================== */
  resourceIdUpdate(resDate){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'resourceDetail/updateResId', resDate, this.options);
  }

  /* ===============================================================
    Tp Report Genarate
  =============================================================== */
  setTpReport(resDate){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'countDetail/setTpReport', resDate, this.options);
  }

  /* ===============================================================
    Get TP Report
  =============================================================== */
  getTpList(){
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'tpDetail/getTpList', this.options);
  }

  /* ===============================================================
    Batch Report Genarate
  =============================================================== */
  setBatchReport(sendData){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'countDetail/setBatchReport', sendData, this.options);
  }

  /* ===============================================================
    Get Batch Report
  =============================================================== */
  getBacthList(){
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'batchDetail/getAllBatchList', this.options);
  }

  /* ===============================================================
    Get Active Batch Report
  =============================================================== */
  getActiveBacthList(){
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'batchDetail/getAllActiveBatch', this.options);
  }


  /* ===============================================================
    Enroll Report Genarate
  =============================================================== */
  setEnrollmentReport(sendData){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'countDetail/setEnrollmentReport',sendData, this.options);
  }

  /* ===============================================================
    Get Enroll Report
  =============================================================== */
  getEnrollmentReport(){
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'batchDetail/getAllBatchList', this.options);
  }

  /* ===============================================================
    Center Report Genarate
  =============================================================== */
  setCenterReport(sendData){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'countDetail/setReportForCenter', sendData, this.options);
  }

  /* ===============================================================
    Get Center Report
  =============================================================== */
  getCenterReport(){
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'centerDetail/getAllCenterForReport', this.options);
  }

  /* ===============================================================
    Get Trainee Report
  =============================================================== */
  getTraineeReport(){
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'resourceDetail/getAllResourceForReport', this.options);
  }

  /* ===============================================================
    Get Camera Report By Scheme
  =============================================================== */
  getCamReportByScm(schemeAccessId){
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getADSCBySchemes/' + schemeAccessId, this.options);
  }

  
  /* ===============================================================
    Get Center Report
  =============================================================== */
  getAllSectorCourse(sehemeCode){
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'centerDetail/getAllSectorCourse/' + sehemeCode, this.options);
  }

}
