import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';;
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/authService/auth.service';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {
  date;
  now;
  today;
  hour;
  roleId;
  classAccessId;
  usertype;
  adminNav: boolean;
  centerNav: boolean;
  piaNav: boolean;
  courseAccessId;
  batchAccessId;
  centerAccessId;
  allAttendance;
  courseDetails;
  todayDate;
  allBatch;
  courseList = 'show';
  batchList = 'hide';
  courseName;
  batchCode;
  todayAttenCount;
  selectStep;
  search;
  todayAttendance;
  centerDetails

  lHintCourse;
  lDataCourse;
  lHinttextCourse;
  lHintBatch;
  lDataBatch;
  lHinttextBatch;
  courseCss= 'hide';
  batchCss= 'hide';
  resourceMar= 'hide';
  attenInfo;
  selectResAccessId;
  selectResAccessIdPre;
  changeDate;
  dateToday;
  bubble:boolean=true;
  testing:boolean;
  inputData;

  secFilter = 'left-p-bar';
  fLocation = 'hide';

  rightSec = 'expand';
  header = 'expand-h';
  totalEnroll;
  totalEnrollCss ='hide';

  activeMenuStudent;
  activeMenuTeacher;
  activeMenuEmployee;
  allAttendanceSort;
  fdSec = 'hide';
  
  // Min moment: February 12 2018, 10:30
  public min = new Date(2018, 1, 12, 10, 30);

  // Max moment: April 21 2018, 20:30
  public max = new Date;
  public selectedMoment = new Date();
  private paramsSubscription: Subscription;

  constructor(
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private authService: AuthService,
    private centerService: CenterDetailsService
  ) { }

  /* ===============================================================
      Attendance Fetch
  =============================================================== */
  getAllCourse() {
    this.now = moment(); // add this 2 of 4
    this.date = this.now.format('YYYY-MM-DD');
    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      this.roleId = profile.user.roleId;
      if (profile.user.roleId == 77) {
        this.centerAccessId = profile.user.accessId;
      } else {
        this.centerAccessId = localStorage.getItem('UserInfoCenterAccessID');
      }
      this.centerService.getCenter(this.centerAccessId).subscribe((centerDetail:any)=>{
        this.centerDetails = centerDetail.centerDetails;
        console.log(this.centerDetails);
        
      })
      this.centerService.attendanceCount(this.centerAccessId, this.date).subscribe((attenCount: any) => {
        this.todayAttenCount = attenCount;
        //console.log(this.todayAttenCount);
        this.centerService.allCourse(this.centerAccessId).subscribe((data: any) => {
          this.courseDetails = data.courseDetails;
        });
      });
      this.centerService.attendanceByToday(this.centerAccessId, this.date).subscribe((todayAtten:any) =>{
        this.todayAttendance = todayAtten.resourceDetails;
        //console.log(todayAtten);
      });
    });
  }

  onClickBatch() {
    this.batchList = 'show';
    this.courseList = 'hide';
  }

  onClickCourse() {
    this.batchList = 'hide';
    this.courseList = 'show';
  }

  
  onClickStudent(){
    if(this.changeDate == undefined){
      this.date = this.now.format('YYYY-MM-DD');
    }else{
      this.date = this.changeDate;
    }
    this.centerService.attendanceByToday(this.centerAccessId, this.date).subscribe((resourceData:any) =>{
      this.allAttendanceSort = resourceData.resourceDetails.sort((a, b) => a.resourceName > b.resourceName ? 1 : -1);
      this.allAttendance = this.allAttendanceSort.filter(res => res.resourceType === 'S');
    });
    this.centerService.allStudents(this.centerAccessId).subscribe((resources:any) =>{
      this.totalEnroll = resources.resourceDetails.length;
      //console.log(this.totalEnroll);
      this.secFilter = 'left-p-s-bar';
      this.fLocation = 'show';
      this.courseList = 'show';
      this.batchList = 'hide';
      this.rightSec = '';
      this.header = '';
      this.lHintCourse = 'hint-select';
      this.activeMenuStudent = 'active-menu';
      this.activeMenuEmployee = '';
      this.activeMenuTeacher = '';
      this.resourceMar = 'show';
      this.courseCss = 'hide';
      this.batchCss = 'hide';
      this.selectStep = 'student';
      this.fdSec = 'show';
      this.testing = false;
      this.totalEnrollCss = 'show';
    });
  }

  onSelectCourse(course) {
    this.courseName = course.courseName;
    this.courseAccessId = course.courseAccessId ;
    this.selectStep = 'course';
    this.now = moment(); // add this 2 of 4
    if(this.changeDate == undefined){
      this.date = this.now.format('YYYY-MM-DD');
    }else{
      this.date = this.changeDate;
    }
    this.centerService.attendanceByCourse(this.centerAccessId, this.courseAccessId, this.date).subscribe((resourceData: any) => {
      this.allAttendanceSort = resourceData.resourceDetails.sort((a, b) => a.resourceName > b.resourceName ? 1 : -1);
      this.allAttendance = this.allAttendanceSort.filter(res => res.resourceType === 'S');
      this.centerService.getAllBatch(this.centerAccessId, course.courseAccessId).subscribe((allBatch: any) => {
        this.allBatch = allBatch.batchDetails.sort((a, b) => a.batchCode > b.batchCode ? 1 : -1);
        console.log(allBatch);
        
        this.centerService.courseBasicResources(this.centerAccessId, course.courseAccessId).subscribe((resources: any) => {
          this.totalEnroll = resources.resourceDetails.length;
          //console.log(this.totalEnroll);
          this.batchList = 'show';
          this.courseList = 'hide';
          this.batchAccessId = null;
          this.batchCode = null;
          this.lHintCourse = 'hint-select';
          this.lDataCourse = 'l-data';
          this.lHinttextCourse = 'bottom-text';
          this.lHintBatch = '';
          this.lDataBatch = '';
          this.lHinttextBatch = '';
          this.search = '';
          this.courseCss = 'show';
          this.resourceMar = 'show'
          this.batchCss = 'hide';
          this.fdSec = '';
          this.testing = false;
          this.totalEnrollCss = 'show';
        });
      });
    });
  }

  onSelectBatch(batch) {
    this.batchCode = batch.batchCode;
    this.batchAccessId = batch.accessId;
    this.selectStep = 'batch';
    this.now = moment(); // add this 2 of 4
    this.date = this.now.format('YYYY-MM-DD');
    if(this.changeDate == undefined){
      this.date = this.now.format('YYYY-MM-DD');
    }else{
      this.date = this.changeDate;
    }
    this.centerService.attendanceByBatch(this.centerAccessId, this.batchAccessId, this.date).subscribe((resourceData: any) => {
      this.allAttendanceSort = resourceData.resourceDetails.sort((a, b) => a.resourceName > b.resourceName ? 1 : -1);
      this.allAttendance = this.allAttendanceSort.filter(res => res.resourceType === 'S');
      this.centerService.batchBasicResources(this.centerAccessId, this.batchAccessId).subscribe((resources: any) => {
        this.totalEnroll = resources.resourceDetails.length;
        //console.log(this.totalEnroll);
        this.lHintBatch = 'hint-select';
        this.lDataBatch = 'l-data';
        this.lHinttextBatch = 'bottom-text';
        this.batchCss = 'show';
        this.resourceMar = 'show';
        this.selectResAccessId = undefined;
        this.fdSec = '';
        this.testing = false;
        this.totalEnrollCss = 'show';
      }); 
    });
  }

  onClickTeacher(){
    this.now = moment(); // add this 2 of 4
    this.date = this.now.format('YYYY-MM-DD');
    this.centerService.attendanceByToday(this.centerAccessId, this.date).subscribe((resourceData:any) =>{
      //console.log(resourceData);
      this.allAttendanceSort = resourceData.resourceDetails.sort((a, b) => a.resourceName > b.resourceName ? 1 : -1);
      this.allAttendance = this.allAttendanceSort.filter(res => res.resourceType === 'T');
      this.centerService.allTeachers(this.centerAccessId).subscribe((resources:any) =>{
        this.totalEnroll = resources.resourceDetails.length;
        //console.log(this.totalEnroll);
        this.secFilter = 'left-p-bar';
        this.fLocation = 'hide';
        this.rightSec = 'expand';
        this.header = 'expand-h'
        this.activeMenuStudent = '';
        this.activeMenuEmployee = '';
        this.activeMenuTeacher = 'active-menu';
  
        this.batchCode = undefined;
        this.courseAccessId = undefined;
  
        this.lHintCourse = '';
        this.lDataCourse= '';
        this.lHinttextCourse= '';
        this.lHintBatch= '';
        this.lDataBatch= '';
        this.lHinttextBatch= '';
  
        this.allBatch =  undefined;
        this.resourceMar = 'show'
        this.courseCss = 'hide';
        this.batchCss = 'hide';
        this.selectStep = 'teacher';
        this.fdSec = '';
        this.testing = false;
        this.totalEnrollCss = 'show';
      });
    });
  }

  onClickEmployee(){
    this.centerService.attendanceByToday(this.centerAccessId, this.date).subscribe((resourceData:any) =>{
      //console.log(resourceData);
      this.allAttendanceSort = resourceData.resourceDetails.sort((a, b) => a.resourceName > b.resourceName ? 1 : -1);
      this.allAttendance = this.allAttendanceSort.filter(res => res.resourceType === 'E');
      this.centerService.allEmployee(this.centerAccessId).subscribe((resources:any) =>{
        this.totalEnroll = resources.resourceDetails.length;
        //console.log(this.totalEnroll);
        this.secFilter = 'left-p-bar';
        this.fLocation = 'hide';
        this.rightSec = 'expand';
        this.header = 'expand-h'
        this.activeMenuStudent = '';
        this.activeMenuEmployee = 'active-menu';
        this.activeMenuTeacher = '';
  
        this.lHintCourse = '';
        this.lDataCourse= '';
        this.lHinttextCourse= '';
        this.lHintBatch= '';
        this.lDataBatch= '';
        this.lHinttextBatch= '';
  
        this.batchCode =  undefined;
        this.courseAccessId = undefined;
        this.resourceMar = 'show'
        this.courseCss = 'hide';
        this.batchCss = 'hide';
        this.selectStep = 'staff';
        this.fdSec = '';
        this.testing = false;
        this.totalEnrollCss = 'show';
      });
    });
  }

  /* ===============================================================
      Date basic Image and video Fetch
  =============================================================== */
  onChange(value) {
    this.route.params.subscribe(paramas => {
      this.date = (this.datePipe.transform(value._d, 'yyyy-MM-dd'));
      this.changeDate = (this.datePipe.transform(value._d, 'yyyy-MM-dd'));
      this.now = moment(); // add this 2 of 4
      this.dateToday = this.now.format('YYYY-MM-DD');
      if(this.changeDate == this.dateToday){
        this.bubble = true;
      }else{
        this.bubble = false;
      }
      if(this.selectStep == 'student'){
        this.centerService.attendanceByToday(this.centerAccessId, this.date).subscribe((resourceData:any) =>{
          this.allAttendanceSort = resourceData.resourceDetails.sort((a, b) => a.resourceName > b.resourceName ? 1 : -1);
          this.allAttendance = this.allAttendanceSort.filter(res => res.resourceType === 'S');
        })
      }else if (this.selectStep == 'course') {
        this.centerService.attendanceByCourse(this.centerAccessId, this.courseAccessId, this.date).subscribe((resourceData: any) => {
          this.allAttendanceSort = resourceData.resourceDetails.sort((a, b) => a.resourceName > b.resourceName ? 1 : -1);
          this.allAttendance = this.allAttendanceSort.filter(res => res.resourceType === 'S');
          //console.log(this.allAttendance);
        });
      } else if (this.selectStep == 'batch') {
        this.centerService.attendanceByBatch(this.centerAccessId, this.batchAccessId, this.date).subscribe((resourceData: any) => {
          this.allAttendanceSort = resourceData.resourceDetails.sort((a, b) => a.resourceName > b.resourceName ? 1 : -1);
          this.allAttendance = this.allAttendanceSort.filter(res => res.resourceType === 'S');
          //console.log(this.allAttendance);
        });
      }else if (this.selectStep == 'staff') {
        this.centerService.attendanceByToday(this.centerAccessId, this.date).subscribe((attendance: any) => {
          this.allAttendanceSort = attendance.resourceDetails.sort((a, b) => a.resourceName > b.resourceName ? 1 : -1);
          this.allAttendance = this.allAttendanceSort.filter(res => res.resourceType === 'E');
          //console.log(this.allAttendance);
        });
      }else if (this.selectStep == 'teacher') {
        this.centerService.attendanceByToday(this.centerAccessId, this.date).subscribe((attendance: any) => {
          this.allAttendanceSort = attendance.resourceDetails.sort((a, b) => a.resourceName > b.resourceName ? 1 : -1);
          this.allAttendance = this.allAttendanceSort.filter(res => res.resourceType === 'T');
          //console.log(this.allAttendance);
        });
       } else {
        this.allAttendance = [];
      }
    });
  }

  selectRes(data){
    this.selectResAccessId = data.accessId;
    this.centerService.attendanceByTodayForRes(data.centerAccessId, data.accessId, this.date).subscribe((attendanceInfo:any) =>{
      this.attenInfo = attendanceInfo.attendanceData.sort((a, b) => b.attendanceTime > a.attendanceTime ? 1 : -1);
    });
  }

  selectResPre(data){
    this.selectResAccessId = data.accessId;
    this.centerService.attendanceByTodayForRes(data.centerAccessId, data.accessId, this.changeDate).subscribe((attendanceInfo:any) =>{
      this.attenInfo = attendanceInfo.attendanceData.sort((a, b) => b.attendanceTime > a.attendanceTime ? 1 : -1);
    });
  }

  closeRes(data){
    this.selectResAccessId = undefined;
  }

  somethingChanged(data) {
    // this.batchList = 'hide';
    // this.courseList = 'show';
    // this.batchAccessId = null;
    // this.batchCode = null;
    // this.lHintCourse = '';
    // this.lDataCourse = '';
    // this.lHinttextCourse = '';
    // this.lHintBatch = '';
    // this.lDataBatch = '';
    // this.lHinttextBatch = '';
    // this.courseName = null;
    // this.courseAccessId = null;
    // this.courseCss = 'hide';
    // this.batchCss = 'hide';
    // this.resourceMar = 'hide';
    // this.courseAccessId = undefined;
    // this.batchAccessId =  undefined;
    // this.selectResAccessId = undefined;
    // this.allBatch = [];
    this.testing = true;
    if (data.length > 2) {
      this.inputData = data
    } else {

    }
  }

  ngOnInit() {
    this.now = moment(); // add this 2 of 4
    this.todayDate = this.now.format('Do MMMM YYYY');
    this.paramsSubscription = this.route.params
      .subscribe(params => {
        this.getAllCourse();
      });
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }

}
