import { Component, OnInit, Compiler } from '@angular/core';
import * as moment from 'moment';
import * as XLSX from '@sheet/image';

import { AdminServiceService } from 'src/app/services/adminService/admin-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/services/authService/auth.service';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { PiaServiceService } from 'src/app/services/piaService/pia-service.service';
import { CCService } from 'src/app/services/cCService/c-c.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  now;
  date;
  dateTime;
  totalData = [];
  roleId;
  sideCount;
  allScheme;
  loader:boolean = true;

  sehemeSec = 'show-anim';
  districtSec = 'hide-anim';
  piaSec = 'hide-anim'; 
  sdcSec = 'hide-anim';
  selectStep = 'scheme';
  selectFilterType= 'scheme';
  seleSdcAccessId;
  allDistrict;
  selectDistrictName;
  allPia;
  selectPiaName;
  piaAccessId;
  allSdc;
  selectDistrictCode;
  selectSchemeAccessId;
  selectCourseAccessId;

  lHintSdc;
  lDataSdc;
  lHinttextSdc;
  lHintCourse;
  lDataCourse;
  lHinttextCourse;
  lHintBatch;
  lDataBatch;
  lHinttextBatch;

  sdcName;
  courseName;
  batchName;
  allCourse;
  allBatch;
  sdcListSec='hide';
  courseListSec='hide';
  batchListSec = 'hide';
  systemCheck:boolean;
  systemCheckTime;
  sdcReportType = 'overAll';
  allEnroll;
  allPresent;
  valueList: boolean;
  valueListDist: boolean;
  valueListTp: boolean;

  reportType = 'main';
  newGetDataMain = [];
  newGetDataMainFilt;
  newGetDataTp = [];
  newGetDataTpFilt;
  newGetDataBatch = [];
  newGetDataBatchFilt;
  newGetDataActBatch = [];
  newGetDataActBatchFilt;
  newGetDataTrainee = [];
  newGetDataTraineeFilt;
  newGetSdcData = [];
  newGetSdcDataFilt
  newGetDataEnroll = [];
  newGetDataEnrollFilt;
  newGetDataSchemeCam = [];
  newGetDataSchemeCamFilt = [];
  newGetDataTPCam = [];
  newGetDataTPCamFilt;
  newGetReportDetaData = [];
  newGetReportDetaDataFilt;

  selectSchemeCode;
  selectDistName;
  selectTpName;
  newGetDataMainScmeFilt;
  newGetDataTpScmeFilt;
  newGetDataSdcScmeFilt;
  newGetDataReportDetaScmeFilt;
  newGetDataBatchScmeFilt;
  newGetDataActBatchScmeFilt;
  newGetDataTraineScmeFilt;
  newGetDataEnrollScmeFilt;
  newGetDataCameraScmeFilt;
  newGetDataTPcameraScmeFilt;

  newGetDataMainDistFilt;
  newGetDataTpDistFilt;
  newGetDataSdcDistFilt;
  newGetDataReportDetaDistFilt;
  newGetDataBatchDistFilt;
  newGetDataActBatchDistFilt;
  newGetDataTraineDistFilt;
  newGetDataEnrollDistFilt;
  newGetDataTPcameraDistFilt;

  selectSchemeCodeSer;
  selectDistNameSer;
  searchTP;
  // Min moment: February 12 2018, 10:30
  public min = new Date(2018, 1, 12, 10, 30);

  // Max moment: April 21 2018, 20:30
  public max = new Date;
  public selectedMoment = new Date();

  config ={
    search:true,
    displayKey: 'tpName',
    height: 'auto',
    placeholder:'Select',
    moreText: 'more'
  }

  testing:boolean;

  constructor(
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    private _compiler: Compiler,
    private ccService: CCService,
    private piaService: PiaServiceService,
    private authService: AuthService,
    private centerService: CenterDetailsService,
    private adminService: AdminServiceService
  ) { }

  totalCount1st(data) {
    let total = 0;
    data.forEach((d) => {
      total += parseInt(d.camData.allCenterCount);
    });
    return total;
  }

  totalCount2nd(data) {
    let total = 0;
    data.forEach((d) => {
      total += parseInt(d.camData.allCamera);
    });
    return total;
  }

  totalCount3rd(data) {
    let total = 0;
    data.forEach((d) => {
      total += parseInt(d.camData.allActiveCamera);
    });
    return total;
  }

  totalCount4th(data) {
    let total = 0;
    data.forEach((d) => {
      total += parseInt(d.camData.allInActiveCamera);
    });
    return total;
  }

  getReportData() {
    this.now = moment(); // add this 2 of 4
    this.date = this.now.format('YYYY-MM-DD');

    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      this.roleId = profile.user.roleId;
      console.log(this.roleId);
      
    });
    this.adminService.getScheme().subscribe((data: any) => {
      //console.log(data);
      this.allScheme = data.schemeDetails;
    });
    this.centerService.getAllDistrict().subscribe((district:any) =>{
      //console.log(district);
      this.allDistrict = district.districtDetails.sort((a, b) => a.districtName > b.districtName ? 1 : -1);
      //console.log(this.allDistrict);
    });
    this.piaService.getAllTP().subscribe((tp:any) =>{
      this.allPia =  tp.tpDetails.sort((a, b) => a.tpName > b.tpName ? 1 : -1);
      //console.log(this.allTP);
    });
  }

  reload(){
    this.loader= true;
    this.adminService.getADSideCount(moment().format('YYYY-MM-DD')).subscribe((data:any)=>{
      //console.log(data);
      this.sideCount = data;
      this.reportType = 'global';

      this.sehemeSec = 'show-anim';
      this.districtSec = 'hide-anim';
      this.piaSec = 'hide-anim';
      this.sdcSec = 'hide-anim';
      this.selectStep = 'scheme';
      this.selectSchemeCode = undefined;
      this.selectSchemeAccessId = undefined;
      this.selectDistrictName = undefined;
      this.selectDistrictCode = undefined;
      this.selectPiaName = undefined;
      this.piaAccessId = undefined;
      this.seleSdcAccessId = undefined;
      this.sdcName = undefined;
      this.selectCourseAccessId = undefined;
      this.courseName = undefined;
      this.batchName = undefined;
      setTimeout(() => {
        this.loader = false;
      }, 2000);
    });
  }

  /* ===============================================================
      OnClick Scheme
  =============================================================== */
  onClickScheme(){
    this.sehemeSec = 'show-anim';
    this.districtSec = 'hide-anim';
    this.piaSec = 'hide-anim';
    this.sdcSec = 'hide-anim';
    this.selectStep = 'scheme';
  }

  /* On Select Scheme */
  onSelectScheme(scheme){
    //console.log(scheme);
    this.loader = true;
    this.selectSchemeCode = scheme.schemeCode;
    this.selectSchemeAccessId = scheme.accessId;
    this.selectFilterType = 'scheme';
    this.valueList = false;
    //this.reportType = 'scheme';
    this.centerService.reportByScheme(scheme.accessId, moment().format('YYYY-MM-DD')).subscribe((data:any) =>{
      this.sideCount = data;
      //console.log(this.sideCount);
      this.selectDistrictName = undefined;
      this.selectDistrictCode = undefined;
      this.selectPiaName = undefined;
      this.piaAccessId = undefined;
      this.seleSdcAccessId = undefined;
      this.sdcName = undefined;
      this.selectCourseAccessId = undefined;
      this.courseName = undefined;
      this.batchName = undefined;
      setTimeout(() => {
        this.loader = false;
      }, 2000);
    });
  }

  /* ===============================================================
      OnClick District
  =============================================================== */
  onClickDistrict(){
    this.centerService.getAllDistrict().subscribe((district: any) => {
      //console.log(district);
      if(this.selectSchemeCode != 'Total'){
        this.allDistrict = district.districtDetails;
        this.sehemeSec = 'hide-anim';
        this.districtSec = 'show-anim';
        this.piaSec = 'hide-anim';
        this.sdcSec = 'hide-anim';
        this.selectStep = 'dist';
        this.seleSdcAccessId = undefined;
      }else{
      }
    });
  }

  /* On Select District */
  onSelectDistrict(district) {
    this.loader = true;
    if (this.selectSchemeCode === undefined) {
    } else if (this.selectSchemeCode != undefined) {
      this.selectDistrictName = district.districtName;
      this.selectDistrictCode = district.districtCode;
      this.seleSdcAccessId = undefined;
      this.selectFilterType = 'dist';
      this.selectPiaName = undefined;
      this.piaAccessId = undefined;
      this.seleSdcAccessId = undefined;
      this.sdcName = undefined;
      this.selectCourseAccessId = undefined;
      this.courseName = undefined;
      this.batchName = undefined;
      this.reportType = 'dist';
      // this.centerService.reportBySchemeNDist(this.selectSchemeAccessId, moment().format('YYYY-MM-DD')).subscribe((count:any) =>{
      //   console.log(count)
      //   this.sideCount = count;
      //   setTimeout(() => {
      //     this.loader = false;
      //   }, 2000);
      // });
        setTimeout(() => {
          this.loader = false;
        }, 2000);
    } else { }
  }

  /* ===============================================================
      OnClick Pia/TP
  =============================================================== */
  onClickPia() {
    this.piaService.getAllTP().subscribe((pia: any) => {
      //console.log(pia);
      if (this.selectDistrictName != undefined) {
        var distPiaList = pia.tpDetails.filter(ele => ele.districtName === this.selectDistrictName);
        this.allPia = distPiaList.sort((a, b) => a.tpName > b.tpName ? 1 : -1);
        //console.log(this.allPia);
        this.sehemeSec = 'hide-anim';
        this.districtSec = 'hide-anim';
        this.piaSec = 'show-anim';
        this.sdcSec = 'hide-anim';
        this.selectStep = 'pia';
        this.seleSdcAccessId = undefined;
      } else {}
    });
  }

    /* On Select Pia/TP */
    onSelectPia(pia) {
      //console.log(pia);
      //console.log(this.sdcList)
      this.loader = true;
      this.selectPiaName = pia.tpShortName;
      this.piaAccessId = pia.accessId;
      this.seleSdcAccessId = undefined;
      this.sdcName = undefined;
      this.selectCourseAccessId = undefined;
      this.courseName = undefined;
      this.batchName = undefined;
      this.reportType = 'pia';
      if (this.selectDistrictName != undefined) {
        this.centerService.reportBySchemeNDistNTp(this.selectSchemeAccessId, this.piaAccessId,moment().format('YYYY-MM-DD')).subscribe((count:any) =>{
          //console.log(count);
          this.sideCount = count;
          setTimeout(() => {
            this.loader = false;
          }, 2000);
        });
      } else {
      }
    }

  /* ===============================================================
      OnClick SDC
  =============================================================== */
  onClickSdc() {
    this.ccService.getCenter().subscribe((sdc: any) => {
      //console.log(sdc)
      this.sehemeSec = 'hide-anim';
      this.districtSec = 'hide-anim';
      this.piaSec = 'hide-anim';
      this.sdcSec = 'show-anim';
      this.selectStep = 'sdc';
      this.sdcListSec = 'show';
      this.courseListSec ='hide';
      this.batchListSec ='hide';
      if(this.selectPiaName != undefined){
        var sdcList = sdc.centerDetails.filter(ele => ele.tpAccessId === this.piaAccessId);
        this.allSdc = sdcList.sort(((a, b) => a.centerName > b.centerName ? 1 : -1));
      }else{
        this.allSdc = sdc.centerDetails;
      }

    });
  }

  /* On select Sdc */
  onSelectSdc(sdc) {
    //console.log(sdc);
    this.loader = true;
    this.seleSdcAccessId = sdc.accessId;
    this.sdcName = sdc.centerName;
    this.reportType = 'sdc';
    this.sdcReportType= "overAll";
    this.centerService.allCourse(this.seleSdcAccessId).subscribe((allCourse:any) =>{
      this.allCourse = allCourse.courseDetails;
      this.courseListSec = 'show';
      this.sdcListSec = 'hide';
      this.batchListSec = 'hide';

      this.lHintSdc = 'hint-select';
      this.lDataSdc= 'l-data';
      this.lHinttextSdc= 'bottom-text';
      this.lHintCourse ='';
      this.lDataCourse ='';
      this.lHinttextCourse = '';
      this.lHintBatch='';
      this.lDataBatch='';
      this.lHinttextBatch='';

      this.selectCourseAccessId = undefined;
      this.courseName = undefined;
      this.batchName = undefined;
    });
    if(this.selectPiaName != undefined){
      // console.log(this.selectSchemeAccessId);
      // console.log(this.selectDistrictCode);
      // console.log(this.piaAccessId);
      // console.log(this.seleSdcAccessId);      
      this.centerService.reportBySchemeNDistNTpNSdc(this.selectSchemeAccessId, this.piaAccessId, this.seleSdcAccessId, moment().format('YYYY-MM-DD')).subscribe((count:any) =>{
       //console.log(count);
        this.sideCount = count;
        setTimeout(() => {
          this.loader = false;
        }, 2000);
      });
    }else{
      this.centerService.reportByCenter(this.seleSdcAccessId, moment().format('YYYY-MM-DD')).subscribe((count:any) =>{
        //console.log(count);
        this.sideCount = count;
        setTimeout(() => {
          this.loader = false;
        }, 2000);
      });
    }
  }

  onClickOverAll(){
    this.loader = true;
    this.sdcReportType= "overAll";
    if(this.selectPiaName != undefined){      
      this.centerService.reportBySchemeNDistNTpNSdc(this.selectSchemeAccessId, this.piaAccessId, this.seleSdcAccessId, moment().format('YYYY-MM-DD')).subscribe((count:any) =>{
       //console.log(count);
        this.sideCount = count;
        setTimeout(() => {
          this.loader = false;
        }, 2000);
      });
    }else{
      this.centerService.reportByCenter(this.seleSdcAccessId, moment().format('YYYY-MM-DD')).subscribe((count:any) =>{
        //console.log(count);
        this.sideCount = count;
        setTimeout(() => {
          this.loader = false;
        }, 2000);
      });
    }
  }

  onClickEnroll(){
    this.loader = true;
    this.centerService.allStudents(this.seleSdcAccessId).subscribe((resources:any) =>{
      this.allEnroll = resources.resourceDetails;
      //console.log(this.allEnroll);
      this.sdcReportType= "enroll";
      setTimeout(() => {
        this.loader = false;
      }, 2000);
    });
  }

  onClickAttendance(){
    this.loader = true;
    this.centerService.attendanceByToday(this.seleSdcAccessId, moment().format('YYYY-MM-DD')).subscribe((resourceData:any) =>{
      this.allPresent = resourceData.resourceDetails.filter(res => res.resourceType === 'S');
      //console.log(this.allPresent)
      this.sdcReportType= "present";
      setTimeout(() => {
        this.loader = false;
      }, 2000);
    });
  }

  /* on Click Course */
  onClickCourse(){
    if(this.seleSdcAccessId != undefined){
      this.sdcListSec ='hide';
      this.courseListSec = 'show';
      this.batchListSec = 'hide';
    }
  }

  /* On Click batch */
  onClickBatch(){
    if(this.selectCourseAccessId != undefined){
      this.sdcListSec ='hide';
      this.courseListSec = 'hide';
      this.batchListSec = 'show';     
    }
  }


  /* On Select Course */
  onSelectCourse(course){
    //console.log(course)
    this.loader = true;
    this.courseName = course.courseName;
    this.selectCourseAccessId= course.accessId;
    this.centerService.reportByCenterNCourse(this.seleSdcAccessId, course.accessId, moment().format('YYYY-MM-DD')).subscribe((count:any) =>{
      //console.log(count);
      this.sideCount = count;
      this.reportType = 'course';
      setTimeout(() => {
        this.loader = false;
      }, 2000);
    });
    this.centerService.getAllBatch(course.centerAccessId, course.accessId).subscribe((allbatch:any) => {
      //console.log(allbatch);
      this.allBatch = allbatch.batchDetails;
      this.sdcListSec = 'hide';
      this.courseListSec = 'hide';
      this.batchListSec = 'show';

      this.lHintCourse ='hint-select';
      this.lDataCourse ='l-data';
      this.lHinttextCourse = 'bottom-text';
      this.lHintBatch='';
      this.lDataBatch='';
      this.lHinttextBatch='';
      this.batchName = undefined;
    });
  }

  /* On Select Batch */
  onSelectBatch(batch){
    //console.log(batch);
    this.loader = true;
    this.batchName = batch.batchCode;
    this.centerService.reportByCenterNCourseNBatch(this.seleSdcAccessId, this.selectCourseAccessId, batch.accessId, moment().format('YYYY-MM-DD')).subscribe((count:any) =>{
      //console.log(count);
      this.sideCount = count;
      this.reportType = 'batch';
      this.lHintBatch='hint-select';
      this.lDataBatch='l-data';
      this.lHinttextBatch='bottom-text';
      setTimeout(() => {
        this.loader = false;
      }, 2000);
    });
  }

  onSelectDefalt(){
    this.loader = true;
    this.adminService.getADSideCount(moment().format('YYYY-MM-DD')).subscribe((data:any)=>{
      //console.log(data);
      this.sideCount = data;
      this.reportType = 'global';
      setTimeout(() => {
        this.loader = false;
      }, 2000);
    });
  }


  /* ===============================================================
        Date basic Image and video Fetch
  =============================================================== */
  onChange(value) {
    //console.log(this.classAccessId);
    this.totalData = [];
    this.route.params.subscribe(paramas => {
      this.date = (this.datePipe.transform(value._d, 'yyyy-MM-dd'));
      //console.log(this.date);
      this.adminService.getAllRsldcListByAttendanceDate(this.date).subscribe((attendanceDetail: any) => {
        attendanceDetail.resourceDetails.forEach(element => {
          this.adminService.getAttendanceAllCountRsldc(element, this.date).subscribe((count: any) => {
            this.totalData.push(count);
          });
        });
      });
    });
  }


  onClickMainReport(){
    this.reportType = 'main'; 
    this.loader = true;
    this.selectSchemeCode = undefined;
    this.selectSchemeAccessId = undefined;
    this.selectDistName = undefined;
    this.selectTpName = undefined;
    this.valueList = false;
    this.selectSchemeCodeSer = '';
    this.selectDistNameSer = '';
    this.searchTP = '';
    this.dateTime = moment().format('DD-MM-YYYY, HH:mm:ss');
    const sendData ={
      attendanceDate: moment().format('YYYY-MM-DD')
    }
    this.adminService.setTpReport(sendData).subscribe((info:any) =>{
      //console.log(info);
      if(info.success === 1){
        setTimeout(() => {
          this.adminService.getTpList().subscribe((tpData:any) =>{
            //console.log(tpData)
            this.newGetDataMain = tpData.tpDetails;
            this.newGetDataMainFilt = tpData.tpDetails;
            this.loader = false;
          });
        }, 10000);
      }
    });
  }

  ab2a(ab) {
    if(ab instanceof ArrayBuffer) return this.ab2a(new Uint8Array(ab));
    var x = "";
    for(var i = 0; i < ab.length; ++i) x += String.fromCharCode(ab[i]);
    return btoa(x);
  }

  exportAs() {
    var tbl = XLSX.utils.table_to_book(document.getElementById('myTableElementIdMain'));
    var ws = tbl.Sheets.Sheet1;
    // ws.B2.s = { color: { rgb: "FF0000" }}

    const range = XLSX.utils.decode_range(ws["!ref"]);
    range.s.c = range.e.c = XLSX.utils.decode_col("C"); // set range to all cells in column D
    XLSX.utils.sheet_set_range_style(ws, range, { alignment: { wrapText: true } });
    
    const rangea = XLSX.utils.decode_range(ws["!ref"]);
    rangea.s.c = rangea.e.c = XLSX.utils.decode_col("D"); // set range to all cells in column E
    XLSX.utils.sheet_set_range_style(ws, rangea, { alignment: { wrapText: true } });

    const rangeb = XLSX.utils.decode_range(ws["!ref"]);
    rangeb.s.c = rangeb.e.c = XLSX.utils.decode_col("E"); // set range to all cells in column E
    XLSX.utils.sheet_set_range_style(ws, rangeb, { alignment: { wrapText: true } });

    fetch("../../../assets/image/rsldc-rep-logo.png").then(res => res.arrayBuffer()).then(ab => {
      var imdata = this.ab2a(ab);
      if(!ws["!merges"]) ws["!merges"] = [];
      // ws["!merges"].push(XLSX.utils.decode_range("A1:F1")); 
      if(!ws["!images"]) ws["!images"] = [];
      ws["!images"].push({
        "!pos": { x: 280, y: 0, w: 700, h: 76 },
        "!datatype": "base64",
        "!data": imdata,
      });
      XLSX.writeFile(tbl, "Main Report("+moment().format('DD-MM-YYYY') +").xlsx", {cellStyles: true , bookImages: true});
    });
  }

  onClickTpReport(){
    this.reportType = 'tp';
    this.loader = true;
    this.selectSchemeCode = undefined;
    this.selectSchemeAccessId = undefined;
    this.selectDistName = undefined;
    this.selectTpName = undefined;
    this.valueList = false;
    this.selectSchemeCodeSer = '';
    this.selectDistNameSer = '';
    this.searchTP = '';
    this.dateTime = moment().format('DD-MM-YYYY, hh:mm:ss a');
    const sendData ={
      attendanceDate: moment().format('YYYY-MM-DD')
    }
    this.adminService.setTpReport(sendData).subscribe((info:any) =>{
      //console.log(info);
      if(info.success === 1){
        setTimeout(() => {
          this.adminService.getTpList().subscribe((tpData:any) =>{
            //console.log(tpData)
            this.newGetDataTp = tpData.tpDetails;
            this.newGetDataTpFilt = tpData.tpDetails;
            console.log(this.newGetDataTp);
            this.loader = false;
          });
        }, 10000);
      }
    });
  }

  exportAsTp() {
    var tbl = XLSX.utils.table_to_book(document.getElementById('myTableElementIdTp'));
    var ws = tbl.Sheets.Sheet1;
    
    const range = XLSX.utils.decode_range(ws["!ref"]);
    range.s.c = range.e.c = XLSX.utils.decode_col("C"); // set range to all cells in column C
    XLSX.utils.sheet_set_range_style(ws, range, { alignment: { wrapText: true } });
    
    const rangea = XLSX.utils.decode_range(ws["!ref"]);
    rangea.s.c = rangea.e.c = XLSX.utils.decode_col("E"); // set range to all cells in column E
    XLSX.utils.sheet_set_range_style(ws, rangea, { alignment: { wrapText: true } });

    const rangeb = XLSX.utils.decode_range(ws["!ref"]);
    rangeb.s.c = rangeb.e.c = XLSX.utils.decode_col("F"); // set range to all cells in column F
    XLSX.utils.sheet_set_range_style(ws, rangeb, { alignment: { wrapText: true } });

    fetch("../../../assets/image/rsldc-rep-logo.png").then(res => res.arrayBuffer()).then(ab => {
      var imdata = this.ab2a(ab);
      if(!ws["!merges"]) ws["!merges"] = [];
      ws["!merges"].push(XLSX.utils.decode_range("A1:F1")); 
      if(!ws["!images"]) ws["!images"] = [];
      ws["!images"].push({
        "!pos": { x: 100, y: 0, w: 700, h: 76 },
        "!datatype": "base64",
        "!data": imdata,
      });
      XLSX.writeFile(tbl, "TP Report("+moment().format('DD-MM-YYYY') +").xlsx", {cellStyles: true , bookImages: true});
    });
  }


  onClickSdcReport(){
    this.reportType = 'sdc'; 
    this.loader = true;
    this.selectSchemeCode = undefined;
    this.selectSchemeAccessId = undefined;
    this.selectDistName = undefined;
    this.selectTpName = undefined;
    this.valueList = false;
    this.selectSchemeCodeSer = '';
    this.selectDistNameSer = '';
    this.searchTP = '';
    this.dateTime = moment().format('DD-MM-YYYY, hh:mm:ss a');
    const sendData ={
      attendanceDate: moment().format('YYYY-MM-DD')
    }
    //console.log(sendData);
    
    this.adminService.setCenterReport(sendData).subscribe((info:any) =>{
      //console.log(info)
      if(info.success === 1){
        setTimeout(() => {
          this.adminService.getCenterReport().subscribe((sdcData:any) =>{
            //console.log(sdcData);
            this.newGetSdcData = sdcData.centerDetails;
            this.newGetSdcDataFilt = sdcData.centerDetails;
            //console.log(this.newGetSdcData);
            this.loader = false;
          });
        }, 10000);
      }
    });
  }

  exportAsSdc() {
    var tbl = XLSX.utils.table_to_book(document.getElementById('myTableElementIdSdc'));
    var ws = tbl.Sheets.Sheet1;

    const range = XLSX.utils.decode_range(ws["!ref"]);
    range.s.c = range.e.c = XLSX.utils.decode_col("C"); // set range to all cells in column C
    XLSX.utils.sheet_set_range_style(ws, range, { alignment: { wrapText: true } });
    
    const rangea = XLSX.utils.decode_range(ws["!ref"]);
    rangea.s.c = rangea.e.c = XLSX.utils.decode_col("E"); // set range to all cells in column E
    XLSX.utils.sheet_set_range_style(ws, rangea, { alignment: { wrapText: true } });

    const rangeb = XLSX.utils.decode_range(ws["!ref"]);
    rangeb.s.c = rangeb.e.c = XLSX.utils.decode_col("F"); // set range to all cells in column F
    XLSX.utils.sheet_set_range_style(ws, rangeb, { alignment: { wrapText: true } });

    fetch("../../../assets/image/rsldc-rep-logo.png").then(res => res.arrayBuffer()).then(ab => {
      var imdata = this.ab2a(ab);
      if(!ws["!merges"]) ws["!merges"] = [];
      ws["!merges"].push(XLSX.utils.decode_range("A1:F1")); 
      if(!ws["!images"]) ws["!images"] = [];
      ws["!images"].push({
        "!pos": { x: 500, y: 0, w: 700, h: 76 },
        "!datatype": "base64",
        "!data": imdata,
      });
      XLSX.writeFile(tbl, "SDC Report("+moment().format('DD-MM-YYYY') +").xlsx", {cellStyles: true , bookImages: true});
    });
  }

    onClickDetailReport(){
    this.reportType = 'reportDetail'; 
    this.loader = true;
    this.selectSchemeCode = undefined;
    this.selectSchemeAccessId = undefined;
    this.selectDistName = undefined;
    this.selectTpName = undefined;
    this.valueList = false;
    this.selectSchemeCodeSer = '';
    this.selectDistNameSer = '';
    this.searchTP = '';
    this.dateTime = moment().format('DD-MM-YYYY, hh:mm:ss a');
    const sendData ={
      attendanceDate: moment().format('YYYY-MM-DD')
    }
    //console.log(sendData);
    
    this.adminService.setCenterReport(sendData).subscribe((info:any) =>{
      //console.log(info)
      if(info.success === 1){
        setTimeout(() => {
          this.adminService.getCenterReport().subscribe((sdcData:any) =>{
            //console.log(sdcData);
            this.newGetReportDetaData = sdcData.centerDetails;
            this.newGetReportDetaDataFilt = sdcData.centerDetails;
            console.log(this.newGetReportDetaData);
            this.loader = false;
          });
        }, 10000);
      }
    });
  }

  exportAsReportDeta() {
    var tbl = XLSX.utils.table_to_book(document.getElementById('myTableElementIdReportDeta'));
    var ws = tbl.Sheets.Sheet1;

    const range = XLSX.utils.decode_range(ws["!ref"]);
    range.s.c = range.e.c = XLSX.utils.decode_col("C"); // set range to all cells in column C
    XLSX.utils.sheet_set_range_style(ws, range, { alignment: { wrapText: true } });
    
    const rangea = XLSX.utils.decode_range(ws["!ref"]);
    rangea.s.c = rangea.e.c = XLSX.utils.decode_col("E"); // set range to all cells in column E
    XLSX.utils.sheet_set_range_style(ws, rangea, { alignment: { wrapText: true } });

    const rangeb = XLSX.utils.decode_range(ws["!ref"]);
    rangeb.s.c = rangeb.e.c = XLSX.utils.decode_col("F"); // set range to all cells in column F
    XLSX.utils.sheet_set_range_style(ws, rangeb, { alignment: { wrapText: true } });

    fetch("../../../assets/image/rsldc-rep-logo.png").then(res => res.arrayBuffer()).then(ab => {
      var imdata = this.ab2a(ab);
      if(!ws["!merges"]) ws["!merges"] = [];
      ws["!merges"].push(XLSX.utils.decode_range("A1:F1")); 
      if(!ws["!images"]) ws["!images"] = [];
      ws["!images"].push({
        "!pos": { x: 500, y: 0, w: 700, h: 76 },
        "!datatype": "base64",
        "!data": imdata,
      });
      XLSX.writeFile(tbl, "Report Detail("+moment().format('DD-MM-YYYY') +").xlsx", {cellStyles: true , bookImages: true});
    });
  }


  onClickBatchReport(){
    this.reportType = 'batch'; 
    this.loader = true;
    this.selectSchemeCode = undefined;
    this.selectSchemeAccessId = undefined;
    this.selectDistName = undefined;
    this.selectTpName = undefined;
    this.valueList = false;
    this.selectSchemeCodeSer = '';
    this.selectDistNameSer = '';
    this.searchTP = '';
    this.dateTime = moment().format('DD-MM-YYYY, hh:mm:ss a');
    const sendData ={
      parmas: moment().format('DD-MM-YYYY, hh:mm:ss a')
    }
    this.adminService.setEnrollmentReport(sendData).subscribe((info:any) =>{
      //console.log(info);
      if(info.success === 1){
        setTimeout(() => {
          this.adminService.getBacthList().subscribe((batchData:any) =>{
            this.newGetDataBatch = batchData.batchDetails;
            this.newGetDataBatchFilt= batchData.batchDetails;
            this.loader = false;
            console.log(this.newGetDataBatch);
          });
        }, 10000);
      }
    });
  }

  exportAsBatch() {
    var tbl = XLSX.utils.table_to_book(document.getElementById('myTableElementIdBatch'));
    var ws = tbl.Sheets.Sheet1;

    const range = XLSX.utils.decode_range(ws["!ref"]);
    range.s.c = range.e.c = XLSX.utils.decode_col("C"); // set range to all cells in column C
    XLSX.utils.sheet_set_range_style(ws, range, { alignment: { wrapText: true } });
    
    const rangea = XLSX.utils.decode_range(ws["!ref"]);
    rangea.s.c = rangea.e.c = XLSX.utils.decode_col("E"); // set range to all cells in column E
    XLSX.utils.sheet_set_range_style(ws, rangea, { alignment: { wrapText: true } });

    const rangeb = XLSX.utils.decode_range(ws["!ref"]);
    rangeb.s.c = rangeb.e.c = XLSX.utils.decode_col("G"); // set range to all cells in column G
    XLSX.utils.sheet_set_range_style(ws, rangeb, { alignment: { wrapText: true } });

    const rangec = XLSX.utils.decode_range(ws["!ref"]);
    rangec.s.c = rangec.e.c = XLSX.utils.decode_col("L"); // set range to all cells in column L
    XLSX.utils.sheet_set_range_style(ws, rangec, { alignment: { wrapText: true } });

    fetch("../../../assets/image/rsldc-rep-logo.png").then(res => res.arrayBuffer()).then(ab => {
      var imdata = this.ab2a(ab);
      if(!ws["!merges"]) ws["!merges"] = [];
      ws["!merges"].push(XLSX.utils.decode_range("A1:F1")); 
      if(!ws["!images"]) ws["!images"] = [];
      ws["!images"].push({
        "!pos": { x: 300, y: 0, w: 700, h: 76 },
        "!datatype": "base64",
        "!data": imdata,
      });
      XLSX.writeFile(tbl, "Batch Report("+moment().format('DD-MM-YYYY') +").xlsx", {cellStyles: true , bookImages: true});
    });
  }

  onClickActBatchReport(){
    this.reportType = 'activeBatch'; 
    this.loader = true;
    this.selectSchemeCode = undefined;
    this.selectSchemeAccessId = undefined;
    this.selectDistName = undefined;
    this.selectTpName = undefined;
    this.valueList = false;
    this.selectSchemeCodeSer = '';
    this.selectDistNameSer = '';
    this.searchTP = '';
    this.dateTime = moment().format('DD-MM-YYYY, hh:mm:ss a');
    const sendData ={
      parmas: moment().format('DD-MM-YYYY, hh:mm:ss a')
    }
    this.adminService.setEnrollmentReport(sendData).subscribe((info:any) =>{
      //console.log(info);
      if(info.success === 1){
        setTimeout(() => {
          this.adminService.getActiveBacthList().subscribe((batchData:any) =>{
            this.newGetDataActBatch = batchData.batchDetails;
            this.newGetDataActBatchFilt= batchData.batchDetails;
            this.loader = false;
            console.log(this.newGetDataActBatch);
          });
        }, 10000);
      }
    });
  }

  exportAsActBatch(){
    var tbl = XLSX.utils.table_to_book(document.getElementById('myTableElementIdActBatch'));
    var ws = tbl.Sheets.Sheet1;

    const range = XLSX.utils.decode_range(ws["!ref"]);
    range.s.c = range.e.c = XLSX.utils.decode_col("C"); // set range to all cells in column C
    XLSX.utils.sheet_set_range_style(ws, range, { alignment: { wrapText: true } });
    
    const rangea = XLSX.utils.decode_range(ws["!ref"]);
    rangea.s.c = rangea.e.c = XLSX.utils.decode_col("F"); // set range to all cells in column F
    XLSX.utils.sheet_set_range_style(ws, rangea, { alignment: { wrapText: true } });

    const rangeb = XLSX.utils.decode_range(ws["!ref"]);
    rangeb.s.c = rangeb.e.c = XLSX.utils.decode_col("I"); // set range to all cells in column I
    XLSX.utils.sheet_set_range_style(ws, rangeb, { alignment: { wrapText: true } });

    const rangec = XLSX.utils.decode_range(ws["!ref"]);
    rangec.s.c = rangec.e.c = XLSX.utils.decode_col("J"); // set range to all cells in column J
    XLSX.utils.sheet_set_range_style(ws, rangec, { alignment: { wrapText: true } });

    fetch("../../../assets/image/rsldc-rep-logo.png").then(res => res.arrayBuffer()).then(ab => {
      var imdata = this.ab2a(ab);
      if(!ws["!merges"]) ws["!merges"] = [];
      ws["!merges"].push(XLSX.utils.decode_range("A1:F1")); 
      if(!ws["!images"]) ws["!images"] = [];
      ws["!images"].push({
        "!pos": { x: 300, y: 0, w: 700, h: 76 },
        "!datatype": "base64",
        "!data": imdata,
      });
      XLSX.writeFile(tbl, "Active Batch Report("+moment().format('DD-MM-YYYY') +").xlsx", {cellStyles: true , bookImages: true});
    });
  }


  onClickTraineeReport(){
    this.reportType = 'trainee'; 
    this.loader = true;
    this.selectSchemeCode = undefined;
    this.selectSchemeAccessId = undefined;
    this.selectDistName = undefined;
    this.selectTpName = undefined;
    this.valueList = false;
    this.selectSchemeCodeSer = '';
    this.selectDistNameSer = '';
    this.searchTP = '';
    this.dateTime = moment().format('DD-MM-YYYY, HH:mm:ss');
    this.adminService.getTraineeReport().subscribe((traniee:any) =>{
      //console.log(traniee)
      this.newGetDataTrainee = traniee.resourceDetails;
      this.newGetDataTraineeFilt = traniee.resourceDetails;
      console.log(this.newGetDataTrainee);
      this.loader = false;
    });
  }

  exportAsTrainee() {  
    var tbl = XLSX.utils.table_to_book(document.getElementById('myTableElementIdTrainee'));
    var ws = tbl.Sheets.Sheet1;

    const range = XLSX.utils.decode_range(ws["!ref"]);
    range.s.c = range.e.c = XLSX.utils.decode_col("C"); // set range to all cells in column C
    XLSX.utils.sheet_set_range_style(ws, range, { alignment: { wrapText: true } });
    
    const rangea = XLSX.utils.decode_range(ws["!ref"]);
    rangea.s.c = rangea.e.c = XLSX.utils.decode_col("D"); // set range to all cells in column D
    XLSX.utils.sheet_set_range_style(ws, rangea, { alignment: { wrapText: true } });

    const rangeb = XLSX.utils.decode_range(ws["!ref"]);
    rangeb.s.c = rangeb.e.c = XLSX.utils.decode_col("E"); // set range to all cells in column E
    XLSX.utils.sheet_set_range_style(ws, rangeb, { alignment: { wrapText: true } });

    fetch("../../../assets/image/rsldc-rep-logo.png").then(res => res.arrayBuffer()).then(ab => {
      var imdata = this.ab2a(ab);
      if(!ws["!merges"]) ws["!merges"] = [];
      // ws["!merges"].push(XLSX.utils.decode_range("A1:F1")); 
      if(!ws["!images"]) ws["!images"] = [];
      ws["!images"].push({
        "!pos": { x: 260, y: 0, w: 700, h: 76 },
        "!datatype": "base64",
        "!data": imdata,
      });
      XLSX.writeFile(tbl, "Trainee Report("+moment().format('DD-MM-YYYY') +").xlsx", {cellStyles: true , bookImages: true});
    });
  }

  onClickEnrollReport(){
    this.reportType = 'enroll'; 
    this.loader = true;
    this.selectSchemeCode = undefined;
    this.selectSchemeAccessId = undefined;
    this.selectDistName = undefined;
    this.selectTpName = undefined;
    this.valueList = false;
    this.selectSchemeCodeSer = '';
    this.selectDistNameSer = '';
    this.searchTP = '';
    this.dateTime = moment().format('DD-MM-YYYY, hh:mm:ss a');
    const sendData ={
      parmas: 'N/A'
    }
    this.adminService.setEnrollmentReport(sendData).subscribe((info:any) =>{
      //console.log(info);
      if(info.success === 1){
        setTimeout(() => {
          this.adminService.getEnrollmentReport().subscribe((enrollData:any)=>{
            this.newGetDataEnroll = enrollData.batchDetails;
            this.newGetDataEnrollFilt = enrollData.batchDetails;
            console.log(this.newGetDataEnroll);
            this.loader = false;
            //console.log(enrollData);
          });
        }, 10000);
      }
    });
  }


  exportAsEnroll() {
    var tbl = XLSX.utils.table_to_book(document.getElementById('myTableElementIdEnroll'));
    var ws = tbl.Sheets.Sheet1;

    const range = XLSX.utils.decode_range(ws["!ref"]);
    range.s.c = range.e.c = XLSX.utils.decode_col("D"); // set range to all cells in column C
    XLSX.utils.sheet_set_range_style(ws, range, { alignment: { wrapText: true } });
    
    const rangea = XLSX.utils.decode_range(ws["!ref"]);
    rangea.s.c = rangea.e.c = XLSX.utils.decode_col("E"); // set range to all cells in column E
    XLSX.utils.sheet_set_range_style(ws, rangea, { alignment: { wrapText: true } });

    const rangeb = XLSX.utils.decode_range(ws["!ref"]);
    rangeb.s.c = rangeb.e.c = XLSX.utils.decode_col("F"); // set range to all cells in column F
    XLSX.utils.sheet_set_range_style(ws, rangeb, { alignment: { wrapText: true } });

    fetch("../../../assets/image/rsldc-rep-logo.png").then(res => res.arrayBuffer()).then(ab => {
      var imdata = this.ab2a(ab);
      if(!ws["!merges"]) ws["!merges"] = [];
      ws["!merges"].push(XLSX.utils.decode_range("A1:F1")); 
      if(!ws["!images"]) ws["!images"] = [];
      ws["!images"].push({
        "!pos": { x: 280, y: 0, w: 700, h: 76 },
        "!datatype": "base64",
        "!data": imdata,
      });
      XLSX.writeFile(tbl, "Enroll Report("+moment().format('DD-MM-YYYY') +").xlsx", {cellStyles: true , bookImages: true});
    });
  }

  onClickCameraReport(){
    this.newGetDataSchemeCam = [];
    this.reportType = 'camera'; 
    this.loader = true;
    this.selectSchemeCode = undefined;
    this.selectSchemeAccessId = undefined;
    this.selectDistName = undefined;
    this.selectTpName = undefined;
    this.valueList = false;
    this.selectSchemeCodeSer = '';
    this.selectDistNameSer = '';
    this.searchTP = '';
    this.dateTime = moment().format('DD-MM-YYYY, hh:mm:ss a');
    this.adminService.getScheme().subscribe((data: any) => {
      //console.log(data);
      this.allScheme = data.schemeDetails;
      this.allScheme.forEach((ele, i) => {
        setTimeout(() => {
          this.adminService.getCamReportByScm(ele.accessId).subscribe((camData:any) =>{
            this.newGetDataSchemeCam.push({camData, "schemeCode": ele.schemeCode }) ;
            this.newGetDataSchemeCamFilt.push({camData, "schemeCode": ele.schemeCode });
            console.log(this.newGetDataSchemeCam);
          });
          if(i+1 === this.allScheme.length) {
            this.loader = false;
          }
        }, 1000*(i+1));
      });
    });

  }


  exportAsCam() {
    var tbl = XLSX.utils.table_to_book(document.getElementById('myTableElementIdCam'));
    var ws = tbl.Sheets.Sheet1;
    fetch("../../../assets/image/rsldc-rep-logo.png").then(res => res.arrayBuffer()).then(ab => {
      var imdata = this.ab2a(ab);
      if(!ws["!merges"]) ws["!merges"] = [];
      // ws["!merges"].push(XLSX.utils.decode_range("A1:F1")); 
      if(!ws["!images"]) ws["!images"] = [];
      ws["!images"].push({
        "!pos": { x: 220, y: 0, w: 700, h: 76 },
        "!datatype": "base64",
        "!data": imdata,
      });
      XLSX.writeFile(tbl, "Camera Report("+moment().format('DD-MM-YYYY') +").xlsx", {cellStyles: true , bookImages: true});
    });
  }

  onClickCameraTPReport(){
    this.reportType = 'tpCamera'; 
    this.loader = true;
    this.selectSchemeCode = undefined;
    this.selectSchemeAccessId = undefined;
    this.selectDistName = undefined;
    this.selectTpName = undefined;
    this.valueList = false;
    this.selectSchemeCodeSer = '';
    this.selectDistNameSer = '';
    this.searchTP = '';
    this.dateTime = moment().format('DD-MM-YYYY, hh:mm:ss a');
    const sendData ={
      attendanceDate: moment().format('YYYY-MM-DD')
    }
    this.adminService.setTpReport(sendData).subscribe((info:any) =>{
      //console.log(info);
      if(info.success === 1){
        setTimeout(() => {
          this.adminService.getTpList().subscribe((tpData:any) =>{
            //console.log(tpData)
            this.newGetDataTPCam = tpData.tpDetails;
            this.newGetDataTPCamFilt = tpData.tpDetails;
            console.log(this.newGetDataTPCam);
            this.loader = false;
          });
        }, 10000);
      }
    });

  }

  exportAsTPCam() {
    var tbl = XLSX.utils.table_to_book(document.getElementById('myTableElementIdTPCam'));
    var ws = tbl.Sheets.Sheet1;

    const range = XLSX.utils.decode_range(ws["!ref"]);
    range.s.c = range.e.c = XLSX.utils.decode_col("C"); // set range to all cells in column C
    XLSX.utils.sheet_set_range_style(ws, range, { alignment: { wrapText: true } });
    
    const rangea = XLSX.utils.decode_range(ws["!ref"]);
    rangea.s.c = rangea.e.c = XLSX.utils.decode_col("D"); // set range to all cells in column D
    XLSX.utils.sheet_set_range_style(ws, rangea, { alignment: { wrapText: true } });

    const rangeb = XLSX.utils.decode_range(ws["!ref"]);
    rangeb.s.c = rangeb.e.c = XLSX.utils.decode_col("E"); // set range to all cells in column E
    XLSX.utils.sheet_set_range_style(ws, rangeb, { alignment: { wrapText: true } });

    fetch("../../../assets/image/rsldc-rep-logo.png").then(res => res.arrayBuffer()).then(ab => {
      var imdata = this.ab2a(ab);
      if(!ws["!merges"]) ws["!merges"] = [];
      // ws["!merges"].push(XLSX.utils.decode_range("A1:F1")); 
      if(!ws["!images"]) ws["!images"] = [];
      ws["!images"].push({
        "!pos": { x: 280, y: 0, w: 700, h: 76 },
        "!datatype": "base64",
        "!data": imdata,
      });
      XLSX.writeFile(tbl, "Camera Report("+moment().format('DD-MM-YYYY') +").xlsx", {cellStyles: true , bookImages: true});
    });
  }

  onClickAutoPlay() {
    this.router.navigate(['/cCAutoPlay']);
    localStorage.removeItem('selectSdcAccessID')
    localStorage.removeItem('totalCamList')
  }

  onClickOnlyLive() {
    this.router.navigate(['/cCOnlyLive']);
    localStorage.removeItem('selectSdcAccessID');
    localStorage.removeItem('totalCamList');
  }

  onclickDashboard() {
    this.router.navigate(['/cCSearch']);
    localStorage.removeItem('selectSdcAccessID')
    localStorage.removeItem('totalCamList')
  }

  onClickAllHistory() {
    this.router.navigate(['/history-all']);
    localStorage.removeItem('selectSdcAccessID');
    localStorage.removeItem('totalCamList');
  }

  /* ===============================================================
    System Check
  =============================================================== */
  onClickSystemCheck() {
    this.ccService.systemCheck().subscribe((data: any) => {
      if (data.success === 1) {
        //console.log(data);
        this.systemCheck = true;
        this.systemCheckTime = moment();
      }
    });
  }

  onClickSysChkClo() {
    this.systemCheck = false;
  }

  onClickLogout(){
    const accessId = {
      accessId: localStorage.getItem('UserInfo'),
    }
    this.authService.userLogout(accessId).subscribe((data) => {
      //console.log(data);
      this.router.navigate(['/']);
      this._compiler.clearCache();
      localStorage.clear();
      setTimeout(() => {
        location.reload();
      }, 100);
    });
  }

  mainSecClick(){
    this.valueList = false;
    this.valueListDist = false;
    this.valueListTp = false;
  }

  inputClick(){
    this.valueList = !this.valueList;
    this.valueListDist = false;
    this.valueListTp = false;
  }

  inputClickDist(){
    this.valueListDist = !this.valueListDist;
    this.valueList = false;
    this.valueListTp =  false;

  }

  inputClickTp(){
    this.valueListTp = !this.valueListTp;
    this.valueList = false;
    this.valueListDist =  false;
  }

  onSelectSchemeFilter(scheme){   
    this.valueList = false;
    this.valueListDist = false;
    this.valueListTp = false;
    this.selectSchemeCode = scheme.schemeCode;  
    this.selectSchemeAccessId = scheme.accessId;    
    this.selectDistName = undefined;
    this.selectTpName = undefined;
    if(this.reportType === 'main'){
      this.newGetDataMain = this.newGetDataMainFilt.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
      this.newGetDataMainScmeFilt =  this.newGetDataMainFilt.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
    }else if(this.reportType === 'tp'){
      this.newGetDataTp= this.newGetDataTpFilt.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
      this.newGetDataTpScmeFilt = this.newGetDataTpFilt.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
    }else if(this.reportType === 'sdc'){
      this.newGetSdcData = this.newGetSdcDataFilt.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
      this.newGetDataSdcScmeFilt = this.newGetSdcDataFilt.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
    }else if(this.reportType === 'reportDetail'){
      this.newGetReportDetaData = this.newGetReportDetaDataFilt.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
      this.newGetDataReportDetaScmeFilt = this.newGetReportDetaDataFilt.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
    }else if(this.reportType === 'batch'){
      this.newGetDataBatch = this.newGetDataBatchFilt.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
      this.newGetDataBatchScmeFilt = this.newGetDataBatchFilt.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
    }else if(this.reportType === 'activeBatch'){
      this.newGetDataActBatch = this.newGetDataActBatchFilt.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
      this.newGetDataActBatchScmeFilt = this.newGetDataActBatchFilt.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
    }else if(this.reportType === 'trainee'){
      this.newGetDataTrainee = this.newGetDataTraineeFilt.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
      this.newGetDataTraineScmeFilt = this.newGetDataTraineeFilt.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
    }else if(this.reportType === 'enroll'){
      this.newGetDataEnroll = this.newGetDataEnrollFilt.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
      this.newGetDataEnrollScmeFilt = this.newGetDataEnrollFilt.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
    }else if(this.reportType === 'camera'){
      this.newGetDataSchemeCam = this.newGetDataSchemeCamFilt.filter(res => res.schemeCode === this.selectSchemeCode);
      this.newGetDataCameraScmeFilt = this.newGetDataSchemeCamFilt.filter(res => res.schemeCode === this.selectSchemeCode);
    }else if(this.reportType === 'tpCamera'){
      this.newGetDataTPCam = this.newGetDataTPCam.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
      this.newGetDataTPcameraScmeFilt =  this.newGetDataTPCam.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
    }else{}
  }

  onSelectSchemeFilterAll(){
    this.valueList = false;
    this.valueListDist = false;
    this.valueListTp = false;
    this.selectDistName = undefined;
    this.selectTpName = undefined;
    this.selectSchemeCodeSer = '';
    this.selectDistNameSer = '';
    this.searchTP = '';
    setTimeout(() => {
      this.selectSchemeCode = 'All';
    }, 200);
    this.selectSchemeAccessId = 'All';
    if(this.reportType === 'main'){
      this.newGetDataMain = this.newGetDataMainFilt;
      this.newGetDataMainScmeFilt =  this.newGetDataMainFilt;
    }else if(this.reportType === 'tp'){
      this.newGetDataTp= this.newGetDataTpFilt;
      this.newGetDataTpScmeFilt = this.newGetDataTpFilt;
    }else if(this.reportType === 'sdc'){
      this.newGetSdcData = this.newGetSdcDataFilt;
      this.newGetDataSdcScmeFilt = this.newGetSdcDataFilt;
    }
    else if(this.reportType === 'reportDetail'){
      this.newGetReportDetaData = this.newGetReportDetaDataFilt;
      this.newGetDataReportDetaScmeFilt = this.newGetReportDetaDataFilt;
    }
    else if(this.reportType === 'batch'){
      this.newGetDataBatch = this.newGetDataBatchFilt;
      this.newGetDataBatchScmeFilt = this.newGetDataBatchFilt;
    }else if(this.reportType === 'activeBatch'){
      this.newGetDataActBatch = this.newGetDataActBatchFilt;
      this.newGetDataActBatchScmeFilt = this.newGetDataActBatchFilt;
    }else if(this.reportType === 'trainee'){
      this.newGetDataTrainee = this.newGetDataTraineeFilt;
      this.newGetDataTraineScmeFilt = this.newGetDataTraineeFilt;
    }else if(this.reportType === 'enroll'){
      this.newGetDataEnroll = this.newGetDataEnrollFilt;
    }else if(this.reportType === 'camera'){
      this.newGetDataSchemeCam = this.newGetDataSchemeCamFilt;
      this.newGetDataCameraScmeFilt = this.newGetDataSchemeCamFilt;
    }else if(this.reportType === 'tpCamera'){
      this.newGetDataTPCam = this.newGetDataTPCamFilt;
      this.newGetDataTPcameraScmeFilt =  this.newGetDataTPCam;
    }else{}
  }

  changedSchmeSer(value){
    if (value.length > 2) {
      this.testing = true;
    } else {
      this.testing = false
   }
  }

  changedDistSer(value){
    if (value.length > 2) {
      this.testing = true;
    } else {
      this.testing = false
   }
  }

   changedTpSer(value){
    if (value.length > 2) {
      this.testing = true;
    } else {
      this.testing = false
   }
  }

  onSelectDistFilter(dist){
    this.selectDistName = dist.districtName;
    this.selectTpName= undefined;
    this.valueList = false;
    this.valueListDist = false;
    //console.log(this.selectDistName);
    if(this.reportType === 'main'){
      if(this.selectSchemeCode === undefined){
        this.newGetDataMain = this.newGetDataMainFilt.filter(res => res.districtName === this.selectDistName);
        this.newGetDataMainDistFilt = this.newGetDataMainFilt.filter(res => res.districtName === this.selectDistName);
      }else{
        this.newGetDataMain = this.newGetDataMainScmeFilt.filter(res => res.districtName === this.selectDistName);
        this.newGetDataMainDistFilt = this.newGetDataMainScmeFilt.filter(res => res.districtName === this.selectDistName);
      }
    }else if(this.reportType === 'tp'){
      if(this.selectSchemeCode === undefined){
        this.newGetDataTp = this.newGetDataTpFilt.filter(res => res.districtName === this.selectDistName);
        this.newGetDataTpDistFilt = this.newGetDataTpFilt.filter(res => res.districtName === this.selectDistName);
      }else{
        this.newGetDataTp = this.newGetDataTpScmeFilt.filter(res => res.districtName === this.selectDistName);
        this.newGetDataTpDistFilt = this.newGetDataTpFilt.filter(res => res.districtName === this.selectDistName);
      }
    }else if(this.reportType === 'sdc'){
      if(this.selectSchemeCode === undefined){
        this.newGetSdcData = this.newGetSdcDataFilt.filter(res => res.districtName === this.selectDistName);
        this.newGetDataSdcDistFilt = this.newGetSdcDataFilt.filter(res => res.districtName === this.selectDistName);
      }else{
        this.newGetSdcData = this.newGetDataSdcScmeFilt.filter(res => res.districtName === this.selectDistName);
        this.newGetDataSdcDistFilt = this.newGetDataSdcScmeFilt.filter(res => res.districtName === this.selectDistName);
      }
    }else if(this.reportType === 'reportDetail'){
      if(this.selectSchemeCode === undefined){
        this.newGetReportDetaData = this.newGetReportDetaDataFilt.filter(res => res.districtName === this.selectDistName);
        this.newGetDataReportDetaDistFilt = this.newGetReportDetaDataFilt.filter(res => res.districtName === this.selectDistName);
      }else{
        this.newGetSdcData = this.newGetDataSdcScmeFilt.filter(res => res.districtName === this.selectDistName);
        this.newGetDataReportDetaDistFilt = this.newGetDataReportDetaScmeFilt.filter(res => res.districtName === this.selectDistName);
      }
    }else if(this.reportType === 'batch'){
      if(this.selectSchemeCode === undefined){
        this.newGetDataBatch = this.newGetDataBatchFilt.filter(res => res.districtName === this.selectDistName);
        this.newGetDataBatchDistFilt = this.newGetDataBatchFilt.filter(res => res.districtName === this.selectDistName);
      }else{
        this.newGetDataBatch = this.newGetDataBatchScmeFilt.filter(res => res.districtName === this.selectDistName);
        this.newGetDataBatchDistFilt = this.newGetDataBatchFilt.filter(res => res.districtName === this.selectDistName);
      }
    }else if(this.reportType === 'activeBatch'){
      if(this.selectSchemeCode === undefined){
        this.newGetDataActBatch = this.newGetDataActBatchFilt.filter(res => res.districtName === this.selectDistName);
        this.newGetDataActBatchDistFilt = this.newGetDataActBatchFilt.filter(res => res.districtName === this.selectDistName);
      }else{
        this.newGetDataActBatch = this.newGetDataActBatchScmeFilt.filter(res => res.districtName === this.selectDistName);
        this.newGetDataActBatchDistFilt = this.newGetDataActBatchFilt.filter(res => res.districtName === this.selectDistName);
      }
    }else if(this.reportType === 'trainee'){
      if(this.selectSchemeCode === undefined){
        this.newGetDataTrainee = this.newGetDataTraineeFilt.filter(res => res.districtName === this.selectDistName);
        this.newGetDataTraineDistFilt = this.newGetDataTraineeFilt.filter(res => res.districtName === this.selectDistName);
      }else{
        this.newGetDataTrainee = this.newGetDataTraineScmeFilt.filter(res => res.districtName === this.selectDistName);
        this.newGetDataTraineDistFilt = this.newGetDataTraineeFilt.filter(res => res.districtName === this.selectDistName);
      }
    }else if(this.reportType === 'enroll'){
      if(this.selectSchemeCode === undefined){
        this.newGetDataEnroll = this.newGetDataEnrollFilt.filter(res => res.districtName === this.selectDistName);
        this.newGetDataEnrollDistFilt = this.newGetDataEnrollFilt.filter(res => res.districtName === this.selectDistName);
      }else{ 
        this.newGetDataEnroll = this.newGetDataEnrollScmeFilt.filter(res => res.districtName === this.selectDistName);
        this.newGetDataEnrollDistFilt = this.newGetDataEnrollScmeFilt.filter(res => res.districtName === this.selectDistName);
      }
    }else if(this.reportType === 'camera'){
      this.newGetDataSchemeCam = this.newGetDataSchemeCam.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
    }else if(this.reportType === 'tpCamera'){
      if(this.selectSchemeCode === undefined){
        this.newGetDataTPCam = this.newGetDataTPCamFilt.filter(res => res.districtName === this.selectDistName);
        this.newGetDataTPcameraDistFilt = this.newGetDataTPCamFilt.filter(res => res.districtName === this.selectDistName);
      }else{
        this.newGetDataEnroll = this.newGetDataTPcameraScmeFilt.filter(res => res.districtName === this.selectDistName);
        this.newGetDataTPcameraDistFilt = this.newGetDataTPcameraScmeFilt.filter(res => res.districtName === this.selectDistName);
      }
    }else{}
  }

  onSelectDistFilterAll(){
    this.valueList = false;
    this.valueListDist = false;
    this.selectTpName= undefined;
    this.selectDistNameSer = '';
    this.searchTP = '';
    setTimeout(() => {
      this.selectDistName = 'All';
    }, 200);
    if(this.reportType === 'main'){
      if(this.selectSchemeCode === undefined){
        this.newGetDataMain = this.newGetDataMainFilt;
        this.newGetDataMainDistFilt = this.newGetDataMainFilt;
      }else{
        this.newGetDataMain =this.newGetDataMainScmeFilt;
        this.newGetDataMainDistFilt = this.newGetDataMainScmeFilt;
      }
    }else if(this.reportType === 'tp'){
      if(this.selectSchemeCode === undefined){
        this.newGetDataTp = this.newGetDataTpFilt;
        this.newGetDataTpDistFilt = this.newGetDataTpFilt;
      }else{
        this.newGetDataTp = this.newGetDataTpScmeFilt;
        this.newGetDataTpDistFilt = this.newGetDataTpFilt;
      }
    }else if(this.reportType === 'sdc'){
      if(this.selectSchemeCode === undefined){
        this.newGetSdcData = this.newGetSdcDataFilt;
        this.newGetDataSdcDistFilt = this.newGetSdcDataFilt;
      }else{
        this.newGetSdcData = this.newGetDataSdcScmeFilt;
        this.newGetDataSdcDistFilt = this.newGetDataSdcScmeFilt;
      }
    }else if(this.reportType === 'reportDetail'){
      if(this.selectSchemeCode === undefined){
        this.newGetReportDetaData = this.newGetReportDetaDataFilt;
        this.newGetDataReportDetaDistFilt = this.newGetSdcDataFilt;
      }else{
        this.newGetReportDetaData = this.newGetDataSdcScmeFilt;
        this.newGetDataReportDetaDistFilt = this.newGetDataReportDetaScmeFilt;
      }
    }else if(this.reportType === 'batch'){
      if(this.selectSchemeCode === undefined){
        this.newGetDataBatch = this.newGetDataBatchFilt;
        this.newGetDataBatchDistFilt = this.newGetDataBatchFilt
      }else{
        this.newGetDataBatch = this.newGetDataBatchScmeFilt;
        this.newGetDataBatchDistFilt = this.newGetDataBatchFilt;
      }
    }else if(this.reportType === 'activeBatch'){
      if(this.selectSchemeCode === undefined){
        this.newGetDataActBatch = this.newGetDataActBatchFilt;
        this.newGetDataActBatchDistFilt = this.newGetDataActBatchFilt
      }else{
        this.newGetDataActBatch = this.newGetDataActBatchScmeFilt;
        this.newGetDataActBatchDistFilt = this.newGetDataActBatchFilt;
      }
    }else if(this.reportType === 'trainee'){
      if(this.selectSchemeCode === undefined){
        this.newGetDataTrainee = this.newGetDataTraineeFilt;
        this.newGetDataTraineDistFilt = this.newGetDataTraineeFilt
      }else{
        this.newGetDataTrainee = this.newGetDataTraineScmeFilt;
        this.newGetDataTraineDistFilt = this.newGetDataTraineeFilt;
      }
    }else if(this.reportType === 'enroll'){
      if(this.selectSchemeCode === undefined){
        this.newGetDataEnroll = this.newGetDataEnrollFilt;
        this.newGetDataEnrollDistFilt = this.newGetDataEnrollFilt;
      }else{
        this.newGetDataEnroll = this.newGetDataEnrollScmeFilt;
        this.newGetDataEnrollDistFilt = this.newGetDataEnrollScmeFilt;
      }
    }else if(this.reportType === 'camera'){
      this.newGetDataSchemeCam = this.newGetDataSchemeCamFilt;
    }else if(this.reportType === 'tpCamera'){
      if(this.selectSchemeCode === undefined){
        this.newGetDataTPCam = this.newGetDataTPCamFilt;
        this.newGetDataTPcameraDistFilt = this.newGetDataTPCamFilt;
      }else{
        this.newGetDataEnroll = this.newGetDataTPcameraScmeFilt;
        this.newGetDataTPcameraDistFilt = this.newGetDataTPcameraScmeFilt;
      }
    }else{}
  }

  onSelectTpFilter(tp){  
    this.selectTpName = tp.tpName;
    this.valueList = false;
    this.valueListDist = false;
    this.valueListTp = false;
    if(this.reportType === 'main'){
      if(this.selectSchemeCode === undefined){
        if(this.selectDistName === undefined){
          this.newGetDataMain = this.newGetDataMainFilt.filter(res => res.tpName === this.selectTpName);
        }else{
          this.newGetDataMain = this.newGetDataMainDistFilt.filter(res => res.tpName === this.selectTpName);
        }
      }else{
        if(this.selectDistName === undefined){
          this.newGetDataMain = this.newGetDataMainScmeFilt.filter(res =>  res.tpName === this.selectTpName);
        }else{
          this.newGetDataMain = this.newGetDataMainDistFilt.filter(res =>  res.tpName === this.selectTpName);
        }
      }
    }else if(this.reportType === 'tp'){
      if(this.selectSchemeCode === undefined){
        if(this.selectDistName === undefined){
          this.newGetDataTp = this.newGetDataTpFilt.filter(res => res.tpName === this.selectTpName);
        }else{
          this.newGetDataTp = this.newGetDataTpDistFilt.filter(res => res.tpName === this.selectTpName);
        }
      }else{
        if(this.selectDistName === undefined){
          this.newGetDataTp = this.newGetDataTpScmeFilt.filter(res =>  res.tpName === this.selectTpName);
        }else{
          this.newGetDataTp = this.newGetDataTpDistFilt.filter(res =>  res.tpName === this.selectTpName);
        }
      }
    }else if(this.reportType === 'sdc'){
      if(this.selectSchemeCode === undefined){
        if(this.selectDistName === undefined){
          this.newGetSdcData = this.newGetSdcDataFilt.filter(res => res.tpName === this.selectTpName);
        }else{
          this.newGetSdcData = this.newGetDataSdcDistFilt.filter(res => res.tpName === this.selectTpName);
        }
      }else{
        if(this.selectDistName === undefined){
          this.newGetSdcData = this.newGetDataSdcScmeFilt.filter(res =>  res.tpName === this.selectTpName);
        }else{
          this.newGetSdcData = this.newGetDataSdcDistFilt.filter(res =>  res.tpName === this.selectTpName);
        }
      }
    }else if(this.reportType === 'reportDetail'){
      if(this.selectSchemeCode === undefined){
        if(this.selectDistName === undefined){
          this.newGetReportDetaData = this.newGetReportDetaDataFilt.filter(res => res.tpName === this.selectTpName);
        }else{
          this.newGetReportDetaData = this.newGetDataReportDetaDistFilt.filter(res => res.tpName === this.selectTpName);
        }
      }else{
        if(this.selectDistName === undefined){
          this.newGetReportDetaData = this.newGetDataReportDetaScmeFilt.filter(res =>  res.tpName === this.selectTpName);
        }else{
          this.newGetReportDetaData = this.newGetDataReportDetaDistFilt.filter(res =>  res.tpName === this.selectTpName);
        }
      }
    }else if(this.reportType === 'batch'){
      if(this.selectSchemeCode === undefined){
        if(this.selectDistName === undefined){
          this.newGetDataBatch = this.newGetDataBatchFilt.filter(res => res.tpName === this.selectTpName);
        }else{
          this.newGetDataBatch = this.newGetDataBatchDistFilt.filter(res => res.tpName === this.selectTpName);
        }
      }else{
        if(this.selectDistName === undefined){
          this.newGetDataBatch = this.newGetDataBatchScmeFilt.filter(res =>  res.tpName === this.selectTpName);
        }else{
          this.newGetDataBatch = this.newGetDataBatchDistFilt.filter(res =>  res.tpName === this.selectTpName);
        }
      }
    }else if(this.reportType === 'activeBatch'){
      if(this.selectSchemeCode === undefined){
        if(this.selectDistName === undefined){
          this.newGetDataActBatch = this.newGetDataActBatchFilt.filter(res => res.tpName === this.selectTpName);
        }else{
          this.newGetDataActBatch = this.newGetDataActBatchDistFilt.filter(res => res.tpName === this.selectTpName);
        }
      }else{
        if(this.selectDistName === undefined){
          this.newGetDataActBatch = this.newGetDataActBatchScmeFilt.filter(res =>  res.tpName === this.selectTpName);
        }else{
          this.newGetDataActBatch = this.newGetDataActBatchDistFilt.filter(res =>  res.tpName === this.selectTpName);
        }
      }
    }else if(this.reportType === 'trainee'){
      if(this.selectSchemeCode === undefined){
        if(this.selectDistName === undefined){
          this.newGetDataTrainee = this.newGetDataTraineeFilt.filter(res => res.tpName === this.selectTpName);
        }else{
          this.newGetDataTrainee = this.newGetDataTraineDistFilt.filter(res => res.tpName === this.selectTpName);
        }
      }else{
        if(this.selectDistName === undefined){
          this.newGetDataTrainee = this.newGetDataTraineScmeFilt.filter(res =>  res.tpName === this.selectTpName);
        }else{
          this.newGetDataTrainee = this.newGetDataTraineDistFilt.filter(res =>  res.tpName === this.selectTpName);
        }
      }
    }else if(this.reportType === 'enroll'){
      if(this.selectSchemeCode === undefined){
        if(this.selectDistName === undefined){
          this.newGetDataEnroll = this.newGetDataEnrollFilt.filter(res => res.tpName === this.selectTpName);
        }else{
          this.newGetDataEnroll = this.newGetDataEnrollDistFilt.filter(res => res.tpName === this.selectTpName);
        }
      }else{
        if(this.selectDistName === undefined){
          this.newGetDataEnroll = this.newGetDataEnrollScmeFilt.filter(res =>  res.tpName === this.selectTpName);
        }else{
          this.newGetDataEnroll = this.newGetDataEnrollDistFilt.filter(res =>  res.tpName === this.selectTpName);
        }
      }
    }else if(this.reportType === 'camera'){
      this.newGetDataSchemeCam = this.newGetDataSchemeCam.filter(res => res.schemeAccessId === this.selectSchemeAccessId);
    }else if(this.reportType === 'tpCamera'){
      if(this.selectSchemeCode === undefined){
        if(this.selectDistName === undefined){
          this.newGetDataTPCam = this.newGetDataTPCamFilt.filter(res => res.tpName === this.selectTpName);
        }else{
          this.newGetDataTPCam = this.newGetDataTPcameraDistFilt.filter(res => res.tpName === this.selectTpName);
        }
      }else{
        if(this.selectDistName === undefined){
          this.newGetDataTPCam = this.newGetDataTPcameraScmeFilt.filter(res =>  res.tpName === this.selectTpName);
        }else{
          this.newGetDataTPCam = this.newGetDataTPcameraDistFilt.filter(res =>  res.tpName === this.selectTpName);
        }
      }

    }else{}
  }

  onSelectTpFilterAll(){
    this.valueListTp = false;
    this.searchTP = '';
    setTimeout(() => {
      this.selectTpName = 'All';
    }, 200);
    if(this.reportType === 'main'){
      if(this.selectSchemeCode === undefined){
        if(this.selectDistName === undefined){
          this.newGetDataMain = this.newGetDataMainFilt;
        }else{
          this.newGetDataMain = this.newGetDataMainDistFilt;
        }
      }else{
        if(this.selectDistName === undefined){
          this.newGetDataMain = this.newGetDataMainScmeFilt;
        }else{
          this.newGetDataMain = this.newGetDataMainDistFilt;
        }
      }
    }else if(this.reportType === 'tp'){
      if(this.selectSchemeCode === undefined){
        if(this.selectDistName === undefined){
          this.newGetDataTp = this.newGetDataTpFilt;
        }else{
          this.newGetDataMain = this.newGetDataTpDistFilt;
        }
      }else{
        if(this.selectDistName === undefined){
          this.newGetDataTp = this.newGetDataTpScmeFilt;
        }else{
          this.newGetDataTp = this.newGetDataTpDistFilt;
        }
      }
    }else if(this.reportType === 'sdc'){
      if(this.selectSchemeCode === undefined){
        if(this.selectDistName === undefined){
          this.newGetSdcData = this.newGetSdcDataFilt;
        }else{
          this.newGetSdcData = this.newGetDataSdcDistFilt;
        }
      }else{
        if(this.selectDistName === undefined){
          this.newGetSdcData = this.newGetDataSdcScmeFilt;
        }else{
          this.newGetSdcData = this.newGetDataSdcDistFilt;
        }
      }
    }else if(this.reportType === 'reportDetail'){
      if(this.selectSchemeCode === undefined){
        if(this.selectDistName === undefined){
          this.newGetReportDetaData = this.newGetReportDetaDataFilt;
        }else{
          this.newGetReportDetaData = this.newGetDataReportDetaDistFilt;
        }
      }else{
        if(this.selectDistName === undefined){
          this.newGetReportDetaData = this.newGetDataReportDetaScmeFilt;
        }else{
          this.newGetReportDetaData = this.newGetDataReportDetaDistFilt;
        }
      }
    }else if(this.reportType === 'batch'){
      if(this.selectSchemeCode === undefined){
        if(this.selectDistName === undefined){
          this.newGetDataBatch = this.newGetDataBatchFilt;
        }else{
          this.newGetDataBatch = this.newGetDataBatchDistFilt;
        }
      }else{
        if(this.selectDistName === undefined){
          this.newGetDataBatch = this.newGetDataBatchScmeFilt;
        }else{
          this.newGetDataBatch = this.newGetDataBatchDistFilt;
        }
      }
    }else if(this.reportType === 'activeBatch'){
      if(this.selectSchemeCode === undefined){
        if(this.selectDistName === undefined){
          this.newGetDataActBatch = this.newGetDataActBatchFilt;
        }else{
          this.newGetDataActBatch = this.newGetDataActBatchDistFilt;
        }
      }else{
        if(this.selectDistName === undefined){
          this.newGetDataActBatch = this.newGetDataActBatchScmeFilt;
        }else{
          this.newGetDataActBatch = this.newGetDataActBatchDistFilt;
        }
      }
    }else if(this.reportType === 'trainee'){
      if(this.selectSchemeCode === undefined){
        if(this.selectDistName === undefined){
          this.newGetDataTrainee = this.newGetDataTraineeFilt;
        }else{
          this.newGetDataTrainee = this.newGetDataTraineDistFilt;
        }
      }else{
        if(this.selectDistName === undefined){
          this.newGetDataTrainee = this.newGetDataTraineScmeFilt;
        }else{
          this.newGetDataTrainee = this.newGetDataTraineDistFilt;
        }
      }
    }else if(this.reportType === 'enroll'){
      if(this.selectSchemeCode === undefined){
        if(this.selectDistName === undefined){
          this.newGetDataEnroll = this.newGetDataEnrollFilt;
        }else{
          this.newGetDataEnroll = this.newGetDataEnrollDistFilt;
        }
      }else{
        if(this.selectDistName === undefined){
          this.newGetDataEnroll = this.newGetDataEnrollScmeFilt;
        }else{
          this.newGetDataEnroll = this.newGetDataEnrollDistFilt;
        }
      }
    }else if(this.reportType === 'camera'){
      this.newGetDataSchemeCam = this.newGetDataSchemeCamFilt;
    }else if(this.reportType === 'tpCamera'){
      if(this.selectSchemeCode === undefined){
        if(this.selectDistName === undefined){
          this.newGetDataTPCam = this.newGetDataTPCamFilt;
        }else{
          this.newGetDataTPCam = this.newGetDataTPcameraDistFilt;
        }
      }else{
        if(this.selectDistName === undefined){
          this.newGetDataTPCam = this.newGetDataTPcameraScmeFilt;
        }else{
          this.newGetDataTPCam = this.newGetDataTPcameraDistFilt;
        }
      }
    }else{}
  }

  ngOnInit() {    
    this.getReportData();
    this.dateTime = moment().format('DD-MM-YYYY, hh:mm:ss a');
    const sendData ={
      attendanceDate: moment().format('YYYY-MM-DD')
    }
    this.adminService.setTpReport(sendData).subscribe((info:any) =>{
      //console.log(info);
      if(info.success === 1){
        setTimeout(() => {
          this.adminService.getTpList().subscribe((tpData:any) =>{
            this.newGetDataMain = tpData.tpDetails;
            this.newGetDataMainFilt = tpData.tpDetails;
            console.log(this.newGetDataMain);
            this.loader = false;
          });
        }, 10000);
      }
    });
  }


}
