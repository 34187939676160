import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'verifySearch'
})
export class VerifySearchPipe implements PipeTransform {

  transform(filterAllResources: any, search: any): any {
    if (!filterAllResources || !search) {
      return filterAllResources;
    }
    return filterAllResources.filter(resources =>
      resources.resourceName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      resources.resourceCode.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      resources.batchCode.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      resources.courseName.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }

}
