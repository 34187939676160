import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tpSearch'
})
export class TpSearchPipe implements PipeTransform {

  transform(allPia: any, search: any): any {
    if (!allPia || !search) {
      return allPia;
    }
    return allPia.filter(tplist =>
      tplist.tpCode.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      tplist.tpName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      tplist.location.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      tplist.districtName.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }

}
