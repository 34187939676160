import { DatePipe } from '@angular/common';
import { Component, OnInit, HostListener, Compiler } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { AuthService } from 'src/app/services/authService/auth.service';
import { CCService } from 'src/app/services/cCService/c-c.service';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { ClassService } from 'src/app/services/classService/class.service';
import { PiaServiceService } from 'src/app/services/piaService/pia-service.service';

@Component({
  selector: 'app-history-all',
  templateUrl: './history-all.component.html',
  styleUrls: ['./history-all.component.css']
})
export class HistoryAllComponent implements OnInit {

  dateImage;
  dateVideo;
  dataImages;
  dataVideos;
  myModal;
  slideCount;
  prevHide;
  nextHide;
  myVidModal;
  slideVidCount;
  prevVidHide;
  nextVidHide;
  imageLength;
  videoLength;
  date;
  now;
  changeDate;
  systemCheck: boolean = false;
  systemCheckTime;

  allSdc;
  allTp;
  selectCenter;
  selectSdcName;
  valueList: boolean;
  valueListTp: boolean;
  selectTpName;
  searchTP;

  /* ===============================================================
      Keybord key Event
  =============================================================== */
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {

    if (event.keyCode === 27) {
      if (this.slideCount != null) {
        this.closeModal();
      } else if (this.slideVidCount != null) {
        this.closeVidModal();
      } else { }
    }

    if (event.keyCode === 39) {
      //Image Next
      if (this.slideCount != null) {
        if (this.slideCount != this.imageLength) {
          this.slideCount++;
          if (this.slideCount == '1') {
            this.prevHide = 'hide';
          } else {
            this.prevHide = 'show';
          }
          if (this.imageLength == this.slideCount) {
            this.nextHide = 'hide';
          } else {
            this.nextHide = 'show';
          }
        }
      }
      //Video Next
      if (this.slideVidCount != null) {
        if (this.slideVidCount != this.videoLength) {
          this.slideVidCount++;
          if (this.slideVidCount == '1') {
            this.prevVidHide = 'hide';
          } else {
            this.prevVidHide = 'show';
          }
          if (this.videoLength == this.slideVidCount) {
            this.nextVidHide = 'hide';
          } else {
            this.nextVidHide = 'show';
          }
        }
      }
    } else if (event.keyCode === 37) {
      //Image Prev
      if (this.slideCount != null) {
        if (this.slideCount != '1') {
          this.slideCount--;
          if (this.slideCount == '1') {
            this.prevHide = 'hide';
          } else {
            this.prevHide = 'show';
          }
          if (this.imageLength == this.slideCount) {
            this.nextHide = 'hide';
          } else {
            this.nextHide = 'show';
          }
        }
      }
      //Video Prev
      if (this.slideVidCount != null) {
        if (this.slideVidCount != '1') {
          this.slideVidCount--;
          if (this.slideVidCount == '1') {
            this.prevVidHide = 'hide';
          } else {
            this.prevVidHide = 'show';
          }
          if (this.videoLength == this.slideVidCount) {
            this.nextVidHide = 'hide';
          } else {
            this.nextVidHide = 'show';
          }
        }
      }
    } else { }
  }

    // Min moment: February 12 2018, 10:30
    public min = new Date(2018, 1, 12, 10, 30);

    // Max moment: April 21 2018, 20:30
    public max = new Date;
    public selectedMoment = new Date();
    private paramsSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
    private _compiler: Compiler,
    private ccService: CCService,
    private piaService: PiaServiceService,
    private authService: AuthService,
    private classService: ClassService,
    private centerService: CenterDetailsService
  ) { }

  ngOnInit() {
    this.getHisData();

    this.ccService.getCenter().subscribe((sdc:any) =>{
      //console.log(sdc)
      this.allSdc = sdc.centerDetails;
    });

    this.piaService.getAllTP().subscribe((sdc:any) =>{
      //console.log(sdc)
      this.allTp = sdc.tpDetails;
    })
  }

  /* ===============================================================
      Date basic Image and video Fetch
  =============================================================== */
  onChange(value) {
    this.route.params.subscribe(paramas => {
      this.date = (this.datePipe.transform(value._d, 'yyyy-MM-dd'));
      //console.log(this.date);
      this.changeDate = this.date;
      this.centerService.imageAll(this.date).subscribe((data: any) => {
        this.dataImages = data.imageDetails;
        //console.log(this.dataImages)
        this.imageLength = this.dataImages.length;
      });
      this.centerService.videoAll(this.date).subscribe((data: any) => {
        this.dataVideos = data.videoDetails;
        //console.log(this.dataVideos)
        this.videoLength = this.dataVideos.length;
      });
    });
  }

  /* ===============================================================
  Get Center Details
=============================================================== */
  getHisData() {
    //console.log(this.centerAccessId);

    this.now = moment(); // add this 2 of 4
    this.date = this.now.format('YYYY-MM-DD');
    /* All Images */
    this.centerService.imageAll( this.date).subscribe((data: any) => {
      //console.log(data);
      
      this.dataImages = data.imageDetails;
      this.imageLength = this.dataImages.length;
    });
    /* All Videos */
    this.centerService.videoAll(this.date).subscribe((data: any) => {
      //console.log(data);
      
      this.dataVideos = data.videoDetails;
      this.videoLength = this.dataVideos.length;
    });
  }

  onSelectTpHis(tp){
    console.log(tp);
    
    this.centerService.imageAllByTp(tp.accessId, this.date).subscribe((data: any) => {
      console.log(data);
      if(data.success === 1){
        this.dataImages = data.imageDetails;
        this.imageLength = this.dataImages.length;
      }

    });
    /* All Videos */
    this.centerService.videoAllByTp(tp.accessId, this.date).subscribe((data: any) => {
      console.log(data); 
      if(data.success === 1){
        this.dataVideos = data.videoDetails;
        this.videoLength = this.dataVideos.length;
      }     
      
    });  
  }


  onSelectSdcHis(sdc){
    console.log(sdc);
    
    this.centerService.imageAllBySdc(sdc.accessId, this.date).subscribe((data: any) => {
      console.log(data);
      if(data.success === 1){
        this.dataImages = data.imageDetails;
        this.imageLength = this.dataImages.length;
      }

    });
    /* All Videos */
    this.centerService.videoAllBySdc(sdc.accessId, this.date).subscribe((data: any) => {
      console.log(data); 
      if(data.success === 1){
        this.dataVideos = data.videoDetails;
        this.videoLength = this.dataVideos.length;
      }     
      
    });  
  }

  /* ===============================================================
    Gallery
  =============================================================== */
  /* Image */
  currentSlide(data) {
    this.slideCount = data;
    this.myModal = 'show';
    if (this.slideCount == '1') {
      this.prevHide = 'hide';
    } else {
      this.prevHide = 'show';
    }
    if (this.imageLength == this.slideCount) {
      this.nextHide = 'hide';
    } else {
      this.nextHide = 'show';
    }
  }
  closeModal() {
    this.myModal = 'hide';
    this.slideCount = null;
  }
  prev() {
    this.slideCount--;
    if (this.slideCount == '1') {
      this.prevHide = 'hide';
    } else {
      this.prevHide = 'show';
    }
    if (this.imageLength == this.slideCount) {
      this.nextHide = 'hide';
    } else {
      this.nextHide = 'show';
    }
  }
  next() {
    this.slideCount++;
    if (this.slideCount == '1') {
      this.prevHide = 'hide';
    } else {
      this.prevHide = 'show';
    }
    if (this.imageLength == this.slideCount) {
      this.nextHide = 'hide';
    } else {
      this.nextHide = 'show';
    }
  }

  /* Video */
  openVidModal(data) {
    this.slideVidCount = data;
    this.myVidModal = 'show';
    if (this.slideVidCount == '1') {
      this.prevVidHide = 'hide';
    } else {
      this.prevVidHide = 'show';
    }
    if (this.videoLength == this.slideVidCount) {
      this.nextVidHide = 'hide';
    } else {
      this.nextVidHide = 'show';
    }
  }
  closeVidModal() {
    this.myVidModal = 'hide';
    this.slideVidCount = null;
  }
  prevVid() {
    this.slideVidCount--;
    if (this.slideVidCount == '1') {
      this.prevVidHide = 'hide';
    } else {
      this.prevVidHide = 'show';
    }
    if (this.videoLength == this.slideVidCount) {
      this.nextVidHide = 'hide';
    } else {
      this.nextVidHide = 'show';
    }
  }
  nextVid() {
    this.slideVidCount++;
    if (this.slideVidCount == '1') {
      this.prevVidHide = 'hide';
    } else {
      this.prevVidHide = 'show';
    }
    if (this.videoLength == this.slideVidCount) {
      this.nextVidHide = 'hide';
    } else {
      this.nextVidHide = 'show';
    }
  }

  onClickSearch() {
    this.router.navigate(['/cCSearch']);
    localStorage.removeItem('locSetValue');
    localStorage.removeItem('totalCamList');
  }


  onClickAutoPlay() {
    this.router.navigate(['/cCAutoPlay']);
    localStorage.removeItem('selectSdcAccessID')
    localStorage.removeItem('totalCamList')
  }

  onClickOnlyLive() {
    this.router.navigate(['/cCOnlyLive']);
    localStorage.removeItem('selectSdcAccessID');
    localStorage.removeItem('totalCamList');
  }

  
  onClickReport(){
    this.router.navigate(['report']);
  }


  onClickLogout() {
    const accessId = {
      accessId: localStorage.getItem('UserInfo'),
    }
    this.authService.userLogout(accessId).subscribe((data) => {
      //console.log(data);
      this.router.navigate(['/']);
      this._compiler.clearCache();
      localStorage.clear();
      setTimeout(() => {
        location.reload();
      }, 100);
    });
  }
  onClickSystemCheck() {
    this.ccService.systemCheck().subscribe((data: any) => {
      if (data.success === 1) {
        //console.log(data);
        this.systemCheck = true;
        this.systemCheckTime = moment();
      }
    });
  }
  
  onClickSysChkClo() {
    this.systemCheck = false;
  }

  inputClick(){
    this.valueList = !this.valueList;
    this.valueListTp = false;
  }

  inputClickTp(){
    this.valueListTp = !this.valueListTp;
    this.valueList = false;
  }


}
