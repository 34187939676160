import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distSearch'
})
export class DistSearchPipe implements PipeTransform {

  transform(allDistrict: any, search: any): any {
    if (!allDistrict || !search) {
      return allDistrict;
    }
    return allDistrict.filter(dist =>
      dist.districtName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      dist.districtCode.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }

}
