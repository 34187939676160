import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './authService/auth.service';

@Injectable({
  providedIn: 'root'
})
export class VideoRecordService {

  options;
  domain = this.authService.domain;
  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  /* ===============================================================
      Authentication Header
  =============================================================== */
  createAuthenticationHeaders() {
    this.authService.loadToken();
      this.options = { headers : new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'authorization': this.authService.authToken
      })
    }
  }

  isRecording(isRecord){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'video/isRecording', isRecord, this.options);
  }
  startRecording(startRecord){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'video/startVideoRecord', startRecord, this.options)
  }

  stopRecording(stopRecord){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'video/stopVideoRecord', stopRecord, this.options);
  }
  getAllVideos(dateVideo, streamNameVideo) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'video/allVideos/' + dateVideo + '/' + streamNameVideo, this.options);
  }
}
