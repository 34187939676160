import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';
import * as moment from 'moment';;

import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { ToastrService } from 'ngx-toastr';
import { ClassService } from 'src/app/services/classService/class.service';
import { AuthService } from 'src/app/services/authService/auth.service';

@Component({
  selector: 'app-camera-list',
  templateUrl: './camera-list.component.html',
  styleUrls: ['./camera-list.component.css']
})
export class CameraListComponent implements OnInit {

  form;
  username;
  allCamera;
  loader = 'show';
  camSearch;
  selectCam;
  selectCamDele;
  selectClsUpDte;
  creBtn: boolean = true;
  mainSec: boolean;
  cameraCenterDetails;
  cameraDeviceId;
  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private centerService: CenterDetailsService,
    private classService: ClassService
  ) { this.createForm(); }

  /* ===============================================================
      Validation
=============================================================== */
  createForm() {
    this.form = this.formBuilder.group({
      classSubName: [null, [Validators.required, Validators.pattern("^.{3,40}$")]],
    });
  }

  onClickCopy(text) {
    this.selectCam = text.cameraDeviceId;
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text.cameraDeviceId;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success(text.cameraDeviceId, 'Copied', {
      timeOut: 10000
    });
    //console.log(text);
  }

  onClickCamDel(data) {
    this.selectCamDele = data;
    this.cameraDeviceId = data.cameraDeviceId;
    //console.log(data);
    //console.log(sndData);
    // for( var i = 0; i < this.allCamera.length; i++){ 
    //   if ( this.allCamera[i].cameraDeviceId === data.cameraDeviceId) {
    //   //this.allCamera.splice(i, 1); 
    //     console.log(this.allCamera.length);
    //array object update
    //     this.allCamera[i].streamTime= 'jjjjjjj';
    //     console.log(this.allCamera);
    //   }
    // }
  }

  confDel() {
    const sndData = {
      cameraDeviceId: this.selectCamDele.cameraDeviceId,
      classAccessId: this.selectCamDele.classAccessId
    }
    this.classService.deleteCameraAssgin(sndData).subscribe((info: any) => {
      //console.log(info);
      if (info.success === 1) {
        this.toastr.success('Camera & maped class deleted.')
          for (var i = 0; i < this.allCamera.length; i++) {
            if (this.allCamera[i].cameraDeviceId === this.cameraDeviceId) {
              this.allCamera.splice(i, 1);
              //console.log(this.allCamera.length);
              this.selectCamDele = undefined;
              this.camSearch = '';
            }
          }
      }
    });
  }

  canDel() {
    this.selectCamDele = undefined;
  }

  onClickClsNamUpDat(data) {
    this.selectClsUpDte = data;
    this.cameraDeviceId = data.cameraDeviceId
  }

  canUpdate() {
    this.selectClsUpDte = undefined;
  }

  /* ===============================================================
      Class Name Update;
  =============================================================== */
  confUpdate() {
    this.creBtn = false;
    const user = {
      accessId: this.selectClsUpDte.classAccessId,
      className: this.selectClsUpDte.className,
      classSubName: this.form.get('classSubName').value,
      createdBy: this.username,
      createdAt: moment().format("YYYY-MM-DD HH:mm:s")
    }
    // console.log(user);
    this.classService.updateClass(user).subscribe((data: any) => {
      if (data.success == 1) {
        this.toastr.success(data.message, 'success');
      } else {
        this.toastr.info(data.message, 'info');
      }
      for (var i = 0; i < this.allCamera.length; i++) {
        if (this.allCamera[i].cameraDeviceId === this.cameraDeviceId) {
          this.allCamera[i].classSubName = this.form.get('classSubName').value;
          //console.log(this.allCamera);
          this.form.reset();
          this.creBtn = true;
          this.selectClsUpDte = undefined;
        }
      }
    });
  }

  somethingChanged(data) {
    //console.log(data.length)
    if (data.length <= 0) {
      this.mainSec = false;
    } else {
      this.mainSec = true;
    }
  }

  ngOnInit() {
    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      this.username = profile.user.username;
    });
    this.centerService.getAllCamDet().subscribe((allCam: any) => {
      //console.log(allCam);
      if (allCam.success === 1) {
        this.loader = 'hide';
        this.allCamera = allCam.cameraDetails;
      } else { }
    });
  }

}
