import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { AdminServiceService } from 'src/app/services/adminService/admin-service.service';
import { PiaServiceService } from 'src/app/services/piaService/pia-service.service';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';

import * as moment from 'moment';
import { AuthService } from 'src/app/services/authService/auth.service';

@Component({
  selector: 'app-pia-list',
  templateUrl: './pia-list.component.html',
  styleUrls: ['./pia-list.component.css']
})
export class PiaListComponent implements OnInit {

  now;
  date;
  schemes;
  allPia;
  schemeAccessId;
  allCenterList;
  schemeBasicCenterList;
  piaListSec;
  schemeCode;
  piaAccessId;
  piaDetailsSecCSS = 'hide';
  piaSelectArrowboardCSS;
  partnerCentactPersonName;
  partnerMobile;
  partnerEmail;
  totalScheme;
  piaAddress;
  roleId;
  trialScAccessId;
  search;

  constructor(
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private adminService: AdminServiceService,
    private piaService: PiaServiceService,
    private centerService: CenterDetailsService
  ) { }

  /* ===============================================================
      Scheme Information Fetch
  =============================================================== */
  fetchScheme() {
    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      this.roleId = profile.user.roleId;
      this.adminService.getSchemeWithTrial().subscribe((schemeList: any) => {
        this.schemes = schemeList.schemeDetails;
        //console.log(this.trialScAccessId);
        console.log(this.schemes);
        if(this.roleId == 0o0){
          this.trialScAccessId = undefined;
        }else{
          this.trialScAccessId = this.schemes[this.schemes.length-1].accessId;         
        }
      });
    });
  }
  /* ===============================================================
      All PIA / TP List
  =============================================================== */
  onSelectScheme(data) {
    //console.log(data.accessId)
    this.schemeAccessId = data.accessId;
    this.schemeCode = data.schemeCode;
    this.piaService.getAllPia(data.accessId).subscribe((piaList: any) => {
      //console.log(piaList);
      this.allPia = piaList.tpDetails;
      this.piaListSec = "show-list-sec";
      this.totalScheme = this.allPia.length;
    });
  }

  /* ===============================================================
      Back TP/PIA list to Scheme List
  =============================================================== */
  backToSchemeList() {
    this.piaListSec = "";
    this.schemeAccessId = null;
    this.schemeBasicCenterList = null;
    this.allPia = null;
    this.piaAccessId = null;
    this.piaSelectArrowboardCSS = '';
    this.piaDetailsSecCSS = 'hide';
    this.search = ''
  }



  /* ===============================================================
      All Center List With Scheme basic
  =============================================================== */
  onClickOfCenterList(data) {
    //console.log(data);
    this.piaAccessId = data.accessId;
    this.partnerCentactPersonName = data.contactPerson;
    this.partnerMobile = data.mobile;
    this.partnerEmail = data.email;
    this.piaAddress = data.address;
    this.centerService.getAllCenter(this.piaAccessId).subscribe((centerList: any) => {
      this.allCenterList = centerList.centerDetails;
      this.piaSelectArrowboardCSS = 'hide';
      this.piaDetailsSecCSS = '';
      this.search = ''
      this.schemeBasicCenterList = this.allCenterList.filter(centerLst => centerLst.schemeAccessId === this.schemeAccessId);
      //console.log(this.schemeBasicCenterList);
    });
  }

    /* ===============================================================
      Select PIA / TP and Pia Dashboard Page
  =============================================================== */
  onPiaDashboard() {
    //console.log(this.piaAccessId);
    localStorage.setItem('piaAccessUserInfo', this.piaAccessId);
    this.router.navigate(['/piaDashboard/u/', 1]);
  }

  /* ===============================================================
      Center Dashboard Route
  =============================================================== */
  onSelectCenter(accessId) {
    let centerAccessId = 'UserInfoCenterAccessID';
    localStorage.setItem(centerAccessId, accessId);
    localStorage.setItem('piaAccessUserInfo', this.piaAccessId);
    this.router.navigate(['/centerDashboard/u/', 2])
  }

  /* ===============================================================
      Back Function
  =============================================================== */
  back() {
    this.router.navigate(['/dashboard']);
  }

  ngOnInit() {
    this.fetchScheme();
    this.now = moment(); // add this 2 of 4
    this.date = this.now.format('Do MMMM YYYY');
  }

}
