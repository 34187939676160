import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from 'src/app/services/authService/auth.service';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { AdminServiceService } from 'src/app/services/adminService/admin-service.service';
import { PiaServiceService } from 'src/app/services/piaService/pia-service.service';
import * as moment from 'moment';

@Component({
  selector: 'app-create-center',
  templateUrl: './create-center.component.html',
  styleUrls: ['./create-center.component.css']
})
export class CreateCenterComponent implements OnInit {

  now;
  date;
  form: FormGroup;
  accessId;
  timestamp;
  datetime;
  username;
  schemes;
  piaDetils;
  schemeAccessId;
  schemeCode;
  shortSchemeCode;
  tpAccessId;
  tpCode;
  tpName;
  schemeInfo:Array<any> = []; 
  disableBtn;
  allDistrict;
  getData;
  districtCode;
  district;
  roleId;
  piaAccessId;
  tpCodeCSS:boolean;
  schemeTp;
  pia;
  piaListCss = 'hide';
  selectPiaData;

  centerAccessId;
  centerCode;
  centerName;
  centerLocation;
  centerAddress;
  contactPersonName;
  contactPersonMobile;
  courseAccessId;
  courseName;
  courseCode;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private location: Location,
    private toastr: ToastrService,
    private authService: AuthService,
    private adminService: AdminServiceService,
    private piaService: PiaServiceService,
    private centerService: CenterDetailsService
  ) { this.createForm() }

  focusFunction(){
    this.tpCodeCSS = true;
  }
  setInputFoucusOut(){
    this.tpCodeCSS = false;
  }

  /* ===============================================================
        Validation
  =============================================================== */
  createForm() {
    this.form = this.formBuilder.group({
      centerName: [null, [Validators.required, Validators.pattern("^.{3,250}$")]],
      centerCode: [null, [Validators.required, Validators.pattern("^.{3,120}$")]],
      contactPerson: [null, [Validators.required, Validators.pattern("^.{3,60}$")]],
      mobile: [null, Validators.compose([Validators.required, Validators.pattern("^\\d{10,10}$")])],
      email: [null, [Validators.required, Validators.pattern("[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]],
      address: [null, [Validators.required, Validators.maxLength(150), Validators.minLength(3)]],
      location: [null, [Validators.required, Validators.pattern("^.{3,40}$")]],
      district: [null, [Validators.required]],
      centerType: [null, [Validators.required]],
      userScheme: [null, [Validators.required]],
      username: [null, [Validators.required, Validators.pattern("^.{3,15}$")]],
    });
  }

  /* ===============================================================
    Profile Fetch
  =============================================================== */
  fetchProfile() {
    let key = 'UserInfo';
    this.accessId = localStorage.getItem(key);
    this.authService.getProfile(this.accessId).subscribe((profile: any) => {
      this.username = profile.user.username;
      this.roleId = profile.user.roleId;
      if(this.roleId == 0o0){
        this.piaAccessId = localStorage.getItem('piaAccessUserInfo');
        //this.adminService.getScheme().subscribe((schemeList: any) => {
        this.adminService.getSchemeWithTrial().subscribe((schemeList: any) => {
          this.schemes = schemeList.schemeDetails;
          this.schemeTp = '';
          //console.log(this.schemes)
        })
      }else{
        this.piaAccessId = localStorage.getItem('UserInfo');
        this.piaService.getPia(this.piaAccessId).subscribe((piaDetils: any) => {
          //console.log(piaDetils);
          this.piaDetils = piaDetils.tpDetails;
          this.tpAccessId = this.piaDetils.accessId;
          this.tpCode = this.piaDetils.tpCode;
          this.tpName = this.piaDetils.tpName;
          this.schemeCode = this.piaDetils.schemeCode;
          //this.schemeInfo = this.piaDetils.schemesInfo;
          this.schemeInfo.push({
            schemeAccessId: this.piaDetils.schemeAccessId, schemeCode:this.piaDetils.schemeCode , schemeName:this.piaDetils.schemeName 
          });
          this.schemeTp = 'hide';
        });
      }
    });
    this.centerService.getAllDistrict().subscribe((district:any) =>{
      //console.log(district);
      this.allDistrict = district.districtDetails
    });
  }
  selectScheme(scheme){
    this.piaService.getAllPia(scheme).subscribe((piaList: any) => {
      this.pia = piaList.tpDetails;
      this.piaListCss = 'show';
    });
  }

  selectPia(pia){
    this.piaService.getPia(pia).subscribe((piaDetils: any) => {
      //console.log(piaDetils);
      this.piaDetils = piaDetils.tpDetails;
      this.tpAccessId = this.piaDetils.accessId;
      this.tpCode = this.piaDetils.tpCode;
      this.tpName = this.piaDetils.tpName;
      this.schemeCode = this.piaDetils.schemeCode;
      //this.schemeInfo = this.piaDetils.schemesInfo;
      this.schemeInfo.push({
        schemeAccessId: this.piaDetils.schemeAccessId, schemeCode:this.piaDetils.schemeCode , schemeName:this.piaDetils.schemeName 
      });
      //console.log(this.schemeInfo);
      this.schemeTp = 'hide';
    });
  }


  changeEventInRadioButton(data) {
    this.schemeAccessId = data.schemeAccessId;
    this.schemeCode = data.schemeCode;
    //console.log(this.schemeCode)
  }

  onChangeDis(data){
    this.getData = data.split(',');
    this.districtCode = this.getData.pop();
    //console.log(this.districtCode);
    this.district = this.getData.join(',');
    //console.log(this.district);
  }


  /* ===============================================================
      Create Center 
  =============================================================== */
  /* Profile Create */
  onCreateCenter() {
    this.disableBtn = 'click-disable';
    this.now = moment();
    this.timestamp = this.now.format('YYYYMMDDHHmmss');
    this.datetime = this.now.format("YYYY-MM-DD HH:mm:ss");
    const user = {
      email: this.form.get('email').value,
      username: this.form.get('username').value,
      roleId: 77,
      accessId: "DWS-OPTG-CA-" + this.timestamp,
      createdBy: this.username,
      createdAt: this.datetime
    };
    console.log(user);
    //this.onCreateCenterDetails();
    this.authService.registerUser(user).subscribe((data:any) => {
      console.log(data);
      if(data.success === 1 ){
      this.onCreateCenterDetails();
      }else{}
      //console.log(data);
    });
  }
  /* Pia Details Create */
  onCreateCenterDetails() {
    this.centerAccessId = "DWS-OPTG-CA-" + this.timestamp;
    this.centerCode = this.schemeCode + '-' + this.form.get('centerCode').value;
    this.centerName = this.form.get('centerName').value;
    this.centerLocation = this.form.get('location').value;
    this.centerAddress = this.form.get('address').value;
    this.contactPersonName = this.form.get('contactPerson').value;
    this.contactPersonMobile = this.form.get('mobile').value;
    const user = {
      accessId: "DWS-OPTG-CA-" + this.timestamp,
      schemeAccessId: this.schemeAccessId,
      schemeCode: this.schemeCode,
      tpAccessId: this.tpAccessId,
      tpCode: this.tpCode,
      tpName: this.tpName,
      centerCode: this.schemeCode + '-' + this.form.get('centerCode').value,
      centerName: this.form.get('centerName').value,
      contactPerson: this.form.get('contactPerson').value,
      mobile: this.form.get('mobile').value,
      email: this.form.get('email').value,
      address: this.form.get('address').value,
      location: this.form.get('location').value,
      districtName: this.district,
      districtCode: this.districtCode,
      centerType: this.form.get('centerType').value,
      createdBy: this.username,
      createdAt: this.datetime
    }
    console.log(user);
    //this.createAdminCourse();
    this.centerService.createCenter(user).subscribe((data:any) => {
      console.log(data);
      if(data.success == 1){
        this.createAdminCourse();
        this.toastr.success(data.message, 'success');
      }else{
        this.toastr.info(data.message, 'info');
      }
    });
  }

  /* Auto Create Administration course for SDC */
  createAdminCourse(){
    const creData = {
      centerAccessId: this.centerAccessId,
      sectorAccessId: 'DWS-OPTG-SE-60600347',
      sectorName: 'Official',
      courseAccessId: 'DWS-OPTG-SEC-70700638',
      courseName: 'Administration',
      courseCode: 'AD/0999'
    }
    console.log(creData);
    this.centerService.createCourseNewFormat(creData).subscribe((info: any) => {
      console.log(info);
      if(info.success == 1){
        //this.toastr.success(course.message, 'success');
        this.creAdminBatch();
      }else{
        //this.toastr.info(course.message, 'info');
      }
    });
  }

  creAdminBatch(){
    this.now = moment();
    this.timestamp = this.now.format('YYYYMMDDHHmmss');
    this.datetime = this.now.format("YYYY-MM-DD HH:mm:s");
    if(this.schemeCode === 'ELSTP'){
      this.shortSchemeCode = 'ELS';
    }else if(this.schemeCode === 'RSTP'){
      this.shortSchemeCode = 'RST';
    }else if(this.schemeCode === 'DDU-GKY'){
      this.shortSchemeCode = 'DDU';
    }else if(this.schemeCode === 'PMKVY'){
      this.shortSchemeCode = 'PMK';
    }else if(this.schemeCode === 'TRIAL'){
      this.shortSchemeCode = 'TRI';
    }else{}
    const newBatchData = {
      accessId: "DWS-OPTG-BA-" + this.timestamp, 
      schemeAccessId: this.schemeAccessId,
      schemeCode: this.schemeCode,
      tpAccessId: this.tpAccessId,
      tpCode: this.tpCode,
      tpName: this.tpName,
      centerAccessId: this.centerAccessId,
      centerCode: this.centerCode,
      centerName: this.centerName,
      centerLocation: this.centerLocation,
      centerAddress: this.centerAddress,
      contactPersonName: this.contactPersonName,
      contactPersonMobile: this.contactPersonMobile,
      courseAccessId: 'DWS-OPTG-SEC-70700638',
      courseCode: 'AD/0999',
      courseName: 'Administration',
      sectorAccessId: 'DWS-OPTG-SE-60600347',
      sectorName: 'Official',
      districtName: this.district,
      districtCode: this.districtCode,
      batchType: 'N/A',
      batchCode: this.shortSchemeCode + '-999' ,
      batchHours : '999',

      batchStratDate: moment().format('YYYY-MM-DD')+' 09:00:00',
      batchEndDate: moment().add(3, 'M').format('YYYY-MM-DD')+' 18:00:00',

      batchStartTime: '09:00:00',
      batchEndTime: '18:00:00',
      createdBy: this.username,
      createdAt: this.datetime
    }
    console.log(newBatchData);
    this.centerService.createBatch(newBatchData).subscribe((batch:any) =>{
      console.log(batch);
      this.form.reset();
      this.disableBtn = '';
      if(this.roleId == 0){
        this.router.navigate(['/SUDashboard']);
        localStorage.removeItem('piaAccessUserInfo');
        localStorage.removeItem('UserInfoCenterAccessID');
      }else{
        this.router.navigate(['/piaDashboard/u/', 0])
      }
    });
  }


  /* ===============================================================
      Back Function
  =============================================================== */
  back() {
    if(this.roleId == 0o0){
      this.router.navigate(['/SUDashboard']);
      localStorage.removeItem('piaAccessUserInfo');
      localStorage.removeItem('UserInfoCenterAccessID');
    }else{
      this.router.navigate(['/piaDashboard/u/', 0]);
    }
  }

  /* ===============================================================
      JavaScript Load 
  =============================================================== */
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/js/common.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  ngOnInit() {
    this.loadScript();
    this.fetchProfile();
    this.now = moment();
    this.date = this.now.format('Do MMMM YYYY');
  }

}
