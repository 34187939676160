import { Component, OnInit, Compiler, ViewChild, ElementRef } from '@angular/core';
import { CCService } from 'src/app/services/cCService/c-c.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { ClassService } from 'src/app/services/classService/class.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/authService/auth.service';
import { AdminServiceService } from 'src/app/services/adminService/admin-service.service';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { PiaServiceService } from 'src/app/services/piaService/pia-service.service';
declare var jwplayer: any;
import * as moment from 'moment';
import { Chart } from "chart.js";
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import html2canvas from 'html2canvas';
import { AzureBlobStorageService } from 'src/app/services/azure/azure-blob-storage.service';
import { WowzaWebRTCPlayer } from 'wowza-webrtc-player';

import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';


@Component({
  selector: 'app-c-csearch',
  templateUrl: './c-csearch.component.html',
  styleUrls: ['./c-csearch.component.css']
})
export class CCSearchComponent implements OnInit {

  cameraList;
  finalCameraList;
  mainSec: boolean = false;
  ccSideMenu = 'remove-roundc';
  stream;
  getSelectSdcAccessId;
  getCameraList;
  loader: boolean;
  allCamera;
  selectVidForZoom;
  search;
  selectSdc;

  allScheme;
  selectSchemeCode;
  allDistrict;
  sehemeSec = 'show-anim';
  districtSec = 'hide-anim';
  piaSec = 'hide-anim';
  sdcSec = 'hide-anim';
  selectDistrictName;
  allSdc;
  allPia;
  sdcList;
  schemeSdcList;
  finalSDCList;
  districtSdcList;
  selectPiaName;
  piaSdcList;
  seleSdcAccessId;
  sdcListSec: boolean = false;
  streamDashSec: boolean = true;
  selectSec = 'liveStm';
  allCount;
  LineChart: any[];

  selectFilterType = 'scheme';
  systemCheck: boolean = false;
  systemCheckTime;
  sideCount;
  piaAccessId;
  sdcMainSearch;
  selectStep = 'scheme';
  sdcSearch;
  piaSearch;
  distSearch;
  onStep;
  streamDetailsLoader;

  disableBtn
  datetime;
  captureImageSec: boolean;
  selectClass;
  username;
  startRecBtn: boolean = true;
  stopRecBtn: boolean = false;

  date;
  dataImages;
  dataVideos;
  myModal;
  slideCount;
  prevHide;
  nextHide;
  myVidModal;
  slideVidCount;
  prevVidHide;
  nextVidHide;
  imageLength;
  videoLength;
  histType;
  changeDate;
  capturedImageUrl;
  schemeCss;
  distCss;
  piaCss;
  sdcListHide;
  intervel;
  setImageName;
  screenShotImg:any;

  mailSec: boolean = false;
  mailSecScreen: boolean = false;
  captureImageSecScreenShot: boolean = false;
  toMailId = [];
  tpEmailId;
  emailFiled;
  clearMailId = [];
  form: FormGroup;
  mailRightSec: boolean = false;
  removeMailId;
  alertBox = 'hide';

  fileForm: FormGroup;
  fileFormArray;
  fileDetails;
  fileData = [];
  associatedFiles = ['https://optagersldc.s3.amazonaws.com/screenshots/1700416062.png'];
  attchImage = 0;
  attchVideo = 0;
  pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  @ViewChild('box') private elementRef: ElementRef;

  allAttendance;
  testing: boolean;
  selectResAccessId;
  attenInfo;
  bubble: boolean = true;
  now;
  dateToday;
  inputData;

  notAssgin= 'hide';

  FOLDER = 'screenshots';
  videoElement;


  // Min moment: February 12 2018, 10:30
  public min = new Date(2018, 1, 12, 10, 30);

  // Max moment: April 21 2018, 20:30
  public max = new Date;
  public selectedMoment = new Date();

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',

    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  sas = "sp=racwdl&st=2021-09-02T14:28:22Z&se=2025-08-31T22:28:22Z&sv=2020-08-04&sr=c&sig=v9Nal6PBJxpBeHqY1LmSOb%2FqCic%2B3kvRYF9d%2BbGONQc%3D"

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private ccService: CCService,
    private _compiler: Compiler,
    private datePipe: DatePipe,
    private piaService: PiaServiceService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private blobService: AzureBlobStorageService,
    private centerService: CenterDetailsService,
    private adminService: AdminServiceService,
    private classService: ClassService
  ) {
    this.createForm();
    this.addFileForm();
  }

  onClickAutoPlay() {
    this.router.navigate(['/cCAutoPlay']);
    localStorage.removeItem('selectSdcAccessID')
    localStorage.removeItem('totalCamList')
  }

  onClickOnlyLive() {
    this.router.navigate(['/cCOnlyLive']);
    localStorage.removeItem('selectSdcAccessID');
    localStorage.removeItem('totalCamList');
  }

  onClickAllHistory() {
    this.router.navigate(['/history-all']);
    localStorage.removeItem('selectSdcAccessID');
    localStorage.removeItem('totalCamList');
  }

  onClickVideoWall() {
    this.router.navigate(['/cCVideoWall']);
    localStorage.removeItem('selectSdcAccessID')
    localStorage.removeItem('totalCamList')
  }

  getSantizeUrl(url: string) {
    //console.log(url)
    if (!url) return null;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  /* ===============================================================
      Get All SDC list
  =============================================================== */
  getAllSDCList() {
    this.ccService.getCenter().subscribe((sdc: any) => {
      //console.log(sdc);
      this.sdcList = sdc.centerDetails.filter(sdcActive => sdcActive.isCenterActive === "1");
      //console.log(this.sdcList);
      
      this.adminService.getScheme().subscribe((data: any) => {
        //console.log(data);
        this.allScheme = data.schemeDetails;
      });
    });
    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      this.username = profile.user.username;

    });
  }

  /* ===============================================================
      OnClick Scheme
  =============================================================== */
  onClickScheme() {
    this.sehemeSec = 'show-anim';
    this.districtSec = 'hide-anim';
    this.piaSec = 'hide-anim';
    this.sdcSec = 'hide-anim';
    this.selectStep = 'scheme';
    this.seleSdcAccessId = undefined;
    this.sdcMainSearch = '';
    this.streamDashSec = true;
    this.notAssgin = 'hide';
    localStorage.removeItem('selectSdcAccessID');
    if (this.selectSchemeCode == undefined && this.selectPiaName == undefined && this.selectDistrictName == undefined) {
      this.finalSDCList = undefined;
      this.graphLoad();
    } else { }
  }

  /* On Select Scheme */
  onSelectScheme(scheme) {
    this.selectSchemeCode = scheme.schemeCode;
    this.selectFilterType = 'scheme';
    this.sdcListSec = true;
    this.streamDashSec = true;
    this.selectPiaName = undefined;
    this.piaSdcList = undefined;
    this.sdcMainSearch = '';
    this.notAssgin = 'hide';
    this.sdcListHide = 'show';

    if (this.selectDistrictName != undefined) {
      this.schemeSdcList = this.districtSdcList.filter(ele => ele.schemeCode === this.selectSchemeCode);
      this.finalSDCList = this.schemeSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
      this.schemeCss = 'sec-s';
      //console.log(this.finalSDCList)
    } else if (this.selectPiaName != undefined) {
      this.schemeSdcList = this.piaSdcList.filter(ele => ele.schemeCode === this.selectSchemeCode);
      this.finalSDCList = this.schemeSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
      this.schemeCss = 'sec-s';
      //console.log(this.finalSDCList)
    } else {
      this.schemeSdcList = this.sdcList.filter(ele => ele.schemeCode === this.selectSchemeCode);
      this.finalSDCList = this.schemeSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
      //console.log(this.finalSDCList);
      this.seleSdcAccessId = undefined;
      this.selectDistrictName = undefined;
      this.selectPiaName = undefined;
      this.schemeCss = 'first-s';
    }
  }

  /* ===============================================================
      OnClick District
  =============================================================== */
  onClickDistrict() {
    this.centerService.getAllDistrict().subscribe((district: any) => {
      //console.log(district);
      this.allDistrict = district.districtDetails;
      this.sehemeSec = 'hide-anim';
      this.districtSec = 'show-anim';
      this.piaSec = 'hide-anim';
      this.sdcSec = 'hide-anim';
      this.selectStep = 'dist';
      this.distSearch = '';
      this.sdcMainSearch = '';
      this.notAssgin = 'hide';
      this.seleSdcAccessId = undefined;
      this.streamDashSec = true;
      if (this.selectSchemeCode == undefined && this.selectPiaName == undefined && this.selectDistrictName == undefined) {
        this.finalSDCList = undefined;
        this.graphLoad();
      } else { }
    });
  }

  /* On Select District */
  onSelectDistrict(district) {
    this.selectDistrictName = district.districtName;
    this.sdcListSec = true;
    this.streamDashSec = true;
    this.seleSdcAccessId = undefined;
    this.selectFilterType = 'dist';
    this.sdcMainSearch = '';
    this.sdcListHide = 'show';
    if (this.selectSchemeCode === undefined) {
      this.districtSdcList = this.sdcList.filter(ele => ele.districtName === this.selectDistrictName);
      this.finalSDCList = this.districtSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
      this.distCss = 'first-s';
      //console.log(this.finalSDCList);
    } else if (this.selectSchemeCode != undefined) {
      this.districtSdcList = this.schemeSdcList.filter(ele => ele.districtName === this.selectDistrictName);
      this.finalSDCList = this.districtSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
      this.distCss = 'sec-s';
      //console.log(this.finalSDCList)
    } else { }
  }

  /* ===============================================================
      OnClick Pia/TP
  =============================================================== */
  onClickPia() {
    this.piaService.getAllTP().subscribe((pia: any) => {
      //console.log(pia);
      this.sehemeSec = 'hide-anim';
      this.districtSec = 'hide-anim';
      this.piaSec = 'show-anim';
      this.sdcSec = 'hide-anim';
      this.selectStep = 'pia';
      this.piaSearch = '';
      this.sdcMainSearch = '';
      this.notAssgin = 'hide';
      this.seleSdcAccessId = undefined;
      this.streamDashSec = true;
      if (this.selectSchemeCode == undefined && this.selectPiaName == undefined && this.selectDistrictName == undefined) {
        this.finalSDCList = undefined;
        this.graphLoad();
      } else { }
      if (this.selectSchemeCode != undefined) {
        if (this.selectDistrictName != undefined) {
          var schemePiaList = pia.tpDetails.filter(ele => ele.schemeCode === this.selectSchemeCode);
          var distPiaList = schemePiaList.filter(ele => ele.districtName === this.selectDistrictName);
          this.allPia = distPiaList.sort((a, b) => a.tpName > b.tpName ? 1 : -1);
          //console.log(this.allPia)
        } else {
          var schemePiaList = pia.tpDetails.filter(ele => ele.schemeCode === this.selectSchemeCode);
          this.allPia = schemePiaList.sort((a, b) => a.tpName > b.tpName ? 1 : -1);
          //console.log(this.allPia);
        }
      } else if (this.selectDistrictName != undefined) {
        var distPiaList = pia.tpDetails.filter(ele => ele.districtName === this.selectDistrictName);
        this.allPia = distPiaList.sort((a, b) => a.tpName > b.tpName ? 1 : -1);
        //console.log(this.allPia);
      } else {
        this.allPia = pia.tpDetails.sort((a, b) => a.tpName > b.tpName ? 1 : -1);
        //console.log(this.allPia)
      }
    });
  }

  /* On Select Pia/TP */
  onSelectPia(pia) {
    //console.log(pia);
    //console.log(this.sdcList)
    this.notAssgin = 'hide';
    this.selectPiaName = pia.tpShortName;
    this.piaAccessId = pia.accessId;
    this.sdcListSec = true;
    this.streamDashSec = true;
    this.seleSdcAccessId = undefined;
    this.selectFilterType = 'pia';
    this.sdcMainSearch = '';
    this.sdcListHide = 'show';
    if (this.selectSchemeCode != undefined) {
      if (this.selectDistrictName != undefined) {
        this.piaSdcList = this.districtSdcList.filter(ele => ele.tpAccessId === this.piaAccessId);
        this.finalSDCList = this.piaSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
        this.piaCss = 'third-s';
        //console.log(this.finalSDCList);
      } else {
        this.piaSdcList = this.schemeSdcList.filter(ele => ele.tpAccessId === this.piaAccessId);
        this.finalSDCList = this.piaSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
        this.piaCss = 'sec-s';
        //console.log(this.finalSDCList);
      }
    } else if (this.selectDistrictName != undefined) {
      this.piaSdcList = this.districtSdcList.filter(ele => ele.tpAccessId === this.piaAccessId);
      this.finalSDCList = this.piaSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
      this.piaCss = 'sec-s';
      //console.log(this.finalSDCList);
    } else {
      this.piaSdcList = this.sdcList.filter(ele => ele.tpAccessId === this.piaAccessId);
      this.finalSDCList = this.piaSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
      this.piaCss = 'first-s';
      //console.log(this.finalSDCList);
    }
  }

  schemeClose() {
    this.schemeCss = undefined;
    this.sdcMainSearch = '';
    if (this.selectSchemeCode != undefined) {
      if (this.selectDistrictName != undefined) {
        if (this.selectFilterType == 'scheme') {
          this.finalSDCList = this.districtSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
          this.sehemeSec = 'hide-anim';
          this.districtSec = 'show-anim';
          this.piaSec = 'hide-anim';
          this.sdcSec = 'hide-anim';
          this.selectFilterType = 'dist';
          this.selectSchemeCode = undefined;
          //console.log(this.finalSDCList);
          this.selectStep = 'dist';
        } else {
          if (this.selectPiaName != undefined) {
            this.finalSDCList = this.piaSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
            this.selectSchemeCode = undefined;
            this.selectPiaName = undefined
            this.sehemeSec = 'show-anim';
            this.districtSec = 'hide-anim';
            this.piaSec = 'hide-anim';
            this.sdcSec = 'hide-anim';
            this.selectFilterType = 'pia';
            this.selectPiaName = undefined;
            this.selectStep = 'scheme';
            this.graphLoad();
            this.finalSDCList = undefined;
            //console.log(this.finalSDCList);
          } else {
            this.sehemeSec = 'show-anim';
            this.districtSec = 'hide-anim';
            this.piaSec = 'hide-anim';
            this.sdcSec = 'hide-anim';
            this.selectFilterType = 'scheme';
            this.selectStep = 'scheme';
            this.finalSDCList = undefined;
            this.selectSchemeCode = undefined;
            this.selectDistrictName = undefined;
            this.selectPiaName = undefined;
            this.graphLoad();
            //console.log(this.finalSDCList);
          }
        }
      } else if (this.selectPiaName != undefined) {
        if (this.selectFilterType == 'scheme') {
          this.finalSDCList = this.piaSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
          this.sehemeSec = 'hide-anim';
          this.districtSec = 'hide-anim';
          this.piaSec = 'show-anim';
          this.sdcSec = 'hide-anim';
          this.selectFilterType = 'pia';
          this.selectSchemeCode = undefined;
          //console.log(this.finalSDCList)
        } else {
          this.sehemeSec = 'show-anim';
          this.districtSec = 'hide-anim';
          this.piaSec = 'hide-anim';
          this.sdcSec = 'hide-anim';
          this.selectFilterType = 'scheme';
          this.finalSDCList = undefined;
          this.selectSchemeCode = undefined;
          this.selectDistrictName = undefined;
          this.selectPiaName = undefined
          this.selectStep = 'scheme';
          this.graphLoad();
          //console.log(this.finalSDCList);
        }
      } else {
        this.finalSDCList = undefined;
        this.sehemeSec = 'show-anim';
        this.districtSec = 'hide-anim';
        this.piaSec = 'hide-anim';
        this.sdcSec = 'hide-anim';
        this.selectFilterType = 'scheme';
        this.selectStep = 'scheme';
        this.selectSchemeCode = undefined;
        this.selectDistrictName = undefined;
        //console.log(this.finalSDCList)
        this.graphLoad();
      }
    } else {
      this.finalSDCList = undefined;
      this.sehemeSec = 'show-anim';
      this.districtSec = 'hide-anim';
      this.piaSec = 'hide-anim';
      this.sdcSec = 'hide-anim';
      this.selectFilterType = 'scheme';
      this.selectStep = 'scheme';
      this.selectPiaName = undefined;
      this.selectDistrictName = undefined;
      //console.log(this.finalSDCList)
      this.graphLoad();
    }
  }

  districtClose() {
    this.distCss = undefined;
    this.sdcMainSearch = '';
    if (this.selectDistrictName != undefined) {
      if (this.selectSchemeCode != undefined) {
        if (this.selectFilterType == 'dist') {
          this.finalSDCList = this.schemeSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
          this.sehemeSec = 'show-anim';
          this.districtSec = 'hide-anim';
          this.piaSec = 'hide-anim';
          this.sdcSec = 'hide-anim';
          this.selectFilterType = 'scheme';
          this.selectStep = 'scheme';
          this.selectDistrictName = undefined;
          //console.log(this.finalSDCList);
        } else {
          if (this.selectPiaName != undefined) {
            this.finalSDCList = this.schemeSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
            this.sehemeSec = 'show-anim';
            this.districtSec = 'hide-anim';
            this.piaSec = 'hide-anim';
            this.sdcSec = 'hide-anim';
            this.selectFilterType = 'scheme';
            this.selectPiaName = undefined;
            this.selectDistrictName = undefined;
            this.selectStep = 'scheme';
            //console.log(this.finalSDCList)
          } else {
            this.finalSDCList = undefined;
            this.sehemeSec = 'show-anim';
            this.districtSec = 'hide-anim';
            this.piaSec = 'hide-anim';
            this.sdcSec = 'hide-anim';
            this.selectFilterType = 'dist';
            this.selectSchemeCode = undefined;
            this.selectDistrictName = undefined;
            this.selectPiaName = undefined;
            this.graphLoad()
            //console.log(this.finalSDCList)
          }
        }
      } else {
        this.sehemeSec = 'hide-anim';
        this.districtSec = 'show-anim';
        this.piaSec = 'hide-anim';
        this.sdcSec = 'hide-anim';
        this.selectFilterType = 'dist';
        this.finalSDCList = undefined;
        this.districtSdcList = undefined;
        this.sdcListSec = false;
        this.streamDashSec = true;
        this.selectDistrictName = undefined;
        this.selectPiaName = undefined;
        //console.log(this.finalSDCList)
        this.graphLoad()
      }
    } else {
      this.sehemeSec = 'hide-anim';
      this.districtSec = 'show-anim';
      this.piaSec = 'hide-anim';
      this.sdcSec = 'hide-anim';
      this.selectFilterType = 'dist';
      this.finalSDCList = undefined;
      this.districtSdcList = undefined;
      this.sdcListSec = false;
      this.streamDashSec = true;
      this.selectDistrictName = undefined;
      this.graphLoad()
      //console.log(this.finalSDCList)
    }
  }

  piaClose() {
    this.piaCss = undefined;
    this.sdcMainSearch = '';
    if (this.selectPiaName != undefined) {
      if (this.selectSchemeCode != undefined) {
        if (this.selectDistrictName != undefined) {
          this.finalSDCList = this.districtSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
          this.sehemeSec = 'hide-anim';
          this.districtSec = 'show-anim';
          this.piaSec = 'hide-anim';
          this.sdcSec = 'hide-anim';
          this.selectFilterType = 'dist';
          this.selectStep = 'dist';
          this.selectPiaName = undefined;
          //console.log(this.finalSDCList);
        } else {
          if (this.selectFilterType == 'pia') {
            this.finalSDCList = this.schemeSdcList;
            this.sehemeSec = 'show-anim';
            this.districtSec = 'hide-anim';
            this.piaSec = 'hide-anim';
            this.sdcSec = 'hide-anim';
            this.selectFilterType = 'scheme';
            this.selectStep = 'scheme';
            this.selectPiaName = undefined;
            //console.log(this.finalSDCList);
          } else {
            this.finalSDCList = undefined;
            this.sehemeSec = 'hide-anim';
            this.districtSec = 'hide-anim';
            this.piaSec = 'show-anim';
            this.sdcSec = 'hide-anim';
            this.selectFilterType = 'pia';
            this.selectSchemeCode = undefined;
            this.selectPiaName = undefined;
            this.graphLoad();
            //console.log(this.finalSDCList);
          }
        }
      } else if (this.selectDistrictName != undefined) {
        if (this.selectFilterType == 'pia') {
          this.finalSDCList = this.districtSdcList.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
          this.sehemeSec = 'hide-anim';
          this.districtSec = 'show-anim';
          this.piaSec = 'hide-anim';
          this.sdcSec = 'hide-anim';
          this.selectFilterType = 'dist';
          this.selectPiaName = undefined;
          //console.log(this.finalSDCList);
        } else {
          this.finalSDCList = undefined;
          this.sehemeSec = 'hide-anim';
          this.districtSec = 'show-anim';
          this.piaSec = 'hide-anim';
          this.sdcSec = 'hide-anim';
          this.selectFilterType = 'pia';
          this.selectDistrictName = undefined;
          this.selectPiaName = undefined;
          //console.log(this.finalSDCList);
          this.graphLoad();
        }
      } else {
        this.sehemeSec = 'hide-anim';
        this.districtSec = 'hide-anim';
        this.piaSec = 'show-anim';
        this.sdcSec = 'hide-anim';
        this.selectFilterType = 'pia';
        this.finalSDCList = undefined;
        this.districtSdcList = undefined;
        this.sdcListSec = false;
        this.streamDashSec = true;
        this.selectPiaName = undefined;
        //console.log(this.finalSDCList)
        this.graphLoad()
      }
    } else {
      this.sehemeSec = 'hide-anim';
      this.districtSec = 'show-anim';
      this.piaSec = 'hide-anim';
      this.sdcSec = 'hide-anim';
      this.selectFilterType = 'dist';
      this.finalSDCList = undefined;
      this.districtSdcList = undefined;
      this.sdcListSec = false;
      this.streamDashSec = true;
      this.selectDistrictName = undefined;
      this.graphLoad()
      //console.log(this.finalSDCList)
    }
  }

  /* ===============================================================
      OnClick SDC
  =============================================================== */
  onClickSdc() {
    this.ccService.getCenter().subscribe((sdc: any) => {
      //console.log(sdc);
      this.allSdc = sdc.centerDetails.sort((a, b) => a.centerName > b.centerName ? 1 : -1);
      this.sehemeSec = 'hide-anim';
      this.districtSec = 'hide-anim';
      this.piaSec = 'hide-anim';
      this.sdcSec = 'show-anim';
      this.selectFilterType = 'sdc';
      this.getSelectSdcAccessId = undefined;
      this.selectStep = 'sdc';
      this.sdcSearch = '';
      this.sdcMainSearch = '';
    });
  }

  /* On Select SDC */
  onSelectSdc(sdcData) {
    console.log(sdcData);
    if (sdcData.isCenterActive === '1') {
      this.seleSdcAccessId = sdcData.accessId;
      this.sdcListSec = false;
      this.streamDashSec = false;
      this.selectSec = 'liveStm';
      localStorage.setItem('sdcdetails', JSON.stringify(sdcData));
      this.loader = true;
      localStorage.setItem('selectSdcAccessID', sdcData.accessId);
      this.selectSdc = sdcData;
      this.toMailId = [];
      this.clearMailId = [];
      //console.log(this.selectSdc);
      this.selectSchemeCode = undefined;
      this.selectDistrictName = undefined;
      this.selectPiaName = undefined;
      this.sdcMainSearch = '';
      // this.centerService.getCentMainCount(this.seleSdcAccessId, moment().format("YYYY-MM-DD")).subscribe((data: any) => {
      //   //console.log(data);
      //   this.allCount = data;
      // });
      this.piaService.getPia(sdcData.tpAccessId).subscribe((tpDetail: any) => {
        this.tpEmailId = tpDetail.tpDetails.email;
      })
      this.ccService.getAllCameraBySdc(sdcData.accessId).subscribe((allCam: any) => {
        //console.log(allCam);
        this.cameraList = allCam.cameraDetails;
        //console.log(this.cameraList.length);
        
        if (this.cameraList.length > 0) {
          this.notAssgin = 'hide';
          //console.log(this.cameraList);
          this.mainSec = false;
          if (localStorage.getItem('totalCamList') === null) {
            //console.log('llll');
            for (let i = 0; i < this.cameraList.length; i++) {
              this.onStep = 'first';
              const camAvailData = {
                cameraDeviceId: this.cameraList[i].cameraDeviceId
              }
              this.classService.cameraAvailabililty(camAvailData).subscribe((camAvail: any) => {
                //console.log(camAvail)
                if (camAvail.success == 1) {
                  const camspeed = {
                    cameraDeviceId: this.cameraList[i].cameraDeviceId
                  }
                  //console.log(camspeed)
                  this.centerService.camSpeed(camspeed).subscribe((camSpeed: any) => {
                    //console.log(camSpeed)
                  });
                }
              });
              if (i + 1 === this.cameraList.length) {
                setTimeout(() => {
                  //console.log('same');
                  this.ccService.getAllCameraBySdc(this.seleSdcAccessId).subscribe((cam: any) => {
                    //console.log(cam);
                    this.cameraList = cam.cameraDetails;
                    //console.log(this.cameraList);
                    this.onStep = 'second';
                    for (let x = 0; x < this.cameraList.length; x++) {
                      setTimeout(() => {
                        //console.log(this.cameraList[x].isCameraActive)
                        if (this.cameraList[x].isCameraActive === '1') {
                          const onPrams = {
                            cameraDeviceId: this.cameraList[x].cameraDeviceId,
                            cameraId: this.cameraList[x].cameraId,
                            channelId: '12'
                          }
                          //console.log(onPrams);
                          this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                            //console.log(onCam);
                          });
                        } else { }
                        if (x + 1 === this.cameraList.length) {
                          this.onStep = 'third';
                          this.streamDetailsLoader = 'show';
                          //console.log('show Stream list');
                          localStorage.setItem('totalCamList', JSON.stringify(this.cameraList));
                          setTimeout(() => {
                            this.loader = false;
                            this.finalCameraList = this.cameraList;
                            this.finalCameraList.forEach((ele:any, i:any) =>{
                              setTimeout(() => {
                                this.videoElement = document.getElementById(i);
                                const player = new WowzaWebRTCPlayer(this.videoElement, {
                                  sdpUrl: 'wss://638ee05658d80.streamlock.net/webrtc-session.json',
                                  applicationName: 'live',
                                  streamName: ele.cameraId,
                                });
                                player.playRemote();                                
                              }, 2000);
                            });
                            setTimeout(() => {
                              this.streamDetailsLoader = 'hide';
                            }, 6666);
                          }, 2000);
                        } else { }
                      }, 1000 * (x + 1));
                    }
                  });
                }, 2000);
              } else {
                //console.log('other'); 
              }
            }
          } else {
            //console.log('dddd');
            this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
            for (let a = 0; a < this.allCamera.length; a++) {
              setTimeout(() => {
                //console.log(this.allCamera[a].isCameraActive)
                if (this.allCamera[a].isCameraActive === '1') {
                  const offPrams = {
                    cameraDeviceId: this.allCamera[a].cameraDeviceId,
                    cameraId: this.allCamera[a].cameraId,
                    channelId: '12'
                  }
                  //console.log(offPrams);
                  this.classService.offCamera(offPrams).subscribe((offCam: any) => {
                    //console.log(offCam);
                  });
                }
              }, 1000 * (a + 1));
              if (a + 1 === this.allCamera.length) {
                this.onStep = 'first';
                for (let b = 0; b < this.cameraList.length; b++) {
                  setTimeout(() => {
                    const camAvailData = {
                      cameraDeviceId: this.cameraList[b].cameraDeviceId
                    }
                    this.classService.cameraAvailabililty(camAvailData).subscribe((camAvail: any) => {
                      //console.log(camAvail)
                      if (camAvail.success === 1) {
                        const camSpeedData = {
                          cameraDeviceId: this.cameraList[b].cameraDeviceId
                        }
                        //console.log(camSpeedData)
                        this.centerService.camSpeed(camSpeedData).subscribe((camSpe: any) => {
                          //console.log(camSpe);
                        });
                      }
                    });
                    if (b + 1 === this.cameraList.length) {
                      setTimeout(() => {
                        //console.log('same');
                        this.onStep = 'second';
                        this.ccService.getAllCameraBySdc(this.seleSdcAccessId).subscribe((cam: any) => {
                          this.cameraList = cam.cameraDetails;
                          //console.log(this.cameraList);
                          for (let c = 0; c < this.cameraList.length; c++) {
                            setTimeout(() => {
                              //console.log(this.cameraList[x].isCameraActive)
                              if (this.cameraList[c].isCameraActive === '1') {
                                const onPrams = {
                                  cameraDeviceId: this.cameraList[c].cameraDeviceId,
                                  cameraId: this.cameraList[c].cameraId,
                                  channelId: '12'
                                }
                                //console.log(onPrams);
                                this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                                  //console.log(onCam);
                                });
                              } else { }
                              if (c + 1 === this.cameraList.length) {
                                //console.log('show Stream list');
                                this.onStep = 'third';
                                this.streamDetailsLoader = 'show';
                                localStorage.setItem('totalCamList', JSON.stringify(this.cameraList));
                                setTimeout(() => {
                                  this.loader = false;
                                  this.finalCameraList = this.cameraList;
                                  this.finalCameraList.forEach((ele:any, i:any) =>{
                                    setTimeout(() => {
                                      this.videoElement = document.getElementById(i);
                                      const player = new WowzaWebRTCPlayer(this.videoElement, {
                                        sdpUrl: 'wss://638ee05658d80.streamlock.net/webrtc-session.json',
                                        applicationName: 'live',
                                        streamName: ele.cameraId,
                                      });
                                      player.playRemote();                                
                                    }, 2000);
                                  }) 
                                  setTimeout(() => {
                                    this.streamDetailsLoader = 'hide';
                                  }, 6666);
                                }, 2000);
                              } else { }
                            }, 1000 * (c + 1));
                          }
                        });
                      }, 2000);
                    }
                  }, 1000 * (b + 1));
                }
              }
            }
          }
        } else {
          console.log('no camera');
          this.loader = false;
          this.notAssgin = 'show';
   
        }
      });

    } else {
      this.toastr.info('Account is deactivated due to non-payment for the service.', 'Contact :' + sdcData.contactPerson + ', Mobile:' + sdcData.mobile)
    }

  }

  onLiveStream() {
    this.selectSec = 'liveStm';
    if(this.cameraList.length === 0){
      this.notAssgin = 'show';

    }
  }

  onclickDashboard() {
    this.centerService.getCentMainCount(this.seleSdcAccessId, moment().format("YYYY-MM-DD")).subscribe((data: any) => {
      //console.log(data);
      this.allCount = data;
      this.notAssgin = 'hide';

      this.selectSec = 'dashboard';
      this.LineChart = new Chart('mainchart', {
        type: 'bar',
        data: {
          labels: ["All", "Student", "Teacher", "Staff"],
          datasets: [{
            label: '',
            data: [this.allCount.allRegisteredStudent + this.allCount.allRegisteredTeacher + this.allCount.allRegisteredEmployee, this.allCount.allRegisteredStudent,
            this.allCount.allRegisteredTeacher, this.allCount.allRegisteredEmployees],
            barThickness: 80,
            backgroundColor: [
              'rgba(52, 73, 94,0.6)',
              'rgba(52, 152, 219,0.6)',
              'rgba(230, 126, 34,0.6)',
              'rgba(155, 89, 182,0.6)',
            ],
          }]
        },
        options: {
          title: {
            text: "Line Chart",
            display: false
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 10,
                display: false,
              },
              gridLines: {
                drawBorder: true,
                display: false
              }
            }],
            xAxes: [{
              gridLines: {
                drawBorder: true,
                display: false
              }
            }]
          }
        }

      })
      this.LineChart = new Chart('studentChart', {
        type: 'horizontalBar',
        data: {
          labels: ["Present", "Absent"],
          datasets: [{
            label: '',
            data: [this.allCount.attendanceCountStudent, this.allCount.allRegisteredStudent - this.allCount.attendanceCountStudent],
            barThickness: 30,
            backgroundColor: [
              'rgba(52, 152, 219,0.9)',
              'rgba(52, 152, 219,0.6)',
              'rgba(52, 152, 219,0.3)',
            ],
          }]
        },
        options: {
          title: {
            text: "Line Chart",
            display: false
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              gridLines: {
                drawBorder: true,
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                display: false,
              },
              gridLines: {
                drawBorder: true,
                display: false
              }
            }]
          }
        }
      })
      this.LineChart = new Chart('teacherChart', {
        type: 'horizontalBar',
        data: {
          labels: ["Present", "Absent"],
          datasets: [{
            label: '',
            data: [this.allCount.attendanceCountTeacher, this.allCount.allRegisteredTeacher - this.allCount.attendanceCountTeacher],
            barThickness: 30,
            backgroundColor: [
              'rgba(230, 126, 34,0.9)',
              'rgba(230, 126, 34,0.6)',
              'rgba(230, 126, 34,0.3)',
            ],
          }],

        },
        options: {
          title: {
            text: "Line Chart",
            display: false
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              gridLines: {
                drawBorder: true,
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                display: false,
              },
              gridLines: {
                drawBorder: true,
                display: false
              }
            }]
          }
        }
      })
      this.LineChart = new Chart('staffChart', {
        type: 'horizontalBar',
        data: {
          labels: ["Present", "Absent"],
          datasets: [{
            label: '',
            data: [this.allCount.attendanceCountEmp, this.allCount.allRegisteredEmployees - this.allCount.attendanceCountEmp],
            barThickness: 30,
            backgroundColor: [
              'rgba(155, 89, 182,0.9)',
              'rgba(155, 89, 182,0.6)',
              'rgba(155, 89, 182,0.3)',
            ],
          }],

        },
        options: {
          title: {
            text: "Line Chart",
            display: false
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              gridLines: {
                drawBorder: true,
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                display: false,
              },
              gridLines: {
                drawBorder: true,
                display: false
              }
            }]
          }
        }
      })
    });

  }

  somethingChanged(data) {
    //console.log(data.length)
    if (data.length <= 0) {
      this.mainSec = false;
    } else {
      this.mainSec = true;
    }
  }


  onClickLogout() {
    const accessId = {
      accessId: localStorage.getItem('UserInfo'),
    }
    this.authService.userLogout(accessId).subscribe((data) => {
      //console.log(data);
      this.router.navigate(['/']);
      this._compiler.clearCache();
      localStorage.clear();
      setTimeout(() => {
        location.reload();
      }, 100);
    });
  }

  onClickVideoZoom(data) {
    //console.log(data)
    this.selectVidForZoom = data.cameraDeviceId;
    this.selectClass = data;
    setTimeout(() => {
      this.videoElement = document.getElementById('player');
      //console.log(this.videoElement);
      
      const player = new WowzaWebRTCPlayer(this.videoElement, {
        sdpUrl: 'wss://638ee05658d80.streamlock.net/webrtc-session.json',
        applicationName: 'live',
        streamName: data.cameraId,
      });
      player.playRemote();
    }, 1000);
  }

  onClickVidZomClo() {
    this.selectVidForZoom = undefined;
  }

  onClickHistory() {
    this.loader = true;
    this.notAssgin = 'hide';

    this.selectSec = 'history';
    if (this.changeDate === undefined) {
      this.date = moment().format('YYYY-MM-DD');
    } else {
      this.date = this.changeDate;
    }
    //console.log(this.selectSdcAccessId);
    this.centerService.getAllImageByCenter(this.seleSdcAccessId, this.date).subscribe((image: any) => {
      //console.log(image);
      this.loader = false;
      this.dataImages = image.imageDetails;
      this.histType = 'image';
    });
  }



  onClickAttendance() {
    this.loader = true;
    this.notAssgin = 'hide';
    this.selectSec = 'attendance';
    if (this.changeDate === undefined) {
      this.date = moment().format('YYYY-MM-DD');
    } else {
      this.date = this.changeDate;
    }
    //console.log(this.selectSdcAccessId);
    this.centerService.attendanceByToday(this.seleSdcAccessId, this.date).subscribe((resourceData: any) => {
      this.allAttendance = resourceData.resourceDetails.sort((a, b) => a.resourceName > b.resourceName ? 1 : -1);
      this.loader = false;
    });
  }

  onChangeAtten(value) {
    this.date = (this.datePipe.transform(value._d, 'yyyy-MM-dd'));
    this.changeDate = (this.datePipe.transform(value._d, 'yyyy-MM-dd'));
    this.now = moment(); // add this 2 of 4
    this.dateToday = this.now.format('YYYY-MM-DD');

    this.centerService.attendanceByToday(this.seleSdcAccessId, this.date).subscribe((resourceData: any) => {
      //console.log(resourceData);

      this.allAttendance = resourceData.resourceDetails.sort((a, b) => a.resourceName > b.resourceName ? 1 : -1);
      this.loader = false;
    });

  }
  somethingChangedAtten(data) {
    this.testing = true;
    if (data.length > 2) {
      this.inputData = data
    } else {
    }
  }

  onClickImage() {
    this.loader = true;
    if (this.changeDate === undefined) {
      this.date = moment().format('YYYY-MM-DD');
    } else {
      this.date = this.changeDate;
    }
    this.centerService.getAllImageByCenter(this.seleSdcAccessId, this.date).subscribe((image: any) => {
      //console.log(image);
      this.loader = false;
      this.dataImages = image.imageDetails;
      this.histType = 'image';
    });
  }

  onClickVideo() {
    this.loader = true;
    if (this.changeDate === undefined) {
      this.date = moment().format('YYYY-MM-DD');
    } else {
      this.date = this.changeDate;
    }
    this.centerService.getAllVideoByCenter(this.seleSdcAccessId, this.date).subscribe((video: any) => {
      //console.log(video);
      this.loader = false;
      this.dataVideos = video.videoDetails;
      this.histType = 'video';
    });
  }


  /* ===============================================================
        Date basic Image and video Fetch
    =============================================================== */
  onChange(value) {
    this.loader = true;
    this.date = (this.datePipe.transform(value._d, 'yyyy-MM-dd'));
    this.changeDate = this.date;
    //console.log(this.date);
    if (this.histType === 'image') {
      this.centerService.getAllImageByCenter(this.seleSdcAccessId, this.date).subscribe((image: any) => {
        //console.log(image);
        this.loader = false;
        this.dataImages = image.imageDetails;
        this.histType = 'image';
      });
    } else if (this.histType === 'video') {
      this.centerService.getAllVideoByCenter(this.seleSdcAccessId, this.date).subscribe((video: any) => {
        //console.log(video);
        this.loader = false;
        this.dataVideos = video.videoDetails;
        this.histType = 'video';
      });
    } else { }

  }

  /* ===============================================================
      Gallery
  =============================================================== */
  /* Image */
  currentSlide(data) {
    this.slideCount = data;
    this.myModal = 'show';
    this.imageLength = this.dataImages.length;
    if (this.slideCount == '1') {
      this.prevHide = 'hide';
    } else {
      this.prevHide = 'show';
    }
    if (this.imageLength == this.slideCount) {
      this.nextHide = 'hide';
    } else {
      this.nextHide = 'show';
    }
  }
  closeModal() {
    this.myModal = 'hide';
    this.slideCount = null;
  }
  prev() {
    this.slideCount--;
    if (this.slideCount == '1') {
      this.prevHide = 'hide';
    } else {
      this.prevHide = 'show';
    }
    if (this.imageLength == this.slideCount) {
      this.nextHide = 'hide';
    } else {
      this.nextHide = 'show';
    }
  }
  next() {
    this.slideCount++;
    if (this.slideCount == '1') {
      this.prevHide = 'hide';
    } else {
      this.prevHide = 'show';
    }
    if (this.imageLength == this.slideCount) {
      this.nextHide = 'hide';
    } else {
      this.nextHide = 'show';
    }
  }

  /* Video */
  openVidModal(data) {
    this.slideVidCount = data;
    this.videoLength = this.dataVideos.length;
    this.myVidModal = 'show';
    if (this.slideVidCount == '1') {
      this.prevVidHide = 'hide';
    } else {
      this.prevVidHide = 'show';
    }
    if (this.videoLength == this.slideVidCount) {
      this.nextVidHide = 'hide';
    } else {
      this.nextVidHide = 'show';
    }
  }
  closeVidModal() {
    this.myVidModal = 'hide';
    this.slideVidCount = null;
  }
  prevVid() {
    this.slideVidCount--;
    if (this.slideVidCount == '1') {
      this.prevVidHide = 'hide';
    } else {
      this.prevVidHide = 'show';
    }
    if (this.videoLength == this.slideVidCount) {
      this.nextVidHide = 'hide';
    } else {
      this.nextVidHide = 'show';
    }
  }
  nextVid() {
    this.slideVidCount++;
    if (this.slideVidCount == '1') {
      this.prevVidHide = 'hide';
    } else {
      this.prevVidHide = 'show';
    }
    if (this.videoLength == this.slideVidCount) {
      this.nextVidHide = 'hide';
    } else {
      this.nextVidHide = 'show';
    }
  }


  /* ===============================================================
    Image Capture
  =============================================================== */
  captureImage() {
    this.disableBtn = 'click-disable';
    this.datetime = moment().format("YYYY-MM-DD HH:mm:s");
    const user = {
      user: "RSLDC",
      streamName: this.selectClass.cameraId,
      centerAccessId: this.selectClass.centerAccessId,
      classAccessId: this.selectClass.classAccessId,
      capturedDate: moment().format("YYYY-MM-DD"),
      capturedTime: moment().format("HH:mm"),
      createdBy: this.username,
      createdAt: this.datetime
    }
    //console.log(user);
    this.classService.imageCapture(user).subscribe((data: any) => {
      //console.log(data);
      this.capturedImageUrl = data.imagePath;
      this.captureImageSec = true;
      setTimeout(() => {
        this.disableBtn = '';
      }, 1000);
      setTimeout(() => {
        this.captureImageSec = false;
      }, 3500);
    });
  }

  /* ===============================================================
  Video Recording Start
  =============================================================== */
  recordingStart() {
    const user = {
      user: "RSLDC",
      streamName: this.selectClass.cameraId,
    }
    this.classService.videoRecStart(user).subscribe((data: any) => {
      //console.log(data);
      if (data.success == 1) {
        this.startRecBtn = false;
        this.stopRecBtn = true;
        this.toastr.info('The maximum length of the video to record is 1 minute', 'Recording started');
        this.autoStopVidRec();
      } else { }
    });
  }

  /* ===============================================================
    Video Recording Stop
  =============================================================== */
  recordingStop() {
    this.datetime = moment().format("YYYY-MM-DD HH:mm:s");
    const user = {
      user: "RSLDC",
      streamName: this.selectClass.cameraId,
      centerAccessId: this.selectClass.centerAccessId,
      classAccessId: this.selectClass.classAccessId,
      recordedDate: moment().format("YYYY-MM-DD"),
      recordedTime: moment().format("HH:mm"),
      createdBy: this.username,
      createdAt: this.datetime
    }
    this.classService.videoRecStop(user).subscribe((data: any) => {
      //console.log(data);
      if (data.success == 1) {
        this.startRecBtn = true;
        this.stopRecBtn = false;
        this.toastr.info('You can check the recorded video in the history', 'Recording stopped');
        clearInterval(this.intervel);
      } else { }
    });
  }

  /* ===============================================================
    Video Auto Stop
  =============================================================== */
  autoStopVidRec() {
    this.intervel = setInterval(() => {
      this.recordingStop();
      this.startRecBtn = true;
      this.stopRecBtn = false;
    }, 60000)
  }

  /* ===============================================================
    System Check
  =============================================================== */
  onClickSystemCheck() {
    this.ccService.systemCheck().subscribe((data: any) => {
      if (data.success === 1) {
        //console.log(data);
        this.systemCheck = true;
        this.systemCheckTime = moment();
      }
    });
  }

  onClickSysChkClo() {
    this.systemCheck = false;
  }

  onClickReport() {
    this.router.navigate(['report']);
  }

  onClickResReport() {

    localStorage.setItem('resType', 'all')
    //console.log('ddd')
  }

  onClickStuReport() {

    localStorage.setItem('resType', 'student')

    //console.log('ddd')
  }

  onClickTeaReport() {

    localStorage.setItem('resType', 'teacher')
    //console.log('ddd')

  }

  onClickStaffReport() {

    localStorage.setItem('resType', 'staff')
    //console.log('ddd')

  }

  onClickSdcReport() {
    this.router.navigate(['/allSdcDetail']);
  }


  onClickMailSec() {
    this.mailSec = true;
    this.mailSecScreen = false;
    this.toMailId.push('rsldclive4@gmail.com', this.selectSdc.email, this.tpEmailId);

    setTimeout(() => {
      //console.log(this.toMailId)
      this.elementRef.nativeElement.focus();
    }, 500);
  }

  onClickScreenShot(useWorkaround = true) {
    this.mailSec = false;
    this.toMailId.push('rsldclive4@gmail.com', this.selectSdc.email, this.tpEmailId)
    //console.log(this.toMailId)

    setTimeout(() => {
      this.finalCameraList = [];
      this.mailSecScreen = true;
    }, 3000);

    // html2canvas(document.getElementById('cap-screenshot')).then((canvas:any)=> {
    //   document.body.appendChild(canvas);
    //   // Get base64URL
    //   var base64URL = canvas.toDataURL('image/jpeg');
    //   //console.log(base64URL);

    //   this.testFunct(base64URL);
    // });
    if (useWorkaround) {
      this.captureVideos();
    }


    //const imageName = moment().unix() + '.png';
    //const imageBlob = this.dataURItoBlob(this.imgResultAfterCompression);
    //const file = new File([imageBlob], imageName, { type: 'image/png' });


    html2canvas(document.getElementById("cap-screenshot")).then(canvas => {
      //var ttttttt = canvas.toDataURL();

      var blob = this.dataURItoBlob(canvas.toDataURL());
      const imageName = moment().unix() + '.png';

      const file = new File([blob], imageName, { type: 'image/png' });

      console.log(blob);

      const bucket = new S3(
        {
          accessKeyId: 'AKIA5XW7ZYGEQ4PIHKLF',
          secretAccessKey: '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',
          region: 'us-east-1'
        }
      );
      const params = {
        Bucket: 'optagersldc',
        Key: this.FOLDER + '/' + imageName,
        Body: file,
        ACL: 'public-read',
        ContentType: 'png'
      };
  
      console.log(file);
      bucket.upload(params, function (this: any, err: any, data: any) {
        if (err) {
          console.log('There was an error uploading your file: ', err);
          return false;
        }
        console.log('Successfully uploaded file.', data);
        this.screenShotImg = data.Location.toString();
        console.log(this.screenShotImg)
        this.associatedFiles= [this.screenShotImg];
        console.log(this.associatedFiles.length);
        
        this.captureImageSecScreenShot = true;
        setTimeout(() => {
          this.captureImageSecScreenShot = false;
        }, 2000);

        return false;
      });


      //this.setImageName = moment().format('YYYYMMDDHHmmss') + '.jpg';

      // this.blobService.uploadImage(this.sas, blob, this.setImageName, () => {
      //   console.log('ffff');
      //   this.screenShotImg = 'https://doptagestore.blob.core.windows.net/optage-screenshot/' + this.setImageName;
      //   //console.log(this.screenShotImg)
      //   this.associatedFiles.push(this.screenShotImg);
      //   this.captureImageSecScreenShot = true;
      //   setTimeout(() => {
      //     this.captureImageSecScreenShot = false;
      //   }, 2000);

      // });
      // const sendImage = {
      //   base64str: ttttttt.split(',')[1],
      //   imageName: this.setImageName
      // }
      // console.log(sendImage);
      // this.http.post('http://52.183.128.125:7777/upload/uploadScreenShot/', sendImage).subscribe((files: any) => {
      //   //console.log(files)
      //   if (files.success === 1) {
      //     this.screenShotImg = 'http://optage.blob.core.windows.net/optage-screenshot/' + this.setImageName;
      //     //console.log(this.screenShotImg)
      //     this.associatedFiles.push(this.screenShotImg);
      //     this.captureImageSecScreenShot = true;
      //     setTimeout(() => {
      //       this.captureImageSecScreenShot = false;
      //     }, 2000);
      //   } else { }
      // });
    });
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    const byteString = atob(dataURI.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  captureVideos() {
    let canvas: any = document.getElementById("canvas"); // declare a canvas element in your html
    let ctx = canvas.getContext("2d");
    let videos = document.querySelectorAll("video");
    let w, h;

    for (let i = 0, len = videos.length; i < len; i++) {
      const v = videos[i];
      if (!v.src) continue; // no video here
      try {
        w = v.videoWidth;
        h = v.videoHeight;
        canvas.width = w;
        canvas.height = h;
        ctx.fillRect(0, 0, w, h);
        ctx.drawImage(v, 0, 0, w, h);
        const a = canvas.toDataURL();
        v.style.backgroundImage = `url(${a})`;
        v.style.backgroundSize = "cover";
        ctx.clearRect(0, 0, w, h); // clean the canvas
        //console.log(a);
      } catch (e) {
        //console.log(e);
        continue;
      }
    }
  }

  // testFunct(data){
  //   //console.log(data);
  //   this.setImageName = moment().format('YYYYMMDDHHmmss')+'.jpg';
  //   const sendImage = {
  //     base64str: data.split(',')[1],
  //     imageName: this.setImageName
  //   }
  //   //console.log(data);
  //   this.http.post('http://52.183.128.125:7777/upload/uploadScreenShot/', sendImage).subscribe((files: any) => {
  //     //console.log(files)
  //     if(files.success === 1){
  //       this.screenShotImg = 'http://optage.blob.core.windows.net/optage-screenshot/'+ this.setImageName;
  //       //console.log(this.screenShotImg)
  //     }else{}
  //   });
  // }

  onClickMailSecClo() {
    this.mailSec = false;
    this.mailSecScreen = false;
    this.toMailId = [];
    this.clearMailId = [];
    this.mailRightSec = false;
    this.emailFiled = '';
    this.fileData = [];
    this.associatedFiles = [];
    this.form.reset();
    this.attchVideo = 0;
    this.attchImage = 0;
    this.finalCameraList = this.cameraList;
    if (this.fileFormArray != undefined) {
      while (this.fileFormArray.length !== 0) {
        this.fileFormArray.removeAt(0)
      }
    } else { }
  }

  onEnter(value: string) {
    //console.log(value);
    var str_array = value.split(/[ ,;]+/);
    for (var i = 0; i < str_array.length; i++) {
      str_array[i] = str_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
      //console.log((str_array[i]));
      this.toMailId.push((str_array[i]))
    }
    setTimeout(() => {
      //console.log(this.toMailId)
      this.emailFiled = '';
      this.elementRef.nativeElement.focus();
    }, 100);
  }

  onSpace(value: string) {
    if (value.length > 0) {
      var str_array = value.split(/[ ,;]+/);
      for (var i = 0; i < str_array.length; i++) {
        str_array[i] = str_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
        //console.log((str_array[i]));
        this.toMailId.push((str_array[i]))
      }
      setTimeout(() => {
        //console.log(this.toMailId)
        this.emailFiled = '';
        this.elementRef.nativeElement.focus();
      }, 100);
    } else { }
  }

  focusOutFunction(value: string) {
    //console.log(value.length);
    if (value.length > 0) {
      var str_array = value.split(/[ ,;]+/);
      for (var i = 0; i < str_array.length; i++) {
        str_array[i] = str_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
        //console.log((str_array[i]));
        this.toMailId.push((str_array[i]))
      }
      setTimeout(() => {
        //console.log(this.toMailId)
        this.emailFiled = '';
        this.elementRef.nativeElement.focus();
      }, 100);
    } else {
    }

  }

  clrMailId(email) {
    this.removeMailId = email;
    this.elementRef.nativeElement.focus();
    setTimeout(() => {
      var index = this.toMailId.indexOf(email);
      if (index > -1) {
        this.toMailId.splice(index, 1);
      };
      if (email == this.tpEmailId || email === this.selectSdc.email) {
        this.clearMailId.push(email);

        setTimeout(() => {
          //console.log(this.clearMailId);
        }, 100);
      }
      this.removeMailId = undefined;
    }, 600);

  }

  addStaticMail(email) {
    this.toMailId.push(email);
    var index = this.clearMailId.indexOf(email);
    this.elementRef.nativeElement.focus();
    if (index > -1) {
      this.clearMailId.splice(index, 1);
    };
  }

  // onKeydown(event){
  //   // console.log('fffff');
  //   // var getData = document.getElementById('txt');
  //   // getData.innerHTML+'<br>'    

  //   if (event.which == 12) {
  //     console.log(event);
  //     event.preventDefault();
  //     var space = event.val();
  //     event.val(space+"<br>");

  //   }
  // }

  /* ===============================================================
        Validation
  =============================================================== */
  createForm() {
    this.form = this.formBuilder.group({
      subject: [null, [Validators.required, Validators.pattern("^.{3,250}$")]],
      story: [null, [Validators.required]],
    });
  }

  onSelectFile() {
    // console.log(this.form.get('story').value)
    this.mailRightSec = true;
    this.centerService.getAllImageByCenter(this.seleSdcAccessId, moment().format('YYYY-MM-DD')).subscribe((image: any) => {
      //console.log(image);
      this.loader = false;
      this.dataImages = image.imageDetails;
      this.histType = 'image';
    });
    this.centerService.getAllVideoByCenter(this.seleSdcAccessId, moment().format('YYYY-MM-DD')).subscribe((video: any) => {
      //console.log(video);
      this.dataVideos = video.videoDetails;
    });
  }

  onClickImageMail() {
    this.histType = 'image';
  }

  onClickVideoMail() {
    this.histType = 'video';
  }

  /* ===============================================================
      Validation
  =============================================================== */
  addFileForm() {
    this.fileForm = this.formBuilder.group({
      userFile: this.formBuilder.array([], Validators.required)
    });
  }

  /* ===============================================================
      Select Image and video file
  =============================================================== */
  addFile(file: any, isChecked: boolean) {
    this.fileFormArray = <FormArray>this.fileForm.controls.userFile;
    //console.log(this.fileFormArray.length);
    if (file.fileType === '1') {
      //console.log('image')
      if (isChecked) {
        this.fileDetails = file.imagePath;
        this.fileFormArray.push(new FormControl(file.imagePath));
        this.fileData = this.fileFormArray.value;
        this.associatedFiles = this.fileData.map(item => item);
        this.attchImage++;
        //console.log(this.associatedFiles);
        if (this.associatedFiles.length === 5) {
          this.toastr.info('You have 5 files attached', 'Limit reached')
        } else { }
      } else {
        this.fileDetails = file.imagePath;
        let index = this.fileFormArray.controls.findIndex(x => x.value == file.imagePath)
        this.fileFormArray.removeAt(index);
        this.fileData = this.fileFormArray.value;
        this.associatedFiles = this.fileData.map(item => item);
        this.attchImage--;
        //console.log(this.associatedFiles);
        if (this.associatedFiles.length === 5) {
          this.toastr.info('You have 5 files attached', 'Limit reached')
        } else { }
      }
    } else if (file.fileType === '2') {
      //console.log('video')
      if (isChecked) {
        this.fileDetails = file.videoPath;
        this.fileFormArray.push(new FormControl(file.videoPath));
        this.fileData = this.fileFormArray.value;
        this.associatedFiles = this.fileData.map(item => item);
        this.attchVideo++;
        //console.log(this.associatedFiles);
        if (this.associatedFiles.length === 5) {
          this.toastr.info('You have 5 files attached', 'Limit reached')
        } else { }
      } else {
        this.fileDetails = file.videoPath;
        let index = this.fileFormArray.controls.findIndex(x => x.value == file.videoPath)
        this.fileFormArray.removeAt(index);
        this.fileData = this.fileFormArray.value;
        this.associatedFiles = this.fileData.map(item => item);
        this.attchVideo--;
        //console.log(this.associatedFiles);
        if (this.associatedFiles.length === 5) {
          this.toastr.info('You have 5 files attached', 'Limit reached')
        } else { }
      }
    } else { }
  }

  onChangeMail(value) {
    //console.log(this.fileFormArray)
    this.date = (this.datePipe.transform(value._d, 'yyyy-MM-dd'));
    this.changeDate = this.date;
    //console.log(this.associatedFiles.length);
    if (this.associatedFiles.length === 0) {
      this.associatedFiles = [];
      this.attchVideo = 0;
      this.attchImage = 0;
      if (this.fileFormArray != undefined) {
        while (this.fileFormArray.length !== 0) {
          this.fileFormArray.removeAt(0)
        }
      } else { }
      this.alertBox = 'hide';
      this.centerService.getAllImageByCenter(this.seleSdcAccessId, this.date).subscribe((image: any) => {
        this.dataImages = image.imageDetails;
      });
      this.centerService.getAllVideoByCenter(this.seleSdcAccessId, this.date).subscribe((video: any) => {
        //console.log(video);
        this.dataVideos = video.videoDetails;
      });
    } else {
      this.alertBox = 'show';
    }
  }

  conformDateChange() {
    this.associatedFiles = [];
    this.attchVideo = 0;
    this.attchImage = 0;
    if (this.fileFormArray != undefined) {
      while (this.fileFormArray.length !== 0) {
        this.fileFormArray.removeAt(0)
      }
    } else { }
    this.alertBox = 'hide';
    this.centerService.getAllImageByCenter(this.seleSdcAccessId, this.date).subscribe((image: any) => {
      this.dataImages = image.imageDetails;
    });
    this.centerService.getAllVideoByCenter(this.seleSdcAccessId, this.date).subscribe((video: any) => {
      //console.log(video);
      this.dataVideos = video.videoDetails;
    });
  }

  cancelDateChangej() {
    this.date = undefined;
    this.changeDate = undefined;
    this.alertBox = 'hide';
  }

  /* ===============================================================
    Final send mail function
  =============================================================== */
  onClickMailSend() {
    const allData = {
      sendTo: this.toMailId,
      attachFiles: this.associatedFiles,
      emailSubject: (this.form.get('subject').value),
      emailText: (this.form.get('story').value),
    }
    //console.log(allData);
    this.centerService.sendMail(allData).subscribe((mail: any) => {
      //console.log(mail);
      this.onClickMailSecClo();
      this.toastr.success('Successfully sent email with attachment', 'Email sent');
    });
  }

  graphLoad() {
    this.adminService.getADSideCount(moment().format('YYYY-MM-DD')).subscribe((data: any) => {
      console.log(data);
      this.sideCount = data;
      //console.log(this.sideCount.allRegisteredStudent);
      this.LineChart = new Chart('mainchart2', {
        type: 'bar',
        data: {
          labels: ["Resources", "Student", "Teacher", "Staff"],
          datasets: [{
            label: '',
            data: [this.sideCount.allRegisteredStudent + this.sideCount.allRegisteredTeacher + this.sideCount.allRegisteredEmp,
            this.sideCount.allRegisteredStudent, this.sideCount.allRegisteredTeacher, this.sideCount.allRegisteredEmp],
            barThickness: 80,
            backgroundColor: [
              'rgba(52, 73, 94,0.6)',
              'rgba(52, 152, 219,0.6)',
              'rgba(230, 126, 34,0.6)',
              'rgba(155, 89, 182,0.6)',
            ],
          }]
        },
        options: {
          responsive: true,
          title: {
            text: "Line Chart",
            display: false
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 10,
                display: false,
              },
              gridLines: {
                drawBorder: true,
                display: false
              }
            }],
            xAxes: [{
              gridLines: {
                drawBorder: true,
                display: false,
              }
            }]
          },
          plugins: {
            datalabels: {
              anchor: 'end',
              align: 'end',
            }
          }
        }
      })

      this.LineChart = new Chart('studentChart2', {
        type: 'horizontalBar',
        data: {
          labels: ["Present", "Absent"],
          datasets: [{
            label: '',
            data: [this.sideCount.attendanceCountStudent, this.sideCount.allRegisteredStudent - this.sideCount.attendanceCountStudent],
            barThickness: 30,
            backgroundColor: [
              'rgba(52, 152, 219,0.9)',
              'rgba(52, 152, 219,0.6)',
              'rgba(52, 152, 219,0.3)',
            ],
          }]
        },
        options: {
          tooltips: {
            position: 'average',
            axis: 'x',
          },
          title: {
            text: "Line Chart",
            display: false
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              gridLines: {
                drawBorder: true,
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 10,
                display: false,
              },
              gridLines: {
                drawBorder: true,
                display: false,
              }
            }]
          }
        }
      })
      this.LineChart = new Chart('teacherChart2', {
        type: 'horizontalBar',
        data: {
          labels: ["Present", "Absent"],
          datasets: [{
            label: '',
            data: [this.sideCount.attendanceCountTeacher, this.sideCount.allRegisteredTeacher - this.sideCount.attendanceCountTeacher],
            barThickness: 30,
            backgroundColor: [
              'rgba(230, 126, 34,0.9)',
              'rgba(230, 126, 34,0.6)',
              'rgba(230, 126, 34,0.3)',
            ],
          }]
        },
        options: {
          title: {
            text: "Line Chart",
            display: false
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 10,
              },
              gridLines: {
                drawBorder: true,
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 10,
                display: false,
              },
              gridLines: {
                drawBorder: true,
                display: false
              }
            }]
          }
        }
      })
      this.LineChart = new Chart('staffChart2', {
        type: 'horizontalBar',
        data: {
          labels: ["Present", "Absent"],
          datasets: [{
            label: '',
            data: [this.sideCount.attendanceCountEmp, this.sideCount.allRegisteredEmp - this.sideCount.attendanceCountEmp],
            barThickness: 30,
            backgroundColor: [
              'rgba(155, 89, 182,0.9)',
              'rgba(155, 89, 182,0.6)',
              'rgba(155, 89, 182,0.3)',
            ],
          }]
        },
        options: {
          title: {
            text: "Line Chart",
            display: false
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              gridLines: {
                drawBorder: true,
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                display: false,
              },
              gridLines: {
                drawBorder: true,
                display: false
              }
            }]
          }
        }
      })
    })
  }

  /* ===============================================================
      On Click Audit
  =============================================================== */
  onClickAudit(data) {
    //console.log(data)
    if (data.cameraSpeed > 30) {
      //console.log('fff')
      const onPrams = {
        cameraDeviceId: data.cameraDeviceId,
        cameraId: data.cameraId,
        channelId: '11'
      }
      this.classService.onCameraForAudit(onPrams).subscribe((onCam: any) => {
        //console.log(onCam);
        localStorage.setItem('ClassAccessId', data.classAccessId);
        localStorage.setItem('CameraUrl', data.streamUrl);
        this.router.navigate(['audit']);
        // setTimeout(() => {
        //   location.reload();
        // }, 1000);
      });

    } else {//console.log('aaaa');

    }
  }

  onClickPiaList() {
    this.router.navigate(['/allPiaList']);
    //this.router.navigate([]).then(result => {  window.open('/allResourcesList'); });
    // this.router.navigate { window.open ('/allPiaList', '_blank'); };
  }

  onClickSdcList() {
    this.router.navigate(['/allSdcList']);
  }

  onClickCameraList() {
    this.router.navigate(['/allCameraList']);
  }

  /* ===============================================================
      JavaScript Load 
  =============================================================== */
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/js/cCSearch.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }


  ngOnInit() {
    this.getAllSDCList();
    this.graphLoad();
    this.loadScript();
    // localStorage.removeItem('selectSdcAccessID')
    // localStorage.removeItem('searchKey')
    // localStorage.removeItem('totalCamList')
    //console.log(localStorage.getItem('selectSdcAccessID'));
    //console.log(localStorage.getItem('searchKey'));
    //console.log(JSON.parse(localStorage.getItem('totalCamList')));
    if (localStorage.getItem('selectSdcAccessID') != null) {
      //console.log('ddddd')
      this.sdcListHide = 'hide';
      this.getSelectSdcAccessId = localStorage.getItem('selectSdcAccessID');
      this.seleSdcAccessId = localStorage.getItem('selectSdcAccessID');
      this.selectSdc = JSON.parse(localStorage.getItem('sdcdetails'));
      //console.log(this.selectSdc)
      this.piaService.getPia(this.selectSdc.tpAccessId).subscribe((tpDetail: any) => {
        this.tpEmailId = tpDetail.tpDetails.email;
      });
      this.sdcListSec = true;
      this.streamDashSec = false;
      this.selectSec = 'liveStm';
      this.onClickSdc();
      this.loader = true;
      this.ccService.getAllCameraBySdc(this.getSelectSdcAccessId).subscribe((cam: any) => {
        this.cameraList = cam.cameraDetails;
        console.log(this.cameraList.length);
        if (this.cameraList.length > 0) {
          this.onStep = 'first';
          this.notAssgin = 'hide';
          for (let b = 0; b < this.cameraList.length; b++) {
            setTimeout(() => {
              const camAvailData = {
                cameraDeviceId: this.cameraList[b].cameraDeviceId
              }
              this.classService.cameraAvailabililty(camAvailData).subscribe((camAvail: any) => {
                //console.log(camAvail)
                if (camAvail.success === 1) {
                  const camSpeedData = {
                    cameraDeviceId: this.cameraList[b].cameraDeviceId
                  }
                  //console.log(camSpeedData)
                  this.centerService.camSpeed(camSpeedData).subscribe((camSpe: any) => {
                    //console.log(camSpe);
                  });
                }
              });
              if (b + 1 === this.cameraList.length) {
                setTimeout(() => {
                  //console.log('same');
                  this.onStep = 'second';
                  this.ccService.getAllCameraBySdc(this.seleSdcAccessId).subscribe((cam: any) => {
                    this.cameraList = cam.cameraDetails;
                    //console.log(this.cameraList);
                    for (let c = 0; c < this.cameraList.length; c++) {
                      setTimeout(() => {
                        //console.log(this.cameraList[x].isCameraActive)
                        if (this.cameraList[c].isCameraActive === '1') {
                          const onPrams = {
                            cameraDeviceId: this.cameraList[c].cameraDeviceId,
                            cameraId: this.cameraList[c].cameraId,
                            channelId: '12'
                          }
                          //console.log(onPrams);
                          this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                            //console.log(onCam);
                          });
                        } else { }
                        if (c + 1 === this.cameraList.length) {
                          //console.log('show Stream list');
                          this.onStep = 'third';
                          this.streamDetailsLoader = 'show';
                          localStorage.setItem('totalCamList', JSON.stringify(this.cameraList));
                          setTimeout(() => {
                            this.loader = false;
                            this.finalCameraList = this.cameraList;
                            this.finalCameraList.forEach((ele:any, i:any) =>{
                              setTimeout(() => {
                                this.videoElement = document.getElementById(i);
                                const player = new WowzaWebRTCPlayer(this.videoElement, {
                                  sdpUrl: 'wss://638ee05658d80.streamlock.net/webrtc-session.json',
                                  applicationName: 'live',
                                  streamName: ele.cameraId,
                                });
                                player.playRemote();                                
                              }, 2000);
                            });
                            setTimeout(() => {
                              this.streamDetailsLoader = 'hide';
                            }, 6666);
                          }, 2000);
                        } else { }
                      }, 1000 * (c + 1));
                    }
                  });
                }, 2000);
              }
            }, 1000 * (b + 1));
          }
        } else {
          console.log('no camera');
          this.loader = false;
          this.notAssgin = 'show';

        }
      })
      this.mainSec = false;
    } else {
      localStorage.removeItem('selectSdcAccessID')
      localStorage.removeItem('totalCamList')
    }
  }


}
