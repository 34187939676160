import { Component, OnInit, Compiler } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { AuthService } from 'src/app/services/authService/auth.service';
import { AdminServiceService } from 'src/app/services/adminService/admin-service.service';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { PiaServiceService } from 'src/app/services/piaService/pia-service.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-resource',
  templateUrl: './add-resource.component.html',
  styleUrls: ['./add-resource.component.css']
})
export class AddResourceComponent implements OnInit {

  form;
  editSec: boolean;
  schemes;
  allDist
  allTP;
  allSdc;
  allCourse;
  allBatch;
  allResouces;
  resCheckSec:boolean;
  mainForm:boolean;
  search;
  username;
  roleId;

  schemeSec = '';
  schemeData;
  tpSec = '';
  tpData;
  sdcSec = ''
  sdcData;
  courseSec = '';
  courseData;
  batchSec = '';
  batchData;

  resAccessId;
  getRsldcId;

  schemeAccessId;
  schemeCode;

  getReligion = 'N/A';
  getCategory = 'N/A';
  getQualification = 'N/A';
  getSectors = 'N/A';
  getPhysicalDisa = 'N/A';
  getNativeDistrict = 'N/A';


  fatherName;
  motherName;
  aadhaarNumber
  mobile;
  dateOfBirth;
  age;
  village;
  block;
  municipality;
  tehsildar;
  pinCode;
  constructor(
    private router: Router,
    private _compiler: Compiler,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthService,
    private adminService: AdminServiceService,
    private piaService: PiaServiceService,
    private centerService: CenterDetailsService
  ) {
    this.createForm();
  }

  /* ===============================================================
      Validation
  =============================================================== */
  createForm() {
    this.form = this.formBuilder.group({
      resType: [null, [Validators.required]],
      rsldcId: [null, [Validators.required, Validators.pattern("^.{3,20}$")]],
      aspirantId: [null, [Validators.required, Validators.pattern("^.{3,20}$")]],
      gender: [null, [Validators.required]],
      resourceName: [null, [Validators.required, Validators.pattern("^.{2,40}$")]],

      fatherName: [null],
      motherName: [null],
      mobile: [null],
      age: [null],
      dob: [null],
      aadhaarNumber: [null],
      religion: [null, [Validators.required]],
      category: [null, [Validators.required]],
      education: [null, [Validators.required]],
      physicalDisa: [null, [Validators.required]],
      village: [null],
      block: [null],
      dist: [null, [Validators.required]],
      municipality: [null],
      tehsildar: [null],
      pinCode: [null],
    });
  }


  onClickScheme() {
    this.adminService.getSchemeWithTrial().subscribe((schemeList: any) => {
      this.schemes = schemeList.schemeDetails;
      //console.log(this.schemes);
      this.schemeSec = 'choose-list-active';
    });
  }

  onSelectScheme(scm) {
    this.schemeData = scm;
    //console.log(this.schemeData);
    this.schemeSec = '';
    this.mainForm = false;
    this.tpData = undefined;
    this.sdcData = undefined;
    this.courseData = undefined;
    this.batchData = undefined;
  }

  onClickTp() {
    this.piaService.getAllPia(this.schemeData.accessId).subscribe((piaList: any) => {
      //console.log(piaList);
      this.allTP = piaList.tpDetails.sort((a, b) => a.tpName > b.tpName ? 1 : -1);
      //console.log(this.allTP)
      this.tpSec = 'choose-list-active';
    });
  }

  onSelectTp(tp) {
    this.tpData = tp;
    this.tpSec = '';
    this.mainForm = false;
    this.sdcData = undefined;
    this.courseData = undefined;
    this.batchData = undefined;
  }

  onClickSdc() {
    this.centerService.getAllCenter(this.tpData.accessId).subscribe((centerList: any) => {
      this.allSdc = centerList.centerDetails;
      //console.log(this.allSdc);
      this.sdcSec = 'choose-list-active';
    });
  }

  onSelectSdc(sdc){
    this.sdcData = sdc;
    //console.log(this.sdcData);
    this.allCourse = sdc.associatedCourseInfo;
    this.sdcSec = '';
    this.mainForm = false;
    this.courseData = undefined;
    this.batchData = undefined;
  }

  onClickCourse(){
    // this.centerService.allCourse(this.sdcData.accessId).subscribe((course: any) => {
    //   this.allCourse = course.courseDetails;
    //   //console.log(this.allCourse);
    //   this.courseSec = 'choose-list-active';
    // });
    this.courseSec = 'choose-list-active';
  }

  onSelectCourse(course) {
    this.courseData = course;
    this.courseSec = '';
    this.mainForm = false;
    //console.log(this.courseData);
    this.batchData = undefined;
  }

  onClickBatch(){   
    this.centerService.getAllBatch(this.sdcData.accessId, this.courseData.courseAccessId).subscribe((allBatch: any) => {
      this.allBatch = allBatch.batchDetails;
      this.batchSec = 'choose-list-active';
      //console.log(this.allBatch);
    });
  }

  onSelectBatch(batch) {
    this.batchData = batch;
    //console.log(this.batchData);
    
    //this.mainForm = false;
    this.batchSec = '';
  }
  /* ===============================================================
      Select Details
  =============================================================== */
  onClickSec() {
    this.editSec = true;
  }

  somethingChanged(data) {
    if (data.length > 2) {
      this.resCheckSec = true;
      this.getRsldcId = data;
    }else{
      this.resCheckSec = true;
    }
  }

  
  /* Check Resources available or not */
  onClickResourcesAvail() {  
    //console.log(this.sdcData);
    this.centerService.resAvailable(this.sdcData.accessId, this.getRsldcId).subscribe((resAvail: any) => {
      // console.log(resAvail)
      if(resAvail.success === 0){
        this.mainForm =  true;
        //res not found
      }else if(resAvail.success === 1){
        //res found
        this.toastr.info('Resource alredy register');
      }else{}
    });
  }

  backScm(){
    this.schemeSec = '';
  }
  backTp(){
    this.tpSec = '';
  }
  backSdc(){
    this.sdcSec = '';
  }

  backCourse(){
    this.courseSec = '';
  }
  backBatch(){
    this.batchSec = '';
  }

  onclickSubmit(){
    this.resAccessId = "DWS-OPTG-RS-" + moment().format('YYYYMMDDHHmmssSSS');
    if( this.form.get('fatherName').value === undefined || this.form.get('fatherName').value === null){
      this.fatherName = 'N/A';
    }else{
      this.fatherName = this.form.get('fatherName').value;
    }
    if( this.form.get('aadhaarNumber').value === undefined || this.form.get('aadhaarNumber').value === null){
      this.aadhaarNumber = this.form.get('rsldcId').value;
    }else{
      this.aadhaarNumber = this.form.get('aadhaarNumber').value;
    }
    if(this.form.get('motherName').value === undefined || this.form.get('fatherName').value === null){
      this.motherName = 'N/A';
    }else{
      this.motherName = this.form.get('motherName').value;
    }
    if(this.form.get('mobile').value === undefined || this.form.get('mobile').value === null){
      this.mobile = 'N/A';
    }else{
      this.mobile = this.form.get('mobile').value;
    }
    if(this.form.get('dob').value === undefined || this.form.get('dob').value === null){
      this.dateOfBirth = 'N/A';
    }else{
      this.dateOfBirth = this.form.get('dob').value;
    }
    if(this.form.get('age').value === undefined || this.form.get('fatherName').value === null){
      this.age = 'N/A';
    }else{
      this.age = this.form.get('age').value;
    }
    if(this.form.get('village').value === undefined || this.form.get('fatherName').value === null){
      this.village = 'N/A';
    }else{
      this.village = this.form.get('village').value;
    }
    if(this.form.get('block').value === undefined || this.form.get('fatherName').value === null){
      this.block = 'N/A';
    }else{
      this.block = this.form.get('block').value;
    }
    if(this.form.get('municipality').value === undefined || this.form.get('fatherName').value === null){
      this.municipality = 'N/A';
    }else{
      this.municipality = this.form.get('municipality').value;
    }
    if(this.form.get('tehsildar').value === undefined || this.form.get('fatherName').value === null){
      this.tehsildar = 'N/A';
    }else{
      this.tehsildar = this.form.get('tehsildar').value;
    }
    if(this.form.get('pinCode').value === undefined || this.form.get('fatherName').value === null){
      this.pinCode = 'N/A';
    }else{
      this.pinCode = this.form.get('pinCode').value;
    }

    const user = {
      accessId: this.resAccessId,
      schemeAccessId: this.schemeData.accessId,
      schemeCode: this.schemeData.schemeCode,

      tpAccessId: this.tpData.accessId,
      tpCode: this.tpData.tpCode,
      tpName: this.tpData.tpName,

      centerAccessId: this.sdcData.accessId,
      centerCode: this.sdcData.centerCode,
      centerName: this.sdcData.centerName,
      districtCode: this.sdcData.location,
      districtName: this.sdcData.districtName,

      courseAccessId: this.courseData.courseAccessId,
      courseCode: this.courseData.courseCode,
      courseName: this.courseData.courseName,

      batchAccessId: this.batchData.accessId,
      batchCode: this.batchData.batchCode,
      batchHours: this.batchData.batchHours,

      resourceType: this.form.get('resType').value,
      resourceCode: this.form.get('rsldcId').value,
      aspirantId: this.form.get('aspirantId').value, 
      resourceAaadhaar: this.aadhaarNumber,
      gender: this.form.get('gender').value,

      resourceName: this.form.get('resourceName').value,
      fatherName: this.fatherName, 
      motherName: this.motherName, 

      mobile: this.mobile, 
      dateOfBirth: this.dateOfBirth, 
      age: this.age, 
      religion: this.form.get('religion').value, 
      category: this.form.get('category').value, 
      qualification: this.form.get('education').value, 
      //sectors: this.form.get('sector').value, 
      sectorAccessId: this.batchData.sectorAccessId,
      sectorName: this.batchData.sectorName,
      phsycalDisStat: this.form.get('physicalDisa').value,
      village: this.village, 
      blockName: this.block, 
      nativeDistrict: this.form.get('dist').value, 
      panchayatName: this.municipality, 
      tehsilName: this.tehsildar, 
      pincode: this.pinCode,

      profileImagePath: 'N/A',

      createdBy: this.username,
      createdAt: moment().format("YYYY-MM-DD HH:mm:s")
    }
    //  console.log(user);
    // setTimeout(() => {
    //   this.mainForm = false;
    //   this.resCheckSec = false;
    //   this.getRsldcId = undefined;
    //   this.search = ''
    //   this.form.reset();

    //   this.getReligion = 'N/A';
    //   this.getCategory = 'N/A';
    //   this.getQualification = 'N/A';
    //   this.getSectors = 'N/A';
    //   this.getPhysicalDisa = 'N/A';
    //   this.getNativeDistrict = 'N/A';
    // }, 5000);
    this.centerService.createResoures(user).subscribe((info:any) => {
      //console.log(info);
      if(info.success === 1){
        this.mainForm = false;
        this.resCheckSec = false;
        this.getRsldcId = undefined;
        this.search = ''
        this.form.reset();
  
        this.getReligion = 'N/A';
        this.getCategory = 'N/A';
        this.getQualification = 'N/A';
        this.getSectors = 'N/A';
        this.getPhysicalDisa = 'N/A';
        this.getNativeDistrict = 'N/A';
        this.toastr.success('Resources Created');
      }else{
        this.toastr.error('Resources Create Faild');
        this.form.reset();
      }
    });
  }

  onLogoutClick(){
    const accessId = {
      accessId: localStorage.getItem('UserInfo'),
    }
    this.authService.userLogout(accessId).subscribe((data) => {
      //console.log(data);
      this.router.navigate(['/']);
      this._compiler.clearCache();
      localStorage.clear();
      setTimeout(() => {
        location.reload();
      }, 100);
    });
  }

  onClickAllRes(){
    this.router.navigate(['allResources'])
  }

  /* ===============================================================
      JavaScript Load 
  =============================================================== */
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/js/common.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }


  ngOnInit() {
    this.loadScript();
    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      this.username = profile.user.username;
      this.roleId = profile.user.roleId;
      //console.log(this.roleId);
      
      if(profile.user.roleId == '77'){
        this.centerService.getCenter(profile.user.accessId).subscribe((centerDetail: any) => {
          //console.log(centerDetail);          
          const sdcSchemeData= { accessId: centerDetail.centerDetails.schemeAccessId, schemeCode: centerDetail.centerDetails.schemeCode }
          this.schemeData = sdcSchemeData;
          //console.log(this.schemeData);
          const sdcTpData ={ accessId: centerDetail.centerDetails.tpAccessId, tpCode: centerDetail.centerDetails.tpCode, tpName: centerDetail.centerDetails.tpName }
          this.tpData = sdcTpData;
          //console.log(this.tpData);
          this.sdcData = centerDetail.centerDetails;
          this.allCourse = centerDetail.centerDetails.associatedCourseInfo;
        });
      }else{
        //console.log('others login');
        
      }
    });
    this.centerService.getAllDistrict().subscribe((district: any) => {
      //console.log(district);
      this.allDist = district.districtDetails;
    });
  }

}
