import { Component, OnInit, Output, EventEmitter, Compiler } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from 'src/app/services/authService/auth.service';
import { ClassService } from 'src/app/services/classService/class.service';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-center-nav',
  templateUrl: './center-nav.component.html',
  styleUrls: ['./center-nav.component.css']
})
export class CenterNavComponent implements OnInit {

  accessId;
  profileAccessId;
  classDetails;
  listClass: boolean=false;
  listClassHistory: boolean= false;
  listClassAttendance: boolean;
  classAccessId;
  toastrCannotAssgin = 'hide';
  camDetails;
  currentComponent;
  usertype;
  allCourse;
  courseAccessId;

  activeClassView;
  activeClassHis;
  activeClassAttendance;

  public timer;

  getCamID;
  getDeviceID;
  camNtFond = 'hide';
  preLoader = 'hide';
  reloadSec= 'reload-t-sec';
  allCamera;
  @Output()
  uploaded = new EventEmitter<string>();

  @Output()
  uploadedHis = new EventEmitter<string>();


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _compiler: Compiler,
    private authService: AuthService,
    private classService: ClassService,
    private centerService: CenterDetailsService
  ) { }

  getProfile() {
    this.profileAccessId = localStorage.getItem('UserInfo');
    //console.log(this.profileAccessId);
  }

  onClickAnalytice() {
    this.router.navigate(['/centerDashboard/u/', 0]);
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    localStorage.removeItem('CourseAccessIDAttendance');
    if (this.currentComponent == 'LiveStreamComponent') {
      //localStorage.removeItem('totalCamList');
      this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
      for (let a = 0; a < this.allCamera.length; a++) {
        setTimeout(() => {
          //console.log(this.allCamera[a].isCameraActive)
          if (this.allCamera[a].isCameraActive === '1') {
            const offPrams = {
              cameraDeviceId: this.allCamera[a].cameraDeviceId,
              cameraId: this.allCamera[a].cameraId,
              channelId: '13'
            }
            //console.log(offPrams);
            this.classService.offCamera(offPrams).subscribe((offCam: any) => {
              //console.log(offCam);
            });
          }
          if (a + 1 === this.allCamera.length) {
            localStorage.removeItem('totalCamList');
            //console.log('f')
          }else{}
        }, 1000 * (a + 1));
      }
    }else if(this.currentComponent == 'CreateResourcesComponent') {
      this.getCamID = localStorage.getItem('ResCameraID');
      this.getDeviceID = localStorage.getItem('ResDeviceID');
      const offPrams = {
        cameraDeviceId: this.getDeviceID,
        cameraId: this.getCamID,
        channelId: '11'
      }
      this.classService.offCamera(offPrams).subscribe((offcam: any) => {
        //console.log(offcam);
        localStorage.removeItem('ResCameraID');
        localStorage.removeItem('ResDeviceID');
        location.reload();
      });
     } else { }
  }

  getAllClass() {
    let key = 'UserInfo';
    this.accessId = localStorage.getItem(key);
    this.classService.getAllClass(this.accessId).subscribe((data: any) => {
      this.classDetails = data.classDetails;
      //console.log(this.classDetails);
    });
  }

  getAllCourse() {
    let key = 'UserInfo';
    this.accessId = localStorage.getItem(key);
    this.centerService.allCourse(this.accessId).subscribe((data: any) => {
      //console.log(data);
      this.allCourse = data.courseDetails;
    });
  }

  onClickLiveStream() {
    //this.listClass = !this.listClass;
    this.listClassHistory = false;
    this.listClassAttendance = false;
    this.router.navigate(['liveStream/u/', 0]);
  }

  onClickHistory() {
    this.listClassHistory = !this.listClassHistory;
    this.listClass = false;
    this.listClassAttendance = false;

  }

  selectClass(data) {
    if (data.isCameraAssigned == '1') {
      localStorage.removeItem('ClassAccessIdHis');
      localStorage.removeItem('CourseAccessIDAttendance');
      this.classAccessId = data.accessId;
      localStorage.setItem('ClassAccessId', this.classAccessId);
      this.classService.getCamInfo(this.classAccessId).subscribe((camData: any) => {
        //console.log(camData);
        const camAvailData = {
          cameraDeviceId: camData.cameraDetails.cameraDeviceId
        }
        this.classService.cameraAvailabililty(camAvailData).subscribe((camAvail: any) => {
          //console.log(camAvail);
          if (camAvail.success == 1) {
            this.activeClassView = data.accessId;
            this.listClass = false;
            if (camData.success == 1) {
              let getCamId = 'CameraID';
              this.getCamID = localStorage.getItem(getCamId);
              if (this.getCamID == null) {
                const onPrams = {
                  cameraDeviceId: camData.cameraDetails.cameraDeviceId,
                  cameraId: camData.cameraDetails.cameraId,
                  channelId: '13'
                }
                this.preLoader = 'show';
                this.reloadSecFun();
                this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                  //console.log(onCam);
                  if(onCam.success == 1){
                    let camId = 'CameraID';
                    localStorage.setItem(camId, camData.cameraDetails.cameraId);
                    let deviceId = 'DeviceID';
                    localStorage.setItem(deviceId, camData.cameraDetails.cameraDeviceId)
  
                    this.camDetails = camData.cameraDetails;
                    //console.log(this.camDetails);
                    let key = 'CameraUrl';
                    localStorage.setItem(key, this.camDetails.streamUrl);
                    this.router.navigate(['liveStream/u/', 0]);
                    setTimeout(() => {
                      location.reload();
                    }, 2000);
                  }else{
                    //success 0
                  }
                });
              } else {
                /*Camera Off */
                this.preLoader = 'show';
                let getCamId = 'CameraID';
                this.resetTime();;
                this.reloadSecFun();
                this.getCamID = localStorage.getItem(getCamId);
                let deviceId = 'DeviceID';
                this.getDeviceID = localStorage.getItem(deviceId);
                if (camData.cameraDetails.cameraId != this.getCamID) {
                  const offPrams = {
                    cameraDeviceId: this.getDeviceID,
                    cameraId: this.getCamID,
                    channelId: '11'
                  }
                  this.classService.offCamera(offPrams).subscribe((offcam: any) => {
                    //console.log(offcam);
                    /*Camera Start */
                    if(offcam.success == 1){
                      setTimeout(() => {
                        const onPrams = {
                          cameraDeviceId: camData.cameraDetails.cameraDeviceId,
                          cameraId: camData.cameraDetails.cameraId,
                          channelId: '13'
                        }
                        this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                          //console.log(onCam);
                          if(onCam.success == 1){
                            setTimeout(() => {
                              let camId = 'CameraID';
                              localStorage.setItem(camId, camData.cameraDetails.cameraId);
                              let deviceId = 'DeviceID';
                              localStorage.setItem(deviceId, camData.cameraDetails.cameraDeviceId)
                            }, 2000);
                            this.camDetails = camData.cameraDetails;
                            //console.log(this.camDetails);
                            let key = 'CameraUrl';
                            localStorage.setItem(key, this.camDetails.streamUrl);
                            this.router.navigate(['liveStream/u/', 0]);
                            setTimeout(() => {
                              location.reload();
                            }, 2000);
                          }else{
                            //success 0
                          }
                        });
                      }, 2000);
                    }else{
                      //success 0
                    }
                  });
                } else {
                  this.router.navigate(['liveStream/u/', 0]);
                  setTimeout(() => {
                    location.reload();
                  }, 2000);
                 }
              }
            } else { }
          } else {
            //console.log(camAvail.message);
            this.camNtFond = 'show';
            localStorage.removeItem('ClassAccessId');
            setTimeout(() => {
              this.camNtFond = 'hide';
            }, 5000);
          }
        });

      });
    } else {
      this.toastrCannotAssgin = 'show';
      setTimeout(() => {
        this.toastrCannotAssgin = 'hide';
      }, 5000);
    }
  }

  selectClassHistory(data) {
    //if (data.isCameraAssigned == '1') {
      localStorage.removeItem('ClassAccessId');
      localStorage.removeItem('CourseAccessIDAttendance');
      this.classAccessId = data.accessId;
      this.activeClassHis = data.accessId;
      let classAccessId = 'ClassAccessIdHis';
      localStorage.setItem(classAccessId, this.classAccessId);
      this.router.navigate(['/history/u/', 0]);
      this.uploadedHis.emit('complete');
      if (this.currentComponent == 'LiveStreamComponent') {
        //localStorage.removeItem('totalCamList');
        this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
        for (let a = 0; a < this.allCamera.length; a++) {
          setTimeout(() => {
            //console.log(this.allCamera[a].isCameraActive)
            if (this.allCamera[a].isCameraActive === '1') {
              const offPrams = {
                cameraDeviceId: this.allCamera[a].cameraDeviceId,
                cameraId: this.allCamera[a].cameraId,
                channelId: '13'
              }
              //console.log(offPrams);
              this.classService.offCamera(offPrams).subscribe((offCam: any) => {
                //console.log(offCam);
              });
            }
            if (a + 1 === this.allCamera.length) {
              localStorage.removeItem('totalCamList');
              //console.log('f')
            }else{}
          }, 1000 * (a + 1));
        }
      }else if(this.currentComponent == 'CreateResourcesComponent') {
        this.getCamID = localStorage.getItem('ResCameraID');
        this.getDeviceID = localStorage.getItem('ResDeviceID');
        const offPrams = {
          cameraDeviceId: this.getDeviceID,
          cameraId: this.getCamID,
          channelId: '11'
        }
        this.classService.offCamera(offPrams).subscribe((offcam: any) => {
          //console.log(offcam);
          localStorage.removeItem('ResCameraID');
          localStorage.removeItem('ResDeviceID');
          location.reload();
        });
       } else { }
    // } else {
    //   this.toastrCannotAssgin = 'show';
    //   setTimeout(() => {
    //     this.toastrCannotAssgin = 'hide';
    //   }, 5000);
    // }
  }

  onclickAddResorces() {
    this.listClass = false;
    this.listClassHistory = false;
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    this.router.navigate(['/addResources']);
    if (this.currentComponent == 'LiveStreamComponent') {
      //localStorage.removeItem('totalCamList');
      this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
      for (let a = 0; a < this.allCamera.length; a++) {
        setTimeout(() => {
          //console.log(this.allCamera[a].isCameraActive)
          if (this.allCamera[a].isCameraActive === '1') {
            const offPrams = {
              cameraDeviceId: this.allCamera[a].cameraDeviceId,
              cameraId: this.allCamera[a].cameraId,
              channelId: '13'
            }
            //console.log(offPrams);
            this.classService.offCamera(offPrams).subscribe((offCam: any) => {
              //console.log(offCam);
            });
          }
          if (a + 1 === this.allCamera.length) {
            localStorage.removeItem('totalCamList');
            //console.log('f')
          }else{}
        }, 1000 * (a + 1));
      }
    }else if(this.currentComponent == 'CreateResourcesComponent') {
      this.getCamID = localStorage.getItem('ResCameraID');
      this.getDeviceID = localStorage.getItem('ResDeviceID');
      const offPrams = {
        cameraDeviceId: this.getDeviceID,
        cameraId: this.getCamID,
        channelId: '11'
      }
      this.classService.offCamera(offPrams).subscribe((offcam: any) => {
        //console.log(offcam);
        localStorage.removeItem('ResCameraID');
        localStorage.removeItem('ResDeviceID');
        location.reload();
      });
     } else { }

  }

  onClickAttendance() {
    this.listClass = false;
    this.listClassHistory = false;
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    this.router.navigate(['/attendance/u/', 0]);
    if (this.currentComponent == 'LiveStreamComponent') {
      //localStorage.removeItem('totalCamList');
      this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
      for (let a = 0; a < this.allCamera.length; a++) {
        setTimeout(() => {
          //console.log(this.allCamera[a].isCameraActive)
          if (this.allCamera[a].isCameraActive === '1') {
            const offPrams = {
              cameraDeviceId: this.allCamera[a].cameraDeviceId,
              cameraId: this.allCamera[a].cameraId,
              channelId: '13'
            }
            //console.log(offPrams);
            this.classService.offCamera(offPrams).subscribe((offCam: any) => {
              //console.log(offCam);
            });
          }
          if (a + 1 === this.allCamera.length) {
            localStorage.removeItem('totalCamList');
            //console.log('f')
          }else{}
        }, 1000 * (a + 1));
      }
    }else if(this.currentComponent == 'CreateResourcesComponent') {
      this.getCamID = localStorage.getItem('ResCameraID');
      this.getDeviceID = localStorage.getItem('ResDeviceID');
      const offPrams = {
        cameraDeviceId: this.getDeviceID,
        cameraId: this.getCamID,
        channelId: '11'
      }
      this.classService.offCamera(offPrams).subscribe((offcam: any) => {
        //console.log(offcam);
        localStorage.removeItem('ResCameraID');
        localStorage.removeItem('ResDeviceID');
        location.reload();
      });
     } else { }

  }

  
  onClickReport() {
    this.listClass = false;
    this.listClassHistory = false;
    localStorage.setItem('selectSdcAccessID', localStorage.getItem('UserInfo'))
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    this.router.navigate(['sdc_report']);
    if (this.currentComponent == 'LiveStreamComponent') {
      //localStorage.removeItem('totalCamList');
      this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
      for (let a = 0; a < this.allCamera.length; a++) {
        setTimeout(() => {
          //console.log(this.allCamera[a].isCameraActive)
          if (this.allCamera[a].isCameraActive === '1') {
            const offPrams = {
              cameraDeviceId: this.allCamera[a].cameraDeviceId,
              cameraId: this.allCamera[a].cameraId,
              channelId: '13'
            }
            //console.log(offPrams);
            this.classService.offCamera(offPrams).subscribe((offCam: any) => {
              //console.log(offCam);
            });
          }
          if (a + 1 === this.allCamera.length) {
            localStorage.removeItem('totalCamList');
            //console.log('f')
          }else{}
        }, 1000 * (a + 1));
      }
    }else if(this.currentComponent == 'CreateResourcesComponent') {
      this.getCamID = localStorage.getItem('ResCameraID');
      this.getDeviceID = localStorage.getItem('ResDeviceID');
      const offPrams = {
        cameraDeviceId: this.getDeviceID,
        cameraId: this.getCamID,
        channelId: '11'
      }
      this.classService.offCamera(offPrams).subscribe((offcam: any) => {
        //console.log(offcam);
        localStorage.removeItem('ResCameraID');
        localStorage.removeItem('ResDeviceID');
        location.reload();
      });
     } else { }

  }

  // selectCourseAttendance(data) {
  //   //console.log(data);
  //   localStorage.removeItem('ClassAccessId');
  //   localStorage.removeItem('ClassAccessIdHis');
  //   this.courseAccessId = data.accessId;
  //   this.activeClassAttendance = data.accessId;
  //   let courseAccessId = 'CourseAccessIDAttendance';
  //   localStorage.setItem(courseAccessId, this.courseAccessId);
  //   let centerAccessId = 'CenterAccessIDAttendance';
  //   //console.log(centerAccessId);
  //   localStorage.setItem(centerAccessId, data.centerAccessId);
  //   this.router.navigate(['/attendance/u/', 0]);
  //   if (this.currentComponent == 'LiveStreamComponent') {
  //     let camId = 'CameraID';
  //     this.getCamID = localStorage.getItem(camId);
  //     let deviceId = 'DeviceID';
  //     this.getDeviceID = localStorage.getItem(deviceId);
  //     this.preLoader = 'show';
  //     this.reloadSecFun();
  //     const offPrams = {
  //       cameraDeviceId: this.getDeviceID,
  //       cameraId: this.getCamID,
  //       channelId: '11'
  //     }
  //     this.classService.offCamera(offPrams).subscribe((offcam: any) => {
  //       //console.log(offcam);
  //       if(offcam.success == 1){
  //         localStorage.removeItem('CameraID');
  //         localStorage.removeItem('DeviceID');
  //         location.reload();
  //       }else{
  //         //success 0
  //       }
  //     });
  //   }else if(this.currentComponent == 'CreateResourcesComponent') {
  //     this.getCamID = localStorage.getItem('ResCameraID');
  //     this.getDeviceID = localStorage.getItem('ResDeviceID');
  //     const offPrams = {
  //       cameraDeviceId: this.getDeviceID,
  //       cameraId: this.getCamID,
  //       channelId: '11'
  //     }
  //     this.classService.offCamera(offPrams).subscribe((offcam: any) => {
  //       //console.log(offcam);
  //       localStorage.removeItem('ResCameraID');
  //       localStorage.removeItem('ResDeviceID');
  //       location.reload();
  //     });
  //    } else { }
  // }

  onClickCourse() {
    this.router.navigate(['/course']);
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    localStorage.removeItem('CourseAccessIDAttendance');
    if (this.currentComponent == 'LiveStreamComponent') {
      //localStorage.removeItem('totalCamList');
      this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
      for (let a = 0; a < this.allCamera.length; a++) {
        setTimeout(() => {
          //console.log(this.allCamera[a].isCameraActive)
          if (this.allCamera[a].isCameraActive === '1') {
            const offPrams = {
              cameraDeviceId: this.allCamera[a].cameraDeviceId,
              cameraId: this.allCamera[a].cameraId,
              channelId: '13'
            }
            //console.log(offPrams);
            this.classService.offCamera(offPrams).subscribe((offCam: any) => {
              //console.log(offCam);
            });
          }
          if (a + 1 === this.allCamera.length) {
            localStorage.removeItem('totalCamList');
            //console.log('f')
          }else{}
        }, 1000 * (a + 1));
      }
    }else if(this.currentComponent == 'CreateResourcesComponent') {
      this.getCamID = localStorage.getItem('ResCameraID');
      this.getDeviceID = localStorage.getItem('ResDeviceID');
      const offPrams = {
        cameraDeviceId: this.getDeviceID,
        cameraId: this.getCamID,
        channelId: '11'
      }
      this.classService.offCamera(offPrams).subscribe((offcam: any) => {
        //console.log(offcam);
        localStorage.removeItem('ResCameraID');
        localStorage.removeItem('ResDeviceID');
        location.reload();
      });
     } else { }
  }

  onClickClass() {
    this.router.navigate(['/class']);
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    localStorage.removeItem('CourseAccessIDAttendance');
    if (this.currentComponent == 'LiveStreamComponent' ) {
      //localStorage.removeItem('totalCamList');
      this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
      for (let a = 0; a < this.allCamera.length; a++) {
        setTimeout(() => {
          //console.log(this.allCamera[a].isCameraActive)
          if (this.allCamera[a].isCameraActive === '1') {
            const offPrams = {
              cameraDeviceId: this.allCamera[a].cameraDeviceId,
              cameraId: this.allCamera[a].cameraId,
              channelId: '13'
            }
            //console.log(offPrams);
            this.classService.offCamera(offPrams).subscribe((offCam: any) => {
              //console.log(offCam);
            });
          }
          if (a + 1 === this.allCamera.length) {
            localStorage.removeItem('totalCamList');
            //console.log('f')
          }else{}
        }, 1000 * (a + 1));
      }
    } else if(this.currentComponent == 'CreateResourcesComponent') {
      this.getCamID = localStorage.getItem('ResCameraID');
      this.getDeviceID = localStorage.getItem('ResDeviceID');
      const offPrams = {
        cameraDeviceId: this.getDeviceID,
        cameraId: this.getCamID,
        channelId: '11'
      }
      this.classService.offCamera(offPrams).subscribe((offcam: any) => {
        //console.log(offcam);
        localStorage.removeItem('ResCameraID');
        localStorage.removeItem('ResDeviceID');
        location.reload();
      });
     }else { }
  }

  onClickResources() {
    this.router.navigate(['/resource']);
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    localStorage.removeItem('CourseAccessIDAttendance');
    if (this.currentComponent == 'LiveStreamComponent' ) {
      //localStorage.removeItem('totalCamList');
      this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
      for (let a = 0; a < this.allCamera.length; a++) {
        setTimeout(() => {
          //console.log(this.allCamera[a].isCameraActive)
          if (this.allCamera[a].isCameraActive === '1') {
            const offPrams = {
              cameraDeviceId: this.allCamera[a].cameraDeviceId,
              cameraId: this.allCamera[a].cameraId,
              channelId: '13'
            }
            //console.log(offPrams);
            this.classService.offCamera(offPrams).subscribe((offCam: any) => {
              //console.log(offCam);
            });
          }
          if (a + 1 === this.allCamera.length) {
            localStorage.removeItem('totalCamList');
            //console.log('f')
          }else{}
        }, 1000 * (a + 1));
      }
    }else if(this.currentComponent == 'CreateResourcesComponent') {
      this.getCamID = localStorage.getItem('ResCameraID');
      this.getDeviceID = localStorage.getItem('ResDeviceID');
      const offPrams = {
        cameraDeviceId: this.getDeviceID,
        cameraId: this.getCamID,
        channelId: '11'
      }
      this.classService.offCamera(offPrams).subscribe((offcam: any) => {
        //console.log(offcam);
        localStorage.removeItem('ResCameraID');
        localStorage.removeItem('ResDeviceID');
        location.reload();
      });
     } else { }
  }

  onLogoutClick() {
    if (this.currentComponent == 'LiveStreamComponent') {
      //localStorage.removeItem('totalCamList');
      this.allCamera = JSON.parse(localStorage.getItem('totalCamList'));
      for (let a = 0; a < this.allCamera.length; a++) {
        setTimeout(() => {
          //console.log(this.allCamera[a].isCameraActive)
          if (this.allCamera[a].isCameraActive === '1') {
            const offPrams = {
              cameraDeviceId: this.allCamera[a].cameraDeviceId,
              cameraId: this.allCamera[a].cameraId,
              channelId: '13'
            }
            //console.log(offPrams);
            this.classService.offCamera(offPrams).subscribe((offCam: any) => {
              //console.log(offCam);
            });
          }
          if (a + 1 === this.allCamera.length) {
            localStorage.removeItem('totalCamList');
            //console.log('f')
            let key = 'UserInfo';
            this.accessId = localStorage.getItem(key);
            const accessId = {
              accessId: this.accessId,
            }
            this.authService.userLogout(accessId).subscribe((data) => {
              //console.log(data);
              this._compiler.clearCache();
              localStorage.clear();
              this.router.navigate(['/']);
              setTimeout(() => {
                location.reload();
              }, 100);
            });
          }else{}
        }, 1000 * (a + 1));
      }

    }else if(this.currentComponent == 'CreateResourcesComponent') {
      this.getCamID = localStorage.getItem('ResCameraID');
      this.getDeviceID = localStorage.getItem('ResDeviceID');
      const offPrams = {
        cameraDeviceId: this.getDeviceID,
        cameraId: this.getCamID,
        channelId: '11'
      }
      this.classService.offCamera(offPrams).subscribe((offcam: any) => {
        //console.log(offcam);
        if(offcam.success == 1){
          let key = 'UserInfo';
          this.accessId = localStorage.getItem(key);
          const accessId = {
            accessId: this.accessId,
          }
          this.authService.userLogout(accessId).subscribe((data) => {
            //console.log(data);
            this._compiler.clearCache();
            localStorage.clear();
            this.router.navigate(['/']);
            setTimeout(() => {
              location.reload();
            }, 100);
          });
        }else{}
      });
     } else {
      let key = 'UserInfo';
      this.accessId = localStorage.getItem(key);
      const accessId = {
        accessId: this.accessId,
      }
      this.authService.userLogout(accessId).subscribe((data) => {
        //console.log(data);
        this._compiler.clearCache();
        this.router.navigate(['/']);
        localStorage.clear()
        setTimeout(() => {
          location.reload();
        }, 100);
      });
    }
  }

  ngOnInit() {
    this.getProfile();
    this.getAllClass();
    this.getAllCourse();
    this.currentComponent = this.route.component['name'];
    //console.log(this.currentComponent);
    let activeClassView = 'ClassAccessId';
    this.activeClassView = localStorage.getItem(activeClassView);

    let activeClassHis = 'ClassAccessIdHis';
    this.activeClassHis = localStorage.getItem(activeClassHis);

    let activeClassAttendance = 'CourseAccessIDAttendance';
    this.activeClassAttendance = localStorage.getItem(activeClassAttendance);

    //console.log(this.currentComponent);
    this.reloadSecFun();
  }

  resetTime(){
    clearTimeout(this.timer);
  }

  reloadSecFun(){
    this.timer = setTimeout(() => {
      if(this.preLoader == 'show'){
        this.reloadSec = 'r-t-enable';
      }else{
        this.reloadSec = 'reload-t-sec';
      }
    }, 20000);
  }

  onclickReload(){
    location.reload();
  }

  /* ===============================================================
      Streamdian Video JS JavaScript Load 
  =============================================================== */
  public loadScript() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/player/player.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script); 
  }
}
