import { Component, OnInit, HostListener } from '@angular/core';

import { AdminServiceService } from 'src/app/services/adminService/admin-service.service';
import { PiaServiceService } from 'src/app/services/piaService/pia-service.service';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ClassService } from 'src/app/services/classService/class.service';
import * as moment from 'moment';

@Component({
  selector: 'app-resources-list',
  templateUrl: './resources-list.component.html',
  styleUrls: ['./resources-list.component.css']
})
export class ResourcesListComponent implements OnInit {
  allScheme;
  allPia;
  piaListSec;
  schemeCode;
  schemeAccessId;
  schemeBasicCenterList;
  piaAccessId;
  piaSelectArrowboardCSS;
  piaDetailsSecCSS;
  allCenter;
  allCourse;
  filterAllResources;
  centerAccessId;
  allResource;
  centerListSec;
  selectPia;
  courseListSec;
  selectCenter;
  courseAccessId;
  approveBtn: boolean = false;
  resTypeBtn: boolean = false;
  disableBtnApprove: boolean = false;
  dataImages;
  myModal;
  slideCount;
  prevHide;
  nextHide;
  imageLength;
  resourcesType;
  resListSec = 'hide';
  alertBox = 'hide';
  studentAccessId;
  resTypes;
  oneTimeClick = 'hide';

  verifyTypeSec = 'hide';
  listTypeAct;
  selectType;

  onenPopup = 'hide';
  allImage;
  selectRes;
  search;

  azureImg: boolean;
  verifyResLen;
  mainSec: boolean;
  resSearch;
  loader = 'hide';
  allClass;
  batchData = [];
  camInfo;
  classList: boolean;

  constructor(
    private datepipe: DatePipe,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private classService: ClassService,
    private adminService: AdminServiceService,
    private piaService: PiaServiceService,
    private centerService: CenterDetailsService
  ) { }


  getAllScheme() {
    this.adminService.getSchemeWithTrial().subscribe((data: any) => {
      //console.log(data);
      this.allScheme = data.schemeDetails;
    });
  }

  /* ===============================================================
      All PIA / TP List
  =============================================================== */
  selectScheme(data) {
    this.schemeAccessId = data.accessId;
    this.schemeCode = data.schemeCode;
    this.piaService.getAllPia(this.schemeAccessId).subscribe((data: any) => {
      //console.log(data);
      this.allPia = data.tpDetails;
      this.piaListSec = "show-list-sec";
    });
  }

  /* ===============================================================
      Back TP/PIA list to Scheme List
  =============================================================== */
  backToSchemeList() {
    this.piaListSec = "";
    this.schemeAccessId = null;
    this.allPia = null;
    this.piaAccessId = null;
    this.piaSelectArrowboardCSS = '';
    this.approveBtn = false;
    this.search = '';
  }

  onClickSelectPia(data) {
    //console.log(data);
    this.piaAccessId = data.accessId;
    this.selectPia = data.tpName
    this.centerService.getAllCenter(this.piaAccessId).subscribe((data: any) => {
      this.allCenter = data.centerDetails;
      //console.log(this.allCenter);
      this.centerListSec = "show-list-sec";
      this.search = '';
    });
  }

  /* ===============================================================
      Back center list to Pia List
  =============================================================== */
  backToPiaList() {
    this.centerListSec = "";
    this.piaAccessId = null;
    this.allCenter = null;
    this.approveBtn = false;
  }

  onClickSelectCenter(data) {
    console.log(data);
    this.centerAccessId = data.accessId;
    this.selectCenter = data.centerName;
    // this.centerService.allCourse(data.accessId).subscribe((data: any) => {
    //   console.log(data);
    //   this.allCourse = data.courseDetails;
    //   this.courseListSec = "show-list-sec";
    // })
    this.courseListSec = "show-list-sec";
    this.allCourse = data.associatedCourseInfo;
    console.log(this.allCourse);
    this.classService.getAllClass(data.accessId).subscribe((data: any) => {
      //console.log(data);
      this.allClass = data.classDetails;
    });
    this.centerService.getAllBatchByCenter(data.accessId).subscribe((batch: any) => {
      //console.log(batch.batchDetails);
      batch.batchDetails.forEach((element: any, i) => {
        setTimeout(() => {
          const endTime = moment('2013-05-09T' + element.batchEndTime);
          const startTime = moment('2013-05-09T' + element.batchStartTime);
          //console.log(startTime);
          this.batchData.push({ "scheduleStartDate": moment.unix(element.batchStratDate).format('YYYY-MM-DD'), "scheduleEndDate": moment.unix(element.batchEndDate).format('YYYY-MM-DD'), "scheduleStartTime": moment(startTime).subtract(5, 'minutes').format('HH:mm:ss'), "scheduleEndTime": moment(endTime).add(10, 'minutes').format('HH:mm:ss') });
          //console.log(this.batchData);
        }, 1000 * (i + 1));
      });
    })
  }

  /* ===============================================================
      Back course list to center List
  =============================================================== */
  backToCenterList() {
    this.courseListSec = "";
    this.selectCenter = null;
    this.allCourse = null;
    this.approveBtn = false;
    this.batchData = [];
  }

  onClickSelectcourse(data) {
    //console.log(data);
    this.courseAccessId = data.courseAccessId;
    //this.selectCourse = data
    this.allImage = undefined;
    this.selectRes = undefined;
    this.onenPopup = 'hide';
    this.resSearch = '';
    this.loader = 'show';
    //this.classAndCenterUnenrolll();
    this.centerService.allResources(this.centerAccessId, this.courseAccessId).subscribe((data: any) => {
      //console.log(data);
      this.allResource = data.resourceDetails;
      this.filterAllResources = this.allResource.filter(res => res.resourceType === 'S');
      this.resourcesType = 'S';
      this.listTypeAct = 'enroll';
      this.loader = 'hide';
      //console.log(this.filterAllResources);
      this.centerService.enrollmentDetails(this.centerAccessId, this.courseAccessId).subscribe((data: any) => {
        //console.log(data);
        if (data.success == 1) {
          this.approveBtn = false;
          this.resListSec = 'show';
          this.verifyTypeSec = 'show';
        } else {
          this.approveBtn = true;
          this.resListSec = 'hide';
          this.verifyTypeSec = 'hide';
        }
      });
      if (this.allResource.length == 0) {
        this.resTypeBtn = false;
      } else {
        this.resTypeBtn = true;
      }
    });
  }

  onClickEnrollList() {
    this.allImage = undefined;
    this.selectRes = undefined;
    this.onenPopup = 'hide';
    this.resSearch = '';
    this.loader = 'show';
    this.centerService.allResources(this.centerAccessId, this.courseAccessId).subscribe((data: any) => {
      //console.log(data);
      this.allResource = data.resourceDetails;
      this.filterAllResources = this.allResource.filter(res => res.resourceType === 'S');
      this.resourcesType = 'S';
      this.listTypeAct = 'enroll';
      this.loader = 'hide';
      if (this.allResource.length == 0) {
        this.resTypeBtn = false;
      } else {
        this.resTypeBtn = true;
      }
    });
  }

  onClickVerifyList() {
    this.allImage = undefined;
    this.selectRes = undefined;
    this.onenPopup = 'hide';
    this.resSearch = '';
    this.loader = 'show';
    this.centerService.allVerifyResourcess(this.centerAccessId, this.courseAccessId).subscribe((data: any) => {
      this.verifyResLen = data.resourceDetails.length;
      this.allResource = data.resourceDetails;
      this.filterAllResources = this.allResource.filter(res => res.resourceType === 'S');
      //console.log(this.filterAllResources)
      this.resourcesType = 'S';
      this.listTypeAct = 'verify';
      this.loader = 'hide';
      if (this.allResource.length == 0) {
        this.resTypeBtn = false;
      } else {
        this.resTypeBtn = true;
      }
    });
  }

  onClickClassList() {
    this.classList = !this.classList;
  }

  onSelectClass(data) {
    //console.log(data.isCameraAssigned);
    if (data.isCameraAssigned === '1') {
      this.classService.getCamInfo(data.accessId).subscribe((camInfo: any) => {
        //console.log(camInfo);
        this.camInfo = camInfo.cameraDetails;
        const camEnroll = {
          centerAccessId: this.centerAccessId,
          cameraDeviceId: this.camInfo.cameraDeviceId,
          cameraId: this.camInfo.cameraId,
          cameraJpgUrl: 'http://52.183.136.186:8086/thumbnail?application=live&streamname=' + this.camInfo.cameraId + 'size=1920x1080&fitmode=letterbox&format=jpeg',
          cameraScheduleInfo: this.batchData
        }
        //console.log(camEnroll);
        this.classService.cameraEnroll(camEnroll).subscribe((info: any) => {
          console.log(info);
        });
      });
    } else {
      this.toastr.info('Camera not assigend');
    }


  }

  classAndCenter() {
    this.disableBtnApprove = true;
    const user = {
      userId: "RSLDC",
      centerAccessId: this.centerAccessId,
      courseAccessId: this.courseAccessId
    }
    this.centerService.enrollmentCC(user).subscribe((data: any) => {
      //console.log(data);
      this.centerService.enrollmentDetails(this.centerAccessId, this.courseAccessId).subscribe((data: any) => {
        //console.log(data);
        if (data.success == 1) {
          this.approveBtn = false;
          this.resListSec = 'show';
          this.verifyTypeSec = 'show';
        } else {
          this.approveBtn = true;
          this.resListSec = 'hide';
          this.verifyTypeSec = 'hide';
        }
      });
      this.disableBtnApprove = false;
    });
  }

  reload() {
    this.allImage = undefined;
    this.selectRes = undefined;
    this.onenPopup = 'hide';
    this.resSearch = '';
    this.loader = 'show';
    if (this.listTypeAct === 'verify') {
      this.centerService.allVerifyResourcess(this.centerAccessId, this.courseAccessId).subscribe((resData: any) => {
        this.allResource = resData.resourceDetails;
        this.loader = 'hide';
        if (this.resourcesType == 'S') {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'S');
          this.resourcesType = 'S';
        } else if (this.resourcesType == 'E') {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'E');
          this.resourcesType = 'E';
        } else {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'T');
          this.resourcesType = 'T';
        }
      });
    } else if (this.listTypeAct === 'enroll') {
      this.centerService.allResources(this.centerAccessId, this.courseAccessId).subscribe((resData: any) => {
        this.allResource = resData.resourceDetails;
        this.loader = 'hide';
        if (this.resourcesType == 'S') {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'S');
          this.resourcesType = 'S';
        } else if (this.resourcesType == 'E') {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'E');
          this.resourcesType = 'E';
        } else {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'T');
          this.resourcesType = 'T';
        }
      });
    } else { }
  }

  onClickImgOpen(data) {
    this.allImage = data.imageDetails;
    this.selectRes = data;
    this.onenPopup = 'show';
    this.resSearch = '';
    //console.log(this.selectRes);
    if (this.datepipe.transform(data.createdAt, 'yyyy-MM-dd') <= '2019-11-29') {
      //console.log('normal')
      this.azureImg = false;
    } else {
      //console.log('azure');
      this.azureImg = true
    }
    setTimeout(() => {
      this.filterAllResources = [];
    }, 1000);

  }


  popUpClose() {
    this.allImage = undefined;
    this.selectRes = undefined;
    this.onenPopup = 'hide';
    this.loader = 'show';
    if (this.listTypeAct === 'verify') {
      this.centerService.allVerifyResourcess(this.centerAccessId, this.courseAccessId).subscribe((resData: any) => {
        this.allResource = resData.resourceDetails;
        this.loader = 'hide';
        if (this.resourcesType == 'S') {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'S');
          this.resourcesType = 'S';
        } else if (this.resourcesType == 'E') {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'E');
          this.resourcesType = 'E';
        } else {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'T');
          this.resourcesType = 'T';
        }
      });
    } else if (this.listTypeAct === 'enroll') {
      this.centerService.allResources(this.centerAccessId, this.courseAccessId).subscribe((resData: any) => {
        this.allResource = resData.resourceDetails;
        this.loader = 'hide';
        if (this.resourcesType == 'S') {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'S');
          this.resourcesType = 'S';
        } else if (this.resourcesType == 'E') {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'E');
          this.resourcesType = 'E';
        } else {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'T');
          this.resourcesType = 'T';
        }
      });
    } else { }
  }

  /* ===============================================================
      Enroll Resource Forcefully 
  =============================================================== */
  forcefullyEnroll(data) {
    this.selectRes = data;
    const userData = {
      userId: 'RSLDC',
      centerAccessId: data.centerAccessId,
      courseAccessId: data.courseAccessId,
      studentAccessId: data.accessId
    }
    //console.log(userData)
    this.centerService.enrollResourceForcefully(userData).subscribe((data: any) => {
      //console.log(data);
      if (data.success === 1) {
        this.centerService.allResources(data.centerAccessId, data.courseAccessId).subscribe((resData: any) => {
          //console.log(data);
          this.allResource = resData.resourceDetails;
          this.oneTimeClick = 'hide';
          if (this.selectRes.resourceType == 'S') {
            this.filterAllResources = this.allResource.filter(res => res.resourceType === 'S');
            this.resourcesType = 'S';
          } else if (this.selectRes.resourceType == 'E') {
            this.filterAllResources = this.allResource.filter(res => res.resourceType === 'E');
            this.resourcesType = 'E';
          } else {
            this.filterAllResources = this.allResource.filter(res => res.resourceType === 'T');
            this.resourcesType = 'T';
          }
          setTimeout(() => {
            this.selectRes = undefined;
          }, 1000);
          //console.log(this.allResource);
          if (this.allResource.length == 0) {
            this.resTypeBtn = false;
          } else {
            this.resTypeBtn = true;
          }
        });
      } else if (data.success === 3) {
        this.toastr.info('Something went wrong');
      } else if (data.success === 0) {
        this.toastr.info('Something went wrong');
      } else { }
    })
  }

  /* ===============================================================
      Approve Student
  =============================================================== */
  approveEnroll() {
    this.oneTimeClick = 'show';
    const user = {
      userId: "RSLDC",
      centerAccessId: this.selectRes.centerAccessId,
      courseAccessId: this.selectRes.courseAccessId,
      studentAccessId: this.selectRes.accessId,
      batchId: this.selectRes.batchCode,
      resourceName: this.selectRes.resourceName,
      imageDetails: this.selectRes.imageDetails,
      resourceCode: this.selectRes.resourceCode,
    }
    //console.log(user)
    this.centerService.enrollmentStudent(user).subscribe((data: any) => {
      //console.log(data);
      this.centerService.allResources(this.centerAccessId, this.courseAccessId).subscribe((resData: any) => {
        //console.log(data);
        this.allResource = resData.resourceDetails;
        if (this.selectRes.resourceType == 'S') {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'S');
          this.resourcesType = 'S';
        } else if (this.selectRes.resourceType == 'E') {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'E');
          this.resourcesType = 'E';
        } else {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'T');
          this.resourcesType = 'T';
        }
        setTimeout(() => {
          this.allImage = undefined;
          this.selectRes = undefined;
          this.onenPopup = 'hide';
          this.oneTimeClick = 'hide';
        }, 1000);
        //console.log(this.allResource);
        if (this.allResource.length == 0) {
          this.resTypeBtn = false;
        } else {
          this.resTypeBtn = true;
        }
      });
    });
  }

  /* ===============================================================
      Unenroll Class
  =============================================================== */
  classAndCenterUnenrolll() {
    this.disableBtnApprove = true;
    const unEnrollCC = {
      userId: 'RSLDC',
      centerAccessId: this.centerAccessId,
      courseAccessId: this.courseAccessId
    }
    //console.log(unEnrollCC)
    this.centerService.unEnrollmentClassCen(unEnrollCC).subscribe((data: any) => {
      this.disableBtnApprove = false;
      //console.log(data)
      this.centerService.enrollmentDetails(this.centerAccessId, this.courseAccessId).subscribe((data: any) => {
        //console.log(data);
        if (data.success == 1) {
          this.approveBtn = false;
          this.resListSec = 'show';
        } else {
          this.approveBtn = true;
          this.resListSec = 'hide';
        }
      });
    });
  }

  onDelete(data) {
    this.allImage = data.imageDetails;
    this.selectRes = data;
    //console.log(this.selectRes);   
    this.studentAccessId = this.selectRes.accessId;
    this.alertBox = 'show';
    this.resTypes = this.selectRes.resourceType;
    if (this.datepipe.transform(data.createdAt, 'yyyy-MM-dd') <= '2019-11-29') {
      //console.log('normal')
      this.azureImg = false;
    } else {
      //console.log('azure');
      this.azureImg = true
    }
  }
  /* ===============================================================
      Reject Student
  =============================================================== */
  rejectStu() {
    this.studentAccessId = this.selectRes.accessId;
    this.alertBox = 'show';
    this.resTypes = this.selectRes.resourceType;
  }

  cancelRej() {
    this.studentAccessId = undefined;
    this.alertBox = 'hide';
    this.resTypes = undefined;
  }

  conformRejectStu() {
    this.oneTimeClick = 'show';
    this.alertBox = 'hide';
    if (this.allImage.length === 0) {
      const rejStuData = {
        studentAccessId: this.studentAccessId,
        user: 'RSLDC'
      }
      //console.log(rejStuData)
      this.centerService.rejectStudent(rejStuData).subscribe((rejStu: any) => {
        //console.log(rejStu);
        this.centerService.allResources(this.centerAccessId, this.courseAccessId).subscribe((resData: any) => {
          //console.log(data);
          this.allResource = resData.resourceDetails;
          //console.log(this.allResource);
          this.oneTimeClick = 'hide';
          if (this.resTypes == 'S') {
            this.filterAllResources = this.allResource.filter(res => res.resourceType === 'S');
            this.resourcesType = 'S';
          } else if (this.resTypes == 'E') {
            this.filterAllResources = this.allResource.filter(res => res.resourceType === 'E');
            this.resourcesType = 'E';
          } else {
            this.filterAllResources = this.allResource.filter(res => res.resourceType === 'T');
            this.resourcesType = 'T';
          }
          setTimeout(() => {
            this.allImage = undefined;
            this.selectRes = undefined;
            this.onenPopup = 'hide';
          }, 1000);
          if (this.allResource.length == 0) {
            this.resTypeBtn = false;
          } else {
            this.resTypeBtn = true;
          }
        });
      });
    } else {
      this.allImage.forEach((ele, i) => {
        setTimeout(() => {
          const user = {
            studentAccessId: this.studentAccessId,
            imageName: ele.imageName
          }
          //console.log(user);
          this.centerService.azureImageDelete(user).subscribe((data: any) => {
            //console.log(data);
          });
          if (this.allImage.length === i + 1) {
            const rejStuData = {
              studentAccessId: this.studentAccessId,
              user: 'RSLDC'
            }
            //console.log(rejStuData)
            this.centerService.rejectStudent(rejStuData).subscribe((rejStu: any) => {
              //console.log(rejStu);
              this.centerService.allResources(this.centerAccessId, this.courseAccessId).subscribe((resData: any) => {
                //console.log(data);
                this.allResource = resData.resourceDetails;
                this.oneTimeClick = 'hide';
                //console.log(this.allResource);
                if (this.resTypes == 'S') {
                  this.filterAllResources = this.allResource.filter(res => res.resourceType === 'S');
                  this.resourcesType = 'S';
                } else if (this.resTypes == 'E') {
                  this.filterAllResources = this.allResource.filter(res => res.resourceType === 'E');
                  this.resourcesType = 'E';
                } else {
                  this.filterAllResources = this.allResource.filter(res => res.resourceType === 'T');
                  this.resourcesType = 'T';
                }
                setTimeout(() => {
                  this.allImage = undefined;
                  this.selectRes = undefined;
                  this.onenPopup = 'hide';
                }, 1000);
                if (this.allResource.length == 0) {
                  this.resTypeBtn = false;
                } else {
                  this.resTypeBtn = true;
                }
              });
            });
          } else { }
        }, 1000 * (i + 1));
      });
    }

  }

  /* =====Old server====== */
  rejectStuOld() {
    this.oneTimeClick = 'show';
    this.alertBox = 'hide';
    const rejStuData = {
      studentAccessId: this.studentAccessId,
      user: 'RSLDC'
    }
    this.centerService.rejectStudent(rejStuData).subscribe((rejStu: any) => {
      //console.log(rejStu);
      this.centerService.allResources(this.centerAccessId, this.courseAccessId).subscribe((resData: any) => {
        //console.log(data);
        this.allResource = resData.resourceDetails;
        //console.log(this.allResource);
        this.oneTimeClick = 'hide';
        if (this.resTypes == 'S') {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'S');
          this.resourcesType = 'S';
        } else if (this.resTypes == 'E') {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'E');
          this.resourcesType = 'E';
        } else {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'T');
          this.resourcesType = 'T';
        }
        setTimeout(() => {
          this.allImage = undefined;
          this.selectRes = undefined;
          this.onenPopup = 'hide';
        }, 1000);
        if (this.allResource.length == 0) {
          this.resTypeBtn = false;
        } else {
          this.resTypeBtn = true;
        }
      });
    });
  }

  /* ===============================================================
      Resources Type Fetch
  =============================================================== */
  resType(Data) {
    this.allImage = undefined;
    this.selectRes = undefined;
    this.onenPopup = 'hide';
    this.resSearch = '';
    this.loader = 'show';
    if (this.listTypeAct === 'verify') {
      this.centerService.allVerifyResourcess(this.centerAccessId, this.courseAccessId).subscribe((resData: any) => {
        //console.log(data);
        this.allResource = resData.resourceDetails.filter(res => res.resourceType === Data);
        //console.log(this.allResource);
        this.resourcesType = Data;
        this.filterAllResources = this.allResource.filter(res => res.resourceType === Data);
        this.loader = 'hide';
        //console.log(this.filterAllResources);
      });
    } else if (this.listTypeAct === 'enroll') {
      this.centerService.allResources(this.centerAccessId, this.courseAccessId).subscribe((resData: any) => {
        //console.log(data);
        this.allResource = resData.resourceDetails.filter(res => res.resourceType === Data);
        //console.log(this.allResource);
        this.resourcesType = Data;
        this.filterAllResources = this.allResource.filter(res => res.resourceType === Data);
        this.loader = 'hide';
        //console.log(this.filterAllResources);
      });
    }
  }

  /* ===============================================================
      Unrollment Resources
  =============================================================== */
  unEnroll() {
    const unenroll = {
      userId: 'RSLDC',
      centerAccessId: this.selectRes.centerAccessId,
      courseAccessId: this.selectRes.courseAccessId,
      studentAccessId: this.selectRes.accessId
    }
    this.centerService.unEnrollmentStudent(unenroll).subscribe((test: any) => {
      this.centerService.allVerifyResourcess(this.centerAccessId, this.courseAccessId).subscribe((resData: any) => {
        this.allResource = resData.resourceDetails;
        this.listTypeAct = 'verify';
        if (this.resourcesType == 'S') {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'S');
          this.resourcesType = 'S';
        } else if (this.resourcesType == 'E') {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'E');
          this.resourcesType = 'E';
        } else {
          this.filterAllResources = this.allResource.filter(res => res.resourceType === 'T');
          this.resourcesType = 'T';
        }
        setTimeout(() => {
          this.allImage = undefined;
          this.selectRes = undefined;
          this.onenPopup = 'hide';
        }, 1000);
        if (this.allResource.length == 0) {
          this.resTypeBtn = false;
        } else {
          this.resTypeBtn = true;
        }
      });
    })
  }

  /* ===============================================================
    Image detete
=============================================================== */
  filename(path) {
    path = path.substring(path.lastIndexOf("/") + 1);
    return (path.match(/[^.]+(\.[^?#]+)?/) || [])[0];
  }

  singleDeleteImgA(data) {
    //console.log(data);
    this.filename(data.imagePath);
    const user = {
      studentAccessId: this.selectRes.accessId,
      imageName: this.allImage[this.slideCount - 1].imageName
    }
    //console.log(user);
    this.centerService.azureImageDelete(user).subscribe((data: any) => {
      //console.log(data);
      //console.log(this.selectRes.accessId, this.filename(data.imagePath));
      this.centerService.resImageList(this.selectRes.centerAccessId, this.selectRes.accessId).subscribe((allImg: any) => {
        this.allImage = allImg.resourceDetails.imageDetails;
        this.imageLength = this.allImage.length;
        //console.log(allImg);
        //console.log(this.imageLength);
        if (this.imageLength + 1 === this.slideCount) {
          this.closeModal();
        } else {

        }
      });
    });
  }


  /* ===============================================================
      Set as a profile pic
  =============================================================== */
  onClickSetProfilePic(image) {
    const profileImg = {
      profileImagePath: this.allImage[this.slideCount - 1].imagePath,
      resourceAccessId: this.selectRes.accessId
    }
    //console.log(profileImg);
    this.centerService.resouresProImageUpload(profileImg).subscribe((setAs: any) => {
      //console.log(setAs);
      if (setAs.success === 1) {
        this.toastr.success('Profile image placed successfully', 'Done')
      } else { }
    });
  }

  somethingChanged(data) {
    //console.log(data.length)
    if (data.length <= 0) {
      this.mainSec = false;
    } else {
      this.mainSec = true;
    }
  }

  /* ===============================================================
      Gallery
  =============================================================== */
  openGallery(data, test) {
    //console.log(test);
    this.slideCount = test;
    //console.log(data.imagePath);
    this.myModal = 'show';
    this.allImage = this.allImage;
    this.imageLength = this.allImage.length;

    if (this.slideCount == '1') {
      this.prevHide = 'hide';
    } else {
      this.prevHide = 'show';
    }
    if (this.imageLength == this.slideCount) {
      this.nextHide = 'hide';
    } else {
      this.nextHide = 'show';
    }
  }

  closeModal() {
    this.myModal = 'hide';
    this.slideCount = null;
  }

  prev() {
    this.slideCount--;
    if (this.slideCount == '1') {
      this.prevHide = 'hide';
    } else {
      this.prevHide = 'show';
    }
    if (this.imageLength == this.slideCount) {
      this.nextHide = 'hide';
    } else {
      this.nextHide = 'show';
    }
  }
  next() {
    this.slideCount++;
    if (this.slideCount == '1') {
      this.prevHide = 'hide';
    } else {
      this.prevHide = 'show';
    }
    if (this.imageLength == this.slideCount) {
      this.nextHide = 'hide';
    } else {
      this.nextHide = 'show';
    }
  }
  /* ===============================================================
      Keybord key Event
  =============================================================== */
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {

    if (event.keyCode === 27) {
      if (this.slideCount != null) {
        this.closeModal();
      } else { }
    }

    if (event.keyCode === 39) {
      //Image Next
      if (this.slideCount != null) {
        if (this.slideCount != this.imageLength) {
          this.slideCount++;
          if (this.slideCount == '1') {
            this.prevHide = 'hide';
          } else {
            this.prevHide = 'show';
          }
          if (this.imageLength == this.slideCount) {
            this.nextHide = 'hide';
          } else {
            this.nextHide = 'show';
          }
        }
      }
    } else if (event.keyCode === 37) {
      //Image Prev
      if (this.slideCount != null) {
        if (this.slideCount != '1') {
          this.slideCount--;
          if (this.slideCount == '1') {
            this.prevHide = 'hide';
          } else {
            this.prevHide = 'show';
          }
          if (this.imageLength == this.slideCount) {
            this.nextHide = 'hide';
          } else {
            this.nextHide = 'show';
          }
        }
      }
    } else { }
  }

  ngOnInit() {
    this.getAllScheme();
  }

}
