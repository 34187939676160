import { Component, OnInit } from '@angular/core';


import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ClassService } from 'src/app/services/classService/class.service';

import { AuthService } from 'src/app/services/authService/auth.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { CCService } from 'src/app/services/cCService/c-c.service';

declare var jwplayer: any;

@Component({
  selector: 'app-live-stream',
  templateUrl: './live-stream.component.html',
  styleUrls: ['./live-stream.component.css']
})

export class LiveStreamComponent implements OnInit {

  username;
  roleId;
  cameraList;
  finalCameraList;
  onStep;
  totalOnCamera;
  loader: boolean;
  selectVidForZoom;
  selectClass;
  disableBtn;
  datetime;
  capturedImageUrl;
  captureImageSec: boolean;
  startRecBtn: boolean = true;
  stopRecBtn: boolean = false;
  startRecBtn10: boolean = true;
  stopRecBtn10: boolean = false;
  centerAccessId;
  centerDetails;
  allCourse;

  videoElement;
  player;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private cCService: CCService,
    private authService: AuthService,
    private centerService: CenterDetailsService,
    private classService: ClassService
  ) { }

  /* ===============================================================
    Profile Fetch
  =============================================================== */
  fetchProfile() {
    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      this.username = profile.user.username;
      this.roleId = profile.user.roleId;
      if (profile.user.roleId == 77) {
        this.centerAccessId = profile.user.accessId;
      } else {
        this.centerAccessId = localStorage.getItem('UserInfoCenterAccessID');
      }
      this.centerService.getCenter(this.centerAccessId).subscribe((centerDetail: any) => {
        this.centerDetails = centerDetail.centerDetails;
        //console.log(this.centerDetails)
      });
      if (localStorage.getItem('totalCamList') === null) {
        //console.log('ffffff');
        this.loader = true;
        //console.log(this.centerAccessId)
        this.cCService.getAllCameraBySdc(this.centerAccessId).subscribe((allCam: any) => {
          console.log(allCam)
          this.cameraList = allCam.cameraDetails;
          this.onStep = 'first';
          //console.log(this.cameraList)
          for (let a = 0; a < this.cameraList.length; a++) {
            const camAvailData = {
              cameraDeviceId: this.cameraList[a].cameraDeviceId
            }
            this.classService.cameraAvailabililty(camAvailData).subscribe((camAvail: any) => {
              //console.log(camAvail);
              if (camAvail.success === 1) {
                const camspeed = {
                  cameraDeviceId: this.cameraList[a].cameraDeviceId
                }
                this.centerService.camSpeed(camspeed).subscribe((camSpeed: any) => {
                  //console.log(camSpeed);
                });
              } else { }
            });
            if (a + 1 === this.cameraList.length) {
              this.onStep = 'second';
              setTimeout(() => {
                this.cCService.getAllCameraBySdc(this.centerAccessId).subscribe((cam: any) => {
                  //console.log(cam);
                  this.cameraList = cam.cameraDetails;
                  for (let b = 0; b < this.cameraList.length; b++) {
                    setTimeout(() => {
                      if (this.cameraList[b].isCameraActive === '1') {
                        const onPrams = {
                          cameraDeviceId: this.cameraList[b].cameraDeviceId,
                          cameraId: this.cameraList[b].cameraId,
                          channelId: '12'
                        }
                        //console.log(onPrams);
                        this.classService.onCamera(onPrams).subscribe((onCam: any) => {
                          //console.log(onCam);
                        });
                      } else { }
                      if (b + 1 === this.cameraList.length) {
                        this.cCService.getAllActiveCameraBySdc(this.centerAccessId).subscribe((allCam: any) => {
                          this.totalOnCamera = allCam.cameraDetails;
                        });
                        this.onStep = 'third';
                        localStorage.setItem('totalCamList', JSON.stringify(this.cameraList));
                        setTimeout(() => {
                          this.loader = false;
                          // this.cameraList.forEach(element => {
                          //   //console.log(element.cameraId);
                          //   jwplayer(element.cameraId).setup({
                          //     file: 'http://' + element.streamUrl + '/playlist.m3u8',
                          //     height: 500,
                          //     width: 500,
                          //   });
                          // });
                          this.finalCameraList = this.cameraList;
                        }, 2000);
                      }
                    }, 1000 * (b + 1));
                  }
                });
              }, 2000);
            }
            //console.log(i)
          }
        });
      } else {
        //console.log('vvvv')
        this.cameraList = JSON.parse(localStorage.getItem('totalCamList'));
        console.log(this.cameraList);
        this.cCService.getAllActiveCameraBySdc(this.centerAccessId).subscribe((allCam: any) => {
          this.totalOnCamera = allCam.cameraDetails;
        });
        setTimeout(() => {
          this.loader = false;
          // this.cameraList.forEach(element => {
          //   //console.log(element.cameraId);
          //   jwplayer(element.cameraId).setup({
          //     file: 'http://' + element.streamUrl + '/playlist.m3u8',
          //     height: 500,
          //     width: 500,
          //   });
          // });
          this.finalCameraList = this.cameraList;
        }, 2000);
      }
    })
  }

  /* ===============================================================
    Video Zoom In
  =============================================================== */
  onClickVideoZoom(data) {
    console.log(data)
    this.selectVidForZoom = data.cameraDeviceId;
    //console.log(data)
    this.selectClass = data;

    this.centerService.allCourse(data.centerAccessId).subscribe((data:any)=>{
      this.allCourse = data.courseDetails;
      //console.log(this.allCourse);
    });
  }

  onClickVidZomClo() {
    this.selectVidForZoom = undefined;
  }

  /* ===============================================================
    Image Capture
  =============================================================== */
  captureImage() {
    this.disableBtn = 'click-disable';
    this.datetime = moment().format("YYYY-MM-DD HH:mm:s");
    const user = {
      user: "RSLDC",
      streamName: this.selectClass.cameraId,
      centerAccessId: this.selectClass.centerAccessId,
      classAccessId: this.selectClass.classAccessId,
      capturedDate: moment().format("YYYY-MM-DD"),
      capturedTime: moment().format("HH:mm"),
      createdBy: this.username,
      createdAt: this.datetime
    }
    console.log(user);
    this.classService.imageCapture(user).subscribe((data: any) => {
      console.log(data);
      this.capturedImageUrl = data.imagePath;
      this.captureImageSec = true;
      setTimeout(() => {
        this.disableBtn = '';
      }, 1000);
      setTimeout(() => {
        this.captureImageSec = false;     
      }, 3500);
    });
  }

  /* ===============================================================
    Video Recording Start
  =============================================================== */
  recordingStart() {
    const user = {
      user: "RSLDC",
      streamName: this.selectClass.cameraId,
    }
    console.log(user);
    
    this.classService.videoRecStart(user).subscribe((data: any) => {
      //console.log(data);
      if (data.success == 1) {
        this.startRecBtn = false;
        this.stopRecBtn = true;
        this.toastr.info('The maximum length of the video to record is 1 minute', 'Recording started');
        this.autoStopVidRec();
      } else { }
    });
  }

  recordingStart10() {
    const user = {
      user: "RSLDC",
      streamName: this.selectClass.cameraId,
    }
    this.classService.videoRecStart(user).subscribe((data: any) => {
      //console.log(data);
      if (data.success == 1) {
        this.startRecBtn10 = false;
        this.stopRecBtn10 = true;
        //this.toastr.info('The maximum length of the video to record is 1 minute', 'Recording started');
        this.autoStopVidRec10();
        console.log('rec start 10 min');
        
      } else { }
    });
  }

  /* ===============================================================
    Video Recording Stop
  =============================================================== */
  recordingStop() {
    this.datetime = moment().format("YYYY-MM-DD HH:mm:s");
    const user = {
      user: "RSLDC",
      streamName: this.selectClass.cameraId,
      centerAccessId: this.selectClass.centerAccessId,
      classAccessId: this.selectClass.classAccessId,
      recordedDate: moment().format("YYYY-MM-DD"),
      recordedTime: moment().format("HH:mm"),
      createdBy: this.username,
      createdAt: this.datetime
    }
    this.classService.videoRecStop(user).subscribe((data: any) => {
      //console.log(data);
      if (data.success == 1) {
        this.startRecBtn = true;
        this.stopRecBtn = false;
        this.toastr.info('You can check the recorded video in the history', 'Recording stopped');
        this.autoStopVidRec();
      } else { }
    });
  }

  recordingStop10() {
    this.datetime = moment().format("YYYY-MM-DD HH:mm:s");
    const user = {
      user: "RSLDC",
      streamName: this.selectClass.cameraId,
      centerAccessId: this.selectClass.centerAccessId,
      classAccessId: this.selectClass.classAccessId,
      recordedDate: moment().format("YYYY-MM-DD"),
      recordedTime: moment().format("HH:mm"),
      createdBy: this.username,
      createdAt: this.datetime
    }
    this.classService.videoRecStop(user).subscribe((data: any) => {
      //console.log(data);
      if (data.success == 1) {
        this.startRecBtn10 = true;
        this.stopRecBtn10 = false;
        this.toastr.info('You can check the recorded video in the history', 'Recording stopped');
        this.autoStopVidRec10();
      } else { }
    });
  }

  /* ===============================================================
    Video Auto Stop
  =============================================================== */
  autoStopVidRec() {
    setTimeout(() => {
      if (this.startRecBtn == false) {
        this.recordingStop();
        //console.log('time stop');
      } else {
        //console.log('alrdy stop');
      }
    }, 50000);
  }
  
  autoStopVidRec10() {
    setTimeout(() => {
      if (this.startRecBtn10 == false) {
        this.recordingStop10();
        //console.log('time stop');
        console.log('rec stop 10 min');

      } else {
        //console.log('alrdy stop');
      }
    }, 300000);
  }

  /* ===============================================================
    Video Auto Stop
  =============================================================== */
  attendance(course){
    const attenData = {
      userId: 'RSLDC',
      centerAccessId: course.centerAccessId,
      courseAccessId: course.accessId,
      cameraDeviceId: this.selectClass.cameraDeviceId
    }
    //console.log(attenData);
    this.centerService.attendanceStart(attenData).subscribe((atten:any) =>{
      //console.log(atten);
    });
  }

  ngOnInit() {
    this.fetchProfile();
  }

}
