import { Component, Input, Compiler } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { ClassService } from './services/classService/class.service';
import { AuthService } from './services/authService/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  @Input() comp: any
  accessId;
  currentComponent;
  getCamID;
  getDeviceID;

  constructor(
    private router: Router,
    private _compiler: Compiler,
    private route: ActivatedRoute,
    private bnIdle: BnNgIdleService,
    private authService: AuthService,
    private classService: ClassService
    ){

    this.bnIdle.startWatching(28800).subscribe((res) => {
      if(res) {
        if (localStorage.getItem('CameraID') != null) {
          this.getCamID = localStorage.getItem('CameraID');
          this.getDeviceID = localStorage.getItem('DeviceID');
          const offPrams = {
            cameraId: this.getCamID,
            cameraDeviceId: this.getDeviceID,
            channelId: '11'
          }
          this.classService.offCamera(offPrams).subscribe((offcam: any) => {
            //console.log(offcam);
            let key = 'UserInfo';
            this.accessId = localStorage.getItem(key);
            const accessId = {
              accessId: this.accessId,
            }
            this.authService.userLogout(accessId).subscribe((data) => {
              //console.log(data);
              this.router.navigate(['/autoLogout']);
              this._compiler.clearCache();
              setTimeout(() => {
                location.reload();
              }, 100);
              setTimeout(() => {
                let falshTest = 'FlashData';
                localStorage.setItem(falshTest, 'other');
              }, 5000);
            });
          });
        } else if(localStorage.getItem('UserInfo') != null)  {
          setTimeout(() => {
            let key = 'UserInfo';
            this.accessId = localStorage.getItem(key);
            const accessId = {
              accessId: this.accessId,
            }
            this.authService.userLogout(accessId).subscribe((data) => {
              //console.log(data);
              this.router.navigate(['/autoLogout']);
              this._compiler.clearCache();
              setTimeout(() => {
                location.reload();
              }, 100);
            });
          }, 4000);
        }else{}
      }
    })
  }
}
