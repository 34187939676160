import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import * as moment from 'moment';

import { AuthService } from 'src/app/services/authService/auth.service';
import { AdminServiceService } from 'src/app/services/adminService/admin-service.service';
@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {

  now;
  date;
  form;
  formVerify
  confmshow;
  show: boolean = false;
  accessId
  adminVerify;
  verify;
  profileEmail;
  profileDetailsSec = 'show';
  isAdminDetails;
  adminName;
  adminPhone;
  verifyPopup ='admin_create';
  userName
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private adminService: AdminServiceService
  ) {
    this.createFormVerify();
    this.createForm();
  }

  /* ===============================================================
      Validation
  =============================================================== */
  createForm() {
    this.form = this.formBuilder.group({
      password: [null, [Validators.required, Validators.pattern("^.{5,15}$")]],
      confirm: ['', [Validators.required, Validators.pattern("^.{5,15}$")]],
      contactPerson: [null, [Validators.required, Validators.pattern("^.{3,40}$")]],
      mobile: [null, Validators.compose([Validators.required, Validators.pattern("^\\d{10,10}$")])],
    }, { validator: this.matchingPasswords('password', 'confirm') });
  }
  matchingPasswords(Password, Confirmpassword) {
    return (group: FormGroup) => {
      if (group.controls[Password].value === group.controls[Confirmpassword].value) {
        return null;
      } else {
        return { 'matchingPasswords': true }
      }
    }
  }

  createFormVerify() {
    this.formVerify = this.formBuilder.group({
      passwordVerify: [null, [Validators.required, Validators.pattern("^.{5,15}$")]],
      confirmVerify: ['', [Validators.required, Validators.pattern("^.{5,15}$")]]
    }, { validator: this.matchingPasswordsVerify('passwordVerify', 'confirmVerify') });
  }

  matchingPasswordsVerify(PasswordVerify, ConfirmpasswordVerify) {
    return (group: FormGroup) => {
      if (group.controls[PasswordVerify].value === group.controls[ConfirmpasswordVerify].value) {
        return null;
      } else {
        return { 'matchingPasswordsVerify': true }
      }
    }
  }

  /* ===============================================================
    Fetch Profile and Condition basic show & hide verify Section
  =============================================================== */
  fetchProfile() {
    let key = 'UserInfo';
    this.accessId = localStorage.getItem(key);
    this.authService.getProfile(this.accessId).subscribe((profile: any) => {
      this.profileEmail = profile.user.email;
      this.userName = profile.user.username;
      if (profile.user.roleId == 99) {
        this.adminVerify = '';
        this.verify = 'hide';
        this.adminService.adminProfileFetch(profile.user.accessId).subscribe((data:any)=>{
          //console.log(data);
          this.isAdminDetails = data.success;
          if(data.success == 0){
            this.profileDetailsSec = 'show';
            this.verifyPopup ='admin_create';
          }else{
            this.profileDetailsSec = 'hide';
            this.adminName = data.adminDetails.contactPerson;
            this.adminPhone = data.adminDetails.mobile;
            this.verifyPopup ='change-password ';
          }
        });
      } else {
        this.adminVerify = 'hide';
        this.verify = '';
      }
    });
  }

/* Profile Verify For TP & Center */
  verifyForm(){
    let key = 'UserInfo';
    this.accessId = localStorage.getItem(key);
    const user = {
      email: this.profileEmail,
      password: this.formVerify.get('passwordVerify').value,
      updatedBy: this.userName,
      updatedAt: moment().format('YYYY-MM-DD HH:mm:ss')
    }
    this.authService.updatePassword(user).subscribe((data:any)=>{
      this.formVerify.reset();
      this.router.navigate(['/dashboard']);
    })
  }

  /* click event function toggle */
  password() {
    this.show = !this.show;
  }
  confomPassword() {
    this.confmshow = !this.confmshow
  }

  /* Profile Verify For Admin */
  adminProfileVerify(){
    let key = 'UserInfo';
    this.accessId = localStorage.getItem(key);
    const user = {
      accessId: this.profileEmail,
      password: this.form.get('password').value,
      updatedBy: this.userName,
      updatedAt: moment().format('YYYY-MM-DD HH:mm:ss')
    }
    //console.log(user);
    this.authService.updatePassword(user).subscribe((data:any)=>{
      //console.log(data);
      if(this.isAdminDetails == 0){
        this.adminDetilsSave();
        //console.log('admin details submit');
      }else{
        //console.log('admin details not submit');
        this.form.reset();
        this.router.navigate(['/dashboard']);
      }

    })
  }
  /* Admin Details Save */
  adminDetilsSave(){
    const user ={
      accessId: this.accessId,
      contactPerson: this.form.get('contactPerson').value,
      mobile: this.form.get('mobile').value,
      email: this.profileEmail
  }
  this.adminService.adminDetils(user).subscribe(data =>{
    //console.log(data);
    this.form.reset();
    this.router.navigate(['/dashboard']);
  })
}

  /* ===============================================================
      Back Function
  =============================================================== */
  back() {
    let key = 'UserInfo';
    this.accessId = localStorage.getItem(key);
    const accessId = {
      accessId: this.accessId,
    }
    this.authService.userLogout(accessId).subscribe((data) => {
      this.router.navigate(['/']);
    });
  }

  /* ===============================================================
      JavaScript Load 
  =============================================================== */
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/js/common.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  ngOnInit() {
    this.fetchProfile();
    this.loadScript();
  }

}
