import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'schemeSer'
})
export class SchemeSerPipe implements PipeTransform {

  transform(allScheme: any, search: any): any {
    if (!allScheme || !search) {
      return allScheme;
    }
    return allScheme.filter(scheme =>
      scheme.schemeName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      scheme.schemeCode.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }

}
