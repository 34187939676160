import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';

@Component({
  selector: 'app-resource-report',
  templateUrl: './resource-report.component.html',
  styleUrls: ['./resource-report.component.css']
})
export class ResourceReportComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private centerService: CenterDetailsService
  ) { }

  ngOnInit() {
    console.log(this.route.snapshot.paramMap.get('accessId'));
    this.centerService.getSingleResDetails(this.route.snapshot.paramMap.get('accessId')).subscribe((res:any) =>{
      console.log(res);
      
    })
    
  }

}
