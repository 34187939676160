import { Component, OnInit, Compiler } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js';

import { CenterDetailsService } from '../../services/centerService/center-details.service'
import * as moment from 'moment';
import { AuthService } from 'src/app/services/authService/auth.service';

@Component({
  selector: 'app-center-dashboard',
  templateUrl: './center-dashboard.component.html',
  styleUrls: ['./center-dashboard.component.css']
})
export class CenterDashboardComponent implements OnInit {

  now;
  time;
  wishMessage;
  date;
  roleId;
  usertype
  adminNav: boolean;
  centerNav: boolean;
  piaNav: boolean;
  centerAccessId;
  centerDetails;
  centerName;
  centerCode;
  LineChart: any[];
  flashSet;
  flash: boolean;
  todayDate;
  allCount;
  centerLocation;
  centerDistrict;

  gaugeType = "arch";
  gaugesize = "100";
  gaugeAppendText = "%";
  gaugeCap = 'round';
  gaugeThick = '4';

  thresholdConfig = {
    '0': { color: '#ff435f' },
    '50': { color: '#d35400' },
    '75.5': { color: '#27ae60' }
  };
  teacherGaugeValue = 100;
  studentGaugeValue = 100;
  employeeGaugeValue = 100;
  
  teacherGauge: boolean = false;
  studentGauge: boolean = false;
  employeeGauge: boolean = false;
  studentAttenData;
  teacherAttenData;
  employeeAttenData
  centerType;
  allBatch;
  batchAccessId;
  batchCode;
  count = 0;
  totalBatch;
  upBtn:boolean= false;
  downBtn:boolean = true
  wishMessageCss;
  batchFound:boolean;

  constructor(
    private router: Router,
    private _compiler: Compiler,
    private route: ActivatedRoute,
    private authService: AuthService,
    private centerService: CenterDetailsService
  ) { }

  /* ===============================================================
      Center Details Fetch
  =============================================================== */
  centerDetailsFetch() {
    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      this.roleId = profile.user.roleId;
      //console.log(this.roleId);
      
      if (this.roleId == 77) {
        let key = 'UserInfo';
        this.centerAccessId = localStorage.getItem(key);
      } else {
        let centerAccessId = 'UserInfoCenterAccessID';
        this.centerAccessId = localStorage.getItem(centerAccessId);
      }
      //console.log(this.centerAccessId);
      this.centerService.getCenter(this.centerAccessId).subscribe((centerDetail: any) => {
        this.centerDetails = centerDetail.centerDetails;
        this.centerName = this.centerDetails.centerName;
        this.centerType = this.centerDetails.centerType;
        this.centerLocation = this.centerDetails.location;
        this.centerDistrict = this.centerDetails.districtName;
        this.centerCode = this.centerDetails.centerCode;
        //console.log(centerDetail);
      });
      /*Count Fetch */
      this.now = moment(); // add this 2 of 4
      this.todayDate = this.now.format('YYYY-MM-DD');
      this.centerService.getCentMainCount(this.centerAccessId, this.todayDate).subscribe((data: any) => {
        //console.log(data);
        this.allCount = data;
      });
      this.centerService.getAllBatchByCenter(this.centerAccessId).subscribe((allBatch: any) => {
        //console.log(allBatch);
        this.allBatch = allBatch.batchDetails.sort((a, b) => a.batchCode > b.batchCode ? 1 : -1);
        if(this.allBatch.length == 0){
          //console.log('no batch found')
          this.batchFound= true;
          this.studentGauge = true;
          this.downBtn = false;
          setTimeout(() => {
            this.studentGaugeValue = 0;
          }, 2000);
        }else if(this.allBatch.length == 1){
          this.downBtn = false;
          this.batchFound= false;
          this.totalBatch = this.allBatch.length -1;
          //console.log(this.totalBatch);
          this.batchAccessId = this.allBatch[this.count].accessId;
          this.batchCode = this.allBatch[this.count].batchCode;
          //console.log(this.batchAccessId);
          this.centerService.getAttendanceDataCountStudentByBatch(this.centerAccessId, this.batchAccessId, this.todayDate).subscribe((studentData: any) => {
            this.studentAttenData = studentData;
            //console.log(this.studentAttenData);
            if (this.studentAttenData.attenPercentage != null) {
              this.studentGauge = true;
              setTimeout(() => {
                this.studentGaugeValue = this.studentAttenData.attenPercentage.toFixed();
              }, 2000);
            } else {
              this.studentGauge = true;
              setTimeout(() => {
                this.studentGaugeValue = 0;
              }, 2000);
            }
          });
        }else {
          this.batchFound= false;
          this.totalBatch = this.allBatch.length -1;
          //console.log(this.totalBatch);
          this.batchAccessId = this.allBatch[this.count].accessId;
          this.batchCode = this.allBatch[this.count].batchCode;
          //console.log(this.batchAccessId);
          this.centerService.getAttendanceDataCountStudentByBatch(this.centerAccessId, this.batchAccessId, this.todayDate).subscribe((studentData: any) => {
            this.studentAttenData = studentData;
            //console.log(this.studentAttenData);
            if (this.studentAttenData.attenPercentage != null) {
              this.studentGauge = true;
              setTimeout(() => {
                this.studentGaugeValue = this.studentAttenData.attenPercentage.toFixed();
              }, 2000);
            } else {
              this.studentGauge = true;
              setTimeout(() => {
                this.studentGaugeValue = 0;
              }, 2000);
            }
          });
        }
      });

      // this.centerService.getAttendanceDataCountStudent(this.centerAccessId, this.todayDate).subscribe((studentData: any) => {
      //   //console.log(studentData);
      //   this.studentAttenData = studentData;
      //   if (this.studentAttenData.attenPercentage != null) {
      //     this.studentGaugeValue = this.studentAttenData.attenPercentage.toFixed();
      //     this.studentGauge = true;
      //   } else {
      //     this.studentGaugeValue = 0;
      //     this.studentGauge = true;
      //   }
      // });
      this.centerService.getAttendanceDataCountTeacher(this.centerAccessId, this.todayDate).subscribe((teacherData: any) => {
        //console.log(teacherData);
        this.teacherAttenData = teacherData;
        if (this.teacherAttenData.attenPercentage != null) {
          this.teacherGauge = true;
          setTimeout(() => {
            this.teacherGaugeValue = this.teacherAttenData.attenPercentage.toFixed();
          }, 2000);

        } else {
          this.teacherGauge = true;
          setTimeout(() => {
            this.teacherGaugeValue = 0;
          }, 2000);

        }
      });
      this.centerService.getAttendanceDataCountEmployee(this.centerAccessId, this.todayDate).subscribe((employeeData: any) => {
        //console.log(employeeData);
        this.employeeAttenData = employeeData;
        if (this.employeeAttenData.attenPercentage != null) {
          this.employeeGauge = true;
          setTimeout(() => {
            this.employeeGaugeValue = this.employeeAttenData.attenPercentage.toFixed();
          }, 2000);

        } else {
          this.employeeGauge = true;
          setTimeout(() => {
            this.employeeGaugeValue = 0;
          }, 2000);

        }
      });
    });
  }

  up(){
    this.count --;
    if(this.count == 0){
      this.upBtn = false
    }else{
      this.upBtn = true;
    }
    if(this.totalBatch <= this.count){
      this. downBtn = false;
    }else{
      this.downBtn = true
    }
    this.batchCode = this.allBatch[this.count].batchCode;
    this.batchAccessId = this.allBatch[this.count].accessId;
    //console.log(this.batchAccessId);
    this.centerService.getAttendanceDataCountStudentByBatch(this.centerAccessId, this.batchAccessId, this.todayDate).subscribe((studentData: any) => {
      this.studentAttenData = studentData;
      //console.log(this.studentAttenData)
      if (this.studentAttenData.attenPercentage != null) {
        this.studentGaugeValue = this.studentAttenData.attenPercentage.toFixed();
        this.studentGauge = true;
      } else {
        this.studentGaugeValue = 0;
        this.studentGauge = true;
      }
    });
  }

  down(){
    this.count ++;
    //console.log(this.count)
    if(this.count == 0){
      this.upBtn = false
    }else{
      this.upBtn = true;
    }
    if(this.totalBatch <= this.count){
      this. downBtn = false;
    }else{
      this.downBtn = true
    }
    this.batchCode = this.allBatch[this.count].batchCode;
    this.batchAccessId = this.allBatch[this.count].accessId;
    //console.log(this.batchAccessId);
    this.centerService.getAttendanceDataCountStudentByBatch(this.centerAccessId, this.batchAccessId, this.todayDate).subscribe((studentData: any) => {
      this.studentAttenData = studentData;
      //console.log(this.studentAttenData)
      if (this.studentAttenData.attenPercentage != null) {
        this.studentGaugeValue = this.studentAttenData.attenPercentage.toFixed();
        this.studentGauge = true;
      } else {
        this.studentGaugeValue = 0;
        this.studentGauge = true;
      }
    });
  }

  onClickLogout(){
    let key = 'UserInfo';
    const accessId = {
      accessId: localStorage.getItem(key),
    }
    this.authService.userLogout(accessId).subscribe((data) => {
      //console.log(data);
      this._compiler.clearCache();
      this.router.navigate(['/']);
      localStorage.clear()
      setTimeout(() => {
        location.reload();
      }, 100);
    });
  }

  onClickBack(){
    this.router.navigate(['/piaDashboard/u/', 0]);
    this.route.params.subscribe(params => {
      this.usertype = params.userType;
      //console.log(this.usertype);
    });
    localStorage.removeItem('ClassAccessId');
    localStorage.removeItem('ClassAccessIdHis');
    localStorage.removeItem('CourseAccessIDAttendance');
  }

  ngOnInit() {
    this.centerDetailsFetch();
    this.now = moment(); // add this 2 of 4
    this.date = this.now.format('Do MMMM YYYY');

    this.time = this.now.format('HH');
    //console.log(this.time);
    if (this.time == '00' || this.time == '01' || this.time == '02' || this.time == '03' || this.time == '04' || this.time == '05') {
      this.wishMessage = 'Good Mornigng';
      this.wishMessageCss = 'morning';
    } else if (this.time == '06' || this.time == '07' || this.time == '08' || this.time == '09' || this.time == '10' || this.time == '11') {
      this.wishMessage = 'Good Morning';
      this.wishMessageCss = 'morning';
    } else if (this.time == '12' || this.time == '13' || this.time == '14' || this.time == '15' || this.time == '16') {
      this.wishMessage = 'Good Afternoon';
      this.wishMessageCss = 'afternoon';
    } else if (this.time == '17' || this.time == '18' || this.time == '19' || this.time == '20' || this.time == '21' || this.time == '22' || this.time == '23' || this.time == '24') {
      this.wishMessage = 'Good Evening';
      this.wishMessageCss = 'evening';
    } else { }

    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      this.roleId = profile.user.roleId;
    });
  }

}
