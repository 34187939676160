import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../authService/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CenterDetailsService {

  options;
  domain = this.authService.domain;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  /* ===============================================================
      Authentication Header
  =============================================================== */
  createAuthenticationHeaders() {
    this.authService.loadToken();
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'authorization': this.authService.authToken
      })
    }
  }

  /* ===============================================================
      Create Center
  =============================================================== */
  createCenter(data) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'centerDetail/createNewCenter', data, this.options);
  }

  /* ===============================================================
      Remove Center
  =============================================================== */
  removeCenter(data) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'centerDetail/removeCenter', data, this.options);
  }


  /* ===============================================================
      Update Center
  =============================================================== */
  updateCenter(data) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'centerDetail/updateCenterDet', data, this.options);
  }

  editUpdateCenter(data) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'centerDetail/updateSdcAllDetails', data, this.options);
  }

  /* ===============================================================
      Get Center Details
  =============================================================== */
  getCenter(accessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'tpDetail/getOneCenter/' + accessId, this.options);
  }

  /* ===============================================================
      Get All Center Details
  =============================================================== */
  getAllCenter(piaAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'tpDetail/getAllCenter/' + piaAccessId, this.options);
  }

  /* ===============================================================
      Get All Center By Scheme
  =============================================================== */
  getAllCenterByScheme(accessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'tpDetail/getCentersBySchemeId/' + accessId , this.options);
  }

  /* ===============================================================
      Create Course
  =============================================================== */
  createCourse(courseData) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'courseDetail/createNewCourse/', courseData, this.options);
  }

  /* ===============================================================
      Create Course new format
  =============================================================== */
  createCourseNewFormat(courseData) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'centerDetail/sectorCourseAssignment', courseData, this.options);
  }

  /* ===============================================================
      Create sector and course assgin
  =============================================================== */
  createSecCourseAssgin(courseData) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'centerDetail/isCourseAssigned', courseData, this.options);
  }

  /* ===============================================================
      Update Course
  =============================================================== */
  updateCourse(courseData) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'courseDetail/updateCourseDet/', courseData, this.options);
  }

  /* ===============================================================
      Get All Course
  =============================================================== */
  allCourse(centerAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'courseDetail/getAllCourse/' + centerAccessId, this.options);
  }

  /* ===============================================================
      Get one Course
  =============================================================== */
  courseDetails(courseAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'courseDetail/getOneCourse/' + courseAccessId, this.options);
  }

  /* ===============================================================
      Create New Batch
  =============================================================== */
  createBatch(batchData) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'batchDetail/createNewBatch/', batchData, this.options);
  }

  /* ===============================================================
      Update Batch
  =============================================================== */
  updateBatch(batchData) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'batchDetail/updateBatchDet/', batchData, this.options);
  }

  /* ===============================================================
      Update Batch only hours and batch code
  =============================================================== */
  updateBatchCodeHour(batchData) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'batchDetail/updateBatchCode/', batchData, this.options);
  }

  /* ===============================================================
      Get All Batch
  =============================================================== */
  getAllBatch(courseAccessId, centerAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'batchDetail/getAllBatch/' + courseAccessId + '/' + centerAccessId, this.options);
  }

  /* ===============================================================
      Remove Batch
  =============================================================== */
  removeBatch(batchData) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'batchDetail/removeBatch/', batchData, this.options);
  }

  /* ===============================================================
    Get All Batch by Center
  =============================================================== */
  getAllBatchByCenter(centerAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'batchDetail/getAllBatchByCenter/' + centerAccessId, this.options);
  }

  /* ===============================================================
      Create Resources
  =============================================================== */
  createResoures(resouresData) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'resourceDetail/createNewRes/', resouresData, this.options);
  }

  /* ===============================================================
      Image Capture
  =============================================================== */
  imageCapture(resouresData) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'cameraDetail/resourceImageCapture', resouresData, this.options);
  }

  /* ===============================================================
      Capture Image Detele
  =============================================================== */
  imageDelete(deleteImage) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'cameraDetail/removeResourceImage', deleteImage, this.options);
  }

  azureImageDelete(deleteImage) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'attendanceDetail/deleteResourceAImage', deleteImage, this.options);
  }

  /* ===============================================================
      Get All Resources
  =============================================================== */
  allResources(centerAccessId, courseAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'resourceDetail/getAllResource/' + centerAccessId + '/' + courseAccessId, this.options);
  }

  /* ===============================================================
      Resources Available
  =============================================================== */
  resAvailable(sdcAccessId, resourcesAccessId){
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'resourceDetail/isResourceAvailable/' + sdcAccessId + '/'+ resourcesAccessId, this.options);
  }

  /* ===============================================================
      Get All Verify Resources
  =============================================================== */
  allVerifyResourcess(centerAccessId, courseAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'resourceDetail/getVerifiedResource/' + centerAccessId + '/' + courseAccessId, this.options);
  }

  /* ===============================================================
      Get All Resources
  =============================================================== */
  allResourcesCount(centerAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'resourceDetail/getAllResourcesCount/' + centerAccessId, this.options);
  }

  /* ===============================================================
      Get All Teacher
  =============================================================== */
  allStudents(centerAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'resourceDetail/getAllStudents/' + centerAccessId, this.options);
  }

  /* ===============================================================
      Get All Teacher
  =============================================================== */
  allTeachers(centerAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'resourceDetail/getAllTeachers/' + centerAccessId, this.options);
  }

  /* ===============================================================
      Get All Employee
  =============================================================== */
  allEmployee(centerAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'resourceDetail/getAllEmployee/' + centerAccessId, this.options);
  }

  /* ===============================================================
      Get Course Basic Resources
  =============================================================== */
  courseBasicResources(centerAccessId, courseAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'resourceDetail/getResources/' + centerAccessId + '/' + courseAccessId, this.options);
  }

  /* ===============================================================
      Get Course Basic batch
  =============================================================== */
  batchBasicResources(centerAccessId, batchAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'resourceDetail/getResourcesByBatch/' + centerAccessId + '/' + batchAccessId, this.options);
  }

  /* ===============================================================
      Get  Enrollment Details
  =============================================================== */
  enrollmentDetails(centerAccessId, courseAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'attendanceDetail/getEnrollmentDetails/' + centerAccessId + '/' + courseAccessId, this.options);
  }

  /* ===============================================================
      Enrollment Class and Course
  =============================================================== */
  enrollmentCC(resouresData) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'attendanceDetail/enrollCC', resouresData, this.options);
  }

  /* ===============================================================
      Un Enroll Class and Course
  =============================================================== */
  unEnrollmentCC(resouresData) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'attendanceDetail/unEnrollCC', resouresData, this.options);
  }

  /* ===============================================================
      Enrollment Person
  =============================================================== */
  enrollmentStudent(resouresData) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'attendanceDetail/enrollStudents', resouresData, this.options);
  }

  /* ===============================================================
      Enrollment Person
  =============================================================== */
  enrollResourceForcefully(resouresData) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'attendanceDetail/enrollResourceForcefully', resouresData, this.options);
  }

  /* ===============================================================
    UnEnrollment Person
  =============================================================== */
  unEnrollmentStudent(resouresData) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'attendanceDetail/unEnrollPerson', resouresData, this.options);
  }

  /* ===============================================================
      Reject Student
  =============================================================== */
  rejectStudent(resouresData) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'attendanceDetail/rejectStudents', resouresData, this.options);
  }

  /* ===============================================================
      Get UnEnrollment Details
  =============================================================== */
  unEnrollmentClassCen(data) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'attendanceDetail/unEnrollCC', data, this.options);
  }

  /* ===============================================================
      Attendance by course
  =============================================================== */
  attendanceByCourse(centerAccessId, courseAccessId, capturedDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'attendanceDetail/getAttendanceByCourse/' + centerAccessId + '/' + courseAccessId + '/' + capturedDate, this.options);
  }

  /* ===============================================================
      Attendance by batch
  =============================================================== */
  attendanceByBatch(centerAccessId, batchAccessId, capturedDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'attendanceDetail/getAttendanceByBatch/' + centerAccessId + '/' + batchAccessId + '/' + capturedDate, this.options);
  }

  /* ===============================================================
      Attendance for today
  =============================================================== */
  attendanceByToday(centerAccessId, capturedDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'attendanceDetail/getAttendanceByDate/' + centerAccessId + '/' + capturedDate, this.options);
  }

  /* ===============================================================
      Attendance for today Res
  =============================================================== */
  attendanceByTodayForRes(centerAccessId, resourceAccessId, capturedDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'attendanceDetail/getAttendanceTimes/' + centerAccessId + '/' + resourceAccessId + '/' + capturedDate, this.options);
  }

  /* ===============================================================
      Attendance Count for today
  =============================================================== */
  attendanceCount(centerAccessId, capturedDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getAttendanceDataCount/' + centerAccessId + '/' + capturedDate, this.options);
  }

  /* ===============================================================
      Inspect Stream
  =============================================================== */
  inspectStream(inspectdata) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'attendanceDetail/inspectStream', inspectdata, this.options);
  }

  /* ===============================================================
      Get All Image For history  ****YYYY-MM-DD
  =============================================================== */
  imageHistory(classAccessId, capturedDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'cameraDetail/getAllImages/' + classAccessId + '/' + capturedDate, this.options);
  }

  
  /* ===============================================================
      Get All Image For history for Comandcenter
  =============================================================== */
  imageAll(capturedDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'cameraDetail/getAllImagesForHistory/' + capturedDate, this.options);
  }

  /* ===============================================================
      Get All Image by TP For history for Comandcenter
  =============================================================== */
  imageAllByTp(tpAccessId, capturedDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'cameraDetail/getAllImagesByTp/' + tpAccessId + '/' + capturedDate, this.options);
  }

  /* ===============================================================
      Get All Image by SDC For history for Comandcenter
  =============================================================== */
  imageAllBySdc(sdcAccessId, capturedDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'cameraDetail/getAllImagesByCenter/' + sdcAccessId + '/' + capturedDate, this.options);
  }

  /* ===============================================================
      Get All Video by SDC For history for Comandcenter
  =============================================================== */
  videoAllBySdc(sdcAccessId, capturedDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'cameraDetail/getAllVideosByCenter/' + sdcAccessId + '/' + capturedDate, this.options);
  }


  /* ===============================================================
      Get All Video by TP For history for Comandcenter
  =============================================================== */
  videoAllByTp(tpAccessId, capturedDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'cameraDetail/getAllVideosByTp/' + tpAccessId + '/' + capturedDate, this.options);
  }

  /* ===============================================================
      Get All Video For history  ****YYYY-MM-DD
  =============================================================== */
  videoHistory(classAccessId, recordedDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'cameraDetail/getAllVideos/' + classAccessId + '/' + recordedDate, this.options);
  }

    /* ===============================================================
      Get All Video For history for Comandcenter
  =============================================================== */
  videoAll(capturedDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'cameraDetail/getAllVideosForHistory/' + capturedDate, this.options);
  }

  /* ===============================================================
     Center Dashboard All Count
 =============================================================== */
  getCentMainCount(centerAccessId, today) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getCESideCount/' + centerAccessId + '/' + today, this.options);
  }

  /* ===============================================================
     Center Dashboard All Count
 =============================================================== */
  getAllDistrict() {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'tpDetail/getAllDistrict', this.options);
  }

  /* ===============================================================
    Get Student Attendance Count Data
=============================================================== */
  getAttendanceDataCountStudent(itiAccessId, todayDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getAttendanceDataCountStudent/' + itiAccessId + '/' + todayDate, this.options);
  }

  /* ===============================================================
      Get Teacher Attendance Count Data
  =============================================================== */
  getAttendanceDataCountTeacher(itiAccessId, todayDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getAttendanceDataCountTeacher/' + itiAccessId + '/' + todayDate, this.options);
  }

  /* ===============================================================
      Get Employee Attendance Count Data
  =============================================================== */
  getAttendanceDataCountEmployee(itiAccessId, todayDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getAttendanceDataCountEmployee/' + itiAccessId + '/' + todayDate, this.options);
  }

  /* ===============================================================
      Get Employee Attendance Count Data
  =============================================================== */
  getAttendanceDataCountStudentByBatch(itiAccessId, batchAccessId, todayDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getAttendanceDataCountStudentByBatch/' + itiAccessId + '/' + batchAccessId + '/' + todayDate, this.options);
  }

  /* ===============================================================
      Get All Image by Center
  =============================================================== */
  getAllImageByCenter(centerAccessId, todayDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'cameraDetail/getAllImagesByCenter/' + centerAccessId + '/' + todayDate, this.options);
  }

  /* ===============================================================
      Get All Image by Center
  =============================================================== */
  getAllVideoByCenter(centerAccessId, todayDate) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'cameraDetail/getAllVideosByCenter/' + centerAccessId + '/' + todayDate, this.options);
  }

  /* ===============================================================
      Check Camera status
  =============================================================== */
  camStatus(inspectdata) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'cameraManagement/setCameraStat', inspectdata, this.options);
  }

  /* ===============================================================
      Check Camera speed
  =============================================================== */
  camSpeed(inspectdata) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'cameraManagement/storeCameraSpeed', inspectdata, this.options);
  }

  /* ===============================================================
      Resources Profile image set
  =============================================================== */
  resouresProImageUpload(profileImage) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'resourceDetail/assignProfileImagePath', profileImage, this.options);
  }

  /* ===============================================================
     Resource basic image list
  =============================================================== */
  resImageList(iticcessId, resourceAccessId) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'resourceDetail/getResImage/' + iticcessId + '/' + resourceAccessId, this.options);
  }

  /* ===============================================================
      Mail send
  =============================================================== */
  sendMail(imageData) {
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'cameraDetail/sendAttachments', imageData, this.options);
  }

  /* ===============================================================
  Report Api
=============================================================== */
  /* Report By Scheme */
  reportByScheme(schemeAccessId, date) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getReportByScheme/' + schemeAccessId + '/' + date, this.options);
  }

  /* Report by Scheme and District */
  reportBySchemeNDist(schemeAccessId, date) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getReportByDistrict/' + schemeAccessId + '/' + date, this.options);
  }

  /* Report by Scheme, District and Pia */
  reportBySchemeNDistNTp(schemeAccessId, piaAccessId, date) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getReportByTp/' + schemeAccessId + '/' + piaAccessId + '/'+ date, this.options);
  }

  /* Report by Scheme, District, Pia and Center */
  reportBySchemeNDistNTpNSdc(schemeAccessId, piaAccessId, sdcAccessId, date) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getReportBySdc/' + schemeAccessId + '/' + piaAccessId + '/' + sdcAccessId + '/' + date, this.options);
  }

  /* Report by Center */
  reportByCenter(sdcAccessId, date) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getReportByOnlyCenter/' + sdcAccessId + '/' + date, this.options);
  }

  /* Report by Center and Course */
  reportByCenterNCourse(sdcAccessId, courseAccessId, date) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getReportByCourse/' + sdcAccessId + '/' + courseAccessId + '/' + date, this.options);
  }

  /* Report by Center, Course and batch */
  reportByCenterNCourseNBatch(sdcAccessId, courseAccessId, batchAccessId, date) {
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'countDetail/getReportByBatch/' + sdcAccessId + '/' + courseAccessId + '/' + batchAccessId + '/' + date, this.options);
  }


  /* ===============================================================
     RSLDC all camera
  =============================================================== */
  getAllCamDet(){
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'cameraDetail/getAllCamDet', this.options);
  }

  /* ===============================================================
    Attendance Start
  =============================================================== */
  attendanceStart(attendanceData){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'attendanceDetail/startRecogRsldc', attendanceData, this.options);
  }

  /* ===============================================================
    Attendance Start
  =============================================================== */
  sdcActiveInactive(centerAccessId){
    this.createAuthenticationHeaders();
    return this.http.post(this.domain + 'centerDetail/setCenterStatus', centerAccessId, this.options);
  }

  /* ===============================================================
    Resource Single Detail
  =============================================================== */
  getSingleResDetails(resAccessId){
    this.createAuthenticationHeaders();
    return this.http.get(this.domain + 'resourceDetail/getSingleStudent/'+ resAccessId, this.options);
  }
}
