import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userUpdate'
})
export class UserUpdatePipe implements PipeTransform {

  transform(allUser: any, search: any): any {
    if (!allUser || !search) {
      return allUser;
    }
    return allUser.filter(alluser =>
      alluser.email.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      alluser.username.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }

}
