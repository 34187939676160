import { Component, OnInit, Compiler } from '@angular/core';
import { AuthService } from 'src/app/services/authService/auth.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AdminServiceService } from 'src/app/services/adminService/admin-service.service';
import * as moment from 'moment';import { CenterDetailsService } from 'src/app/services/centerService/center-details.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
;

@Component({
  selector: 'app-rsldc-res-list',
  templateUrl: './rsldc-res-list.component.html',
  styleUrls: ['./rsldc-res-list.component.css']
})
export class RsldcResListComponent implements OnInit {

  inputData;
  testing:boolean;
  allResouces;
  allDist;
  roleId;
  loader:boolean=true;
  form:FormGroup;
  formResId:FormGroup;
  editSec: boolean;
  selectReligion;
  userName;
  selectRes;
  disableBtn:boolean;
  selectResData=[];
  search;
  resIdUpdatedata;

  getAspirantId;
  getFatherName;
  getMotherName;
  getMobile;
  getDateOfBirth;
  getAge;
  getReligion;
  getCategory;
  getQualification;
  getSectors;
  getPhysicalDisa;
  getVillage;
  getBlockName;
  getNativeDistrict;
  getPanchayatName;
  getTehsilName;
  getPincode;

  constructor(
    private router: Router,
    private _compiler: Compiler,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthService,
    private adminService: AdminServiceService,
    private centerService: CenterDetailsService
  ) { 
    this.createForm();
    this.createFormIdUpdate();
  }

  /* ===============================================================
      Validation
  =============================================================== */
  createForm() {
    this.form = this.formBuilder.group({
      aspirantId: [null, [Validators.required, Validators.pattern("^.{3,20}$")]],
      fatherName: [null, [Validators.required]],
      motherName: [null, [Validators.required]],
      mobile: [null, Validators.compose([Validators.required, Validators.pattern("^\\d{10,10}$")])],
      age: [null, Validators.compose([Validators.required, Validators.pattern("^\\d{2,2}$")])],
      dob: [null, [Validators.required, Validators.pattern("^.{3,10}$")]],
      religion: [null, [Validators.required]],
      category: [null, [Validators.required]],
      education: [null, [Validators.required]],
      sector: [null, [Validators.required]],
      physicalDisa: [null, [Validators.required]],
      village: [null, [Validators.required]],
      block: [null, [Validators.required]],
      dist: [null, [Validators.required]],
      municipality: [null, [Validators.required]],
      tehsildar: [null, [Validators.required]],
      pinCode: [null, [Validators.required]],
    });
  }

  createFormIdUpdate() {
    this.formResId = this.formBuilder.group({
      resourcesId: [null, [Validators.required, Validators.pattern("^.{3,20}$")]],
    });
  }


  onChangeReligion(religion){

  }

  onChangeCategory(category){

  }

  onChangeEducation(education){

  }

  onChangeSector(sector){

  }


  onChangePhysical(physical){

  }


  onChangeVillage(village){

  }

  onChangeBlock(block){

  }

  onChangeDist(dist){

  }

  onChangeMunicipality(municipality){

  }

  onChangeTehsildar(tehsildar){

  }

  onChangePincode(pincode){

  }

  somethingChanged(data) {
    this.inputData = data
    if (data.length > 2) {
      this.testing = true;
    } else {
      this.testing = false
    }
  }

  onClickSecRes(res){
    //console.log(res);
    this.form.reset();
    this.selectRes = res;

    setTimeout(() => {
      this.editSec = true;
      if(res.isUpdated === "1"){
        this.getAspirantId = res.aspirantId;
        this.getFatherName = res.fatherName;
        this.getMotherName = res.motherName;
        this.getMobile = res.mobile;
        this.getDateOfBirth = res.dateOfBirth;
        this.getAge = res.age;
        this.getReligion = res.religion;
        this.getCategory = res.category;
        this.getQualification = res.qualification;
        this.getSectors = res.sectors;
        this.getPhysicalDisa = res.phsycalDisStat;
        this.getVillage = res.village;
        this.getBlockName = res.blockName;
        this.getNativeDistrict = res.nativeDistrict;
        this.getPanchayatName = res.panchayatName;
        this.getTehsilName = res.tehsilName;
        this.getPincode = res.pincode;
        this.toastr.info('This Resource alredy updated');
      }else if(res.isUpdated === "0"){
        this.getAspirantId = undefined;
        this.getFatherName = undefined;
        this.getMotherName = undefined;
        this.getMobile = undefined;
        this.getDateOfBirth = undefined;
        this.getAge = undefined;
        this.getReligion = undefined;
        this.getCategory = undefined;
        this.getQualification = undefined;
        this.getSectors = undefined;
        this.getPhysicalDisa = undefined;
        this.getVillage = undefined;
        this.getBlockName = undefined;
        this.getNativeDistrict = undefined;
        this.getPanchayatName = undefined;
        this.getTehsilName = undefined;
        this.getPincode = undefined;
      }else{}
    }, 1000);

  }

  onclickSubmit(){
    //console.log(this.form.get('dob').value);
    this.disableBtn = true;
    const resData ={
      accessId: this.selectRes.accessId,
      fatherName: this.form.get('fatherName').value, 
      motherName: this.form.get('motherName').value, 
      aspirantId: this.form.get('aspirantId').value, 
      mobile: this.form.get('mobile').value, 
      dateOfBirth: this.form.get('dob').value, 
      age:this.form.get('age').value, 
      religion: this.form.get('religion').value, 
      category: this.form.get('category').value, 
      qualification: this.form.get('education').value, 
      sectors: this.form.get('sector').value, 
      phsycalDisStat: this.form.get('physicalDisa').value,
      village: this.form.get('village').value, 
      blockName: this.form.get('block').value, 
      nativeDistrict: this.form.get('dist').value, 
      panchayatName: this.form.get('municipality').value, 
      tehsilName: this.form.get('tehsildar').value, 
      pincode: this.form.get('pinCode').value,
      updatedBy: this.userName,
      updatedAt: moment().format('YYYY-MM-DD HH:mm:ss')
    }
    //console.log(resData);
    this.adminService.resourceEdit(resData).subscribe((res:any) =>{
      //console.log(res);
      if(res.success === 1){
        this.disableBtn =  false;
        this.editSec = false;
        this.form.reset();
        this.search = '';
        this.testing = false;
        this.toastr.success('Resources Updated')
      }else{
        
      }

    });
  }

  onClickResIdUpdate(res){
    this.resIdUpdatedata = res;

  }

  onClickIdUpdate(){
    this.disableBtn = true;
    const resData ={
      accessId: this.resIdUpdatedata.accessId ,
      resourceCode: this.formResId.get('resourcesId').value
    }
    //console.log(resData);
    this.adminService.resourceIdUpdate(resData).subscribe((info:any) =>{
      //console.log(info);
      if(info.success === 1){
        this.disableBtn = false;
        this.resIdUpdatedata = undefined;
        this.formResId.reset();
        this.toastr.success('Resource id updated');
      }else{}

    });
  }

  onLogoutClick(){
    const accessId = {
      accessId: localStorage.getItem('UserInfo'),
    }
    this.authService.userLogout(accessId).subscribe((data) => {
      //console.log(data);
      this.router.navigate(['/']);
      this._compiler.clearCache();
      localStorage.clear();
      setTimeout(() => {
        location.reload();
      }, 100);
    });
  }

  onClickAddRes(){
    this.router.navigate(['addResources'])
  }

  /* ===============================================================
      JavaScript Load 
  =============================================================== */
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/js/common.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  ngOnInit() {
    this.loadScript();
    this.authService.getProfile(localStorage.getItem('UserInfo')).subscribe((profile: any) => {
      //console.log(profile);
      this.roleId = profile.user.roleId;
      this.userName = profile.user.username;
      this.centerService.getAllDistrict().subscribe((district:any) =>{
        //console.log(district);
        this.allDist = district.districtDetails
      });
      this.adminService.allResource().subscribe((resList:any) =>{
        //console.log(resList);
        this.allResouces = resList.resourceDetails;
        this.loader= false;
      });
    }); 
  }

}
